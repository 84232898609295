import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Div, ModuleContainer } from "../../styles/Common";
import { Link } from "../../components/Link";
import arrowLeft from "../../assets/icons/arrow-left.svg"
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import DownloadWhite from "../../assets/icons/Download-white.svg";
import { Content } from "./components/content";
import { FinancingIcon, SaleIcon, CalendarIcon, CoinsIcon } from "../../assets/icons";
import { theme } from "../../styles/theme";
import useGetFinancialModel from "../InvestorProjectDetail/hooks/useGetFinancialModel";
import { FinancialModelFields, formatNumber, formatToMillions, getUserInfo, unformatNumber } from "../../utilities/helpers";

export const FinancialModel = () => {
  const { t, i18n } = useTranslation("financialModel");
  const locale = i18n.language;
  const role = getUserInfo().role[0];
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isSuccess } = useGetFinancialModel(id);
  const [dashboardInfo, setDashboardInfo] = useState(FinancialModelFields);

  const handleBack = () => {
    navigate("/projects")
  };

  useEffect(() => {
    if (isSuccess) {
      setDashboardInfo({...FinancialModelFields, ...data?.data[0]});
    }
  }, [isSuccess, data]);

  const valueTypes = ["", "-", "#######", null, undefined, "NaN"];

  const formatValue = (value) => {
    if (valueTypes.includes(value)) {
      return "-";
    }
    return formatNumber(unformatNumber(value), locale);
  };
  
  const generalCharacteristics = [
    { label: t("size"), value: `${dashboardInfo?.projectTotalMW} MW` },
    { label: t("location"), value: `${dashboardInfo?.location}` },
    { label: t("production"), value: `${formatValue(dashboardInfo?.productionp50)} - ${formatValue(dashboardInfo?.productionp90)}` },
    { label: t("opex"), value: `${formatValue(dashboardInfo?.opexTotal)} €/MW` },
    { label: t("capex"), value: `${formatValue(dashboardInfo.capexTotal)} €/MW` },
  ];

  const dates = [
    { label: t("rtb"), value: `${dashboardInfo?.rtbDate}` },
    { label: t("cod"), value: `${dashboardInfo?.codDate}` },
  ];

  const originFunds = [
    { label: t("totalUses"), value: `${formatValue(dashboardInfo?.total_uses)} €` },
    { label: t("epc"), value: `${formatValue(dashboardInfo?.EPC)} €` },
    { label: t("otherCapex"), value: `${formatValue(dashboardInfo?.other_capex)}` },
    { label: t("preOperationalCosts"), value: `${formatValue(dashboardInfo?.pre_operational_costs)} €` },
    { label: t("upfrontFee"), value: `${formatValue(dashboardInfo?.upfront_fee)} €` },
    { label: t("advisors"), value: `${formatValue(dashboardInfo?.advisors)} €` },
    { label: t("agencyFee"), value: `${formatValue(dashboardInfo?.agency_fee)} €` },
    { label: t("swaptionFee"), value: `${formatValue(dashboardInfo?.swaption_fee)} €` },
    { label: t("financialExpenses"), value: `${formatValue(dashboardInfo?.financial_expenses)} €` },
    { label: t("dsraEndowment"), value: `${formatValue(dashboardInfo?.DSRA_endowment)} €` },
  ];

  const totals = [
    { label: t("totalSources"), value: `${formatValue(dashboardInfo?.total_sources)} €` },
    { label: t("equity"), value: `${formatValue(dashboardInfo?.equity)} €` },
    { label: t("seniorDebt"), value: `${formatValue(dashboardInfo?.senior_Debt)} €` },
    { label: t("juniorDebt"), value: `${formatValue(dashboardInfo?.junior_Debt)} €` },
  ];

  const interestRate = dashboardInfo?.fixed_variable !== "" && dashboardInfo?.interest
  ? dashboardInfo?.fixed_variable === "Variable"
    ? `${t('euribor')} + ${formatValue(dashboardInfo?.interest)}%`
    : `${t('fixed')} (${formatValue(dashboardInfo?.interest)}%)`
  : "-"

  const financing = [
    { 
      label: t("amount"),
      value: `${formatToMillions(dashboardInfo?.senior_Debt, locale)} €`,
      color: theme.colors.blue,
      icon: <CoinsIcon />
    },
    { 
      label: `${t("leveragePerMw")}:`,
      value: `${formatValue(dashboardInfo?.debt_installed_capacity)} €`,
      color: theme.colors.green,
      icon: <SaleIcon />
    },
    { 
      label: `${t("term")}:`,
      value: `${formatValue(formatValue(dashboardInfo?.year))} ${t("years")}`,
      color: theme.colors.gray300,
      icon: <CalendarIcon />
    },
    { 
      label: `${t("commissions")}:`,
      value: `${formatValue(dashboardInfo?.commitment_fee2)}%`,
      color: theme.colors.gray500,
      icon: <SaleIcon />
    },
    { 
      label: `${t("commitmentFee")}:`,
      value: `${formatValue(dashboardInfo?.commitment_fee)}%`,
      color: theme.colors.gray200,
      icon: <SaleIcon />
    },
    { 
      label: t("interestRate"),
      value: `${interestRate}`,
      color: theme.colors.blue,
      icon: <FinancingIcon fill={theme.colors.white} />
    },
  ];

  const sizingParameters = [
    { label: t("tenor"), value: `${formatValue(formatValue(dashboardInfo?.tenor_from_COD))} ${t("years")}` },
    { label: t("curve"), value: `${dashboardInfo?.curve}` },
    { label: t("%ppa"), value: `${formatValue(dashboardInfo?.percentagePPA)}%` },
    { label: t("ppaPrice"), value: `${formatValue(dashboardInfo?.agreementPPAPrice)}` },
    { label: t("production90"), value: `${formatValue(dashboardInfo?.productionp90)}` },
    { label: t("dscrMerchant"), value: `${formatValue(dashboardInfo?.DSCRForMerchant)}x` },
    { label: t("dscrPpa"), value: `${formatValue(dashboardInfo?.DSCRForPPA)}x` },
    { label: t("maximumLeverage"), value: `${formatValue(dashboardInfo?.leverage)}%` },
  ];


  const irr = [
    { 
      label: t("projectIrr"), 
      value: `${formatValue(dashboardInfo?.proyect_IRR)}%`,
      permission: true, 
    },
    { 
      label: t("equityIrr"), 
      value: `${formatValue(dashboardInfo?.equity_IRR)}%`, 
      permission: role === "Developer" || role === "Manager",
    },
    { 
      label: t("debtIrr"), 
      value: `${formatValue(dashboardInfo?.debt_IRR)}%`,
      permission: role === "Investor" || role === "Manager",
    },
  ];

  const images = {
    img1: dashboardInfo?.financialModelImg1,
    img2: dashboardInfo?.financialModelImg2,
    img4: dashboardInfo?.financialModelImg4,
  };

  const downloadModel = () => {
    const a = document.createElement("a");
    a.href = dashboardInfo?.financialModelFile;
    a.target = "_blank";
    a.setAttribute("download", `${t("financialModel")}.xlsx`);
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <ModuleContainer direction="column" padding="26px 69px 12px 32px">
      <Div justify="space-between">
        <Link icon={<img alt="arrow" src={arrowLeft} />} onClick={handleBack}>
          {t("back")}
        </Link>
        <Button
          width="272px"
          height="30px"
          icon={<img alt='download' src={DownloadWhite} width={"24px"} height={"24px"} />}
          onClick={downloadModel}
        >
          {t("downloadFinancialModel")}
        </Button>
      </Div>
      <Div direction="column" m="25px 0 0 0">
        <Content 
          source={"page"}
          generalCharacteristics={generalCharacteristics}
          dates={dates}
          originFunds={originFunds}
          totals={totals}
          financing={financing}
          sizingParameters={sizingParameters}
          irr={irr}
          images={images}
        />
      </Div>
    </ModuleContainer> 
  )
};
