import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetProjects = (payloadOptions) => {
  const data = useQuery(
    ["getProjects", payloadOptions],
    () => axiosClient.post(`Project/get-projects`, payloadOptions),
    { enabled: payloadOptions !== null }
  );

  return data;
};
export default useGetProjects;
