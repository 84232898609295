import styled from "styled-components";

export const ColorCard = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ background }) => `${background} 0% 0% no-repeat padding-box`};
  border-radius: 15px;
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  opacity: 1;
`;

export const BackgroundIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #FFFFFF34 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
`;