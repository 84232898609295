import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetStrategyByType = (type) => {
  const data = useQuery("getStrategyByType", () =>
    axiosClient.get(`strategy/by-type/${type}`)
  );

  return data;
};
export default useGetStrategyByType;
