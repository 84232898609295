import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetProjectsManager = (payloadOptions) => {
  const data = useQuery(
    ["getProjectsManager", payloadOptions],
    () => axiosClient.post(`project/get-projects-manager`, payloadOptions),
    { enabled: payloadOptions !== null }
  );

  return data;
};
export default useGetProjectsManager;