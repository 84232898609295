import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import { theme } from "../../../styles/theme";
import percent from "../../../assets/icons/percent.svg";
import { NonCall } from "../../../utilities/generalOptions";
import { FormatNumber, HandleChangeNumber } from "../../../utilities/numberFunctions/handleChange";

export const FinancingPrice = ({
  sourceForm,
  handleSelect,
  setSourceForm,
}) => {
  const { t, i18n } = useTranslation("strategies");
  const locale = i18n.language;

  return (
    <Row gutter={[32, 32]} style={{ width: '100%', marginTop: "32px" }}>
      <Col md={8}>
        <Input 
          id="financingOpeningFee"
          label={t("seniorDebtForm.upFrontFee")}
          value={FormatNumber(sourceForm.financingOpeningFee, locale)}
          onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="financingSpread"
          label={t("seniorDebtForm.margin")}
          value={FormatNumber(sourceForm.financingSpread, locale)}
          onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="makeWholeComission"
          label={t("seniorDebtForm.commitmentFee")}
          value={FormatNumber(sourceForm.makeWholeComission, locale)}
          onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.noCallPeriod")}
          placeholder={t("select")}
          options={NonCall}
          value={sourceForm.nonCallPeriod}
          onChange={(e) => handleSelect(e, "nonCallPeriod")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
    </Row>
  )
};

FinancingPrice.propTypes = {
  sourceForm: PropTypes.object,
  handleSelect: PropTypes.func,
  setSourceForm: PropTypes.func,
};