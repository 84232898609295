import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Text, Row, Col, Div } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Card } from "../../../components/Card";
import { SearchFillIcon } from "../../../assets/icons";
import { EmailFillIcon } from "../../../assets/icons";
import { useTranslation } from "react-i18next";
import useGetProjectRequests from "../hooks/useGetProjectRequests";
import { dateFormat } from "../../../utilities/helpers";

export const PendingRequest = ({
  setTabActiveKey,
  setPreSelectedCompany,
  setSearchClicked,
}) => {
  const { t } = useTranslation("scraper");

  const projectRequests = useGetProjectRequests();
  const { data, isSuccess } = projectRequests;
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setRequestList(data?.data?.data);
    }
  }, [data, isSuccess]);

  const handleSearch = (company) => {
    setPreSelectedCompany(company);
    setTabActiveKey("1");
    setSearchClicked(true);
  };

  return (
    <>
      <Row m="32px 0">
        <Text color={theme.colors.gray300}>{t("promotersAlter")}</Text>
      </Row>
      <Row
        style={{ display: "flex", flexWrap: "wrap" }}
        gap="24px"
        width="100%"
      >
        {requestList.map((item, index) => (
          <Card
            width="342px"
            height="105px"
            padding="14px 15px 19px 13px"
            key={index}
          >
            <Row gap="8px">
              <Col justify="center" align="center">
                <Div
                  background={theme.colors.gray100}
                  width="50px"
                  height="50px"
                  radius="50px"
                  align="center"
                  justify="center"
                >
                  <Text
                    color={theme.colors.blue}
                    weight={theme.fonts.weight.semibold}
                  >
                    {_.map(item.userName.split(" "), (name) =>
                      _.upperFirst(_.head(name))
                    )
                      .slice(0, 2)
                      .join("")}
                  </Text>
                </Div>
              </Col>
              <Col width="100%">
                <Row justify="space-between" align="end" m=" 0 0 2px 0">
                  <Text
                    color={theme.colors.gray300}
                    weight={theme.fonts.weight.medium}
                  >
                    {item.userName}
                  </Text>
                  <Div
                    width="32px"
                    height="32px"
                    radius="50px"
                    background={theme.colors.green}
                    align="center"
                    justify="center"
                  >
                    <SearchFillIcon
                      fill={theme.colors.white}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSearch(item.companyName)}
                    />
                  </Div>
                </Row>
                <Row align="center" gap="6px" m=" 0 0 5px 0">
                  <EmailFillIcon
                    width="24px"
                    height="24px"
                    fill={theme.colors.green}
                  />
                  <Text size={theme.fonts.size.sm} color={theme.colors.green}>
                    {item.userEmail}
                  </Text>
                </Row>
                <Row gap="4px">
                  <Text
                    color={theme.colors.gray200}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.xs}
                  >
                    {t("requestDate")}
                  </Text>
                  <Text color={theme.colors.gray200} size={theme.fonts.size.xs}>
                    {dateFormat(item.requestDateTime)}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Card>
        ))}
      </Row>
    </>
  );
};

PendingRequest.propTypes = {
  setTabActiveKey: PropTypes.func,
  setPreSelectedCompany: PropTypes.func,
  setSearchClicked: PropTypes.func,
};
