import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../../styles/Common";
import { Center } from "../styles";
import { theme } from "../../../../styles/theme";
import { Button } from "../../../../components/Button";
import ModalLoadProjects from "./modalLoadProjects";
import { ModalUploadNewProject } from "./modalUploadNewProject";
import { ModalValidationLoadProjects } from "./modalValidationLoadProjects";

export const EmptyProject = ({ isCreated, height }) => {
    const { t } = useTranslation(["projects", "common"]);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModalValidationLoadProjects, setModalValidationLoadProjects] = useState(false);
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal2 = () => {
        setShowModal2(true);
    };

    const handleCloseModal2 = () => {
        setShowModal2(false);
    };

    const handleShowModalValidationLoadProjects = () => {
        handleCloseModal();
        setModalValidationLoadProjects(true);
    };

    const handleShowModalUploadProject = () => {
        handleCloseModalValidationLoadProjects();
        setShowModal2(true);
    };

    const handleCloseModalValidationLoadProjects = () => setModalValidationLoadProjects(false);

    return (
        <Center height={height}>
            <Div direction="column" height="100%" gap="27px" justify="flex-end" align="center">
                <Text 
                    color={theme.colors.blue} 
                    size={theme.fonts.size.h5}
                    weight={theme.fonts.weight.medium} 
                    style={{ alignSelf: "center" }}
                >
                    {t("empty.emptyText")}
                </Text>
                {isCreated && (
                    <Div gap="32px" m="0 0 27px 0">
                        <Button 
                            width={"207px"}
                            variant={"outlined"}
                            onClick={handleShowModal2}
                        >
                            {t("empty.upProjectText")}
                        
                        </Button>
                        <Button 
                            width={"274px"} 
                            onClick={handleShowModal}
                        >
                            {t("empty.loadProjectText")}
                        </Button>
                    </Div>
                )}
                <Text size={theme.fonts.size.sm} color={theme.colors.gray300}>
                    {t("common:poweredBy")}
                </Text>
            </Div>
            {showModal && <ModalLoadProjects showModal={showModal} handleCloseModal={handleCloseModal}
                handleShowModalValidationLoadProjects={handleShowModalValidationLoadProjects} />}
            {showModal2 && <ModalUploadNewProject showModal={showModal2} handleCloseModal={handleCloseModal2} />}
            {showModalValidationLoadProjects &&
                <ModalValidationLoadProjects showModal={showModalValidationLoadProjects}
                    handleCloseModal={handleCloseModalValidationLoadProjects} 
                    handleShowModalUploadProject={handleShowModalUploadProject}/>
            }
        </Center>
    )
};

EmptyProject.propTypes = {
    isCreated: PropTypes.bool,
    height: PropTypes.string
};