import React, { useState, useEffect } from "react";
import { Text, Row, ModuleContainer, Div } from "../../styles/Common";
import { useNavigate } from "react-router-dom";
import { theme } from "../../styles/theme";
import { ArrowLeftIcon, DeleteIcon, UserAddIcon } from "../../assets/icons";
import { Card } from "../../components/Card";
import { Select } from "../../components/Select2";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { DrawerNewInvestmentGroup } from "../Users/components/DrawerNewInvestmentGroup";
import ModalSuccess from "../NewProject/components/ModalSuccess";
import { validateArrayInviteInvestor } from "../../utilities/validations";
import ModalInvitation from "./components/ModalInvitation";
import { useTranslation } from "react-i18next";
import { DrawerStrategies } from "./components/DrawerStrategies";
import useUserSendInvitationInvestor from "./hooks/useUserSendInvitationInvestor";
import toast from "react-hot-toast";
import useGetCompaniesList from "./hooks/useGetCompaniesList";

export const InviteInvestor = () => {
  const { t } = useTranslation("inviteInvestor");
  const navigate = useNavigate();
  const userSendInvitationInvestor = useUserSendInvitationInvestor();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerStrategies, setOpenDrawerStrategies] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalInvitation, setShowModalInvitation] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [errorCompanies, setErrorCompanies] = useState(false);

  const getCompaniesList = useGetCompaniesList();
  const { data, isSuccess } = getCompaniesList;

  const [companiesOptions, setCompaniesOptions] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setCompaniesOptions(
        data?.data?.map((item) => ({ label: item.name, value: item._id }))
      );
    }
  }, [data, isSuccess]);
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setFormDrawer(initialDrawerValues);
    setErrorFormDrawer(initialErrorForm);
  };
  const handleCloseDrawerStrategies = () => {
    setOpenDrawerStrategies(false);
  };

  const handleCloseShowModal = () => {
    setShowModal(false);
    setShowModalInvitation(false);
  };

  const initialDrawerValues = {
    nameCompany: "",
    asset: null,
    address: "",
    logo: null,
    document: null,
  };
  const [formDrawer, setFormDrawer] = useState(initialDrawerValues);

  const initialErrorForm = {
    nameCompany: { error: false, message: "" },
    asset: { error: false, message: "" },
    address: { error: false, message: "" },
    logo: { error: false, message: "" },
    document: { error: false, message: "" },
  };
  const [errorFormDrawer, setErrorFormDrawer] = useState(initialErrorForm);

  const [items, setItems] = useState([{ name: "", position: "", email: "" }]);

  const [errorForm, setErrorForm] = useState([
    {
      name: { error: false, message: "" },
      position: { error: false, message: "" },
      email: { error: false, message: "" },
    },
  ]);

  const handleDelete = (index) => {
    if (items.length > 1) {
      setItems(items.filter((item, idx) => idx !== index));
      setErrorForm(errorForm.filter((item, idx) => idx !== index));
    }
  };

  const handleAdd = () => {
    const nuevoItem = { name: "", position: "", email: "" };
    setItems([...items, nuevoItem]);
    const newErrorForm = {
      name: { error: false, message: "" },
      position: { error: false, message: "" },
      email: { error: false, message: "" },
    };
    setErrorForm([...errorForm, newErrorForm]);
  };

  const handleChange = (event, index) => {
    const { value, id } = event.target;

    const newErrorForm = [...errorForm];
    newErrorForm[index][id].error = false;
    newErrorForm[index][id].message = "";
    setErrorForm(newErrorForm);

    const newForm = [...items];
    newForm[index][id] = value;
    setItems(newForm);
  };

  const handleSelect = (value) => {
    setSelectedCompany(value);
    setErrorCompanies(false);
  };

  const handleSave = () => {
    const validation = validateArrayInviteInvestor.validate(items, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = [...errorForm];
      validation.error.details.forEach((detail) => {
        const index = detail?.path[0];
        const fieldName = detail?.path[1];
        newErrorForm[index][fieldName] = {
          error: true,
          message: t("field"),
        };
      });
      setErrorForm(newErrorForm);
    } else if (!selectedCompany) {
      setErrorCompanies(true);
    } else {
      userSendInvitationInvestor.reset();
      userSendInvitationInvestor.mutate(
        items.map((item) => ({
          name: item.name,
          position: item.position,
          email: item.email,
          companyId: selectedCompany,
        })),
        {
          onSuccess: () => {
            setShowModalInvitation(true);
          },
          onError: (err) => {
            toast.error(err?.response?.data?.result?.errorMessage);
          },
        }
      );
    }
  };

  return (
    <ModuleContainer padding="11px 40px 11px 32px" direction="column">
      <ModalInvitation
        showModal={showModalInvitation}
        handleCloseModal={handleCloseShowModal}
      />
      <ModalSuccess
        showModal={showModal}
        handleCloseModal={handleCloseShowModal}
        page={"users"}
      />
      <DrawerNewInvestmentGroup
        openDrawer={openDrawer}
        handleClose={handleCloseDrawer}
        setShowModal={setShowModal}
        form={formDrawer}
        setForm={setFormDrawer}
        errorForm={errorFormDrawer}
        setErrorForm={setErrorFormDrawer}
      />
      <DrawerStrategies
        openDrawer={openDrawerStrategies}
        handleClose={handleCloseDrawerStrategies}
        selectedCompany={selectedCompany}
      />
      <Row
        align="center"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/users?key=3")}
        m="0 0 36px 0"
      >
        <ArrowLeftIcon width="32px" height="32px" />
        <Text color={theme.colors.green} weight={theme.fonts.weight.medium}>
          {t("goBack")}
        </Text>
      </Row>
      <Card
        width="100%"
        height="auto"
        shadow="0px 7px 26px #0000000B"
        margin="0 0 39px 0"
        padding="36px 34px 46px 38px"
      >
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
          mb="16px"
        >
          {t("infoCompany")}
        </Text>
        <Text color={theme.colors.gray500} mb="32px">
          {t("info")}
        </Text>
        <Row align="center" gap="32px" m="0 0 40px 0">
          <Select
            label={t("company")}
            sizeLabel={theme.fonts.size.default}
            arrowColor={theme.colors.gray200}
            placeholder={t("select")}
            options={companiesOptions}
            helper={errorCompanies ? "Debe seleccionar compañia" : ""}
            error={errorCompanies}
            onChange={(value) => handleSelect(value)}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          />
          <Button
            width="209px"
            variant={"outlined"}
            m="25px 0 0 0"
            onClick={() => setOpenDrawer(true)}
          >
            {t("newGroup")}
          </Button>
        </Row>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
          mb="16px"
        >
          {" "}
          {t("investorCompany")}
        </Text>
        {items.map((item, index) => (
          <Row gap="24px" m="0 0 24px 0" key={index}>
            <Input
              width="30%"
              id="name"
              label={t("name")}
              sizeLabel={theme.fonts.size.default}
              value={items[index].name}
              onChange={(event) => handleChange(event, index)}
              error={errorForm[index].name.error}
              helper={
                errorForm[index].name.error ? errorForm[index].name.message : ""
              }
            />
            <Input
              width="30%"
              id="position"
              label={t("position")}
              sizeLabel={theme.fonts.size.default}
              value={items[index].position}
              onChange={(event) => handleChange(event, index)}
              error={errorForm[index].position.error}
              helper={
                errorForm[index].position.error
                  ? errorForm[index].position.message
                  : ""
              }
            />
            <Input
              width="30%"
              id="email"
              label={t("email")}
              sizeLabel={theme.fonts.size.default}
              value={items[index].email}
              onChange={(event) => handleChange(event, index)}
              error={errorForm[index].email.error}
              helper={
                errorForm[index].email.error
                  ? errorForm[index].email.message
                  : ""
              }
            />
            <Div
              background={theme.colors.green100}
              width="48px"
              height="48px"
              radius="50%"
              align="center"
              m="25px 0 0 0"
              justify="center"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(index)}
            >
              <DeleteIcon fill={theme.colors.gray300} />
            </Div>
          </Row>
        ))}

        <Row gap="24px">
          <Input
            width="29.5%"
            label={t("name")}
            sizeLabel={theme.fonts.size.default}
            colorLabel={theme.colors.gray100}
            border={theme.colors.gray50}
            value=""
          />
          <Input
            width="29.5%"
            label={t("position")}
            sizeLabel={theme.fonts.size.default}
            colorLabel={theme.colors.gray100}
            border={theme.colors.gray50}
            value=""
          />
          <Input
            width="29.5%"
            label={t("email")}
            sizeLabel={theme.fonts.size.default}
            colorLabel={theme.colors.gray100}
            border={theme.colors.gray50}
            value=""
          />
        </Row>
        <Row align="center" m="45px 0 44px 0">
          <UserAddIcon width="32px" height="32px" fill={theme.colors.green} />
          <Text
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.medium}
            color={theme.colors.green}
            onClick={handleAdd}
            style={{ cursor: "pointer" }}
          >
            {t("addInvestor")}
          </Text>
        </Row>
        <Row gap="16px" align="center">
          <Button
            width="177px"
            variant={"outlined"}
            onClick={() => navigate("/users?key=3")}
          >
            {t("cancel")}
          </Button>
          <Button
            width="177px"
            variant={"outlined"}
            onClick={() => setOpenDrawerStrategies(true)}
            disabled={!selectedCompany}
          >
            {t("strategies")}
          </Button>
          <Button
            width="177px"
            onClick={handleSave}
            loading={userSendInvitationInvestor.isLoading}
          >
            {t("inviteInvestor")}
          </Button>
        </Row>
      </Card>
      <Text
        size={theme.fonts.size.sm}
        color={theme.colors.gray300}
        align="center"
      >
        {t("alter")}
      </Text>
    </ModuleContainer>
  );
};
