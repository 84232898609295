import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Radio } from "../../../components/Radio";
import { useTranslation } from "react-i18next";

export const ProjectEngineering = ({
  engineeringInfo,
  setEngineeringInfo,
  handleRadio,
  disabled,
}) => {
  const { t } = useTranslation("infoPlants");

  return (
    <>
      <Row gap="42.5px" m="33.5px 0 0 0">
        <Col>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray200}
            align="left"
            mb="10px"
          >
            {t("updatedEngineering")}
          </Text>
          <Radio.Group
            name="updatedGenerationPlantProject"
            gap="48px"
            value={engineeringInfo.updatedGenerationPlantProject}
            onChange={(event) =>
              handleRadio(event, engineeringInfo, setEngineeringInfo)
            }
            disabled={disabled}
          >
            <Radio label={t("yes")} value={true}></Radio>
            <Radio label={t("no")} value={false}></Radio>
          </Radio.Group>
        </Col>
        <Col>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray200}
            align="left"
            mb="10px"
          >
            {t("energyStorage")}
          </Text>
          <Radio.Group
            name="includesEnergyStorageSystem"
            gap="48px"
            value={engineeringInfo.includesEnergyStorageSystem}
            onChange={(event) =>
              handleRadio(event, engineeringInfo, setEngineeringInfo)
            }
            disabled={disabled}
          >
            <Radio label={t("yes")} value={true}></Radio>
            <Radio label={t("no")} value={false}></Radio>
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
};

ProjectEngineering.propTypes = {
  engineeringInfo: PropTypes.object,
  setEngineeringInfo: PropTypes.func,
  handleRadio: PropTypes.func,
  disabled: PropTypes.bool,
};
