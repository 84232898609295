import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetActivity = (payload) => {
  const data = useQuery(["getActivityInvestor", payload], () =>
    axiosClient.get(
      `investors-activity/getByInvestor/${payload.id}?offset=${payload.offset}&limit=${payload.limit}&query=${payload.query}&projectType=${payload.projectType}`),
  { enabled: !!payload.id }
  );

  return data;
};
export default useGetActivity;
