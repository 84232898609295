import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetScraper = (payloadOptions) => {
  const data = useQuery(
    ["getScraper", payloadOptions],
    () => axiosClient.post(`scraper`, payloadOptions),
    { enabled: payloadOptions !== null }
  );

  return data;
};
export default useGetScraper;
