import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 379px;
  height: auto;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 5px 11px 26px #00000022;
  border-radius: 20px;
  padding: 24px 34px 35px 35px;
  margin-top: 20px;
`;
