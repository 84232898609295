import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import Drawer from "../../../../components/Drawer";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../../styles/Common";
import { Link } from "../../../../components/Link";
import { Select } from "../../../../components/Select2";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../styles/theme";
import { Switch } from "../../../../components/Switch";
import { WrapperModel } from "../styles";
import { DeleteIcon, EyeIcon } from "../../../../assets/icons";
import useGetFinancingSource from "../../hooks/useGetFinancingSources";
import useSendFinancingSource from "../../hooks/useSendFinancingSource";
import { downloadFile, getToken } from "../../../../utilities/helpers";
import { Upload } from "antd";
import useSaveDocuments from "../../../Documents/hooks/useSaveDocuments";
import { axiosClient } from "../../../../core/services/axiosInstance";
import ReactLoading from "react-loading";
import useCreateModel from "../../hooks/useCreateModel";
import useGetModel from "../../hooks/useGetModel";
import useGenerateFinancingModel from "../../hooks/useGenerateFinancingModel";
import { useQueryClient } from "react-query";

export const DrawerA5Model = ({
  openDrawer,
  handleClose,
  selectedProject,
  sourceSelected,
  setSourceSelected,
  payload,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["projects", "common"]);
  const navigate = useNavigate();
  const generate = useGenerateFinancingModel();
  const saveDocs = useSaveDocuments();
  const sendFinancingSource = useSendFinancingSource();
  const createModel = useCreateModel();
  const {
    data: dataModel,
    isSuccess: isSuccessModel,
    isLoading: isLoadingModel,
    isError,
    refetch,
  } = useGetModel(selectedProject?._id);
  const { data, isSuccess, isLoading } = useGetFinancingSource();
  const [financingSourceOptions, setFinancingSourcesOptions] = useState([]);
  const [financingModel, setFinancingModel] = useState({});
  const [publish, setPublish] = useState(false);
  const [hasModel, setHasModel] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [modelId, setModelId] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setFinancingSourcesOptions(
        data?.data.map((ele) => ({
          label: ele.name,
          value: ele?._id,
          strategyId: ele.strategyId,
        }))
      );
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      setHasModel(false);
      setFinancingModel({});
    }
  }, [isError])

  useEffect(() => {
    if (isSuccessModel) {
      const info = dataModel?.data[0];

      if (dataModel?.data.length === 0) {
        setHasModel(false);
        setFinancingModel({});
      } else {
        setHasModel(true);
        setModelId(info?._id);
        setPublish(info?.includeSponsor);
        setFinancingModel({
          id: 1,
          name: t("drawerA5Modeling.financingModel"),
          size: info?.size,
          doc: info?.financialModelFile,
          type: info?.financialModelType,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessModel, dataModel]);

  const handleSelect = (value) => {
    setSourceSelected(value);

    sendFinancingSource.reset();
    sendFinancingSource.mutate(
      {
        financingSource: value,
        id: selectedProject?._id,
      },
      {
        onSuccess: () => {
          toast.success(t("drawerA5Modeling.saveSuccess"));
          queryClient.invalidateQueries(["getProjectsManager", payload]);
        },
        onError: () => {
          toast.error(t("drawerA5Modeling.saveFailed"));
        },
      }
    );
  };

  const handleUpdate = () => {
    navigate(`/projects/${selectedProject?._id}/source/${sourceSelected}`);
  };

  const maxSize = 500000000;

  const validType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel"
  ]

  const handleUploadModel = (info) => {
    const { file } = info;  

    if (file?.type && file?.size && !isUploading) {
      if (validType.includes(file.type) &&
        file.size < maxSize
      ) {
        const payload = {
          projectId: selectedProject?._id,
          uploadtype: 2,
          category: 8,
          name: file?.name,
          documents: [
            {
              id: 1,
              name: t("drawerA5Modeling.financingModel"),
              size: file?.size,
              doc: file,
            },
          ],
        };

        setIsUploading(true);
        saveDocs.reset();
        saveDocs.mutate(
          { ...payload },
          {
            onSuccess: (res) => {
              const data = res.data.result.data.createDocumentationResponse[0];

              toast.success(t("drawerA5Modeling.documentSuccess"));
              createModel.reset();
              createModel.mutate(
                {
                  proyect_id: selectedProject?._id,
                  includeSponsor: publish,
                  financialModelType: "File",
                  url: data?.url,
                },
                {
                  onSuccess: () => {
                    refetch();
                    setFinancingModel({
                      id: 1,
                      name: t("drawerA5Modeling.financingModel"),
                      size: file?.size,
                      doc: file,
                    });
                    setIsUploading(false);
                  },
                  onError: (err) => {
                    setIsUploading(false);
                    toast.error(
                      t(`common:${err?.response?.data?.result?.code}`) ||
                        "No ha sido posible crear el modelo financiero, por favor intenta nuevamente"
                    );
                  },
                }
              );
            },
            onError: (err) => {
              setIsUploading(false);
              toast.error(
                t(`common:${err?.response?.data?.result?.code}`) ||
                  "No ha sido posible guardar los documentos, por favor intenta nuevamente"
              );
            },
          }
        );
      } else {
        toast.remove();
        toast.error(t("drawerA5Modeling.notPermited"));
      }
    }
  };

  const handleDeleteModel = () => {
    setFinancingModel({});
  };

  const handleDownloadModel = () => {
    if (financingModel.type === "File") {
      downloadFile(
        `documentation/download/${selectedProject?._id}/8`,
        t("drawerA5Modeling.financingModel"),
        axiosClient,
        getToken()
      );
    } else {
      const a = document.createElement("a");
      a.href = financingModel.doc;
      a.target = "_blank";
      a.setAttribute("download", `${t("drawerA5Modeling.financingModel")}.xlsx`);
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const handlePublish = (event) => {
    setPublish(event);
    createModel.reset();
    createModel.mutate(
      {
        id: modelId,
        proyect_id: selectedProject?._id,
        includeSponsor: event,
        financialModelType: "File",
        url: data?.url,
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const handleGenerate = () => {
    generate.reset();
    generate.mutate(selectedProject?._id, {
      onSuccess: (res) => {
        setModelId(res?.data.data.id);
        refetch();
        toast.success(t("drawerA5Modeling.generateSuccess"));
      },
      onError: (err) => {
        toast.error(
          t(
            err?.response?.data?.result?.code?.message ||
              err?.response?.data?.result?.code ||
              err?.response?.data?.message
          )
        );
      },
    });
  };

  const handleModel = () => {
    navigate(
      `/projects/financial-model/${selectedProject._id}?projectName=${selectedProject.name}`
    );
  };

  const editSourceDisable = sourceSelected === "";

  return (
    <Drawer
      title={t("drawerA5Modeling.title")}
      open={openDrawer}
      onClose={handleClose}
      width="332px"
      padding="10px 36px"
    >
      <Div
        direction="column"
        height="100%"
        justify="space-between"
        p="36px 0px"
      >
        <Div direction="column">
          <Select
            label={t("drawerA5Modeling.financingSource")}
            placeholder={t("drawerA5Modeling.selectSource")}
            options={financingSourceOptions}
            value={sourceSelected}
            loading={isLoading}
            onChange={handleSelect}
            width={"100%"}
            style={{ marginBottom: "24px" }}
          />
          <Button
            width={"100%"}
            variant={"outlined"}
            disabled={editSourceDisable}
            onClick={handleUpdate}
          >
            {t("drawerA5Modeling.editSource")}
          </Button>
          <hr
            style={{
              width: "100%",
              border: `1px dashed ${theme.colors.gray150}`,
              margin: "33px 0 26px 0",
            }}
          />
          <Text
            color={theme.colors.blue}
            size={theme.fonts.size.h6}
            weight={theme.fonts.weight.medium}
            mb="33px"
          >
            {t("drawerA5Modeling.financingModel")}
          </Text>
          <Button
            width={"271px"}
            variant={"outlined"}
            style={{ marginBottom: "24px" }}
            onClick={handleGenerate}
            loading={generate.isLoading}
            disabled={!sourceSelected}
          >
            {t("drawerA5Modeling.generateFinancingModel")}
          </Button>
          <Upload
            multiple={false}
            showUploadList={false}
            onChange={handleUploadModel}
          >
            <Button width={"271px"} disabled={!sourceSelected}>
              {t("drawerA5Modeling.loadFinancingModel")}
            </Button>
          </Upload>
          <Div m="24px 0px">
            {isLoadingModel ? (
              <Div width="100%" height="100%" justify="center" align="center">
                <ReactLoading color={theme.colors.green} />
              </Div>
            ) : (
              Object.keys(financingModel).length > 0 && (
                <WrapperModel>
                  <Text color={theme.colors.gray300}>
                    {financingModel.name}
                  </Text>
                  <Div gap="7px" justify="center" align="center">
                    <DeleteIcon
                      fill={theme.colors.gray300}
                      onClick={handleDeleteModel}
                      style={{ cursor: "pointer" }}
                    />
                    <EyeIcon
                      fill={theme.colors.gray300}
                      onClick={handleDownloadModel}
                      style={{ cursor: "pointer" }}
                    />
                  </Div>
                </WrapperModel>
              )
            )}
          </Div>
          {hasModel && (
            <Div width="100%" justify="center">
              <Link
                size={theme.fonts.size.sm}
                onClick={handleModel}
                style={{ textDecoration: "underline" }}
              >
                {t("drawerA5Modeling.viewModel")}
              </Link>
            </Div>
          )}
          <Div m="24px 0 0 0">
            <Text>{t("drawerA5Modeling.showPromoterPanel")}</Text>
            <Switch
              checked={publish}
              onChange={handlePublish}
              disabled={!hasModel}
            />
          </Div>
        </Div>
        <Div justify="center" align="center">
          <Link onClick={handleClose}>{t("drawerA5Modeling.close")}</Link>
        </Div>
      </Div>
    </Drawer>
  );
};

DrawerA5Model.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedProject: PropTypes.any,
  sourceSelected: PropTypes.string || null,
  setSourceSelected: PropTypes.func,
  payload: PropTypes.object,
};
