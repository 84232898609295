import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useSendPromoterInvitations = () => {
  const mutation = useMutation((users) => {
    return axiosClient.post(`/user/sendPromotersInvitations`, users);
  });
  return mutation;
};
export default useSendPromoterInvitations;
