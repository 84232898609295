import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetMotherCompanies = () => {
  const data = useQuery(["getMotherCompanies"], () =>
    axiosClient.get(`scraper/information/companies`)
  );

  return data;
};
export default useGetMotherCompanies;
