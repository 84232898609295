/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
// import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ModuleContainer } from "../../styles/Common";
import { ProjectsList } from "./components/Promoter/projectsList";
import { setProjectInCreationInfo } from "../../core/store/projects";
// import { ToastCustom } from "../../components/ToastCustom";
// import { theme } from "../../styles/theme";
// import useGetValidateInfoPlant from "./hooks/useGetValidateInfoPlant";
// import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../utilities/helpers";
import { InvestorProjectList } from "./components/Investor/investorProjectList";
import { ProjectsListManager } from "./components/manager/projectsListManager";

export const Projects = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { t } = useTranslation("projects");
  const { role } = getUserInfo();
  useEffect(() => {
    dispatch(setProjectInCreationInfo({}));
  }, []);

  // const validateInfoPlants = useGetValidateInfoPlant();
  // const { data: dataValidatePopUp, isSuccess: isSuccessPopUp } =
  //   validateInfoPlants;

  // useEffect(() => {
  //   if (isSuccessPopUp && dataValidatePopUp?.data?.result?.data?.project_id &&
  //     role[0] === 'Developer'
  //   ) {
  //     toast.custom(
  //       <ToastCustom
  //         message={t(`toast.validatePlants`, {
  //           projectName: dataValidatePopUp?.data?.result?.data?.project_name,
  //         })}
  //         color={theme.colors.notification.text}
  //         onClick={() => {
  //           toast.remove();
  //           if (
  //             !dataValidatePopUp?.data?.result?.data?.isInformationDeclarativa
  //           ) {
  //             navigate(
  //               `/projects/information-declaration?projectName=${dataValidatePopUp?.data?.result?.data?.project_name
  //               }&projectDate=${dataValidatePopUp?.data?.result?.data?.project_date || dateFormat(new Date())
  //               }&projectId=${dataValidatePopUp?.data?.result?.data?.project_id
  //               }`
  //             );
  //           } else {
  //             navigate(
  //               `/projects/edit-project?objective=financing&projectType=eolic&financingType=construction&projectId=${dataValidatePopUp?.data?.result?.data?.project_id}&selected=plants`
  //             );
  //           }
  //         }}
  //       />
  //     );
  //   }
  // }, [dataValidatePopUp]);

  const pageContent = (userRole) => {
    switch (userRole) {
      case "Developer":
        return (
          <ModuleContainer padding={"0px"}>
            <ProjectsList />
          </ModuleContainer>
        );
      case "Investor":
        return (
          <ModuleContainer direction="column" padding={"29px 69px 112px 32px"}>
            <InvestorProjectList />
          </ModuleContainer>
        );
      case "Manager":
        return (
          <ModuleContainer direction="column" padding={"29px 69px 30px 32px"}>
            <ProjectsListManager />
          </ModuleContainer>
        );
      default:
        return (
          <ModuleContainer
            direction="column"
            padding={"29px 69px 30px 32px"}
          ></ModuleContainer>
        );
    }
  };

  return pageContent(role[0]);
};
