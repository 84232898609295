import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { Div } from "../../../../styles/Common";
import { useTranslation } from "react-i18next";
import { CommonFields } from "./commonFields";
import DatePicker from "../../../../components/DatePicker";
import { Input } from "../../../../components/Input";

export const SaleOperationInfoPlants = ({
  plantsInfo,
  setPlantsInfo,
  projectType,
  financingType,
  mwp,
  years,
  anual,
  ha,
  e,
  handleChange,
  handleSelect,
  handleDate,
  handleChangeNumber,
  disabled,
  provincesOptions,
  municipalityOptions,
  formError,
}) => {
  const { t } = useTranslation("newPlant");

  return (
    <>
      <Div width="100%" m="0 0 33.5px 0" />
      <Row gutter={[32, 32]}>
        <CommonFields
          financingType={financingType}
          projectType={projectType}
          plantsInfo={plantsInfo}
          setPlantsInfo={setPlantsInfo}
          mwp={mwp}
          years={years}
          anual={anual}
          ha={ha}
          handleChange={handleChange}
          handleSelect={handleSelect}
          handleChangeNumber={handleChangeNumber}
          disabled={disabled}
          provincesOptions={provincesOptions}
          municipalityOptions={municipalityOptions}
          formError={formError}
        />
        <Col md={8}>
          <DatePicker
            label={t("startUpDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaPuestaEnMarcha}
            style={{ width: "100%" }}
            onChange={(event) =>
              handleDate(
                event,
                "fechaPuestaEnMarcha",
                plantsInfo,
                setPlantsInfo
              )
            }
            disabled={disabled}
          />
        </Col>
        {projectType === "eolic" && (
          <>
            <Col md={8}>
              <Input
                id={"costesEstimadosOM"}
                label={t("o&mCost")}
                suffix={<img alt="e" src={e} />}
                value={plantsInfo.costesEstimadosOM}
                onChange={(event) =>
                  handleChangeNumber(event, plantsInfo, setPlantsInfo)
                }
                disabled={disabled}
              />
            </Col>
            <Col md={8}>
              <Input
                id={"costesSeguros"}
                label={t("insuranceCost")}
                suffix={<img alt="e" src={e} />}
                value={plantsInfo.costesSeguros}
                onChange={(event) =>
                  handleChangeNumber(event, plantsInfo, setPlantsInfo)
                }
                disabled={disabled}
              />
            </Col>
            <Col md={8}>
              <Input
                id={"costesImpuestos"}
                label={t("taxes")}
                suffix={<img alt="e" src={e} />}
                value={plantsInfo.costesImpuestos}
                onChange={(event) =>
                  handleChangeNumber(event, plantsInfo, setPlantsInfo)
                }
                disabled={disabled}
              />
            </Col>
            <Col md={8}>
              <Input
                id={"otrosGastos"}
                label={t("otherCost")}
                suffix={<img alt="e" src={e} />}
                value={plantsInfo.otrosGastos}
                onChange={(event) =>
                  handleChangeNumber(event, plantsInfo, setPlantsInfo)
                }
                disabled={disabled}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

SaleOperationInfoPlants.propTypes = {
  projectType: PropTypes.string,
  plantsInfo: PropTypes.object,
  setPlantsInfo: PropTypes.func,
  financingType: PropTypes.string,
  mwp: PropTypes.string,
  years: PropTypes.string,
  anual: PropTypes.string,
  ha: PropTypes.string,
  e: PropTypes.string,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  disabled: PropTypes.bool,
  provincesOptions: PropTypes.array,
  municipalityOptions: PropTypes.array,
  formError: PropTypes.object,
};
