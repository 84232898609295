import React from "react";
import PropTypes from "prop-types";
import { Row } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { Input } from "../../../../components/Input";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../components/Select2";
import {
  boolOptions,
  developmentRiskOptions,
  paymentOptions,
  sellOptions,
} from "../../../../utilities/generalOptions";
import { FormatNumber } from "../../../../utilities/numberFunctions/handleChange";

export const TransactionSummary = ({
  strategyData,
  setStrategyData,
  tabActiveKey,
}) => {
  const { t, i18n } = useTranslation("teaserDocumentation");
  const locale = i18n.language;

  const handleChange = (event, locale, source) => {
    const { value, id } = event.target;
    const newForm = [...strategyData];

    const formatValue = value.replace(/[^\d.,]/g, "");

    let formattedValue;
    if (locale === "es") {
      formattedValue = formatValue.replace(".", ",");
    } else {
      formattedValue = formatValue.replace(",", ".");
    }

    if (source === "percentage") {
      const numericValue = parseFloat(formattedValue.replace(",", "."));
      if (numericValue > 100) {
        formattedValue = "100";
      }
    }

    newForm[Number(tabActiveKey) - 1][id] = formattedValue;

    setStrategyData(newForm);
  };

  const handleSelect = (value, id) => {
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][id] = value;
      if (id === "strategy" && value === "Full sale") {
        updatedItems[Number(tabActiveKey) - 1][
          "acquisitionOfMajorityStake"
        ] = 100;
      }
      return updatedItems;
    });
  };

  return (
    <>
      <Row gap="28px" m="33.5px 0 0 0">
        <Select
          allowClear={true}
          colorPlaceholder={theme.colors.gray200}
          label={t("risk")}
          id="developmentRisk"
          width="310px"
          arrowColor={theme.colors.gray200}
          value={strategyData[Number(tabActiveKey) - 1].developmentRisk}
          onChange={(value) => handleSelect(value, "developmentRisk")}
          options={developmentRiskOptions}
        />
        <Select
          label={t("paymentMilestones")}
          colorPlaceholder={theme.colors.gray200}
          width="310px"
          arrowColor={theme.colors.gray200}
          value={strategyData[Number(tabActiveKey) - 1].paymentMilestones}
          onChange={(value) => handleSelect(value, "paymentMilestones")}
          options={paymentOptions}
        />
        <Input
          label={t("minimumPayment")}
          id="minimumPaymentAtClosing"
          width="310px"
          suffix="%"
          value={FormatNumber(
            strategyData[Number(tabActiveKey) - 1].minimumPaymentAtClosing,
            locale
          )}
          onChange={(e) => handleChange(e, locale, "percentage")}
        />
      </Row>

      <Row gap="28px" m="33.5px 0 0 0" p="0 50px 0 0">
        <Select
          allowClear={true}
          colorPlaceholder={theme.colors.gray200}
          label={t("strategy")}
          width="310px"
          arrowColor={theme.colors.gray200}
          value={strategyData[Number(tabActiveKey) - 1].strategy}
          onChange={(value) => handleSelect(value, "strategy")}
          options={sellOptions}
        />

        <Input
          label={t("stake")}
          id="acquisitionOfMajorityStake"
          type="number"
          width="310px"
          suffix="%"
          value={FormatNumber(
            strategyData[Number(tabActiveKey) - 1].acquisitionOfMajorityStake,
            locale
          )}
          onChange={(e) => handleChange(e, locale, "percentage")}
          disabled={
            strategyData[Number(tabActiveKey) - 1].strategy === "Full sale"
          }
        />
      </Row>

      <Row gap="28px" m="33.5px 0 0 0" p="0 50px 0 0">
        <Select
          allowClear={true}
          colorPlaceholder={theme.colors.gray200}
          label={t("seller")}
          width="310px"
          arrowColor={theme.colors.gray200}
          value={
            strategyData[Number(tabActiveKey) - 1]
              .sellerToBeRetainedAsEPCContractor
          }
          onChange={(value) =>
            handleSelect(value, "sellerToBeRetainedAsEPCContractor")
          }
          options={boolOptions}
        />
      </Row>
    </>
  );
};

TransactionSummary.propTypes = {
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
