import React from 'react'
import PropTypes from "prop-types";
import { Modal } from '../../../components/Modal'
import { theme } from '../../../styles/theme';
import { useTranslation } from 'react-i18next';
import { Div, Text } from '../../../styles/Common';
import { Button } from '../../../components/Button';
import { Link } from '../../../components/Link';
import { TextArea } from '../../../components/TextArea';
import Checkbox from '../../../components/Checkbox';

export const ModalReminders = ({
  showModal,
  handleClose
}) => {
  const { t } = useTranslation("projectActivity");
  
  return (
    <Modal open={showModal} onCancel={handleClose} width={"478px"}>
      <Modal.Header
        title={t("sendReminders")}
        weightTitle={theme.fonts.weight.medium}
        sizeTitle={theme.fonts.size.h5i}
        padding={"0 0 0 16px"}
      />
      <Modal.Body
        margin="0px 0 32px 0"
        align={"center"}
        padding="20px 0px 0px 16px"
      >
        <Text color={theme.colors.gray500} mb="32px">
          {t("modalReminders.message")}
        </Text>
        <Div direction="column" gap="16px" m="0 0 32px 0">
          <Checkbox label={t("modalReminders.sendReminderTeaser")} />
          <Checkbox label={t("modalReminders.reminderTc")} />
        </Div>
        <TextArea 
          label={t("modalReminders.otherComments")}
        />
      </Modal.Body>
      <Modal.Footer align="center" justify="center">
        <Div direction="column" align="center" justify="center" gap="24px">
          <Button 
            width={"211px"} 
          >
            {t("sendReminders")}
          </Button>
          <Link onClick={handleClose}>
            {t("cancel")}
          </Link>
        </Div>
      </Modal.Footer>
    </Modal>
  )
};

ModalReminders.propTypes = {
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  userForm: PropTypes.object,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  errorForm: PropTypes.object,
  setErrorForm: PropTypes.func
};
