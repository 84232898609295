import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetPresentPreference = () => {
  const data = useQuery(["presentPreference"], () =>
    axiosClient.get(`company/present-preference`)
  );

  return data;
};
export default useGetPresentPreference;
