import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import { DeleteIcon } from "../../../assets/icons";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { Text } from "../../../styles/Common";
import { Button } from "../../../components/Button";

export const ModalDelete = ({
  showModal,
  modalInfo,
  isLoading
}) => {
  const { t } = useTranslation("strategies");

  return (
    <Modal open={showModal} onCancel={modalInfo.cancelFunc}>
      <Modal.Header
        icon={
          <DeleteIcon fill={theme.colors.error} width={"50px"} height={"50px"} />
        }
        iconBackground={theme.colors.error100}
        iconBorderColor={theme.colors.error}
        iconWidth={"90px"}
        iconHeight={"90px"}
        align="-webkit-center"
        padding={"22px 20px 0px 20px"}
        title={t("modalDelete.deleteTitle")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="12px 0 24px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Text color={theme.colors.gray500} align="center">
          {modalInfo.message}
        </Text>
      </Modal.Body>
      <Modal.Footer gap="16px" align="center" justify="center">
        <Button variant={"outlined"} onClick={modalInfo.cancelFunc}>
          {t("modalDelete.cancel")}
        </Button>
        <Button onClick={modalInfo.okFunc} loading={isLoading}>
          {modalInfo.okButton}
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

ModalDelete.propTypes = {
  showModal: PropTypes.bool,
  modalInfo: PropTypes.object,
  isLoading: PropTypes.bool
};