import { useMutation } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useUpdateStrategies = () => {
  const mutation = useMutation(({ formData, companyId }) => {
    return axiosClient.put(`company/update-strategies/${companyId}`, formData);
  });
  return mutation;
};

export default useUpdateStrategies;
