import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Card } from "../../../components/Card";
import { Row, Text, Div, Col } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { theme } from "../../../styles/theme";
import { Tag } from "../../../components/Tag";
import { Button } from "../../../components/Button";
import { CloseIcon } from "../../../assets/icons";
import { ArrowDownIcon } from "../../../assets/icons";
import { Pagination } from "../../../components/Pagination";
import useGetScraper from "../hooks/useGetScraper";
import useGetProvinces from "../hooks/useGetCommunities";
import {
  PermissionsOptions,
  ProjectTypeOptions,
} from "../../../utilities/generalOptions";
import { dateFormat } from "../../../utilities/helpers";
import DateRangePicker from "../../../components/DateRangePicker";
import { SelectMultiple } from "../../../components/SelectMultiple";

export const SearchScraper = ({
  setShowDrawerEditInfo,
  selectedScraper,
  setShowDrawerAdditionalInfo,
  setShowDrawerContacts,
  preSelectedCompany,
  searchClicked,
  setSearchClicked,
  setShowModal,
  companiesOptions,
}) => {
  const { t } = useTranslation("scraper");
  const [showSelection, setShowSelection] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(5);
  const { data: provincesData, isSuccess: isSuccessProvinces } =
    useGetProvinces();
  const [provincesOptions, setProvincesOptions] = useState([]);

  const pageSize = 5;

  const [payloadOptions, setPayloadOptions] = useState({
    limit: pageSize,
    offset: currentPage,
    publicationDate: null,
    publicationDateFrom: null,
    publicationDateTo: null,
    permitType: null,
    projectType: null,
    autonomousCommunities: null,
    peakPower: null,
    nominalPower: null,
    companyName: preSelectedCompany.length > 0 ? preSelectedCompany : null,
  });
  const getScraper = useGetScraper(payloadOptions);
  const { data, isSuccess } = getScraper;
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setTotalItems(data?.data?.total);
      setItems(
        data?.data?.data.map((item) => ({
          id: item._id,
          name: item.nombreProyecto,
          type: item.tecnologia,
          date: item.fecha_publicacion,
          company: item?.empresaMatriz
            ? item.empresaMatriz
            : item?.companiaPromotora
            ? item?.companiaPromotora
            : "--",
          location: item.ubicacion,
          peakPower: item.potenciaPico,
          capex: item.capex,
          permissions: item.permiso,
          actions: false,
          url: item?.url,
        }))
      );
    } else {
      setTotalItems(5);
      setItems([]);
    }
  }, [data, isSuccess, companiesOptions]);

  useEffect(() => {
    if (isSuccessProvinces) {
      setProvincesOptions(
        provincesData?.data?.data.map((ele, indx) => ({
          key: indx,
          label: ele.label,
          value: ele.label,
        }))
      );
    }
  }, [isSuccessProvinces, provincesData]);

  const onPageChange = (page) => {
    setShowSelection(true);
    const newPayload = { ...payloadOptions };
    newPayload.offset = page - 1;
    setPayloadOptions(newPayload);
    setCurrentPage(page - 1);
  };

  const handleChangeDate = (date) => {
    setShowSelection(true);
    const newPayload = { ...payloadOptions };
    if (date) {
      newPayload["publicationDateFrom"] = date[0];
      newPayload["publicationDateTo"] = date[1];
    } else {
      newPayload["publicationDateFrom"] = null;
      newPayload["publicationDateTo"] = null;
    }
    setPayloadOptions(newPayload);
    setSearchClicked(false);
  };
  const handleSelect = (value, id) => {
    setShowSelection(true);
    const newPayload = { ...payloadOptions };
    newPayload[id] = value;
    setPayloadOptions(newPayload);
    setSearchClicked(false);
  };
  const handleChange = (event) => {
    setShowSelection(true);
    const { value, id } = event.target;
    if (Number(value) < 0) {
      return;
    }

    const newPayload = { ...payloadOptions };
    if (id === "companyName") {
      newPayload[id] = value;
    } else {
      newPayload[id] = Number(value);
    }
    setPayloadOptions(newPayload);
    setSearchClicked(false);
  };
  const handleTagClose = (keyToRemove) => {
    const newPayload = { ...payloadOptions };
    if (keyToRemove === "peakPower") {
      newPayload["nominalPower"] = null;
      newPayload[keyToRemove] = null;
    } else if (keyToRemove === "publicationDateTo") {
      newPayload["publicationDateFrom"] = null;
      newPayload[keyToRemove] = null;
    } else if (keyToRemove === "companyName") {
      newPayload[keyToRemove] = null;
    } else {
      newPayload[keyToRemove] = [];
    }
    setPayloadOptions(newPayload);
    setSearchClicked(false);
  };

  const formatTags = (key, value) => {
    switch (key) {
      case "publicationDateFrom":
      case "publicationDateTo": {
        const dateFrom = new Date(payloadOptions?.publicationDateFrom);
        const dateObject = new Date(value);
        return `${dateFrom.toLocaleDateString()} - ${dateObject.toLocaleDateString()}`;
      }
      case "peakPower":
        return `${payloadOptions.nominalPower}-${value} Mwp`;
      case "permitType":
      case "autonomousCommunities":
        return value.join(", ");
      case "projectType": {
        const formattedProjectTypes = value.map(
          (type) => type.charAt(0).toUpperCase() + type.slice(1)
        );
        return formattedProjectTypes.join(",  ");
      }
      default:
        return value;
    }
  };
  const filteredValues = Object.entries(payloadOptions)
    .filter(
      ([key, value]) =>
        key !== "limit" &&
        key !== "offset" &&
        key !== "nominalPower" &&
        key !== "publicationDateFrom" &&
        (Array.isArray(value) ? value.length > 0 : value !== null) &&
        value !== "" &&
        value !== null
    )
    .map(([key, value]) => ({ key, value }));

  const toggleActions = (index) => {
    const updatedItems = [...items];
    updatedItems[index].actions = !updatedItems[index].actions;
    setItems(updatedItems);
  };

  return (
    <>
      <Card
        width="100%"
        height="auto"
        margin="28px 0px 50px 0"
        padding="28px 26px 40px 32px"
        shadow="5px 11px 26px #00000022"
      >
        <Row gap="29px" justify="space-between" m="0 0 24px 0">
          <Row align="end" gap="5px">
            <DateRangePicker
              width="310px"
              label={t("publicationDate")}
              style={{ width: "100%" }}
              sizeLabel={theme.fonts.size.default}
              placeholder={t("format")}
              onChange={handleChangeDate}
              value={[
                payloadOptions.publicationDateFrom,
                payloadOptions.publicationDateTo,
              ]}
            />
          </Row>

          <SelectMultiple
            width="310px"
            label={t("permissionType")}
            arrowColor={theme.colors.gray200}
            options={PermissionsOptions}
            value={payloadOptions?.permitType}
            onChange={(event) => handleSelect(event, "permitType")}
          />
          <SelectMultiple
            width="310px"
            label={t("projectType")}
            arrowColor={theme.colors.gray200}
            options={ProjectTypeOptions}
            value={payloadOptions?.projectType}
            onChange={(event) => handleSelect(event, "projectType")}
          />
        </Row>
        <Row gap="29px" justify="space-between" align="start" m=" 0 0 29px 0">
          <SelectMultiple
            width="310px"
            label={t("autonomous")}
            arrowColor={theme.colors.gray200}
            options={provincesOptions}
            value={payloadOptions?.autonomousCommunities}
            onChange={(event) => handleSelect(event, "autonomousCommunities")}
          />
          <Row align="center">
            <Input
              id="nominalPower"
              type="number"
              label={t("peakPower")}
              width="149px"
              suffix="MWp"
              onChange={handleChange}
              value={payloadOptions.nominalPower}
            />
            <Text mt="25px">-</Text>
            <Input
              id="peakPower"
              type="number"
              mt="25px"
              width="149px"
              suffix="MWp"
              onChange={handleChange}
              value={payloadOptions.peakPower}
            />
          </Row>
          <Input
            id="companyName"
            width="310px"
            label={t("companyName")}
            onChange={handleChange}
            value={payloadOptions.companyName}
          />
        </Row>
        <Div background={theme.colors.gray100} height="1px" width="100%" />
        {showSelection ? (
          <>
            <Row align="center" m="21.5px 0 27.5px 0" justify="space-between">
              <Div
                direction="row"
                align="center"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <Text
                  color={theme.colors.blue}
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.h6}
                >
                  {t("selections")}
                </Text>
                {filteredValues.map((item, index) => (
                  <Tag
                    key={index}
                    closable
                    closeIcon={
                      <CloseIcon
                        width="24px"
                        height="24px"
                        fill={theme.colors.gray300}
                      />
                    }
                    width="auto"
                    height="auto"
                    color={theme.colors.gray100}
                    onClose={() => handleTagClose(item.key)}
                  >
                    <Text
                      color={theme.colors.gray300}
                      style={{
                        whiteSpace: "normal",
                        textAlign: "left",
                        maxWidth: "95%",
                      }}
                    >
                      {formatTags(item.key, item.value)}
                    </Text>
                  </Tag>
                ))}
              </Div>
              <ArrowDownIcon
                onClick={() => setShowSelection(!showSelection)}
                style={{ transform: "rotate(180deg)", cursor: "pointer" }}
              />
            </Row>
            <Div
              background={theme.colors.gray100}
              height="1px"
              width="100%"
              m="0 0 22px 0"
            />
          </>
        ) : (
          <Row align="center" justify="end" width="100%">
            <ArrowDownIcon
              onClick={() => setShowSelection(!showSelection)}
              style={{ cursor: "pointer" }}
            />
          </Row>
        )}

        <Row justify="center">
          <Button
            width="177px"
            onClick={() => setSearchClicked(true)}
          >
            {t("search")}
          </Button>
        </Row>
      </Card>
      {searchClicked && (
        <>
          <Text
            size={theme.fonts.size.h5}
            color={theme.colors.gray500}
            mb="24px"
          >
            {t("searchResults")}
          </Text>
          {items.length > 0 ? (
            items.map((item, index) => (
              <Card
                width="100%"
                height="auto"
                margin="0 0px 24px 0"
                padding="28px 26px 20px 32px"
                shadow="5px 11px 26px #00000022"
                key={index}
              >
                <Row justify="space-between">
                  <Col width="192px">
                    <Text
                      color={theme.colors.blue}
                      size={theme.fonts.size.h6}
                      weight={theme.fonts.weight.medium}
                      mb="5px"
                      style={{
                        cursor: item?.url && "pointer",
                      }}
                      onClick={() => {
                        if (item?.url) {
                          window.open(item.url, "_blank");
                        }
                      }}
                    >
                      {item.name ? item.name : "Enlace fuente"}
                    </Text>
                    <Row align="center" gap="5px">
                      <Text
                        color={theme.colors.gray300}
                        size={theme.fonts.size.sm}
                        weight={theme.fonts.weight.semibold}
                        style={{ textAlign: "left" }}
                      >
                        {t("typeProject")}
                      </Text>
                      <Text
                        color={theme.colors.gray300}
                        size={theme.fonts.size.sm}
                      >
                        {item.type}
                      </Text>
                    </Row>
                  </Col>
                  <Col width="183px">
                    <Text
                      color={theme.colors.gray300}
                      size={theme.fonts.size.sm}
                      weight={theme.fonts.weight.semibold}
                      mb="5px"
                    >
                      {t("datePublication")}
                    </Text>
                    <Text
                      color={theme.colors.gray300}
                      size={theme.fonts.size.sm}
                    >
                      {dateFormat(item.date)}
                    </Text>
                  </Col>
                  <Col width="150px">
                    <Text
                      color={
                        item?.company
                          ? theme.colors.gray300
                          : theme.colors.error
                      }
                      size={theme.fonts.size.sm}
                      weight={theme.fonts.weight.semibold}
                      mb="5px"
                    >
                      {t("company")}
                    </Text>
                    <Text
                      color={theme.colors.gray300}
                      size={theme.fonts.size.sm}
                    >
                      {item?.company}
                    </Text>
                  </Col>
                  <Col m="0 69px 0 0" width="130px">
                    <Text
                      color={theme.colors.gray300}
                      size={theme.fonts.size.sm}
                      weight={theme.fonts.weight.semibold}
                      mb="5px"
                    >
                      {t("location")}{" "}
                    </Text>
                    <Text
                      color={theme.colors.gray300}
                      size={theme.fonts.size.sm}
                    >
                      {item?.location?.comunidadAutonoma
                        ? `${item?.location?.comunidadAutonoma},${item?.location?.provincia}`
                        : "--"}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Div
                    background={theme.colors.gray100}
                    height="1px"
                    width="100%"
                    m="13px 0 15px 0"
                  />
                </Row>
                <Row justify="space-between" align="center">
                  <Col width="192px">
                    <Row gap="5px">
                      <Text
                        color={theme.colors.gray300}
                        size={theme.fonts.size.sm}
                        weight={theme.fonts.weight.semibold}
                        mb="5px"
                      >
                        CAPEX:
                      </Text>
                      <Text
                        color={theme.colors.gray300}
                        size={theme.fonts.size.sm}
                      >
                        {item.capex}
                      </Text>
                    </Row>
                  </Col>
                  <Col width="183px">
                    <Row gap="5px">
                      <Text
                        color={theme.colors.gray300}
                        size={theme.fonts.size.sm}
                        weight={theme.fonts.weight.semibold}
                        mb="5px"
                        style={{ textAlign: "left" }}
                      >
                        {t("powerPeak")}
                      </Text>
                      <Text
                        color={theme.colors.gray300}
                        size={theme.fonts.size.sm}
                      >
                        {item.peakPower}MWp
                      </Text>
                    </Row>
                  </Col>
                  <Col width="150px">
                    <Row gap="5px">
                      <Text
                        color={theme.colors.gray300}
                        size={theme.fonts.size.sm}
                        weight={theme.fonts.weight.semibold}
                      >
                        {t("permissions")}
                      </Text>
                      <Text
                        color={theme.colors.gray300}
                        size={theme.fonts.size.sm}
                      >
                        {item.permissions}
                      </Text>
                    </Row>
                  </Col>
                  <Col m="0 69px 0 0" width="130px">
                    <Row
                      align="center"
                      onClick={() => toggleActions(index)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Text
                        color={theme.colors.green}
                        weight={theme.fonts.weight.medium}
                      >
                        {t("actions")}
                      </Text>
                      <ArrowDownIcon
                        fill={theme.colors.green}
                        style={{
                          transform: item.actions
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </Row>
                  </Col>
                </Row>

                {item.actions && (
                  <>
                    <Div
                      background={theme.colors.gray100}
                      height="1px"
                      width="100%"
                      m="13px 0 15px 0"
                    />

                    <Row justify="space-between" align="center">
                      <Div gap="12px">
                        <Text
                          color={theme.colors.green}
                          weight={theme.fonts.weight.medium}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            selectedScraper(item);
                            setShowDrawerEditInfo(true);
                          }}
                        >
                          {t("editInformation")}
                        </Text>
                        <Div
                          background={theme.colors.gray100}
                          height="23px"
                          width="1px"
                        />
                        <Text
                          color={theme.colors.green}
                          weight={theme.fonts.weight.medium}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            selectedScraper(item);
                            setShowDrawerContacts(true);
                          }}
                        >
                          {t("contact")}
                        </Text>
                        <Div
                          background={theme.colors.gray100}
                          height="23px"
                          width="1px"
                        />
                        <Text
                          color={theme.colors.green}
                          weight={theme.fonts.weight.medium}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            selectedScraper(item);
                            setShowModal(true);
                          }}
                        >
                          {t("delete")}
                        </Text>
                      </Div>
                      <Button
                        width="190px"
                        height="35px"
                        onClick={() => {
                          selectedScraper(item);
                          setShowDrawerAdditionalInfo(true);
                        }}
                      >
                        {t("additionalInfo")}
                      </Button>
                    </Row>
                  </>
                )}
              </Card>
            ))
          ) : (
            <Text color={theme.colors.green} weight={theme.fonts.weight.medium}>
              {t("noSearch")}
            </Text>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination
              total={totalItems}
              onChange={onPageChange}
              pageSize={pageSize}
              showSizeChanger={false}
              defaultPageSize={5}
            />
          </div>
        </>
      )}
    </>
  );
};

SearchScraper.propTypes = {
  setShowDrawerEditInfo: PropTypes.func,
  selectedScraper: PropTypes.object,
  setShowDrawerAdditionalInfo: PropTypes.func,
  setShowDrawerContacts: PropTypes.func,
  preSelectedCompany: PropTypes.string,
  searchClicked: PropTypes.bool,
  setSearchClicked: PropTypes.func,
  setShowModal: PropTypes.func,
  companiesOptions: PropTypes.array,
};
