import React, { useState, useCallback } from "react";
import ReactLoading from "react-loading";
import { useLocation, useNavigate } from "react-router-dom";
import { ModuleContainer, Row, Col, Text, Div } from "../../styles/Common";
import { Card } from "../../components/Card";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import userAdd from "../../assets/icons/User-add.svg";
import { Button } from "../../components/Button";
import useGetInformationDeclaration from "./hooks/useGetInformationDeclaration";
import usePostInformationDeclaration from "./hooks/usePostInformationDeclaration";
import toast from "react-hot-toast";
import RadioRow from "./components/RadioRow";
import { InviteCollaboratorModal } from "../Documents/components/inviteCollaboratorModal";
import { SendInvitationModal } from "../Documents/components/sendInvitationModal";


export const InformationDeclaration = () => {
    const { t } = useTranslation("informationDeclaration");
    const { search } = useLocation();
    const navigate = useNavigate();
    const { data: informationDeclaration, isLoading } = useGetInformationDeclaration();
    const sendInformationDeclaration = usePostInformationDeclaration();
    const projectId = new URLSearchParams(search).get("projectId");
    const [radioValues, setRadioValues] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showModalSendInvitation, setShowModalSendInvitation] = useState(false);

    const handleRadioChange = useCallback((itemId, optionKey) => () => {
        setRadioValues(prev => ({ ...prev, [itemId]: optionKey }));
    }, []);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleShowModalSendInvitation = () => {
        handleCloseModal();
        setShowModalSendInvitation(true);
    };

    const handleCloseModalSendInvitation = () => setShowModalSendInvitation(false);


    const RadioOptions = [t("obtained"), t("notObtained"), t("requested"), t("na"),];

    const allQuestionsAnswered = useCallback(() => {
        return informationDeclaration?.result?.data.every(item => radioValues[item._id] !== undefined);
    }, [informationDeclaration?.result?.data, radioValues]);

    const handleDataSubmission = useCallback(() => {
        if (!allQuestionsAnswered()) {
            toast.error(t("answerAllQuestions"));
            return;
        }
        const formattedData = informationDeclaration?.result?.data.map(item => ({
            projectId: projectId,
            questionId: item._id,
            obtained: radioValues[item._id] === 'obtenido',
            notObtained: radioValues[item._id] === 'noObtenido',
            requested: radioValues[item._id] === 'solicitado',
            na: radioValues[item._id] === 'na',
        }));

        sendInformationDeclaration.mutate(formattedData, {
            onSuccess: () => {
                toast.success(t("common:saveChanges"));
                navigate("/projects");
            },
            onError: (err) => {
                const message = err.response?.data?.result?.code?.message || err.response?.data?.result?.code || "errorOccurred";
                toast.error(t(`common:${message}`));
            },
        });
    }, [allQuestionsAnswered, radioValues, informationDeclaration?.result?.data, projectId, sendInformationDeclaration, t, navigate]); // Include navigate in the array



    if (isLoading) { 
        return (
            <Div width="100%" height="100%" justify="center" align="center">
                <ReactLoading color={theme.colors.green} />
            </Div>
        )
    }

    return (
        <ModuleContainer direction="column" padding="23px 69px 0 32px">
            <Row justify="space-between" width="100%" m="0 0 32px 0">
                <Col flex="3">
                    <Text size={theme.fonts.size.h5} weight={theme.fonts.weight.medium} color={theme.colors.blue}>
                        {t("informationDeclarative")}
                    </Text>
                </Col>
                <Row align="center" gap="24px">
                    <Button
                        variant={"outlined"}
                        icon={<img alt="add" src={userAdd} />}
                        width={"212px"}
                        onClick={handleShowModal}
                    >
                        {t("inviteCollaborator")}
                    </Button>
                </Row>
            </Row>
            <Card width="100%" height="min-content" padding={"28px 36.5px 49px 38.5px"}>
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr style={{ marginBottom: "1rem" }}>
                            <th style={{ width: "50%", textAlign: "left" }}>
                                <Text color={theme.colors.blue} size={theme.fonts.size.h6} weight={theme.fonts.weight.medium}>{t("projectDocumentation")}</Text></th>
                            {RadioOptions.map(option => (
                                <th key={option}><Text color={theme.colors.blue} size={theme.fonts.size.h6} weight={theme.fonts.weight.medium}>{t(option)}</Text></th>
                            ))}
                        </tr>
                    </thead>
                    <Div m="1rem"></Div>
                    <tbody>
                        {informationDeclaration?.result.data.map(item => (
                            <>
                                <RadioRow key={item._id} item={item} radioValues={radioValues}
                                    handleRadioChange={handleRadioChange} radioOptions={RadioOptions} />

                            </>

                        ))}
                    </tbody>
                </table>
            </Card>
            <Div
                m="2rem 0 2rem 0">
                <Button
                    width={"212px"}
                    onClick={handleDataSubmission}
                >
                    {t("sendInformation")}
                </Button>
            </Div>
            {showModal && (
                <InviteCollaboratorModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    handleShowModalSendInvitation={handleShowModalSendInvitation}
                    projectId={projectId}
                />
            )}
            {showModalSendInvitation && (
                <SendInvitationModal
                    showModal={showModalSendInvitation}
                    handleCloseModalSendInvitation={handleCloseModalSendInvitation}
                />
            )}
        </ModuleContainer>
    );
};
