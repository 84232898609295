import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetPlantsList = (projectId) => {
  const enabled = projectId !== null && projectId !== "null";
  const data = useQuery(
    ["getPlantsList", projectId],
    () => axiosClient.get(`Plant?projectId=${projectId}`), { enabled: enabled});

  return data;
};
export default useGetPlantsList;