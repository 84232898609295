import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Text } from "../../../styles/Common";
import { Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
// import { Button } from "../../../components/Button";
// import { DownloadIcon } from "../../../assets/icons";
import { Card } from "../../../components/Card";
import { Select } from "../../../components/Select2";
import { Collapse } from "../../../components/Collapse";
import { FinancingConstructionInfoPlants } from "../../../pages/InfoPlants/components/PlantsInfoComponents/financingConstructionInfoPlants";
import { FinancingRefinancingInfoPlants } from "../../../pages/InfoPlants/components/PlantsInfoComponents/financingRefinancingInfoPlants";
import { FinancingConstructionPermissions } from "../../../pages/InfoPlants/components/PermissionsComponents/financingConstructionPermissions";
import { SalePreInfoPlants } from "../../../pages/InfoPlants/components/PlantsInfoComponents/salePreInfoPlants";
import { SalePrePermissions } from "../../../pages/InfoPlants/components/PermissionsComponents/salePrePermissions";
import { SaleOperationInfoPlants } from "../../../pages/InfoPlants/components/PlantsInfoComponents/saleOperationInfoPlants";
import { AditionalInfo } from "../../../pages/InfoPlants/components/aditionalInfo";
import { Interconnection } from "../../../pages/InfoPlants/components/interconnection";
import { Terrain } from "../../../pages/InfoPlants/components/terrain";
import { License } from "../../../pages/InfoPlants/components/license";
import { Others } from "../../../pages/InfoPlants/components/others";
import { ProjectEngineering } from "../../../pages/InfoPlants/components/projectEngineering";
import { Taxes } from "../../../pages/InfoPlants/components/taxes";
import { useTranslation } from "react-i18next";
import useGetPlantInfo from "../../NewProject/hooks/useGetPlantInfo";
import mwp from "../../../assets/icons/mwp.svg";
import years from "../../../assets/icons/years.svg";
import anual from "../../../assets/icons/anual.svg";
import ha from "../../../assets/icons/ha.svg";
import m from "../../../assets/icons/m.svg";
import kv from "../../../assets/icons/kv.svg";
import e from "../../../assets/icons/euro.svg";
// import { downloadFile, getToken } from "../../../utilities/helpers";
// import { axiosClient } from "../../../core/services/axiosInstance";

export const InfoDetailInvestor = ({
  projectInfo,
  termsApproved,
  setShowModal,
}) => {
  const { t } = useTranslation(["infoPlants"], ["investorProjectDetail"]);
  const [activeKey, setActiveKey] = useState(["0"]);

  const financingType = projectInfo?.financingType;
  const projectType = projectInfo?.projectType;
  const projectId = projectInfo?.id;

  const [selectedPlant, setSelectedPlant] = useState(projectInfo?.plants[0].id);

  const [plantsOptions, setPlantsOptions] = useState([]);
  useEffect(() => {
    setPlantsOptions(
      projectInfo?.plants.map((plant) => ({
        value: plant.id,
        label: plant.name,
      }))
    );
  }, [projectInfo]);

  const { data, isSuccess } = useGetPlantInfo(selectedPlant);

  const [formError, setFormError] = useState({
    name: {
      error: false,
      message: "",
    },
    potenciaPico: {
      error: false,
      message: "",
    },
    horasEquivalentes: {
      error: false,
      message: "",
    },
    horasEquivalentes90: {
      error: false,
      message: "",
    },
  });

  const [plantsInfo, setPlantsInfo] = useState({
    projectId: projectId,
    potenciaPico: "",
    plazoContratoArrendamiento: "",
    costeArrendamiento: "",
    potenciaNominal: "",
    estadoTerrenoPlanta: null,
    superficie: "",
    name: "",
    location: "",
    horasEquivalentes90: "",
    horasEquivalentes: "",
    actaPuestaEnMarcha: null,
    codigoIT: "",
    costesEstimadosOM: "",
    costesImpuestos: "",
    costesSeguros: "",
    estadoContratoTecnicoCTA: null,
    fechaPuestaEnMarcha: "",
    municipio: "",
    otrosGastos: "",
    longitud: "",
    typeLine: null,
    tensionLine: "",
    fechaEstimadaCoDPlanta: "",
  });
  const [permissions, setPermissions] = useState({
    AAC: null,
    AACDate: null,
    interconexion: null,
    workLicenses: null,
    AAP: null,
    AAPDate: null,
    admisionOSCA: null,
    DIA: null,
    DIADate: null,
    DUP: null,
    DUPDate: null,
    equiposMedicion: null,
    fechaIVA: null,
  });

  const [additionalInfo, setAdditionalInfo] = useState({
    technology: "",
    thirdPartySpv: null,
  });

  const [interconnectionInfo, setInterconnectionInfo] = useState({
    connectionOrTransportationSet: "",
    sharedLine: "",
    requiresIntermediateSubstation: null,
    substationType: "",
    substationCost: null,
    estimatedInterconnectionCost: null,
    connectionSet: "",
    existingOrPlannedSet: "",
  });

  const [landsInfo, setLandsInfo] = useState({
    percentageOfOwnedLand: null,
    landValue: null,
    leasedHectares: null,
    pricePerHectare: null,
    expansionPossibility: "",
    percentageOfLandIncluded: null,
    contractDuration: null,
    estimatedAnnualProduction: null,
  });

  const [engineeringInfo, setEngineeringInfo] = useState({
    updatedGenerationPlantProject: null,
    includesEnergyStorageSystem: null,
  });

  const [constructionLicenseInfo, setConstructionLicenseInfo] = useState({
    estimatedICIO: null,
    estimatedLOObtentionDate: "",
    estimatedLOCost: null,
  });

  const [taxesInfo, setTaxesInfo] = useState({
    estimatedUrbanDevelopmentTax: null,
    estimatedIBICE: null,
    estimatedIAE: null,
  });

  const [otherInfo, setOtherInfo] = useState({
    CODDeadline: "",
    otherDetails: "",
  });

  useEffect(() => {
    if (isSuccess) {
      setPlantsInfo({
        projectId: data?.data?.projectId || projectId,
        potenciaPico: data?.data?.potenciaPico || "",
        plazoContratoArrendamiento:
          data?.data?.plazoContratoArrendamiento || "",
        costeArrendamiento: data?.data?.costeArrendamiento || "",
        potenciaNominal: data?.data?.potenciaNominal || "",
        estadoTerrenoPlanta: data?.data?.estadoTerrenoPlanta || null,
        superficie: data?.data?.superficie || "",
        name: data?.data?.name || "",
        location: data?.data?.location || "",
        horasEquivalentes90: data?.data?.horasEquivalentes90 || "",
        horasEquivalentes: data?.data?.horasEquivalentes || "",
        actaPuestaEnMarcha: data?.data?.actaPuestaEnMarcha || null,
        codigoIT: data?.data?.codigoIT || "",
        costesEstimadosOM: data?.data?.costesEstimadosOM || "",
        costesImpuestos: data?.data?.costesImpuestos || "",
        costesSeguros: data?.data?.costesSeguros || "",
        estadoContratoTecnicoCTA: data?.data?.estadoContratoTecnicoCTA || null,
        fechaPuestaEnMarcha: data.data.fechaPuestaEnMarcha
          ? moment(data.data.fechaPuestaEnMarcha)
          : null,
        municipio: data?.data?.municipio || "",
        otrosGastos: data?.data?.otrosGastos || "",
        longitud: data?.data?.longitud || "",
        typeLine: data?.data?.typeLine || null,
        tensionLine: data?.data?.tensionLine || "",
        fechaEstimadaCoDPlanta: data?.data?.fechaEstimadaCoDPlanta
          ? moment(data?.data?.fechaEstimadaCoDPlanta)
          : null,
        fechaEstimadaRtBPlanta: data?.data?.fechaEstimadaRtBPlanta
          ? moment(data?.data?.fechaEstimadaRtBPlanta)
          : null,
      });
    }
    setPermissions({
      AAC: data?.data?.AAC || null,
      AACDate: data?.data?.AACDate ? moment(data?.data?.AACDate) : null,
      interconexion: data?.data?.interconexion || null,
      workLicenses: data?.data?.workLicenses || null,
      AAP: data?.data?.AAP || null,
      AAPDate: data?.data?.AAPDate ? moment(data?.data?.AAPDate) : null,
      admisionOSCA: data?.data?.admisionOSCA || null,
      DIA: data?.data?.DIA || null,
      DIADate: data?.data?.DIADate ? moment(data?.data?.DIADate) : null,
      DUP: data?.data?.DUP || null,
      DUPDate: data?.data?.DUPDate ? moment(data?.data?.DUPDate) : null,
      equiposMedicion: data?.data?.equiposMedicion || null,
      fechaIVA: data?.data?.fechaIVA ? moment(data?.data?.fechaIVA) : null,
    });
    setAdditionalInfo({
      technology: data?.data?.technology || "",
      thirdPartySpv: data?.data?.thirdPartySpv || null,
    });
    setInterconnectionInfo({
      connectionOrTransportationSet:
        data?.data?.connectionOrTransportationSet || "",
      sharedLine: data?.data?.sharedLine || "",
      requiresIntermediateSubstation:
        data?.data?.requiresIntermediateSubstation || null,
      substationType: data?.data?.substationType || "",
      substationCost: data?.data?.substationCost || null,
      estimatedInterconnectionCost:
        data?.data?.estimatedInterconnectionCost || null,
      connectionSet: data?.data?.connectionSet || "",
      existingOrPlannedSet: data?.data?.existingOrPlannedSet || "",
    });
    setLandsInfo({
      percentageOfOwnedLand: data?.data?.percentageOfOwnedLand || null,
      landValue: data?.data?.landValue || null,
      leasedHectares: data?.data?.leasedHectares || null,
      pricePerHectare: data?.data?.pricePerHectare || null,
      expansionPossibility: data?.data?.expansionPossibility || "",
      percentageOfLandIncluded: data?.data?.percentageOfLandIncluded || null,
      contractDuration: data?.data?.contractDuration || null,
      estimatedAnnualProduction: data?.data?.estimatedAnnualProduction || null,
    });
    setEngineeringInfo({
      updatedGenerationPlantProject:
        data?.data?.updatedGenerationPlantProject || null,
      includesEnergyStorageSystem:
        data?.data?.includesEnergyStorageSystem || null,
    });
    setConstructionLicenseInfo({
      estimatedICIO: data?.data?.estimatedICIO || null,
      estimatedLOObtentionDate: data?.data?.estimatedLOObtentionDate
        ? moment(data?.data?.estimatedLOObtentionDate)
        : null,
      estimatedLOCost: data?.data?.estimatedLOCost || null,
    });
    setTaxesInfo({
      estimatedUrbanDevelopmentTax:
        data?.data?.estimatedUrbanDevelopmentTax || null,
      estimatedIBICE: data?.data?.estimatedIBICE || null,
      estimatedIAE: data?.data?.estimatedIAE || null,
    });
    setOtherInfo({
      CODDeadline: data?.data?.CODDeadline
        ? moment(data?.data?.CODDeadline)
        : null,
      otherDetails: data?.data?.otherDetails || "",
    });
  }, [isSuccess, data, projectId]);

  // const handleDownload = () => {
  //   if (!termsApproved) {
  //     setShowModal(true);
  //   } else {
  //     const pdfUrl = `plant/additional-information/generatedPdf/${selectedPlant}`;
  //     downloadFile(pdfUrl, "PlantDetailedInfo", axiosClient, getToken());
  //   }
  // };

  const handlePanelChange = (key) => {
    if (!termsApproved) {
      setShowModal(true);
    } else {
      setActiveKey(key);
    }
  };

  const handleSelectPlant = (value) => {
    setSelectedPlant(value);
  };

  const handleChange = (event, form, setForm) => {
    const { id, value } = event.target;

    if (id === "name") {
      const newErrorForm = formError;
      newErrorForm[id].error = false;
      newErrorForm[id].message = "";
      setFormError(newErrorForm);
    }

    const newFormInfo = { ...form };

    newFormInfo[id] = value;
    setForm(newFormInfo);
  };

  const handleChangeNumber = (event, form, setForm) => {
    const { id, value } = event.target;
    if (
      id === "potenciaPico" ||
      id === "horasEquivalentes" ||
      id === "horasEquivalentes90"
    ) {
      const newErrorForm = formError;
      newErrorForm[id].error = false;
      newErrorForm[id].message = "";
      setFormError(newErrorForm);
    }
    const newFormInfo = { ...form };

    newFormInfo[id] = Number(value.replace(/\D/g, ""));
    setForm(newFormInfo);
  };

  const handleSelect = (value, id, form, setForm) => {
    const newFormInfo = { ...form };
    if (id === "location") {
      newFormInfo["municipio"] = null;
    }

    newFormInfo[id] = value;
    setForm(newFormInfo);
  };

  const handleDate = (value, id, form, setForm) => {
    const newFormInfo = { ...form };

    newFormInfo[id] = value;
    setForm(newFormInfo);
  };

  const handleRadio = (event, form, setForm) => {
    const { value, name } = event.target;
    const newFormInfo = { ...form };

    newFormInfo[name] = value;
    setForm(newFormInfo);
  };

  const handleComponentPlantInfo = () => {
    switch (financingType) {
      case "construction":
        return (
          <FinancingConstructionInfoPlants
            plantsInfo={plantsInfo}
            setPlantsInfo={setPlantsInfo}
            projectType={projectType}
            financingType={financingType}
            mwp={mwp}
            years={years}
            anual={anual}
            ha={ha}
            m={m}
            kv={kv}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleChangeNumber={handleChangeNumber}
            disabled={true}
            formError={formError}
          />
        );
      case "financed":
        return (
          <FinancingRefinancingInfoPlants
            plantsInfo={plantsInfo}
            setPlantsInfo={setPlantsInfo}
            projectType={projectType}
            financingType={financingType}
            mwp={mwp}
            years={years}
            anual={anual}
            ha={ha}
            m={m}
            kv={kv}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleRadio={handleRadio}
            handleChangeNumber={handleChangeNumber}
            disabled={true}
            formError={formError}
          />
        );
      case "RtBCOD":
        return (
          <SalePreInfoPlants
            plantsInfo={plantsInfo}
            setPlantsInfo={setPlantsInfo}
            projectType={projectType}
            financingType={financingType}
            mwp={mwp}
            years={years}
            anual={anual}
            ha={ha}
            m={m}
            kv={kv}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleChangeNumber={handleChangeNumber}
            disabled={true}
            formError={formError}
          />
        );
      case "operation":
        return (
          <SaleOperationInfoPlants
            plantsInfo={plantsInfo}
            setPlantsInfo={setPlantsInfo}
            projectType={projectType}
            financingType={financingType}
            mwp={mwp}
            years={years}
            anual={anual}
            ha={ha}
            e={e}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleChangeNumber={handleChangeNumber}
            disabled={true}
            formError={formError}
          />
        );
    }
  };

  const handleComponentPermissions = () => {
    switch (financingType) {
      case "construction":
        return (
          <FinancingConstructionPermissions
            permissions={permissions}
            setPermissions={setPermissions}
            handleSelect={handleSelect}
            handleRadio={handleRadio}
            handleDate={handleDate}
            disabled={true}
          />
        );
      case "financed":
        return (
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mt="20px"
            mb="8px"
            align="left"
          >
            N/A
          </Text>
        );
      case "RtBCOD":
        return (
          <SalePrePermissions
            permissions={permissions}
            setPermissions={setPermissions}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleRadio={handleRadio}
            disabled={true}
          />
        );
      case "operation":
        return (
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mt="20px"
            mb="8px"
            align="left"
          >
            N/A
          </Text>
        );
    }
  };

  const items = [
    {
      key: "1",
      header: t("information"),
      panel: handleComponentPlantInfo(),
    },
    {
      key: "2",
      header: t("permissions"),
      panel: handleComponentPermissions(),
    },
    {
      key: "3",
      header: t("additional"),
      panel: (
        <AditionalInfo
          additionalInfo={additionalInfo}
          setAdditionalInfo={setAdditionalInfo}
          handleRadio={handleRadio}
          disabled={true}
        />
      ),
    },
    {
      key: "4",
      header: t("interconnection"),
      panel: (
        <Interconnection
          interconnectionInfo={interconnectionInfo}
          setInterconnectionInfo={setInterconnectionInfo}
          handleRadio={handleRadio}
          disabled={true}
        />
      ),
    },
    {
      key: "5",
      header: t("lands"),
      panel: (
        <Terrain
          landsInfo={landsInfo}
          setLandsInfo={setLandsInfo}
          handleRadio={handleRadio}
          disabled={true}
        />
      ),
    },
    {
      key: "6",
      header: t("project"),
      panel: (
        <ProjectEngineering
          engineeringInfo={engineeringInfo}
          setEngineeringInfo={setEngineeringInfo}
          handleRadio={handleRadio}
          disabled={true}
        />
      ),
    },
    {
      key: "7",
      header: t("license"),
      panel: (
        <License
          constructionLicenseInfo={constructionLicenseInfo}
          setConstructionLicenseInfo={setConstructionLicenseInfo}
          disabled={true}
        />
      ),
    },
    {
      key: "8",
      header: t("Taxes"),
      panel: (
        <Taxes
          taxesInfo={taxesInfo}
          setTaxesInfo={setTaxesInfo}
          disabled={true}
        />
      ),
    },
    {
      key: "9",
      header: t("Others"),
      panel: (
        <Others
          otherInfo={otherInfo}
          setOtherInfo={setOtherInfo}
          disabled={true}
        />
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="center" m=" 0 0 23px 0">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h5i}
          color={theme.colors.blue}
        >
          {t("investorProjectDetail:information")}
        </Text>
        {/* <Button
          width="305px"
          height="45px"
          border={theme.colors.green}
          color={theme.colors.green}
          background={theme.colors.green100}
          hBackground={theme.colors.green100}
          onClick={handleDownload}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
          }}
        >
          <DownloadIcon fill={theme.colors.green} />
          {t("investorProjectDetail:downloadInformation")}
        </Button> */}
      </Row>
      <Card
        margin="0 0 24px 0"
        width="100%"
        height="auto"
        padding="23px 35px 34px 26px"
      >
        <Select
          label={t("investorProjectDetail:select")}
          width="35%"
          borderColor={theme.colors.gray100}
          options={plantsOptions}
          onChange={handleSelectPlant}
          value={selectedPlant}
        />
        <Collapse
          activeKey={activeKey}
          handlePanelChange={handlePanelChange}
          panels={items}
          group={true}
          marginBottom={"0px"}
        />
      </Card>
    </>
  );
};

InfoDetailInvestor.propTypes = {
  projectInfo: PropTypes.object,
  termsApproved: PropTypes.bool,
  setShowModal: PropTypes.func,
};
