import React from "react";
import PropTypes from "prop-types";
import { Row, Text, Col } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { Radio } from "../../../components/Radio";
import { useTranslation } from "react-i18next";
import { EuroIcon } from "../../../assets/icons";

export const Terrain = ({
  landsInfo,
  setLandsInfo,
  handleChangeNumber,
  handleRadio,
  disabled,
}) => {
  const { t } = useTranslation("infoPlants");

  return (
    <>
      <Row gap="28px" m="33.5px 0 32px 0">
        <Input
          id="percentageOfOwnedLand"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("percentage")}
          width="100%"
          suffix="%"
          value={landsInfo.percentageOfOwnedLand}
          onChange={(e) => handleChangeNumber(e, landsInfo, setLandsInfo)}
          disabled={disabled}
        />
        <Input
          id="landValue"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("value")}
          width="100%"
          suffix={<EuroIcon />}
          value={landsInfo.landValue}
          onChange={(e) => handleChangeNumber(e, landsInfo, setLandsInfo)}
          disabled={disabled}
        />
        <Input
          id="leasedHectares"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("leased")}
          width="100%"
          value={landsInfo.leasedHectares}
          onChange={(e) => handleChangeNumber(e, landsInfo, setLandsInfo)}
          disabled={disabled}
        />
      </Row>
      <Row gap="28px" m="0 0 34px 0">
        <Input
          id="pricePerHectare"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("price")}
          width="48.5%"
          suffix="€/Ha"
          value={landsInfo.pricePerHectare}
          onChange={(e) => handleChangeNumber(e, landsInfo, setLandsInfo)}
          disabled={disabled}
        />

        <Col width="100%">
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray200}
            align="left"
            mb="8px"
          >
            {t("possibilities")}
          </Text>
          <Radio.Group
            name="expansionPossibility"
            gap="48px"
            value={landsInfo.expansionPossibility}
            onChange={(event) => handleRadio(event, landsInfo, setLandsInfo)}
            disabled={disabled}
          >
            <Radio
              label={t("discretionPossibility")}
              value={"discretion of the lessee"}
            ></Radio>
            <Radio
              label={t("mutalPossibility")}
              value={"mutual agreement"}
            ></Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row align="center" m="0 0 45px 0" gap="28px" justify="space-between">
        <Text size={theme.fonts.size.default} color={theme.colors.gray200}>
          {" "}
          {t("percentageofLand")}
        </Text>
        <Input
          id="percentageOfLandIncluded"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          width="25%"
          suffix="%"
          value={landsInfo.percentageOfLandIncluded}
          onChange={(e) => handleChangeNumber(e, landsInfo, setLandsInfo)}
          disabled={disabled}
        />
      </Row>
      <Row gap="28px">
        <Input
          id="contractDuration"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("duration")}
          width="32%"
          suffix={t("years")}
          value={landsInfo.contractDuration}
          onChange={(e) => handleChangeNumber(e, landsInfo, setLandsInfo)}
          disabled={disabled}
        />
        <Input
          id="estimatedAnnualProduction"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("estimatedAnnual")}
          width="30%"
          suffix="MWh/MWp"
          value={landsInfo.estimatedAnnualProduction}
          onChange={(e) => handleChangeNumber(e, landsInfo, setLandsInfo)}
          disabled={disabled}
        />
      </Row>
    </>
  );
};

Terrain.propTypes = {
  landsInfo: PropTypes.object,
  setLandsInfo: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  handleRadio: PropTypes.func,
  disabled: PropTypes.bool,
};
