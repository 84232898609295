import React from "react";
import { Radio } from "antd";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";


const RadioRow = ({ item, radioValues, handleRadioChange, radioOptions }) => {
    const { i18n } = useTranslation();

    const itemName = i18n.language.startsWith('es') ? item.nameEs : item.nameEn;

    return (
        <React.Fragment>
            <tr style={{ borderBottom: `1px solid ${theme.colors.gray100}` }}>
                <td style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                    <Text color={theme.colors.gray200} size={theme.fonts.size.default} weight={theme.fonts.weight.regular}>
                        {itemName}
                    </Text>
                </td>
                {radioOptions?.map(optionKey => (
                    <td key={`${item._id}-${optionKey}`} style={{ textAlign: "center" }}>
                        <Radio
                            checked={radioValues[item._id] === optionKey}
                            onChange={handleRadioChange(item._id, optionKey)}
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};


RadioRow.propTypes = {
    item: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        nameEs: PropTypes.string.isRequired,
        nameEn: PropTypes.string.isRequired,
    }).isRequired,
    radioValues: PropTypes.object.isRequired,
    handleRadioChange: PropTypes.func.isRequired,
    radioOptions: PropTypes.array.isRequired,
};

export default RadioRow;