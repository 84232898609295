import React from "react";
import PropTypes from "prop-types";
import { Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../components/DatePicker";

export const Others = ({
  otherInfo,
  setOtherInfo,
  handleChange,
  handleDate,
  disabled,
}) => {
  const { t } = useTranslation("infoPlants");

  return (
    <>
      <Row gap="28px" m="33.5px 0 32px 0">
        <DatePicker
          label={t("deadline")}
          sizeLabel={theme.fonts.size.default}
          width="25%"
          placeholder={t("format")}
          format={"DD/MM/YYYY"}
          value={otherInfo.CODDeadline}
          style={{ width: "100%" }}
          onChange={(event) =>
            handleDate(event, "CODDeadline", otherInfo, setOtherInfo)
          }
          disabled={disabled}
          helper={t("lowerDate")}
          helperColor={theme.colors.gray300}
          helperSize={theme.fonts.size.sm}
        />
      </Row>
      <Row>
        <Input
          id="otherDetails"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("others")}
          width="100%"
          height="150px"
          value={otherInfo.otherDetails}
          onChange={(e) => handleChange(e, otherInfo, setOtherInfo)}
          disabled={disabled}
        />
      </Row>
    </>
  );
};

Others.propTypes = {
  otherInfo: PropTypes.object,
  setOtherInfo: PropTypes.func,
  handleChange: PropTypes.func,
  handleDate: PropTypes.func,
  disabled: PropTypes.bool,
};
