import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text, Row, Div, Col } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { UserAdd } from "../../../../assets/icons";
import { UserCheckIcon } from "../../../../assets/icons";
import { Button } from "../../../../components/Button";
import ReactLoading from "react-loading";

const ModalDistribution = ({
  showModal,
  handleCloseModal,
  selectedStrategy,
  setSelectedStrategy,
  handleOpenInvitationDrawer,
  data,
  isLoadingStrategies,
  handleOpenInvitation,
}) => {
  const { t } = useTranslation("projects");

  const changeStrategy = (strategy) => {
    setSelectedStrategy(strategy);
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        align="-webkit-left"
        padding={"0px 50px 0px 35px"}
        title={t("modalDistribution.select")}
        sizeTitle={theme.fonts.size.h5i}
        weightTitle={theme.fonts.weight.medium}
      />
      <Modal.Body
        margin="28px 0 0 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col>
          <Row
            gap="17px"
            m={selectedStrategy === null ? "0" : "0 0 33px 0"}
            style={{ flexWrap: "wrap" }}
          >
            {isLoadingStrategies ? (
              <Div width="100%" height="100%" justify="center" align="center">
                <ReactLoading color={theme.colors.green} />
              </Div>
            ) : data.length > 0 ? (
              data.map((item) => (
                <Div
                  width="183px"
                  height="47px"
                  background={
                    selectedStrategy?.value === item?.value
                      ? theme.colors.green100
                      : theme.colors.white
                  }
                  border="1px solid"
                  borderColor={theme.colors.green}
                  radius="8px"
                  align="center"
                  justify="center"
                  style={{ cursor: "pointer" }}
                  onClick={() => changeStrategy(item)}
                  key={item.value}
                >
                  <Text color={theme.colors.green}>{item.label}</Text>
                </Div>
              ))
            ) : (
              <Text>{t("modalDistribution.noStrategy")}</Text>
            )}
          </Row>

          {selectedStrategy !== null && (
            <>
              <Row m="0 0 33px 0">
                <Text
                  color={theme.colors.blue}
                  size={theme.fonts.size.h5i}
                  weight={theme.fonts.weight.medium}
                >
                  {t("modalDistribution.do")}
                </Text>
              </Row>
              <Row gap="17px" m="0 0 33px 0">
                <Div
                  width="193px"
                  height="109px"
                  background={theme.colors.white}
                  border="1px solid"
                  borderColor={theme.colors.gray100}
                  radius="8px"
                  align="center"
                  justify="center"
                  direction="column"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenInvitationDrawer}
                >
                  <Div
                    width="50px"
                    m="0 0 8px"
                    height="50px"
                    radius="50px"
                    background={theme.colors.green100}
                    align="center"
                    justify="center"
                  >
                    <UserAdd width="32px" height="32px" />
                  </Div>
                  <Text color={theme.colors.gray300}>
                    {t("modalDistribution.invite")}
                  </Text>
                </Div>
                <Div
                  width="193px"
                  height="109px"
                  background={theme.colors.white}
                  border="1px solid"
                  borderColor={theme.colors.gray100}
                  radius="8px"
                  align="center"
                  justify="center"
                  direction="column"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenInvitation}
                >
                  <Div
                    width="50px"
                    m="0 0 8px"
                    height="50px"
                    radius="50px"
                    background={theme.colors.green100}
                    align="center"
                    justify="center"
                  >
                    <UserCheckIcon
                      width="32px"
                      height="32px"
                      fill={theme.colors.green}
                    />
                  </Div>
                  <Text color={theme.colors.gray300}>
                    {t("modalDistribution.see")}
                  </Text>
                </Div>
              </Row>
              <Row justify="center">
                <Button
                  width="268px"
                  height="45px"
                  onClick={handleCloseModal}
                >
                  {t("modalDistribution.close")}
                </Button>
              </Row>
            </>
          )}
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalDistribution.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  selectedStrategy: PropTypes.string,
  setSelectedStrategy: PropTypes.func,
  handleOpenInvitationDrawer: PropTypes.func,
  data: PropTypes.array,
  isLoadingStrategies: PropTypes.bool,
  handleOpenInvitation: PropTypes.func,
};

export default ModalDistribution;
