import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { Radio } from "../../../components/Radio";
import { useTranslation } from "react-i18next";
import { EuroIcon } from "../../../assets/icons";

export const Interconnection = ({
  interconnectionInfo,
  setInterconnectionInfo,
  handleChange,
  handleChangeNumber,
  handleRadio,
  disabled,
}) => {
  const { t } = useTranslation(["infoPlants"]);

  return (
    <>
      <Row m="33.5px 0 33px  0">
        <Input
          id="connectionOrTransportationSet"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("conection")}
          width="70%"
          value={interconnectionInfo.connectionOrTransportationSet}
          onChange={(e) =>
            handleChange(e, interconnectionInfo, setInterconnectionInfo)
          }
          disabled={disabled}
        />
      </Row>
      <Row m="0 0 32px 0">
        <Input
          id="sharedLine"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("lineShared")}
          width="100%"
          height="102px"
          value={interconnectionInfo.sharedLine}
          onChange={(e) =>
            handleChange(e, interconnectionInfo, setInterconnectionInfo)
          }
          disabled={disabled}
        />
      </Row>

      <Row gap="40px" style={{ flexWrap: "wrap" }} m="0 0 41px 0">
        <Col style={{ flex: "1 1 calc(33.333% - 27px)" }}>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray200}
            align="left"
            mb="8px"
          >
            {t("substation")}
          </Text>
          <Radio.Group
            name="requiresIntermediateSubstation"
            gap="48px"
            value={interconnectionInfo.requiresIntermediateSubstation}
            onChange={(event) =>
              handleRadio(event, interconnectionInfo, setInterconnectionInfo)
            }
            disabled={disabled}
          >
            <Radio label={t("yes")} value={true}></Radio>
            <Radio label={t("no")} value={false}></Radio>
          </Radio.Group>
        </Col>
        <Col style={{ flex: "1 1 calc(33.333% - 27px)" }}>
          <Input
            id="substationType"
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("type")}
            width="100%"
            value={interconnectionInfo.substationType}
            onChange={(e) =>
              handleChange(e, interconnectionInfo, setInterconnectionInfo)
            }
            disabled={disabled}
          />
        </Col>
        <Col style={{ flex: "1 1 calc(33.333% - 27px)" }}>
          <Input
            id="substationCost"
            type="number"
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("estimatedCost")}
            width="100%"
            suffix={<EuroIcon />}
            value={interconnectionInfo.substationCost}
            onChange={(e) =>
              handleChangeNumber(e, interconnectionInfo, setInterconnectionInfo)
            }
            disabled={disabled}
          />
        </Col>
        <Col style={{ flex: "1 1 calc(33.333% - 27px)" }}>
          <Input
            id="estimatedInterconnectionCost"
            type="number"
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("cost")}
            width="100%"
            suffix={<EuroIcon />}
            value={interconnectionInfo.estimatedInterconnectionCost}
            onChange={(e) =>
              handleChangeNumber(e, interconnectionInfo, setInterconnectionInfo)
            }
            disabled={disabled}
          />
        </Col>
        <Col style={{ flex: "1 1 calc(33.333% - 27px)" }}>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray200}
            align="left"
            mb="10px"
          >
            {t("individualOrShared")}
          </Text>
          <Radio.Group
            name="connectionSet"
            gap="48px"
            value={interconnectionInfo.connectionSet}
            onChange={(event) =>
              handleRadio(event, interconnectionInfo, setInterconnectionInfo)
            }
            disabled={disabled}
          >
            <Radio label="Individual" value={"Individual"}></Radio>
            <Radio label={t("shared")} value={"Shared"}></Radio>
          </Radio.Group>
        </Col>
        <Col style={{ flex: "1 1 calc(33.333% - 27px)" }}>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray200}
            align="left"
            mb="10px"
          >
            {t("existingOrPlanned")}
          </Text>
          <Radio.Group
            name="existingOrPlannedSet"
            gap="48px"
            value={interconnectionInfo.existingOrPlannedSet}
            onChange={(event) =>
              handleRadio(event, interconnectionInfo, setInterconnectionInfo)
            }
            disabled={disabled}
          >
            <Radio label={t("existing")} value={"Existing"}></Radio>
            <Radio label={t("planned")} value={"Planned"}></Radio>
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
};

Interconnection.propTypes = {
  interconnectionInfo: PropTypes.object,
  setInterconnectionInfo: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  handleRadio: PropTypes.func,
  disabled: PropTypes.bool,
};
