import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetCompaniesList = () => {
  const data = useQuery(["getCompaniesList"], () =>
    axiosClient.get(`/company/getInvestingCompanies`)
  );

  return data;
};
export default useGetCompaniesList;
