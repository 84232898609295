import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Div, Text, Row, Col } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import Drawer from "../../../../components/Drawer";
import { Card } from "../../../../components/Card";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";
import { Input } from "../../../../components/Input";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  DocumentGrayIcon,
  SponsorIcon,
  UploadIcon,
  DeleteIcon,
  EyeIcon,
} from "../../../../assets/icons";
import { Upload } from "antd";
import useSaveDocuments from "../../../Documents/hooks/useSaveDocuments";
import useSendTermsheet from "../../hooks/useSendTermsheet";
import useGetTermsheetByProjectId from "../../hooks/useGetTermsheetByProjectId";
import useGetDocuments from "../../../Documents/hooks/useGetDocuments";
import useDeleteDocument from "../../../Documents/hooks/useDeleteDocument";
import useSendTeaser from "../../hooks/useSendTeaser";

export const DrawerDocumentation = ({
  openDrawer,
  handleClose,
  selectedProject,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("projects");
  const queryClient = useQueryClient();

  const saveDocs = useSaveDocuments();
  const deleteDocs = useDeleteDocument();
  const sendTermsheet = useSendTermsheet();
  const sendTeaser = useSendTeaser();

  const [document, setDocument] = useState(null);
  const [termsheetForm, setTermsheetForm] = useState({ tenor: "", spread: "" });

  const getTermsheet = useGetTermsheetByProjectId(selectedProject?._id);
  const { data: dataTermsheet } = getTermsheet;
  useEffect(() => {
    if (dataTermsheet?.data.length > 0) {
      setTermsheetForm({
        tenor: dataTermsheet?.data[0].tenor,
        spread: dataTermsheet?.data[0].spread,
      });
    } else {
      setTermsheetForm({ tenor: "", spread: "" });
    }
  }, [dataTermsheet]);

  const { data: dataPromoterDocument, isSuccess: isSuccessPromoterDocument } =
    useGetDocuments(selectedProject?._id, 2, 6);

  useEffect(() => {
    if (
      isSuccessPromoterDocument &&
      dataPromoterDocument?.data?.data.length === 0
    ) {
      setDocument(null);
    } else {
      setDocument(dataPromoterDocument?.data?.data[0]);
    }
  }, [isSuccessPromoterDocument, dataPromoterDocument?.data?.data]);

  const handleChange = (event) => {
    const { value, id } = event.target;
    const newFormInfo = { ...termsheetForm };
    if (id === "tenor") {
      newFormInfo[id] = value.slice(0, 2);
    } else {
      newFormInfo[id] = value;
    }
    setTermsheetForm(newFormInfo);
  };

  const maxSize = 500000000;
  const handleUploadDocument = (info) => {
    if (saveDocs.isLoading) {
      return;
    }
    const { file } = info;
    if (file?.type && file?.size) {
      if (file.type !== "application/x-msdownload" && file.size < maxSize) {
        const payload = {
          description: "termsheet sponsor",
          name: file.name,
          size: file.size,
          type: file.type,
          projectId: selectedProject?._id,
          uploadtype: 2,
          category: 6,
          subdomain: "",
          documents: [{ doc: file }],
        };
        saveDocs.reset();
        saveDocs.mutate(
          { ...payload },
          {
            onSuccess: () => {
              toast.success(t("drawerDocumentation.documentsSuccess"));
              setDocument(file);
            },
            onError: (err) => {
              toast.error(
                err?.response?.data?.result?.code?.message.map((error) =>
                  t(`common:${error}`)
                )
              );
            },
          }
        );
      }
    } else {
      toast.error(t("drawerDocumentation.notPermited"));
    }
  };

  const handleDeleteDocument = (documentId) => {
    if (documentId) {
      deleteDocs.reset();
      deleteDocs.mutate(documentId, {
        onSuccess: () => {
          setDocument(null);
          queryClient.invalidateQueries(["documentsList"]);
          toast.success(t("drawerDocumentation.deleted"));
        },
        onError: (err) => {
          toast.error(
            err?.response?.data?.result?.code?.message.map((error) =>
              t(`${error}`)
            )
          );
        },
      });
    } else {
      setDocument(null);
    }
  };

  const handleSendTermsheet = () => {
    sendTermsheet.reset();
    sendTermsheet.mutate(
      {
        projectId: selectedProject?._id,
        tenor: Number(termsheetForm.tenor),
        spread: Number(termsheetForm.spread),
      },
      {
        onSuccess: () => {
          toast.success(t("common:saveChanges"));
          handleClose();
        },
        onError: (err) => {
          toast.error(
            t(
              `${
                err.response.data.result.code.message ||
                err.response.data.result.code
              }`
            )
          );
        },
      }
    );
  };

  const handleSendTeaser = () => {
    sendTeaser.reset();
    sendTeaser.mutate(
      {
        teaserId: selectedProject?.teaser?._id,
        formData: {
          projectId: selectedProject?._id,
          isPublish: true,
        },
      },
      {
        onSuccess: () => {
          toast.success(t("common:saveChanges"));
          handleClose();
        },
        onError: (err) => {
          toast.error(
            t(
              `${
                err.response.data.result.code.message ||
                err.response.data.result.code
              }`
            )
          );
        },
      }
    );
  };

  return (
    <Drawer
      title={selectedProject?.name}
      open={openDrawer}
      onClose={handleClose}
      width="332px"
      padding="10px 36px"
    >
      <Text color={theme.colors.green} mb="24px" mt="8px">
        {t("drawerDocumentation.documentation")}
      </Text>
      <Card
        width="100%"
        height="auto"
        padding="21px 40px 20px 19px"
        margin="0 0 24px 0"
      >
        <Row justify="center" align="center" m="0 0 16px 0" gap="8px">
          <Div
            background={theme.colors.green100}
            radius="23px"
            width="30px"
            height="30px"
            align="center"
            justify="center"
          >
            <DocumentGrayIcon width="24px" height="24px" />
          </Div>
          <Text color={theme.colors.blue} weight={theme.fonts.weight.medium}>
            Teaser de activos
          </Text>
        </Row>
        <Row
          justify={selectedProject?.teaser?._id && "center"}
          align="center"
          gap="12px"
        >
          <Button
            variant={"outlined"}
            width="95px"
            height="30px"
            onClick={() =>
              navigate(
                `teaser-documentation?projectName=${selectedProject?.name}&teaserId=${selectedProject?.teaser?._id}&projectId=${selectedProject?._id}`
              )
            }
          >
            {t("drawerDocumentation.edit")}
          </Button>
          {selectedProject?.teaser?._id && (
            <Button
              width="95px"
              height="30px"
              onClick={handleSendTeaser}
              loading={sendTeaser.isLoading}
            >
              {t("drawerDocumentation.send")}
            </Button>
          )}
        </Row>
      </Card>
      {selectedProject?.projectType?.instance?.name !== "sale" && (
        <Card
          width="100%"
          height="auto"
          padding="21px 40px 20px 19px"
          margin="0 0 12px 0"
        >
          <Row justify="center" align="center" m="0 0 16px 0" gap="8px">
            <Div
              background={theme.colors.green100}
              radius="23px"
              width="30px"
              height="30px"
              align="center"
              justify="center"
            >
              <SponsorIcon
                width="24px"
                height="24px"
                fill={theme.colors.gray200}
              />
            </Div>
            <Text color={theme.colors.blue} weight={theme.fonts.weight.medium}>
              Termsheet Sponsor
            </Text>
          </Row>
          <Row justify="space-between" align="center">
            <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
              Legal Tenor
            </Text>
            <Input
              id="tenor"
              value={termsheetForm.tenor}
              type="number"
              onChange={handleChange}
              suffix={t("drawerDocumentation.years")}
              width="55%"
            />
          </Row>
          <Row justify="space-between" align="center" m="0 0 30px 0">
            <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
              Spread
            </Text>
            <Input
              id="spread"
              value={termsheetForm.spread}
              type="number"
              onChange={handleChange}
              suffix="%"
              width="55%"
            />
          </Row>
          <Row justify="center" align="center" gap="12px" m="0 0 12px 0">
            {document === null ? (
              <Upload
                multiple={false}
                showUploadList={false}
                onChange={handleUploadDocument}
              >
                <Button variant={"outlined"} width="220px" height="30px">
                  <Row justify="center" align="center">
                    <UploadIcon
                      fill={theme.colors.green}
                      width="24px"
                      height="24px"
                    />
                    {t("drawerDocumentation.load")}
                  </Row>
                </Button>
              </Upload>
            ) : (
              <Col>
                <Div
                  background={theme.colors.green100}
                  radius="7px"
                  align="center"
                  p="10px 18px 10px 12px"
                  border="1px solid"
                  borderColor={theme.colors.gray100}
                  m="0 0 20px 25px"
                >
                  <Col align="left" m="0 15px 0 0">
                    <Text mb="7px" color={theme.colors.gray300}>
                      {document?.name.length > 15
                        ? `${document?.name.substring(
                            0,
                            9
                          )}...${document?.name.substring(
                            document?.name.length - 4
                          )}`
                        : document?.name}
                    </Text>
                    <Text size={theme.fonts.size.sm} color={theme.colors.green}>
                      {document?.size
                        ? (document?.size / (1024 * 1024)).toFixed(2)
                        : "--"}{" "}
                      Mb
                    </Text>
                  </Col>
                  <Row>
                    <Div
                      radius="50%"
                      width="38px"
                      height="38px"
                      justify="center"
                      align="center"
                      m="0 0 8px 0"
                    >
                      <Link onClick={() => handleDeleteDocument(document?._id)}>
                        <DeleteIcon fill={theme.colors.gray200} />
                      </Link>
                    </Div>
                    <Div
                      radius="50%"
                      width="38px"
                      height="38px"
                      justify="center"
                      align="center"
                      m="0 0 8px 0"
                    >
                      <Link>
                        <EyeIcon fill={theme.colors.gray200} />
                      </Link>
                    </Div>
                  </Row>
                </Div>
              </Col>
            )}
          </Row>
          {dataTermsheet?.data.length === 0 &&
            Object.values(termsheetForm).every((value) => value !== "") && (
              <Button
                width="95px"
                height="30px"
                onClick={handleSendTermsheet}
                loading={sendTermsheet.isLoading}
              >
                {t("drawerDocumentation.send")}
              </Button>
            )}
        </Card>
      )}
      <Div
        direction="column"
        align="center"
        justify="center"
        gap="24px"
        style={{ marginTop: "auto" }}
      >
        <Link onClick={handleClose}> {t("drawerDocumentation.close")}</Link>
      </Div>
    </Drawer>
  );
};

DrawerDocumentation.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedProject: PropTypes.object,
};
