import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import percent from "../../../assets/icons/percent.svg";
import years from "../../../assets/icons/years.svg";
import { FormatNumber, HandleChangeNumber } from "../../../utilities/numberFunctions/handleChange";

export const CashSweep = ({
  sourceForm,
  setSourceForm
}) => {
  const { t, i18n } = useTranslation("strategies");
  const locale = i18n.language;

  return (
    <Row gutter={[32, 32]} style={{ width: '100%', marginTop: "32px" }}>
      <Col md={8}>
        <Input 
          id="CSYearsInPeriod"
          label={t("seniorDebtForm.cashSweepDuration")}
          value={FormatNumber(sourceForm.CSYearsInPeriod, locale)}
          onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale)}
          suffix={<img alt="years" src={years} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="CSStartingYear"
          label={t("seniorDebtForm.cashSweepYear")}
          value={FormatNumber(sourceForm.CSStartingYear, locale)}
          onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale)}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="CSRatioPeriod1"
          label={t("seniorDebtForm.cashSweepRatio")}
          value={FormatNumber(sourceForm.CSRatioPeriod1, locale)}
          onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
    </Row>
  )
};

CashSweep.propTypes = {
  sourceForm: PropTypes.object,
  setSourceForm: PropTypes.func,
};