import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import ReactLoading from "react-loading";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { Text, Col, Row, Div } from "../../../styles/Common";
import { SelectMultiple } from "../../../components/SelectMultiple";
import PropTypes from "prop-types";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import Tabs from "../../../components/Tabs";
import Checkbox from "../../../components/Checkbox";
import { AddIcon } from "../../../assets/icons";
import useGetStrategyRelated from "./hooks/useGetStrategyRelated";
import useGetFinancingSource from "../../Projects/hooks/useGetFinancingSources";
import useUpdateStrategies from "./hooks/useUpdateStrategies";

export const DrawerStrategies = ({
  openDrawer,
  handleClose,
  selectedCompany,
}) => {
  const { t } = useTranslation("strategies");
  const queryClient = useQueryClient();
  const [activeKey, setActiveKey] = useState("1");
  const [selectedStrategies, setSelectedStrategies] = useState([]);

  const updateStrategies = useUpdateStrategies();
  const getStrategyRelated = useGetStrategyRelated(selectedCompany);
  const { data, isSuccess, isLoading } = getStrategyRelated;
  const [saleStrategies, setSaleStrategies] = useState([]);
  const [financingStrategies, setFinancingStrategies] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setSaleStrategies(
        data?.data?.data
          .map((item) => ({ name: item.name, type: item.type, id: item.id }))
          .filter((item) => item.type !== 1)
      );
      setFinancingStrategies(
        data?.data?.data
          .map((item) => ({ name: item.name, type: item.type, id: item.id }))
          .filter((item) => item.type !== 2)
      );
      setSelectedStrategies(
        data?.data?.data
          .filter((item) => item.selectedStrategy)
          .map((item) => item.id)
      );
    }
  }, [data, isSuccess, openDrawer]);

  const getFinnancingSource = useGetFinancingSource();
  const { data: dataFinancing } = getFinnancingSource;
  const [optionsFinancingSource, setOptionsFinancingSource] = useState([]);
  useEffect(() => {
    setOptionsFinancingSource(
      dataFinancing?.data.map((item) => ({
        label: item.name,
        value: item._id,
        strategyId: item.strategyId,
      }))
    );
  }, [dataFinancing]);

  const panels = [
    {
      key: "1",
      tab: t("drawerStrategies.sale"),
      children: isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.green} />
        </Div>
      ) : (
        saleStrategies.map((item, index) => (
          <Row key={index} align="center" m="0 0 14px 0">
            <Checkbox
              onChange={() => handleCheckbox(item.id)}
              label={item.name}
              checked={selectedStrategies.includes(item.id)}
            />
          </Row>
        ))
      ),
    },
    {
      key: "2",
      tab: t("drawerStrategies.financing"),
      children: isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.green} />
        </Div>
      ) : (
        <>
          <Row m="0 0 26px 0">
            <Text weight={theme.fonts.weight.medium} color={theme.colors.blue}>
              {t("drawerStrategies.typeOfDebt")}
            </Text>
          </Row>
          {financingStrategies.map((item, index) => (
            <>
              <Row
                key={index}
                width="100%"
                m={selectedStrategies.includes(item.id) ? 0 : "0 0 14px 0"}
              >
                <Checkbox
                  onChange={() => handleCheckbox(item.id)}
                  label={item.name}
                  checked={selectedStrategies.includes(item.id)}
                />
              </Row>
              {selectedStrategies.includes(item.id) && (
                <Col m="16px 0 25px 0" gap="16px">
                  <SelectMultiple
                    options={optionsFinancingSource.filter(
                      (option) => option.strategyId === item.id
                    )}
                    label={t("drawerStrategies.assignFinancingSources")}
                    sizeLabel={theme.fonts.size.default}
                    arrowColor={theme.colors.gray200}
                    width={"260px"}
                  />
                  <Div width="260px">
                    <AddIcon width={"24px"} height={"24px"} />
                    <Text
                      color={theme.colors.green}
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                    >
                      {t("drawerStrategies.newFinancingSource")}
                    </Text>
                  </Div>
                </Col>
              )}
            </>
          ))}
        </>
      ),
    },
  ];

  const handleCheckbox = (id) => {
    setSelectedStrategies((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleSave = () => {
    updateStrategies.reset();
    updateStrategies.mutate(
      {
        formData: { strategies: selectedStrategies },
        companyId: selectedCompany,
      },
      {
        onSuccess: () => {
          toast.success(t("drawerStrategies.success"));
          queryClient.invalidateQueries(["getStrategyRelated"]);
          setSelectedStrategies([]);
          handleClose();
        },
        onError: (err) => {
          toast.error(err?.response?.data?.result?.errorMessage?.message);
        },
      }
    );
  };

  return (
    <Drawer open={openDrawer} onClose={handleClose} width="332px">
      <Text
        size={theme.fonts.size.h5}
        weight={theme.fonts.weight.medium}
        color={theme.colors.blue}
        mb="32px"
      >
        {t("drawerStrategies.defineStrategies")}
      </Text>

      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
        {panels.map((panel) => (
          <Tabs.Panel tab={panel.tab} key={panel.key} disabled={panel.disabled}>
            <Div width="100%" direction="column">
              {panel.children}
            </Div>
          </Tabs.Panel>
        ))}
      </Tabs>

      <Col align="center" style={{ marginTop: "auto" }}>
        <Button
          width="189px"
          background={theme.colors.green}
          hBackground={theme.colors.green}
          color={theme.colors.white}
          border={theme.colors.green}
          m="41px 0 0 0"
          onClick={handleSave}
          loading={updateStrategies.isLoading}
        >
          {t("drawerStrategies.save")}
        </Button>
        <Button
          width="211px"
          background={theme.colors.white}
          color={theme.colors.green}
          border={theme.colors.white}
          onClick={handleClose}
          hBackground={theme.colors.white}
        >
          {t("drawerStrategies.cancel")}
        </Button>
      </Col>
    </Drawer>
  );
};

DrawerStrategies.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedCompany: PropTypes.string,
};
