import React, { useState, useEffect } from "react";
import { Div, Text, Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { CopyGrayIcon, CheckCopyMail } from "../../../assets/icons";
import { Button } from "../../../components/Button";
import useGetScraperById from "../hooks/useGetScraperById";
import useGetContacts from "../hooks/useGetContacts";

export const DrawerContacts = ({
  openDrawer,
  handleClose,
  selectedScraper,
}) => {
  const { t } = useTranslation("scraper");

  const getScraper = useGetScraperById(selectedScraper?.id);
  const { data, isSuccess } = getScraper;
  const [motherCompanyId, setMotherCompanyId] = useState(null);
  useEffect(() => {
    if (isSuccess && data?.data?.data) {
      setMotherCompanyId(data?.data?.data.empresaMatriz);
    }
  }, [data, isSuccess]);

  const getContacts = useGetContacts(motherCompanyId);
  const { data: dataContacts, isSuccess: isSuccessContacts } = getContacts;

  const [contactsList, setContactsList] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);

  useEffect(() => {
    setContactsList([]);
    if (isSuccessContacts && dataContacts?.data?.data) {
      const extractedEmails = dataContacts.data.data;
      setContactsList(extractedEmails);
    }
  }, [isSuccessContacts, dataContacts]);

  const handleCopy = (email, index) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(email).then(() => {
        setCopiedIndex(index);
        setTimeout(() => {
          setCopiedIndex(null);
        }, 2000);
      }).catch(err => {
        this.logger.error(`Error copying text: ${err}`);
      });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = email;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        setCopiedIndex(index);
        setTimeout(() => {
          setCopiedIndex(null);
        }, 2000);
      } catch (err) {
        this.logger.error(`Error copying text: ${err}`);
      }
      document.body.removeChild(textarea);
    }
  };

  return (
    <Drawer
      open={openDrawer}
      onClose={handleClose}
      width="430px"
      padding="0px 31px 40px 28px"
    >
      <Row>
        <Text
          color={theme.colors.blue}
          mb="24px"
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.medium}
        >
          {t("contact")}
        </Text>
      </Row>
      <Row m="0 0 32px 0">
        <Text color={theme.colors.gray500}>{t("followingList")}</Text>
      </Row>
      <Div
        with="371px"
        height="auto"
        background={theme.colors.green100}
        radius="10px"
        m="0 0 24px 0"
        direction="column"
        p="18px 20px 19px 22px"
      >
        <Row m=" 0 0 9px 0" gap="6px">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray300}
            weight={theme.fonts.weight.medium}
          >
            {t("company")}
          </Text>
          <Text size={theme.fonts.size.sm} color={theme.colors.gray200}>
            {selectedScraper?.company}
          </Text>
        </Row>
        <Row gap="6px">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray300}
            weight={theme.fonts.weight.medium}
          >
            {t("projectName")}
          </Text>
          <Text size={theme.fonts.size.sm} color={theme.colors.gray200}>
            {selectedScraper?.name}
          </Text>
        </Row>
      </Div>
      {contactsList.length > 0 ? (
        contactsList.map((email, index) => (
          <Row key={index} height="auto" justify="space-between"
            style={{ display: "flex", justify: "space-between", width: "100%" }}>
            <Text color={theme.colors.gray300} mb="15px" mr="10px"
              style={{ width: "90%" }}>
              {email.name}{email.email ? ` - ${email.email}` : ''}
            </Text>
            {copiedIndex === index ? (
              <CheckCopyMail
                width="24px"
                height="24px"
                style={{ cursor: "pointer", width: "20px", height: "20px", display: "flex", justifyContent: "center", justifyItems: "center" }}
              />
            ) : (
              <CopyGrayIcon
                fill={theme.colors.gray300}
                width="24px"
                height="24px"
                style={{ cursor: "pointer", width: "24px", height: "24px" }}
                onClick={() => handleCopy(email.email, index)}
              />
            )}
          </Row>
        ))
      ) : (
        <Text color={theme.colors.gray300} mb="15px">
          {t("withoutContacts")}
        </Text>
      )}

      <Div justify="center" align="end" height="100%">
        <Button
          width="115px"
          style={{ marginBottom: "15px" }}
          onClick={handleClose}
        >
          {t("close")}
        </Button>
      </Div>
    </Drawer>
  );
};

DrawerContacts.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedScraper: PropTypes.object,
};
