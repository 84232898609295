import React from "react";
import PropTypes from "prop-types";
import { Div } from "../../../styles/Common";
import { Switch } from "../../../components/Switch";

export const ExtraButton = ({ 
  disable,
  handleDisable
}) => {
  return (
    <Div gap="60px" align="center" justify="flex-start">
      <Switch 
        value={disable} 
        onChange={handleDisable}
      />
    </Div>
  )
};

ExtraButton.propTypes = {
  disable: PropTypes.bool,
  handleDisable: PropTypes.func,
};