import React from "react";
import PropTypes from "prop-types";
import { Row } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../components/DatePicker";

export const StateProject = ({
  stateOfProject,
  setStateOfProject,
  handleDate,
  handleChangeNumber,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  return (
    <>
      <Row gap="28px" m="17.5px 0 0 0" p="0 0 0 0">
        <Input
          label={t("plantsContruction")}
          id="plantsUnderConstructionRTB"
          width="100%"
          type="number"
          value={stateOfProject.plantsUnderConstructionRTB}
          onChange={(event) =>
            handleChangeNumber(event, stateOfProject, setStateOfProject)
          }
        />
        <DatePicker
          label={t("plantExpected")}
          width="100%"
          placeholder={t("format")}
          style={{ width: "100%" }}
          value={stateOfProject.RTBForFirstPlantExpected}
          onChange={(date) =>
            handleDate(
              date,
              "RTBForFirstPlantExpected",
              stateOfProject,
              setStateOfProject
            )
          }
        />
        <DatePicker
          label={t("plantLast")}
          width="100%"
          placeholder={t("format")}
          style={{ width: "100%" }}
          value={stateOfProject.RTBForLastPlantExpected}
          onChange={(date) =>
            handleDate(
              date,
              "RTBForLastPlantExpected",
              stateOfProject,
              setStateOfProject
            )
          }
        />
      </Row>

      <Row gap="28px" m="17.5px 0 0 0" p="0 0 0 0">
        <Input
          label={t("plantsCurrently")}
          width="100%"
          id="plantsCurrentlyCOD"
          type="number"
          value={stateOfProject.plantsCurrentlyCOD}
          onChange={(event) =>
            handleChangeNumber(event, stateOfProject, setStateOfProject)
          }
        />
        <DatePicker
          label={t("codFirstPlant")}
          width="100%"
          placeholder={t("format")}
          style={{ width: "100%" }}
          value={stateOfProject.CODOfFirstPlantExpected}
          onChange={(date) =>
            handleDate(
              date,
              "CODOfFirstPlantExpected",
              stateOfProject,
              setStateOfProject
            )
          }
        />
        <DatePicker
          label={t("codLastPlant")}
          width="100%"
          placeholder={t("format")}
          style={{ width: "100%" }}
          value={stateOfProject.CODOfLastPlantExpected}
          onChange={(date) =>
            handleDate(
              date,
              "CODOfLastPlantExpected",
              stateOfProject,
              setStateOfProject
            )
          }
        />
      </Row>
    </>
  );
};

StateProject.propTypes = {
  stateOfProject: PropTypes.array,
  setStateOfProject: PropTypes.func,
  handleDate: PropTypes.func,
  handleChangeNumber: PropTypes.func,
};
