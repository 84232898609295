import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useAnswerQuestion = () => {
  const mutation = useMutation(({ projectId, questionId, description }) => {
    return axiosClient.post(
      `project/${projectId}/questions/${questionId}/answer`,
      { description }
    );
  });
  return mutation;
};
export default useAnswerQuestion;
