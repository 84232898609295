import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Text, Col, Row } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { Button } from "../../../../components/Button";
import toast from "react-hot-toast";
import { getUserInfo } from "../../../../utilities/helpers";
import Checkbox from "../../../../components/Checkbox";
import useSaveInvestorPreferences from "../../hooks/useSaveInvestorPreferences";
import { BateryIcon, SunIcon, WindyIcon } from "../../../../assets/icons";

const ModalPreferences = ({ showModal, handleCloseModal }) => {
  const { t } = useTranslation("projects");

  const queryClient = useQueryClient();

  const savePreferences = useSaveInvestorPreferences();
  const [form, setForm] = useState({
    phase: [],
    type: [],
    size: [],
  });
  const [checkOthersType, setCheckOthersType] = useState(false);

  const { name } = getUserInfo();

  const phaseFilter = [
    { label: "Pre RtB", value: 0 },
    { label: "RtB", value: 1 },
    { label: "CoD", value: 2 },
  ];

  const typeFilter = [
    {
      label: "solar",
      value: "solar",
      icon: <SunIcon fill={theme.colors.gray200} />,
    },
    {
      label: "eolic",
      value: "eolic",
      icon: <WindyIcon fill={theme.colors.gray200} />,
    },
    {
      label: "storage",
      value: "storage",
      icon: <BateryIcon fill={theme.colors.gray200} />,
    },
  ];
  const otherTypeFilter = [
    {
      label: "biogas",
      value: "biogas",
    },
    {
      label: "hydrogen",
      value: "hydrogen",
    },
  ];

  const sizeFilter = [
    { label: "- 10MW", value: "lessThanTen" },
    { label: "10MW - 50MW", value: "fromTenToFifty" },
    { label: "+ 50MW", value: "moreThanFifty" },
  ];

  const handleChangeCheckbox = (group, id) => (e) => {
    const { checked } = e.target;
    const label = id;
    setForm((prevValues) => {
      const newValues = [...prevValues[group]];

      if (checked) {
        newValues.push(label);
      } else {
        const index = newValues.indexOf(label);
        if (index > -1) {
          newValues.splice(index, 1);
        }
      }

      return {
        ...prevValues,
        [group]: newValues,
      };
    });
  };

  const handleSave = () => {
    savePreferences.reset();
    savePreferences.mutate(
      {
        projectStage: form.phase,
        projectType: form.type,
        projectSize: form.size,
        preferenceViewed: true,
      },
      {
        onSuccess: () => {
          toast.success(t("modalInvestorPreferences.saveSuccess"));
          queryClient.invalidateQueries(["presentPreference"]);
          handleCloseModal();
        },
        onError: (err) => {
          toast.error(
            err?.response?.data?.result?.errorMessage?.message ||
              err?.response?.data?.result?.code
          );
        },
      }
    );
  };
  return (
    <Modal open={showModal} onCancel={handleSave} width={"635px"}>
      <Modal.Body
        margin="32px 0 0 0"
        padding="0 20px"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col>
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h5i}
            align="center"
            mb="32px"
            color={theme.colors.blue}
            style={{ padding: "0px 20px" }}
          >
            {t("modalInvestorPreferences.welcome")}{" "}
            <span
              style={{
                color: theme.colors.green,
              }}
            >
              {name}
            </span>{" "}
            {t("modalInvestorPreferences.title")}
          </Text>
          <Text color={theme.colors.gray500} align="center" mb="28px">
            {t("modalInvestorPreferences.subtitle")}
          </Text>
          <Col align="left" m="0 0 40px 0">
            <Text
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.h5i}
              color={theme.colors.blue}
              mb="10px"
            >
              {t("modalInvestorPreferences.state")}
            </Text>
            <Checkbox.Group direction="row">
              {phaseFilter.map((state, index) => (
                <Checkbox
                  key={index}
                  label={state.label}
                  style={{ width: "152px" }}
                  onChange={handleChangeCheckbox("phase", state.value)}
                  checked={form.phase.includes(state.value)}
                />
              ))}
            </Checkbox.Group>
          </Col>

          <Col align="left" m="0 0 33px 0">
            <Text
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.h5i}
              color={theme.colors.blue}
              mb="10px"
            >
              {t("modalInvestorPreferences.technology")}
            </Text>
            <Checkbox.Group direction="row">
              {typeFilter.map((type, index) => (
                <Checkbox
                  key={index}
                  label={
                    <Row align="center">
                      {type.icon}
                      {t(`modalInvestorPreferences.${type.label}`)}
                    </Row>
                  }
                  style={{ width: "152px" }}
                  onChange={handleChangeCheckbox("type", type.value)}
                  checked={form.type.includes(type.value)}
                />
              ))}
            </Checkbox.Group>
          </Col>

          <Col m="0 0 40px 0" gap="13px">
            <Checkbox
              label={t("modalInvestorPreferences.others")}
              onChange={() => setCheckOthersType(!checkOthersType)}
              checked={checkOthersType}
            />
            {checkOthersType && (
              <Checkbox.Group
                direction="row"
                style={{
                  backgroundColor: theme.colors.green100,
                  borderRadius: "11px",
                  padding: "18px 22px",
                }}
              >
                {otherTypeFilter.map((state, index) => (
                  <Checkbox
                    key={index}
                    label={t(`modalInvestorPreferences.${state.label}`)}
                    style={{ width: "170px" }}
                    onChange={handleChangeCheckbox("type", state.value)}
                    checked={form.type.includes(state.value)}
                  />
                ))}
              </Checkbox.Group>
            )}
          </Col>

          <Col align="left" m="0 0 43px 0">
            <Text
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.h5i}
              color={theme.colors.blue}
              mb="10px"
            >
              {t("modalInvestorPreferences.size")}
            </Text>
            <Checkbox.Group direction="row">
              {sizeFilter.map((size, index) => (
                <Checkbox
                  key={index}
                  label={t(size.label)}
                  style={{ width: "152px" }}
                  onChange={handleChangeCheckbox("size", size.value)}
                  checked={form.size.includes(size.value)}
                />
              ))}
            </Checkbox.Group>
          </Col>

          <Col align="center">
            <Button
              width="268px"
              height="45px"
              m="0 0 22px 0"
              onClick={handleSave}
              loading={savePreferences.isLoading}
            >
              {t("modalInvestorPreferences.projects")}
            </Button>
          </Col>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalPreferences.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

export default ModalPreferences;
