import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "../../../components/Table";
import { Div, Text } from "../../../styles/Common";
import { Skeleton } from "../../../components/Skeleton";
import { Select } from "../../../components/Select2";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import editIcon from "../../../assets/icons/Edit-green.svg";
import deleteIcon from "../../../assets/icons/delete-green.svg";
import copyIcon from "../../../assets/icons/Copy.svg";
import { Button } from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import useDeleteSource from "../hooks/useDeleteSource";
import toast from "react-hot-toast";
import { ModalDelete } from "./modalDelete";

export const FinancingTable = ({ 
  tableColumns, 
  data, 
  pages, 
  totalItems, 
  handleChangeTable,
  isLoading,
  expandedRowKey,
  fetchList
}) => {
  const { t } = useTranslation("strategies");
  const navigate = useNavigate();
  const deleteSource = useDeleteSource();
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    message: "",
    okButton: "",
    cancelFunc: () => {},
    okFunc: () => {},
  });

  const columns = [
    {
      title: t("financialSource.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("financialSource.investor"),
      dataIndex: "investor",
      key: "investor",
    },
    {
      title: t("financialSource.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("financialSource.actions"),
      dataIndex: "actions",
      key: "actions",
      align: "left",
      render: (actions) => (
        <Div gap="4px" align="center">
          <Button 
            variant={"outlined"}
            width={"85px"}
            height={"30px"}
          >
            TS Model
          </Button>
          {actions.map((action, i) => (
            <img 
              key={i} 
              alt="icon" 
              src={action.icon}
              style={{ cursor: 'pointer' }}
              onClick={action.onClick}
            />
          ))}
        </Div>
      ),
    }
  ]

  const handleEdit = (id) => {
    navigate(`/strategies/source/${id}`);
  };

  const handleConfirmDelete = (id) => {
    deleteSource.reset();
    deleteSource.mutate(id, {
      onSuccess: () => {
        setShowModal(false);
        fetchList();
      },
      onError: (err) => {
        toast.error(t(`common:${err.response.data.result.code}`));
      },
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleDelete = (id, name) => {
    setShowModal(true);
    setModalInfo({
      message: `${t("modalDelete.messageSource1")} ${name}. ${t("modalDelete.messageSource2")}`,
      okButton: t("modalDelete.deleteSource"),
      cancelFunc: handleCancel,
      okFunc: () => handleConfirmDelete(id),
    })
  };

  const FinancialSourceTable = (record) => {
    const data = record?.record.map((ele, i) => ({
      ...ele,
      key: `${ele._id}`,
      actions: [
        {
          key: `1${i}`,
          id: ele._id,
          icon: editIcon,
          onClick: () => handleEdit(ele._id),
        },
        {
          key: `1${i}`,
          id: ele._id,
          icon: copyIcon,
          onClick: () => {},
        },
        {
          key: `1${i}`,
          id: ele._id,
          icon: deleteIcon,
          onClick: () => handleDelete(ele._id, ele.name),
        },
      ],
    }));

    return (
      <Table
        columns={columns}
        data={data}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        darkRow={theme.colors.gray100}
        background={theme.colors.green100}
        pagination={{
          defaultCurrent: 1,
          ...pages,
          total: totalItems,
          position: ["bottomRight"],
        }}
        onChange={handleChangeTable}
      />
    )
  }

  return (
    <>
      <Div gap="16px" align="center" m="0 0 18px 0">
        <ModalDelete 
          showModal={showModal}
          modalInfo={modalInfo}
          isLoading={deleteSource.isLoading}
        />
        <Text color={theme.colors.gray200}>
          {t("defaultSource")}
        </Text>
        <Select
          disabled 
          placeholderDisable={theme.colors.light}
          arrowColor={theme.colors.gray200}
        />
      </Div>
      <Table
        columns={tableColumns}
        data={data}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        expandable={{
          expandedRowRender: (record) =>  <FinancialSourceTable record={record?.financialSource} />,
          expandedRowKeys: [expandedRowKey],
          expandIcon: () => null,
        }}
        pagination={{
          defaultCurrent: 1,
          ...pages,
          total: totalItems,
          position: ["bottomRight"],
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Div justify="center" gap="16px">
              {[...Array(9)].map((item, idx) => (
                <Skeleton
                  key={idx}
                  title={false}
                  paragraph={{
                    rows: 10,
                    width: "100%",
                  }}
                  loading
                />
              ))}
            </Div>
          ) : (
            "No Data"
          ),
        }}
      />
    </>
  )
};

FinancingTable.propTypes = {
  tableColumns: PropTypes.array, 
  data: PropTypes.array, 
  pages: PropTypes.object, 
  totalItems: PropTypes.number, 
  handleChangeTable: PropTypes.func,
  isLoading: PropTypes.bool,
  expandedRowKey: PropTypes.bool,
  fetchList: PropTypes.func,
};
