import styled from "styled-components";
import { theme } from "../../styles/theme";

export const ColorCard = styled.div`
  display: flex;
  padding-left: 21px;
  align-items: center;
  border-radius: 15px;
  height: 85px;
`;

export const MiniCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: ${theme.colors.white100} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-right: 16px;
`;

export const GreyCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 29px 16px 29px;
  background: ${theme.colors.light} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
`;

export const MiniColorCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  height: 72px;
  width: 159px;
  padding-left: 14px;
`;

export const BackgroundImg = styled.div`
  position: relative;
  display: flex;
  height: ${({ height }) => height};
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  border-radius: 15px;
`;

export const BackgroundImg2 = styled.div`
  display: flex;
  height: 453px;
  background: ${theme.colors.gray500} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  padding: 5px;
`;
