import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetInvestingCompanies = () => {
  const data = useQuery("getInvestingCompanies", () =>
    axiosClient.get(`company/getInvestingCompanies`)
  );

  return data;
};
export default useGetInvestingCompanies;
