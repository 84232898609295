import React from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { Modal } from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Link } from "../../../components/Link";
import { Input } from "../../../components/Input";
import { Div, Text } from "../../../styles/Common";
import Checkbox from "../../../components/Checkbox";

export const ModalCorporation = ({ 
  open, 
  handleClose, 
  plantsList,
  handleCheck,
  handleChange,
  societyForm,
  handleAction,
  textButton,
  isLoading,
  isLoadingContent,
}) => {
  const { t } = useTranslation("documents");

  const invalidForm = societyForm.name === "" && societyForm.plants.length === 0;

  return (
    <Modal open={open} onCancel={handleClose} width={"478px"}>
      <Modal.Header
        title={t("addSociety")}
        weightTitle={theme.fonts.weight.medium}
        sizeTitle={theme.fonts.size.h5i}
        margin={"0 16px"}
      />
      <Modal.Body padding={"0 16px"} margin={"32px 0 46px 0"}>
        {isLoadingContent ? (
          <Div width="100%" height="100%" justify="center" align="center">
            <ReactLoading color={theme.colors.green} />
          </Div> 
        ) : (
          <>
            <Input
              id="name"
              label={t("corporationName")}
              width={"100%"}
              value={societyForm.name}
              onChange={handleChange}
            />
            <Text mt="16px" mb="24px" color={theme.colors.blue} weight={theme.fonts.weight.medium}>
              {t("corporationPlants")}
            </Text>
            {plantsList.map((plant, indx) => (
              <Checkbox 
                key={indx} 
                id={plant.name}
                label={plant.name} 
                onChange={handleCheck}
                checked={societyForm.plants.includes(plant.name)}
              />
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer gap="24px" direction="column" align="center" justify="center">
        <Button 
          onClick={handleAction} 
          disabled={invalidForm}
          loading={isLoading}
        >
          {textButton}
        </Button>
        <Link onClick={handleClose}>
          {t("cancel")}
        </Link>
      </Modal.Footer>
    </Modal>
  )
};

ModalCorporation.propTypes = {
  open: PropTypes.bool, 
  handleClose: PropTypes.func, 
  plantsList: PropTypes.array,
  handleCheck: PropTypes.func, 
  handleChange: PropTypes.func, 
  societyForm: PropTypes.object,
  handleAction: PropTypes.func, 
  textButton: PropTypes.string, 
  isLoading: PropTypes.bool, 
  isLoadingContent: PropTypes.bool, 
};