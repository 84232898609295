import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { formatNumber, formatToMillions, ReturnYearLabel, unformatNumber } from "../../../../utilities/helpers";
import { Card } from "../../../../components/Card";
import { Div, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { BackgroundIcon, ColorCard } from "./styles";
import { CalendarIcon, CoinsIcon, FinancingIcon, SaleIcon } from "../../../../assets/icons";

export const FinancialDashboardSummary = ({ dashboardInfo }) => {
  const { t, i18n } = useTranslation("financialDashboardDetailProject");
  const locale = i18n.language;

  const titleProps = {
    color: theme.colors.blue,
    size: theme.fonts.size.h6,
    weight: theme.fonts.weight.medium
  };

  const labelsProps = {
    color: theme.colors.gray500,
    size: theme.fonts.size.sm,
    weight: theme.fonts.weight.medium
  };

  const valuesProps = {
    color: theme.colors.gray300,
    size: theme.fonts.size.sm,
  };

  const verticalCardProps = {
    direction: "column",
    align: "center",
    width: "127px",
    height: "182px",
    gap: "10px",
    padding: "12px 15px"
  };

  const horizontalCardProps = {
    align: "center",
    width: "242px",
    height: "85px",
    gap: "19px",
    padding: "18px 21px"
  };

  const valuesProps2 = {
    color: theme.colors.white,
    size: theme.fonts.size.h6,
    weight: theme.fonts.weight.medium
  };

  const labelsProps2 = {
    color: theme.colors.white,
    size: theme.fonts.size.sm
  };

  const valueTypes = ["", "-", "#######", null, undefined, "NaN"];

  const formatValue = (value) => {
    if (valueTypes.includes(value)) {
      return "-";
    }
    return formatNumber(unformatNumber(value), locale);
  };

  const generalCharacteristics = [
    { label: t("size"), value: `${dashboardInfo?.projectTotalMW} MW` },
    { label: t("location"), value: `${dashboardInfo?.location}` },
    { label: t("production"), value: `${formatValue(dashboardInfo?.productionp50)} - ${formatValue(dashboardInfo?.productionp90)}` },
    { label: t("opex"), value: `${formatValue(dashboardInfo?.opexTotal)} €` },
    { label: t("capex"), value: `${formatValue(dashboardInfo.capexTotal)} €` },
  ];

  const dates = [
    { label: t("rtb"), value: `${dashboardInfo?.rtbDate}` },
    { label: t("cod"), value: `${dashboardInfo?.codDate}` },
  ];

  const originFunds = [
    { label: t("totalUses"), value: `${formatValue(dashboardInfo?.total_uses)} €` },
    { label: t("epc"), value: `${formatValue(dashboardInfo?.EPC)} €` },
    { label: t("otherCapex"), value: `${formatValue(dashboardInfo?.other_capex)}` },
    { label: t("preOperationalCosts"), value: `${formatValue(dashboardInfo?.pre_operational_costs)} €` },
    { label: t("upfrontFee"), value: `${formatValue(dashboardInfo?.upfront_fee)} €` },
    { label: t("advisors"), value: `${formatValue(dashboardInfo?.advisors)} €` },
    { label: t("agencyFee"), value: `${formatValue(dashboardInfo?.agency_fee)} €` },
    { label: t("swaptionFee"), value: `${formatValue(dashboardInfo?.swaption_fee)} €` },
    { label: t("financialExpenses"), value: `${formatValue(dashboardInfo?.financial_expenses)} €` },
    { label: t("dsraEndowment"), value: `${formatValue(dashboardInfo?.DSRA_endowment)} €` },
  ];

  const totals = [
    { label: t("totalSources"), value: `${formatValue(dashboardInfo?.total_sources)} €` },
    { label: t("equity"), value: `${formatValue(dashboardInfo?.equity)} €` },
    { label: t("seniorDebt"), value: `${formatValue(dashboardInfo?.senior_Debt)} €` },
    { label: t("juniorDebt"), value: `${formatValue(dashboardInfo?.junior_Debt)} €` },
  ];

  const interestRate = dashboardInfo?.fixed_variable !== "" && dashboardInfo?.interest
  ? dashboardInfo?.fixed_variable === "Variable"
    ? `${t('euribor')} + ${formatValue(dashboardInfo?.interest)}%`
    : `${t('fixed')} (${formatValue(dashboardInfo?.interest)}%)`
  : "-"

  return (
    <Row gutter={[16, 27]}>
      <Col md={12}>
        <Card
          shadow={"none"}
          padding={"17px 22px 10px 22px"}
        >
          <Text {...titleProps} mb="16px">
            {t("generalFeatures")}
          </Text>
          {generalCharacteristics.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
          <Text 
            color={theme.colors.green} 
            size={theme.fonts.size.sm}
            weight={theme.fonts.weight.medium}
            mt="24px" 
            mb="16px"
          >
            {t("dates")}
          </Text>
          {dates.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
        </Card>
        <Div justify='space-around' m="16px 0px">
          <ColorCard {...verticalCardProps} background={theme.colors.blue}>
            <BackgroundIcon>
              <CoinsIcon fill={theme.colors.white} />
            </BackgroundIcon>
            <Text {...labelsProps2}>
              {t("maxFinancingAmount")}
            </Text>
            <Text {...valuesProps2}>
              {`€ ${formatToMillions(dashboardInfo?.senior_Debt, locale)}`}
            </Text>
          </ColorCard>
          <Div direction='column' gap="13px">
            <ColorCard {...horizontalCardProps} background={theme.colors.gray200}>
              <BackgroundIcon>
                <CalendarIcon fill={theme.colors.white} />
              </BackgroundIcon>
              <Div direction="column" justify="space-between" style={{ textAlign: 'left' }}>
                <Text {...labelsProps2}>
                  {t("term")}
                </Text>
                <Text {...valuesProps2}>
                  {`${formatValue(dashboardInfo?.year)} ${ReturnYearLabel(dashboardInfo?.tenor_from_COD, t("year"), t("years"))}`}
                </Text>
              </Div>
            </ColorCard>
            <ColorCard {...horizontalCardProps} background={theme.colors.green}>
              <BackgroundIcon>
                <FinancingIcon fill={theme.colors.white} />
              </BackgroundIcon>
              <Div direction="column" justify="space-between" style={{ textAlign: 'left' }}>
                <Text {...labelsProps2}>
                  {t("profitability")}
                </Text>
                <Text {...valuesProps2}>
                  {interestRate}
                </Text>
              </Div>
            </ColorCard>
          </Div>
          <ColorCard {...verticalCardProps} background={theme.colors.gray500}>
            <BackgroundIcon>
              <SaleIcon fill={theme.colors.white} />
            </BackgroundIcon>
            <Text {...labelsProps2}>
              {t("financingCommissions")}
            </Text>
            <Text {...valuesProps2}>
              {`€ -`}
            </Text>
          </ColorCard>
        </Div>
      </Col>
      <Col md={12}>
        <Card
          shadow={"none"}
          padding={"18px 24px 8px 20px"}
        >
          <Text {...titleProps} mb="16px">
            {t("fundSource")}
          </Text>
          {originFunds.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
          <hr 
            style={{ 
              width: "100%", 
              border: `1px solid ${theme.colors.gray100}`,
              margin: "22px 0 23px 0"
            }} 
          />
          {totals.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text 
                {...labelsProps} 
                color={indx === 0 ? theme.colors.green : theme.colors.gray500}
              >
                {ele.label}
              </Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
        </Card>
      </Col>
    </Row>
  )
};

FinancialDashboardSummary.propTypes = {
  dashboardInfo: PropTypes.object,
};