import React from "react";
import PropTypes from "prop-types";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Link } from "../../../components/Link";
import { useTranslation } from "react-i18next";

export const ExtraButtons = ({ 
  id,
  uploadDocuments, 
  totalDocuments, 
  answeredQuestions,
  totalQuestions, 
  totalIncidents,
  handleOpenDrawer,
  questionType, 
}) => {
  const { t } = useTranslation("documents");

  return (
    <Div gap="60px" align="center" justify="flex-start">
      <Text color={totalDocuments === 0 ? theme.colors.disabled100 : theme.colors.green} size={theme.fonts.size.sm}>
        {`${t("documents")} ${uploadDocuments}/${totalDocuments}`}
      </Text>
      <Link 
        width="max-content" 
        size={theme.fonts.size.sm}
        weight={theme.fonts.weight.regular}
        color={totalQuestions === 0 ? theme.colors.disabled100 : theme.colors.green}
        disabled={totalQuestions === 0}
        onClick={(event) => {
          event.stopPropagation();
          handleOpenDrawer(id, questionType);
        }}
      >
        {`${t("questions")} ${answeredQuestions}/${totalQuestions}`}
      </Link>
      <Text color={theme.colors.brown} size={theme.fonts.size.sm}>
        {`${t("incidents")} ${totalIncidents}`}
      </Text>
    </Div>
  )
};

ExtraButtons.propTypes = {
  id: PropTypes.string,
  uploadDocuments: PropTypes.number, 
  totalDocuments: PropTypes.number, 
  answeredQuestions: PropTypes.number,
  totalQuestions: PropTypes.number, 
  totalIncidents: PropTypes.number, 
  handleOpenDrawer: PropTypes.func,
  questionType: PropTypes.string,
};