import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import { useTranslation } from "react-i18next";
import { theme } from "../../../styles/theme";
import { Div, Text } from "../../../styles/Common";
import { WrapperCard, WrapperIcon } from "./styles";
import source from "../../../assets/icons/source.svg";
import { Button } from "../../../components/Button";

export const ModalSourceType = ({ open, handleClose, handleCreate }) => {
  const { t } = useTranslation("strategies");

  const sourceTypes = [
    { label: "Junior Debt", value: 1 },
    { label: "Senior Debt", value: 2 },
    { label: "Holdco", value: 3 },
  ];

  return (
    <Modal open={open} onCancel={handleClose} width={"478px"}>
      <Modal.Header
        title={t("newSource")}
        sizeTitle={theme.fonts.size.h5i}
        weightTitle={theme.fonts.weight.medium}
      />
      <Modal.Body margin="24px 0" padding="0px">
        <Div justify="space-around">
          {sourceTypes.map((ele, indx) => (
            <WrapperCard
              disable={ele.value === 3}
              key={indx}
              onClick={ele.value !== 3 ? () => handleCreate(ele.label) : null}
            >
              <WrapperIcon>
                <img alt="sale" src={source} />
              </WrapperIcon>
              <Text color={theme.colors.gray300}>{ele.label}</Text>
            </WrapperCard>
          ))}
        </Div>
      </Modal.Body>
      <Modal.Footer align="center" justify="center">
        <Button variant={"outlined"} onClick={handleClose}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalSourceType.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCreate: PropTypes.func,
};
