import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetStrategyByFinancingSource = (projectId) => {
  const data = useQuery(
    ["getStrategyByFinancingSource", projectId],
    () => axiosClient.get(`strategy/by-financing-source/${projectId}`),
    { enabled: projectId !== null }
  );

  return data;
};
export default useGetStrategyByFinancingSource;
