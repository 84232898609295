import { useQueries } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetGroupDocuments = (
  projectId,
  uploadType,
  category,
  subdomain,
  societyId
) => {
  let urlString = `/documentation?projectId=${projectId}&uploadtype=${uploadType}&category=${category}`
  const queries = subdomain.map((sub) => ({
    queryKey: ["dueDocumentsList", projectId, uploadType, category, sub],
    queryFn: () => axiosClient.get(`${urlString}&subdomain=${sub}`),
  }));

  if (societyId && societyId.length > 1) {
    urlString += `&societyId=${societyId}`;
  }
  
  const data = useQueries(queries);

  return data;
};
export default useGetGroupDocuments;
