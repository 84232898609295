import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { Col, Row, Text } from "../../../styles/Common";
import { UserAdd } from "../../../assets/icons";

export const SendInvitationModal = ({ showModal, handleCloseModalSendInvitation }) => {
    const { t } = useTranslation("documents");

    return (
        <Modal open={showModal} onCancel={handleCloseModalSendInvitation}>
            <Modal.Header
                iconBackground={theme.colors.green100}
                iconWidth={"50px"}
                iconHeight={"50px"}
                icon={<UserAdd width="50px" height="50px" />}
                align="-webkit-center"
                padding={"20px 63px 0px 63px"}
                title={t("invitationModal.title")}
                weightTitle={theme.fonts.weight.semibold}
            />
            <Modal.Body
                margin="28px 0 32px 0"
                align={"center"}
                style={{ display: "flex", justifyContent: "center" }}
            >
                <Col width="100%" height="100%" >
                    <Row width="100%" m="0 0 32px 0" justify="center" align="center">
                        <Text size={theme.fonts.size.h6} color={theme.colors.gray500}>
                            {t("invitationModal.description")}
                        </Text>
                    </Row>
                </Col>
            </Modal.Body>
        </Modal>
    );
};

SendInvitationModal.propTypes = {
    showModal: PropTypes.bool, 
    handleCloseModalSendInvitation: PropTypes.func, 
  };