import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { Col, Row, Text, Div } from "../../../styles/Common";
import { Modal } from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { DeleteIcon } from "../../../assets/icons";
import { Button } from "../../../components/Button";
import useDeleteUser from "../hooks/useDeleteUser";
import useGetUserRelatedProject from "../hooks/useGetUserRelatedProject";

const ModalDelete = ({ showModal, handleCloseModal, selectedUser }) => {
  const { t } = useTranslation("users");
  const queryClient = useQueryClient();
  const deleteUser = useDeleteUser();

  const getRelatedProject = useGetUserRelatedProject(selectedUser?.id);
  const { data, isSuccess, isLoading } = getRelatedProject;
  const [textCondition, setTextCondition] = useState("");

  useEffect(() => {
    if (isSuccess) {
      const hasRelatedProjects = data?.data?.data?.hasRelatedProjects;
      const uniqueUser = data?.data?.data?.uniqueUser;
      if ((!hasRelatedProjects && !uniqueUser) || !selectedUser?.isActive) {
        setTextCondition("promoterComponent.deleteTextCondition");
        return;
      }
      if (hasRelatedProjects && uniqueUser) {
        setTextCondition("promoterComponent.deleteTextBothCondition");
        return;
      }
      if (uniqueUser) {
        setTextCondition("promoterComponent.deleteTextUnique");
        return;
      }
      if (hasRelatedProjects) {
        setTextCondition("promoterComponent.deleteTextProjects");
        return;
      }
    }
  }, [data, isSuccess, selectedUser]);

  const handleDelete = () => {
    deleteUser.reset();
    deleteUser.mutate(selectedUser?.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAllUsers"]);
        toast.success(t("promoterComponent.deleteSuccess"));
        handleCloseModal();
      },
      onError: (err) => {
        toast.error(
          t(
            err.response.data.result.errorMessage.message ||
              err.response.data.result.code
          )
        );
      },
    });
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        icon={
          <DeleteIcon
            fill={theme.colors.error}
            width={"50px"}
            height={"50px"}
          />
        }
        iconBackground={theme.colors.error100}
        iconBorderColor={theme.colors.error}
        iconWidth={"90px"}
        iconHeight={"90px"}
        align="-webkit-center"
        padding={"22px 50px 0px 50px"}
        title={t("promoterComponent.sure")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="28px 0 32px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          {isLoading ? (
            <Div width="100%" height="100%" justify="center" align="center">
              <ReactLoading color={theme.colors.green} />
            </Div>
          ) : (
            <Row width="100%" m="0 0 32px 0">
              <Text
                size={theme.fonts.size.default}
                color={theme.colors.gray500}
              >
                {
                  t("promoterComponent.deleteText", {
                    userName: selectedUser?.name,
                  }).split(selectedUser?.name)[0]
                }
                <span style={{ color: theme.colors.green }}>
                  {selectedUser?.name}.
                </span>{" "}
                {t(textCondition)}
              </Text>
            </Row>
          )}
          <Row width="100%" gap="20px">
            <Button
              width={"190px"}
              onClick={handleCloseModal}
              variant={"outlined"}
            >
              {t("cancel")}
            </Button>
            <Button
              width={"190px"}
              onClick={handleDelete}
              loading={deleteUser.isLoading}
            >
              {t("promoterComponent.deleteUser")}
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalDelete.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  selectedUser: PropTypes.object,
};

export default ModalDelete;
