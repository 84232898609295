import React from "react";
import PropTypes from "prop-types";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";

export const ExtraLabel = ({ 
  totalDocuments, 
}) => {
  const { t } = useTranslation("documents");

  return (
    <Div>
      <Text color={theme.colors.green} size={theme.fonts.size.sm}>
        {`${totalDocuments} ${t("documents").toLowerCase()}`}
      </Text>
    </Div>
  )
};

ExtraLabel.propTypes = {
  totalDocuments: PropTypes.number, 
};