import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Card, TransparentScroll } from "../styles";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import RadioGroup from "../../../components/Radio2/Group";
import { Radio } from "../../../components/Radio2";
import DatePicker from "../../../components/DatePicker";
import euro from "../../../assets/icons/euro.svg";
import {
  PermissionsOptions,
  TensionNivel,
} from "../../../utilities/plantsOptions";
import { IconButton } from "../../../components/IconButton";
import deleteIcon from "../../../assets/icons/delete-green.svg";
import addIcon from "../../../assets/icons/Add.svg";
import { Link } from "../../../components/Link";

export const DrawerContent = ({
  id,
  interconnection,
  setInterconnection,
  projectInfo,
  setProjectInfo,
  plantPermissions,
  setPlantPermissions,
  environmental,
  setEnvironmental,
  land,
  setLand,
  taxes,
  setTaxes,
  providers,
  setProviders,
  insurance,
  setInsurance,
  handleChange,
  handleChangeNumber,
  handleRadio,
  handleSelect,
  handleChangeLand,
  handleAddAsset,
  handleAddProperty,
  handleDelete,
}) => {
  const { t } = useTranslation("documents");

  return (
    <TransparentScroll>
      <Row gutter={[32, 32]}>
        {id === "int" && (
          <>
            <Col md={24}>
              <Select
                label={t("interconnection.ques1")}
                options={TensionNivel}
                value={interconnection.nivelTension}
                onChange={(value) =>
                  handleSelect(
                    value,
                    "nivelTension",
                    interconnection,
                    setInterconnection
                  )
                }
                helper={"En KV"}
                helperColor={theme.colors.gray300}
              />
            </Col>
            <Col md={24}>
              <Input
                id="nombrePuntoConexion"
                label={t("interconnection.ques2")}
                value={interconnection.nombrePuntoConexion}
                onChange={(e) =>
                  handleChange(e, interconnection, setInterconnection)
                }
              />
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("interconnection.ques3")}
              </Text>
              <RadioGroup
                name="IVAREE"
                gap={"48px"}
                value={interconnection.IVAREE}
                onChange={(e) =>
                  handleRadio(e, interconnection, setInterconnection)
                }
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
            <Col md={24}>
              <Input
                id="puntoConexionConfirmado"
                label={t("interconnection.ques4")}
                value={interconnection.puntoConexionConfirmado}
                onChange={(e) =>
                  handleChange(e, interconnection, setInterconnection)
                }
              />
            </Col>
            <Col md={24}>
              <DatePicker
                label={t("interconnection.ques5")}
                placeholder={t("dateFormat")}
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
                value={interconnection.fechaIVA}
                onChange={(value) =>
                  handleSelect(
                    value,
                    "fechaIVA",
                    interconnection,
                    setInterconnection
                  )
                }
              />
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("interconnection.ques6")}
              </Text>
              <RadioGroup
                name="infraestructurasElectricasEnPropiedadDespuesDeRTB"
                gap={"48px"}
                value={
                  interconnection.infraestructurasElectricasEnPropiedadDespuesDeRTB
                }
                onChange={(e) =>
                  handleRadio(e, interconnection, setInterconnection)
                }
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("interconnection.ques7")}
              </Text>
              <RadioGroup
                name="infraestrcturasMismoExpediente"
                gap={"48px"}
                value={interconnection.infraestrcturasMismoExpediente}
                onChange={(e) =>
                  handleRadio(e, interconnection, setInterconnection)
                }
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("interconnection.ques8")}
              </Text>
              <RadioGroup
                name="tramitacionNoDependePromotor"
                gap={"48px"}
                value={interconnection.tramitacionNoDependePromotor}
                onChange={(e) =>
                  handleRadio(e, interconnection, setInterconnection)
                }
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("interconnection.ques9")}
              </Text>
              <RadioGroup
                name="conflictoTramitacionCNMC"
                gap={"48px"}
                value={interconnection.conflictoTramitacionCNMC}
                onChange={(e) =>
                  handleRadio(e, interconnection, setInterconnection)
                }
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
            <Col md={24}>
              <Input
                id="terminoMunicipal"
                label={t("interconnection.ques10")}
                helper={t("moreOne")}
                helperColor={theme.colors.gray300}
                value={interconnection.terminoMunicipal}
                onChange={(e) =>
                  handleChange(e, interconnection, setInterconnection)
                }
              />
            </Col>
          </>
        )}
        {id === "finan" && (
          <>
            <Col md={24}>
              <Input
                id="cuantiaAval"
                label={t("financialInfo.ques1")}
                suffix={<img alt="euro" src={euro} />}
                value={projectInfo.cuantiaAval}
                onChange={(e) =>
                  handleChangeNumber(e, projectInfo, setProjectInfo)
                }
              />
            </Col>
            <Col md={24}>
              <Input
                id="costeAvales"
                label={t("financialInfo.ques2")}
                suffix={<img alt="euro" src={euro} />}
                value={projectInfo.costeAvales}
                onChange={(e) =>
                  handleChangeNumber(e, projectInfo, setProjectInfo)
                }
              />
            </Col>
            <Col md={24}>
              <Input
                id="costoEstimadoEPC"
                label={t("financialInfo.ques3")}
                suffix={<img alt="euro" src={euro} />}
                value={projectInfo.costoEstimadoEPC}
                onChange={(e) =>
                  handleChangeNumber(e, projectInfo, setProjectInfo)
                }
              />
            </Col>
            <Col md={24}>
              <Input
                id="costoTotalInfraestructurasElectricas"
                label={t("financialInfo.ques4")}
                suffix={<img alt="euro" src={euro} />}
                value={projectInfo.costoTotalInfraestructurasElectricas}
                onChange={(e) =>
                  handleChangeNumber(e, projectInfo, setProjectInfo)
                }
              />
            </Col>
            <Col md={24}>
              <Input
                id="costesEstimadosOM"
                label={t("financialInfo.ques5")}
                suffix={<img alt="euro" src={euro} />}
                value={projectInfo.costesEstimadosOM}
                onChange={(e) =>
                  handleChangeNumber(e, projectInfo, setProjectInfo)
                }
              />
            </Col>
          </>
        )}
        {id === "perm" && (
          <>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("plantPermissions.ques1")}
              </Text>
              <RadioGroup
                name="licenciasCaducadas"
                gap={"48px"}
                value={plantPermissions.licenciasCaducadas}
                onChange={(e) =>
                  handleRadio(e, plantPermissions, setPlantPermissions)
                }
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
            <Col md={24}>
              <Select
                label={t("plantPermissions.ques2")}
                options={PermissionsOptions}
                value={plantPermissions.permisosOrganismosAfectados}
                onChange={(e) =>
                  handleSelect(
                    e,
                    "permisosOrganismosAfectados",
                    plantPermissions,
                    setPlantPermissions
                  )
                }
              />
            </Col>
            {plantPermissions.permisosOrganismosAfectados === 4 && (
              <Col md={24}>
                <Input
                  id="permisosOrganismosAfectadosOtros"
                  label={t("plantPermissions.ques3")}
                  value={plantPermissions.permisosOrganismosAfectadosOtros}
                  onChange={(e) =>
                    handleChange(e, plantPermissions, setPlantPermissions)
                  }
                />
              </Col>
            )}
          </>
        )}
        {id === "env" && (
          <>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("environmental.ques1")}
              </Text>
              <RadioGroup
                name="conflictoAmbiental"
                gap={"48px"}
                value={environmental.conflictoAmbiental}
                onChange={(e) =>
                  handleRadio(e, environmental, setEnvironmental)
                }
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
          </>
        )}
        {id === "land" && (
          <>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("lands.ques1")}
              </Text>
              <Div direction="column" gap="25px" align="center">
                {land.activosInmobiliarios.map((ele, indx) => (
                  <Card key={indx}>
                    <Div direction="column" width="85%" gap="8px">
                      <Input
                        id="nombre"
                        label={t("name")}
                        value={ele.nombre}
                        onChange={(e) =>
                          handleChangeLand(e, indx, "activosInmobiliarios")
                        }
                      />
                      <Input
                        id="datos"
                        label={t("registerData")}
                        value={ele.datos}
                        onChange={(e) =>
                          handleChangeLand(e, indx, "activosInmobiliarios")
                        }
                      />
                    </Div>
                    <Div width="10%">
                      {indx !== 0 && (
                        <IconButton
                          icon={deleteIcon}
                          width="37px"
                          height="37px"
                          onClick={() =>
                            handleDelete(
                              land.activosInmobiliarios,
                              indx,
                              "activosInmobiliarios"
                            )
                          }
                        />
                      )}
                    </Div>
                  </Card>
                ))}
              </Div>
              <Link
                size={theme.fonts.size.sm}
                icon={
                  <img alt="add" src={addIcon} width={"24px"} height={"24px"} />
                }
                onClick={handleAddAsset}
                style={{ marginTop: "15px" }}
              >
                {t("addAsset")}
              </Link>
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("lands.ques2")}
              </Text>
              <Div direction="column" gap="25px" align="center">
                {land.relacionDeBienes.map((ele, indx) => (
                  <Card key={indx}>
                    <Div direction="column" width="85%" gap="8px">
                      <Input
                        id="nombre"
                        label={t("name")}
                        value={ele.nombre}
                        onChange={(e) =>
                          handleChangeLand(e, indx, "relacionDeBienes")
                        }
                      />
                      <Input
                        id="identificacion"
                        label={t("identification")}
                        value={ele.identificacion}
                        onChange={(e) =>
                          handleChangeLand(e, indx, "relacionDeBienes")
                        }
                      />
                    </Div>
                    <Div width="10%">
                      {indx !== 0 && (
                        <IconButton
                          icon={deleteIcon}
                          width="37px"
                          height="37px"
                          onClick={() =>
                            handleDelete(
                              land.relacionDeBienes,
                              indx,
                              "relacionDeBienes"
                            )
                          }
                        />
                      )}
                    </Div>
                  </Card>
                ))}
              </Div>
              <Link
                size={theme.fonts.size.sm}
                icon={
                  <img alt="add" src={addIcon} width={"24px"} height={"24px"} />
                }
                onClick={handleAddProperty}
                style={{ marginTop: "15px" }}
              >
                {t("addProperty")}
              </Link>
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("lands.ques3")}
              </Text>
              <RadioGroup
                name="cargosYGravamenes"
                gap={"48px"}
                value={land.cargosYGravamenes}
                onChange={(e) => handleRadio(e, land, setLand)}
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
          </>
        )}
        {id === "tax" && (
          <>
            <Col md={24}>
              <Input
                id="impuestoConstruccion"
                label={t("taxes.ques1")}
                suffix={<img alt="euro" src={euro} />}
                value={taxes.impuestoConstruccion}
                onChange={(e) => handleChangeNumber(e, taxes, setTaxes)}
              />
            </Col>
            <Col md={24}>
              <Input
                id="otrosImpuestos"
                label={t("taxes.ques2")}
                suffix={<img alt="euro" src={euro} />}
                value={taxes.otrosImpuestos}
                onChange={(e) => handleChangeNumber(e, taxes, setTaxes)}
              />
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("taxes.ques3")}
              </Text>
              <RadioGroup
                name="bonificacionImpuestos"
                gap={"48px"}
                value={taxes.bonificacionImpuestos}
                onChange={(e) => handleRadio(e, taxes, setTaxes)}
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
          </>
        )}
        {id === "supp" && (
          <>
            <Col md={24}>
              <Input
                id="proveedorPaneles"
                label={t("suppliers.ques1")}
                value={providers.proveedorPaneles}
                onChange={(e) => handleChange(e, providers, setProviders)}
              />
            </Col>
            <Col md={24}>
              <Input
                id="proveedorInversores"
                label={t("suppliers.ques2")}
                value={providers.proveedorInversores}
                onChange={(e) => handleChange(e, providers, setProviders)}
              />
            </Col>
            <Col md={24}>
              <Input
                id="proveedorSeguidores"
                label={t("suppliers.ques3")}
                value={providers.proveedorSeguidores}
                onChange={(e) => handleChange(e, providers, setProviders)}
              />
            </Col>
            <Col md={24}>
              <Input
                id="EPCista"
                label={t("suppliers.ques4")}
                value={providers.EPCista}
                onChange={(e) => handleChange(e, providers, setProviders)}
              />
            </Col>
            <Col md={24}>
              <Input
                id="otrosProveedores"
                label={t("suppliers.ques5")}
                value={providers.otrosProveedores}
                onChange={(e) => handleChange(e, providers, setProviders)}
              />
            </Col>
            <Col md={24}>
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.gray200}
                mt="2px"
                mb="8px"
                align="left"
              >
                {t("suppliers.ques6")}
              </Text>
              <RadioGroup
                name="existeContratoPrestacionServicios"
                gap={"48px"}
                value={providers.existeContratoPrestacionServicios}
                onChange={(e) => handleRadio(e, providers, setProviders)}
              >
                <Radio label={t("yes")} value={true} />
                <Radio label={t("no")} value={false} />
              </RadioGroup>
            </Col>
            <Col md={24}>
              <Input
                id="operadorOM"
                label={t("suppliers.ques7")}
                value={providers.operadorOM}
                onChange={(e) => handleChange(e, providers, setProviders)}
              />
            </Col>
          </>
        )}
        {id === "insu" && (
          <>
            <Col md={24}>
              <Input
                id="brokerSeguros"
                label={t("insuranceAndGuarantees.ques1")}
                value={insurance.brokerSeguros}
                onChange={(e) => handleChange(e, insurance, setInsurance)}
              />
            </Col>
          </>
        )}
      </Row>
    </TransparentScroll>
  );
};

DrawerContent.propTypes = {
  id: PropTypes.string,
  interconnection: PropTypes.object,
  setInterconnection: PropTypes.func,
  projectInfo: PropTypes.object,
  setProjectInfo: PropTypes.func,
  plantPermissions: PropTypes.object,
  setPlantPermissions: PropTypes.func,
  environmental: PropTypes.object,
  setEnvironmental: PropTypes.func,
  land: PropTypes.object,
  setLand: PropTypes.func,
  taxes: PropTypes.object,
  setTaxes: PropTypes.func,
  providers: PropTypes.object,
  setProviders: PropTypes.func,
  insurance: PropTypes.object,
  setInsurance: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  handleRadio: PropTypes.func,
  handleSelect: PropTypes.func,
  handleChangeLand: PropTypes.func,
  handleAddAsset: PropTypes.func,
  handleAddProperty: PropTypes.func,
  handleDelete: PropTypes.func,
};
