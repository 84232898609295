import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const TransparentScroll = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 12px 0;
  max-height: 564px;
  height: 564px;
  overflow-x: hidden;

  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* Color del scrollbar */

  /* WebKit */
  &::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Color del thumb del scrollbar */
    border-radius: 10px; /* Borde redondeado */
  }
`;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${theme.colors.light} 0% 0% no-repeat padding-box;
  opacity: 1;
`;

export const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 122px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  border: 1px solid ${theme.colors.gray100};
  border-radius: 12px;
  padding: 13px 0 18px 0;
  gap: 20px;
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
`;
