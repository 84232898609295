import React from "react";
import PropTypes from "prop-types";
import { Table } from "../../../components/Table";
import { Div } from "../../../styles/Common";
import { Skeleton } from "../../../components/Skeleton";

export const SaleTable = ({ 
  tableColumns, 
  data, 
  pages, 
  totalItems, 
  handleChangeTable,
  isLoading
}) => {
  return (
    <Table
      columns={tableColumns}
      data={data}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      pagination={{
        defaultCurrent: 1,
        ...pages,
        total: totalItems,
        position: ["bottomRight"],
      }}
      onChange={handleChangeTable}
      locale={{
        emptyText: isLoading ? (
          <Div justify="center" gap="16px">
            {[...Array(9)].map((item, idx) => (
              <Skeleton
                key={idx}
                title={false}
                paragraph={{
                  rows: 10,
                  width: "100%",
                }}
                loading
              />
            ))}
          </Div>
        ) : (
          "No Data"
        ),
      }}
    />
  )
};

SaleTable.propTypes = {
  tableColumns: PropTypes.array, 
  data: PropTypes.array, 
  pages: PropTypes.object, 
  totalItems: PropTypes.number, 
  handleChangeTable: PropTypes.func,
  isLoading: PropTypes.bool,
};
