import { formatNumber } from "../helpers";

const valueTypes = ["", "-", "#######", null, undefined, "NaN"];

export const unformatNumber = (value) => {
  const newValue = value.toString()
    .replace(/,/g, "") 
    .replace(/\./g, "."); 

  return parseFloat(newValue); 
};

export const formatValue = (value, locale) => {
  if (valueTypes.includes(value)) {
    return "";
  }
  return formatNumber(unformatNumber(value), locale);
};