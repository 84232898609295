import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const usePostInformationDeclaration = () => {
    const mutation = useMutation((formData) => {
        return axiosClient.post(`/informational-declaration/answer`, formData);
    });
    return mutation;
};

export default usePostInformationDeclaration;