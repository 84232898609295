import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetQuestions = (questionType, plantId) => {
  const data = useQuery(
    ["getQuestionsList", questionType, plantId],
    () => axiosClient.get(`question?questionType=${questionType}&plantId=${plantId}`), { enabled: questionType !== "" });

  return data;
};
export default useGetQuestions;