import React, { useState } from "react";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import { Modal } from "../../../components/Modal";
import { useTranslation } from "react-i18next";
import { Text, Row, Col, Div } from "../../../styles/Common";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import useSendPromoterInvitations from "../hooks/useSendPromoterInvitations";
import { validateArrayInvitePromoter } from "../../../utilities/validations";
import { Link } from "../../../components/Link";
import { DeleteIcon } from "../../../assets/icons";

export const ModalInvitePromoter = ({ showModal, handleClose }) => {
  const { t } = useTranslation("users");
  const invite = useSendPromoterInvitations();

  const [promoterForm, setPromoterForm] = useState([{ name: "", email: "" }]);
  const [errorForm, setErrorForm] = useState([
    {
      name: { error: false, message: "" },
      email: { error: false, message: "" },
    },
  ]);

  const handleAddPromoter = () => {
    setPromoterForm([...promoterForm, { name: "", email: "" }]);
    const newErrorForm = {
      name: { error: false, message: "" },
      email: { error: false, message: "" },
    };
    setErrorForm([...errorForm, newErrorForm]);
  };

  const handleDeletePromoter = (indexToDelete) => {
    setPromoterForm(promoterForm.filter((_, index) => index !== indexToDelete));
    setErrorForm(errorForm.filter((_, index) => index !== indexToDelete));
  };

  const handleChange = (index, event) => {
    const { value, id } = event.target;

    const newErrorForm = [...errorForm];
    newErrorForm[index][id].error = false;
    newErrorForm[index][id].message = "";
    setErrorForm(newErrorForm);

    const newPromoterForm = [...promoterForm];
    newPromoterForm[index][id] = value;
    setPromoterForm(newPromoterForm);
  };

  const handleCloseAndReset = () => {
    setPromoterForm([{ name: "", email: "" }]);
    setErrorForm([
      {
        name: { error: false, message: "" },
        email: { error: false, message: "" },
      },
    ]);
    handleClose();
  };

  const handleInvite = () => {
    const validation = validateArrayInvitePromoter.validate(promoterForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = [...errorForm];
      validation.error.details.forEach((detail) => {
        const index = detail?.path[0];
        const fieldName = detail?.path[1];
        newErrorForm[index][fieldName] = {
          error: true,
          message: t("invalidFormat"),
        };
      });
      setErrorForm(newErrorForm);
    } else {
      invite.reset();
      invite.mutate(promoterForm, {
        onSuccess: () => {
          toast.success(t("promoterComponent.successInvitation"));
          handleCloseAndReset();
        },
        onError: (err) => {
          toast.error(
            err.response.data.result.errorMessage?.message ||
              err.response.data.result.errorMessage
          );
        },
      });
    }
  };

  return (
    <Modal open={showModal} onCancel={handleCloseAndReset} width={"478px"}>
      <Modal.Header
        padding={"20px 0px 0px 41px"}
        title={t("promoterComponent.invitePromoter")}
        weightTitle={theme.fonts.weight.medium}
        sizeTitle={theme.fonts.size.h5i}
      />
      <Modal.Body align={"center"} padding="20px 40px">
        <Row m={"0 0 24px 0"}>
          <Text color={theme.colors.gray500}>
            {t("promoterComponent.invitePromoterMessage")}
          </Text>
        </Row>
        {promoterForm.map((promoter, index) => (
          <Col key={index}>
            <Row justify="space-between" align="center">
              <Text
                color={theme.colors.blue}
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h5i}
              >
                {`${t("promoter")} ${index + 1}`}
              </Text>
              {promoterForm.length > 1 && (
                <Div
                  background={theme.colors.light}
                  width="48px"
                  height="48px"
                  radius="50%"
                  align="center"
                  justify="center"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeletePromoter(index)}
                >
                  <DeleteIcon fill={theme.colors.gray300} />
                </Div>
              )}
            </Row>
            <Row>
              <Input
                id="name"
                label={t("fullName")}
                width={"100%"}
                value={promoter.name}
                onChange={(event) => handleChange(index, event)}
                error={errorForm[index].name.error}
                helper={
                  errorForm[index].name.error
                    ? errorForm[index].name.message
                    : ""
                }
              />
            </Row>
            <Row m={"0 0 16px 0"}>
              <Input
                id="email"
                label={t("email")}
                width={"100%"}
                value={promoter.email}
                onChange={(event) => handleChange(index, event)}
                error={errorForm[index].email.error}
                helper={
                  errorForm[index].email.error
                    ? errorForm[index].email.message
                    : ""
                }
              />
            </Row>
          </Col>
        ))}

        <Row m={"0 0 11px 0"}>
          <Link onClick={handleAddPromoter}>
            {t("promoterComponent.addPromoter")}
          </Link>
        </Row>
      </Modal.Body>
      <Modal.Footer align="center" justify="center">
        <Col direction="column" align="center" justify="center" gap="24px">
          <Button
            width={"167px"}
            onClick={handleInvite}
            loading={invite.isLoading}
          >
            {t("sendInvitation")}
          </Button>
          <Link onClick={handleCloseAndReset}>{t("cancel")}</Link>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

ModalInvitePromoter.propTypes = {
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
};
