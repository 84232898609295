import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useUpdateAdditionalInfoPlants = () => {
  const mutation = useMutation(({ plantId, formData }) => {
    return axiosClient.post(
      `/plant/additional-information/${plantId}`,
      formData
    );
  });
  return mutation;
};
export default useUpdateAdditionalInfoPlants;
