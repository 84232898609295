import styled from "styled-components";
import { theme } from "../../styles/theme";

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 22px 26px 26px 29px;
  background-color: ${theme.colors.blue};
  min-height: 137px;
  width: 100%;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 332px;
  height: 378px;
  padding: 32px 25px 36px 26px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 26px #0000000B;
  border-radius: 15px;
  opacity: 1;
`

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  border-radius: 100%;
  background: ${theme.colors.green} 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-right: 14px;
`
export const CardLink = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  padding: 16px 24px 25px 24px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000010;
  border-radius: 14px;
  opacity: 1;
`

export const WrapperUploadIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: ${theme.colors.gray100} 0% 0% no-repeat padding-box;
  opacity: 1;
`;

export const WrapperDocument = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 70px;
  height: 70px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  border: 1px solid ${theme.colors.gray200};
  border-radius: 7px;
  opacity: 1;
  padding: 0px 10px 0px 18px;
`;

export const WrapperDocIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: ${theme.colors.light} 0% 0% no-repeat padding-box;
  opacity: 1;
`;