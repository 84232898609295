import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonEN from "./locales/en/common.json";
import commonES from "./locales/es/common.json";
import loginEN from "./locales/en/login.json";
import loginES from "./locales/es/login.json";
import resgisterEN from "./locales/en/register.json";
import resgisterES from "./locales/es/register.json";
import resetPassReqEN from "./locales/en/resetPassReq.json";
import resetPassReqES from "./locales/es/resetPassReq.json";
import myAccountEN from "./locales/en/myAccount.json";
import myAccountES from "./locales/es/myAccount.json";
import resetPasswordEN from "./locales/en/resetPassword.json";
import resetPasswordES from "./locales/es/resetPassword.json";
import projectsEN from "./locales/en/projects.json";
import projectsES from "./locales/es/projects.json";
import newProjectEN from "./locales/en/newProject.json";
import newProjectES from "./locales/es/newProject.json";
import newPlantEN from "./locales/en/newPlant.json";
import newPlantES from "./locales/es/newPlant.json";
import projectDetailES from "./locales/es/projectDetail.json";
import projectDetailEN from "./locales/en/projectDetail.json";
import infoPlantsES from "./locales/es/infoPlants.json";
import infoPlantsEN from "./locales/en/infoPlants.json";
import investorProjectDetailES from "./locales/es/investorProjectDetail.json";
import investorProjectDetailEN from "./locales/en/investorProjectDetail.json";
import teaserDocumentationEN from "./locales/en/teaserDocumentation.json";
import teaserDocumentationES from "./locales/es/teaserDocumentation.json";
import seeInvitationsEN from "./locales/en/seeInvitations.json";
import seeInvitationsES from "./locales/es/seeInvitations.json";
import scraperES from "./locales/es/scraper.json";
import scraperEN from "./locales/en/scraper.json";
import dealIntelEN from "./locales/en/dealIntel.json";
import dealIntelES from "./locales/es/dealIntel.json";

import documentsES from "./locales/es/documents.json";
import documentsEN from "./locales/en/documents.json";

import ProjectsTableManagerES from "./locales/es/projectTableManager.json";
import ProjectsTableManagerEN from "./locales/en/projectTableManager.json";

import projectTableES from "./locales/es/projectTable.json";
import ProjectsTableEN from "./locales/en/projectTable.json";

import projectsTabsViewES from "./locales/es/projectsTabsView.json";
import projectsTabsViewEN from "./locales/en/projectsTabsView.json";

import informationDeclarationES from "./locales/es/informationDeclaration.json";
import informationDeclarationEN from "./locales/en/informationDeclaration.json";

import strategiesES from "./locales/es/strategies.json";
import strategiesEN from "./locales/en/strategies.json";

import usersEN from "./locales/en/users.json";
import usersES from "./locales/es/users.json";

import inviteInvestorEN from "./locales/en/inviteInvestor.json";
import inviteInvestorES from "./locales/es/inviteInvestor.json";

import executionDocsEN from "./locales/en/executionDocs.json";
import executionDocsES from "./locales/es/executionDocs.json";

import indicativeTermSheetEN from "./locales/en/indicativeTermSheet.json";
import indicativeTermSheetES from "./locales/es/indicativeTermSheet.json";

import financialDashboardDetailProjectEN from "./locales/en/financialDashboardDetailProject.json";
import financialDashboardDetailProjectES from "./locales/es/financialDashboardDetailProject.json";

import financialDashboardEN from "./locales/en/financialDashboard.json";
import financialDashboardES from "./locales/es/financialDashboard.json";

import investorActivityEN from "./locales/en/investorActivity.json";
import investorActivityES from "./locales/es/investorActivity.json";

import financialModelEN from "./locales/en/financialModel.json";
import financialModelES from "./locales/es/financialModel.json";

import managerQuestionsEN from "./locales/en/managerQuestions.json";
import managerQuestionsES from "./locales/es/managerQuestions.json";

import projectActivityEN from "./locales/en/projectActivity.json";
import projectActivityES from "./locales/es/projectActivity.json";

const lng = localStorage.getItem("lng") || "es";

const resources = {
  en: {
    common: commonEN,
    login: loginEN,
    register: resgisterEN,
    resetPassReq: resetPassReqEN,
    myAccount: myAccountEN,
    resetPassword: resetPasswordEN,
    projects: projectsEN,
    newProject: newProjectEN,
    newPlant: newPlantEN,
    projectDetail: projectDetailEN,
    documents: documentsEN,
    projectTable: ProjectsTableEN,
    projectTableManager: ProjectsTableManagerEN,
    projectsTabsView: projectsTabsViewEN,
    infoPlants: infoPlantsEN,
    informationDeclaration: informationDeclarationEN,
    investorProjectDetail: investorProjectDetailEN,
    teaserDocumentation: teaserDocumentationEN,
    strategies: strategiesEN,
    users: usersEN,
    executionDocs: executionDocsEN,
    indicativeTermSheet: indicativeTermSheetEN,
    financialDashboardDetailProject: financialDashboardDetailProjectEN,
    financialDashboard: financialDashboardEN,
    seeInvitations: seeInvitationsEN,
    dealIntel: dealIntelEN,
    scraper: scraperEN,
    inviteInvestor: inviteInvestorEN,
    investorActivity: investorActivityEN,
    financialModel: financialModelEN,
    managerQuestions: managerQuestionsEN,
    projectActivity: projectActivityEN,
  },
  es: {
    common: commonES,
    login: loginES,
    register: resgisterES,
    resetPassReq: resetPassReqES,
    myAccount: myAccountES,
    resetPassword: resetPasswordES,
    projects: projectsES,
    newProject: newProjectES,
    newPlant: newPlantES,
    projectDetail: projectDetailES,
    documents: documentsES,
    projectTable: projectTableES,
    projectTableManager: ProjectsTableManagerES,
    projectsTabsView: projectsTabsViewES,
    infoPlants: infoPlantsES,
    informationDeclaration: informationDeclarationES,
    investorProjectDetail: investorProjectDetailES,
    teaserDocumentation: teaserDocumentationES,
    strategies: strategiesES,
    users: usersES,
    executionDocs: executionDocsES,
    indicativeTermSheet: indicativeTermSheetES,
    financialDashboardDetailProject: financialDashboardDetailProjectES,
    financialDashboard: financialDashboardES,
    seeInvitations: seeInvitationsES,
    dealIntel: dealIntelES,
    scraper: scraperES,
    inviteInvestor: inviteInvestorES,
    investorActivity: investorActivityES,
    financialModel: financialModelES,
    managerQuestions: managerQuestionsES,
    projectActivity: projectActivityES
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
