import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import common from "./common";
import projects from "./projects";

const persistConfig = {
  key: "root",
  storage,
};

const commonPersistedReducer = persistReducer(persistConfig, common);
const projectsPersistReducer = persistReducer(persistConfig, projects);

const store = configureStore({
  reducer: {
    common: commonPersistedReducer,
    projects: projectsPersistReducer,
  },
});

const persistor = persistStore(store);

export { store, persistor };
