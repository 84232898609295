import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDocuments = (
  projectId,
  uploadType,
  category,
  plantId,
) => {
  const enabled = uploadType !== null && category !== null;
  let urlString = `/documentation?projectId=${projectId}&uploadtype=${uploadType}&category=${category}`;

  if (plantId && plantId.length > 1) {
    urlString += `&plantId=${plantId}`;
  }

  const data = useQuery(
    [
      "documentsList",
      projectId,
      uploadType,
      category,
      plantId,
    ],
    () => axiosClient.get(urlString), { refetchOnWindowFocus: false, enabled: enabled }
  );

  return data;
};
export default useGetDocuments;

