/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import ReactLoading from "react-loading";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import arrowLeft from "../../assets/icons/arrow-left.svg"
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { Collapse } from "../../components/Collapse";
import { Upload } from "../../components/Upload";
import { ExtraLabel } from "./components/ExtraLabel";
import useGetGroupDocuments from "./hooks/useGetGroupDocuments";
import useSavePlantsDocuments from "../PlantDocuments/hooks/useSavePlantsDocs";

export const DueDocuments = () => {
  const { t } = useTranslation("documents");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const projectName = new URLSearchParams(search).get("projectName");
  const projectId = new URLSearchParams(search).get("projectId");
  const [activeKey, setActiveKey] = useState(["0"]);
  const subdomain = ["due01", "due02", "due03", "due04", "due05"];
  const getDocumentsList = useGetGroupDocuments(projectId, 2, 4, subdomain);
  const saveDocs = useSavePlantsDocuments();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [docList, setDocList] = useState([
    {
      key: "1",
      code: "due01",
      documents: [],
    },
    {
      key: "2",
      code: "due02",
      documents: [],
    },
    {
      key: "3",
      code: "due03",
      documents: [],
    },
    {
      key: "4",
      code: "due04",
      documents: [],
    },
    {
      key: "5",
      code: "due05",
      documents: [],
    },
  ]);

  const allRequestsSuccessful = getDocumentsList?.every(
    (ele) => ele.isSuccess && !!ele.data
  );

  useEffect(() => {
    if (allRequestsSuccessful) {
      const savedDocs = getDocumentsList.map((ele) => ele.data.data.data);
      const documentsList = docList.map((item, index) => {
        if (savedDocs[index].length > 0) {
          return {
            ...item,
            documents: savedDocs[index]
          };
        }
        return item;
      });
      
      setDocList(documentsList);
      setForceUpdate(!forceUpdate);
    }
  }, [allRequestsSuccessful]);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const handleBack = () => {
    navigate(`/projects/documents?projectName=${projectName}&projectId=${projectId}`)
  };

  const handleSave = (list, code) => {
    const currentDocuments = list.find((ele) => ele.code === code)?.documents;

    const payload = {
      description: "",
      projectId: projectId,
      uploadtype: 2,
      category: 4,
      subdomain: code,
      documents: [...currentDocuments]
    }

    saveDocs.reset();
    saveDocs.mutate(
      { ...payload },
      {
        onSuccess: () => {
          toast.success(t("documentsSuccess"));
          queryClient.invalidateQueries(["dueDocumentsList", projectId, 2, 4, subdomain])
        },
        onError: (err) => {
          toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
            "No ha sido posible guardar los documentos, por favor intenta nuevamente";
        },
      }
    );
    setForceUpdate(!forceUpdate);
  };

  const maxSize = 500000000;  
  const [processedFiles, setProcessedFiles] = useState(new Set());
  const [loadedDocuments, setLoadedDocuments] = useState([]);
  const [errorFiles, setErrorFiles] = useState(new Set());

  const handleUploadDocument = useCallback((file, documentCode) => {
    if (processedFiles.has(file.name) || errorFiles.has(file.name)) return;

    const reader = new FileReader();
    reader.onload = () => {
      const newDoc = {
        name: file.name,
        size: file.size,
        doc: file,
        code: documentCode,
      };

      if (
        file?.type &&
        file?.size &&
        file.type === "application/pdf" &&
        file.size < maxSize
      ) {
        setDocList((prevDocList) =>
          prevDocList.map((doc) =>
            doc.code === documentCode
              ? { ...doc, documents: [...doc.documents, newDoc] }
              : doc
          )
        );

        setProcessedFiles((prev) => new Set(prev).add(file.name));
        setLoadedDocuments((prev) => [...prev, newDoc]);

        const updatedDocs = docList.map((doc) =>
          doc.code === documentCode
            ? { ...doc, documents: [...doc.documents, newDoc] }
            : doc
        );
        handleSave(updatedDocs, documentCode);
      } else {
        if (!errorFiles.has(file.name)) {
          setErrorFiles((prev) => new Set(prev).add(file.name));
          toast.error(t("newProject:invalidDocument"));
        }
      }
    };
    reader.readAsDataURL(file);
  }, [processedFiles, errorFiles, loadedDocuments, docList, handleSave, maxSize]);

  const handleChangeDoc = (info, code) => {
    const { file } = info;
    if (file.status === 'done' || file.status === 'uploading') {
      if (file.originFileObj) {
        handleUploadDocument(file.originFileObj, code);
      }
    }
  };

  const handleDeleteDocument = (index, code) => {
    const currentDocuments = docList.find((ele) => ele.code === code);
    const allDocs = docList.filter((ele) => ele.code !== code);
    const newDocs = currentDocuments.documents.filter((ele, indx) => indx !== index);

    setDocList([...allDocs, {...currentDocuments, documents: newDocs}]);
    handleSave([...allDocs, {...currentDocuments, documents: newDocs}], code);
  }

  const panel = (documents) => {  
    return (
      <Div m="32px 0 0 0">
        <Upload
          docsList={documents.documents}
          handleRemoveDocument={handleDeleteDocument}
          handleUploadDocument={handleChangeDoc}
          documentCode={documents.code}
          direction={'row'}
        />
      </Div>
    );
  };

  const panels = [
    {
      key: "1",
      header: t("dueDoc1"),
      panel: panel(docList.find((ele) => ele.key === "1")),
      extra: <ExtraLabel totalDocuments={docList.find((ele) => ele.key === "1")?.documents.length} />
    },
    {
      key: "2",
      header: t("dueDoc2"),
      panel: panel(docList.find((ele) => ele.key === "2")),
      extra: <ExtraLabel totalDocuments={docList.find((ele) => ele.key === "2")?.documents.length} />
    },
    {
      key: "3",
      header: t("dueDoc3"),
      panel: panel(docList.find((ele) => ele.key === "3")),
      extra: <ExtraLabel totalDocuments={docList.find((ele) => ele.key === "3")?.documents.length} />
    },
    {
      key: "4",
      header: t("dueDoc4"),
      panel: panel(docList.find((ele) => ele.key === "4")),
      extra: <ExtraLabel totalDocuments={docList.find((ele) => ele.key === "4")?.documents.length} />
    },
    {
      key: "5",
      header: t("dueDoc5"),
      panel: panel(docList.find((ele) => ele.key === "5")),
      extra: <ExtraLabel totalDocuments={docList.find((ele) => ele.key === "5")?.documents.length} />
    },
  ];

  if (!allRequestsSuccessful) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div> 
    )
  }

  return (
    <ModuleContainer direction="column" padding="17px 69px 56px 32px">
      <Link icon={<img alt="arrow" src={arrowLeft} />} onClick={handleBack}>
        {t("back")}
      </Link>
      <Text 
        size={theme.fonts.size.h5i}
        color={theme.colors.blue}
        weight={theme.fonts.weight.medium}
        mt="28px"
      >
        {t("dueDiligences")}
      </Text>
      <Text mt="16px" color={theme.colors.gray300}>
        {t("dueNote")}
      </Text>
      <Div m="32px 0 0 0">
        <Collapse
          panels={panels} 
          width={"100%"} 
          background={theme.colors.light}
          activeKey={activeKey}
          handlePanelChange={handlePanelChange}
          headerHeight={"75px"}
        />
      </Div>
      <Text
        color={theme.colors.gray300}
        size={theme.fonts.size.sm}
        style={{ textAlign: "center" }}
        mt="52px"
      >
        {t("common:poweredBy")}
      </Text>
    </ModuleContainer>
  )
}