import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Text } from "../../../styles/Common";
import { Col, Row, Div } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { CopyIcon, DownloadIcon } from "../../../assets/icons";
import { Card } from "../../../components/Card";
import { Link } from "../../../components/Link";
import { useTranslation } from "react-i18next";
import useGetDocumentsByProjectId from "../hooks/useGetDocumentsByProjectId";
import { downloadFile, getToken } from "../../../utilities/helpers";
import { axiosClient } from "../../../core/services/axiosInstance";
import { DocumentTypes } from "../../../utilities/enums/documentTypes";
import { UploadTypes } from "../../../utilities/enums/uploadTypes";

export const DocumentationInvestor = ({ projectId }) => {
  const { t } = useTranslation("investorProjectDetail");

  const { data, isSuccess } = useGetDocumentsByProjectId(projectId);
  const [massiveDocuments, setMassiveDocuments] = useState([]);
  const [linkDocuments, setLinkDocuments] = useState([]);
  const [detailedDocuments, setDetailedDocuments] = useState([]);

  useEffect(() => {
    if (isSuccess && data?.data?.data) {
      setMassiveDocuments(
        data?.data?.data.filter(
          (item) => item.uploadtype === UploadTypes.DOCUMENT && item.category === DocumentTypes.MASSIVE
        )
      );
      setLinkDocuments(
        data?.data?.data.filter((item) => item.uploadtype === UploadTypes.LINK)
      );
      setDetailedDocuments(
        data?.data?.data.filter(
          (item) =>
            item.uploadtype === UploadTypes.DOCUMENT &&
            (item.category === DocumentTypes.PLANTS || item.category === DocumentTypes.SOCIETARIO || item.category === DocumentTypes.DUE)
        )
      );
    }
  }, [data, isSuccess]);

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
  };

  const handleDownloadMassive = () => {
    downloadFile(
      `documentation/download-all-files/${projectId}/${DocumentTypes.MASSIVE}`,
      "Project documentation",
      axiosClient,
      getToken()
    );
  };

  const handleDownloadDocument = (category) => {
    downloadFile(
      `documentation/download-all-files/${projectId}/${category}`,
      "Project documentation",
      axiosClient,
      getToken()
    );
  };

  return (
    <>
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h5i}
        color={theme.colors.blue}
        mb="10px"
      >
        {t("documentationTransactional")}
      </Text>
      {massiveDocuments.length > 0 && (
        <Col>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mb="15px"
          >
            {t("compressed")}
          </Text>
          <Card
            margin="0 0 24px 0"
            width="100%"
            height="auto"
            padding="23px 35px 34px 26px"
          >
            <Button
              width="305px"
              height="45px"
              variant={"outlined"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
              onClick={handleDownloadMassive}
            >
              <DownloadIcon fill={theme.colors.green} />
              {t("projectDocumentation")}
            </Button>
          </Card>
        </Col>
      )}

      {linkDocuments.length > 0 && (
        <Col>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mb="15px"
          >
            {t("uploaded")}
          </Text>
          {linkDocuments.filter((plant) => plant.category === DocumentTypes.PLANTS).length > 0 && (
            <Card
              margin="0 0 24px 0"
              width="100%"
              height="auto"
              padding="23px 35px 34px 26px"
            >
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h6}
                color={theme.colors.blue}
                mb="10px"
              >
                {t("plants")}
              </Text>
              <Row
                gap="20px"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                {linkDocuments
                  .filter((plant) => plant.category === DocumentTypes.PLANTS)
                  .map((plant, index) => (
                    <Div
                      key={index}
                      height="auto"
                      p="0 20px 0 20px"
                      justify="center"
                      background={theme.colors.white}
                      direction="column"
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                      >
                        {`${t("link")} ${index + 1}`}
                      </Text>
                      <Row align="center" justify="space-between">
                        <Text
                          color={theme.colors.green}
                          minheight="53px"
                          align="left"
                          style={{ width: "420px", wordWrap: "break-word" }}
                        >
                          {plant.url}
                        </Text>
                        <Link onClick={() => handleCopy(plant.url)}>
                          <CopyIcon />
                        </Link>
                      </Row>
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                        weight={theme.fonts.weight.bold}
                      >
                        {t("description")}:
                      </Text>
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                      >
                        {plant.description}
                      </Text>
                    </Div>
                  ))}
              </Row>
            </Card>
          )}
          {linkDocuments.filter((plant) => plant.category === DocumentTypes.SOCIETARIO).length > 0 && (
            <Card
              margin="0 0 24px 0"
              width="100%"
              height="auto"
              padding="23px 35px 34px 26px"
            >
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h6}
                color={theme.colors.blue}
                mb="10px"
              >
                {t("corporate")}
              </Text>
              <Row
                gap="20px"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                {linkDocuments
                  .filter((plant) => plant.category === DocumentTypes.SOCIETARIO)
                  .map((document, index) => (
                    <Div
                      key={index}
                      height="auto"
                      p="0 20px 0 20px"
                      justify="center"
                      background={theme.colors.white}
                      direction="column"
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                      >
                        {`${t("link")} ${index + 1}`}
                      </Text>
                      <Row align="center" justify="space-between">
                        <Text
                          color={theme.colors.green}
                          minheight="53px"
                          align="left"
                          style={{ width: "420px", wordWrap: "break-word" }}
                        >
                          {document.url}
                        </Text>
                        <Link onClick={() => handleCopy(document.url)}>
                          <CopyIcon />
                        </Link>
                      </Row>
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                        weight={theme.fonts.weight.bold}
                      >
                        {t("description")}:
                      </Text>
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                      >
                        {document.description}
                      </Text>
                    </Div>
                  ))}
              </Row>
            </Card>
          )}
          {linkDocuments.filter((plant) => plant.category === DocumentTypes.DUE).length > 0 && (
            <Card
              margin="0 0 24px 0"
              width="100%"
              height="auto"
              padding="23px 35px 34px 26px"
            >
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h6}
                color={theme.colors.blue}
                mb="10px"
              >
                Due Diligences
              </Text>
              <Row
                gap="20px"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                {linkDocuments
                  .filter((plant) => plant.category === DocumentTypes.DUE)
                  .map((diligence, index) => (
                    <Div
                      key={index}
                      height="auto"
                      p="0 20px 0 20px"
                      justify="center"
                      background={theme.colors.white}
                      direction="column"
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                      >
                        {`${t("link")} ${index + 1}`}
                      </Text>
                      <Row align="center" justify="space-between">
                        <Text
                          color={theme.colors.green}
                          minheight="53px"
                          align="left"
                          style={{ width: "420px", wordWrap: "break-word" }}
                        >
                          {diligence.url}
                        </Text>
                        <Link onClick={() => handleCopy(diligence.url)}>
                          <CopyIcon />
                        </Link>
                      </Row>
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                        weight={theme.fonts.weight.bold}
                      >
                        {t("description")}:
                      </Text>
                      <Text
                        color={theme.colors.gray200}
                        minheight="53px"
                        align="left"
                      >
                        {diligence.description}
                      </Text>
                    </Div>
                  ))}
              </Row>
            </Card>
          )}
        </Col>
      )}

      {detailedDocuments.length > 0 && (
        <Col>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mb="15px"
          >
            {t("compressedFiles")}
          </Text>
          <Card
            margin="0 0 24px 0"
            width="100%"
            height="auto"
            padding="23px 35px 34px 26px"
          >
            <Row justify="space-between">
              {detailedDocuments.filter((item) => item.category === DocumentTypes.PLANTS).length >
                0 && (
                <Button
                  width="305px"
                  height="45px"
                  variant={"outlined"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                  onClick={() => handleDownloadDocument(DocumentTypes.PLANTS)}
                >
                  <DownloadIcon fill={theme.colors.green} />
                  {t("plantInformation")}
                </Button>
              )}

              {detailedDocuments.filter((item) => item.category === DocumentTypes.SOCIETARIO).length >
                0 && (
                <Button
                  width="305px"
                  height="45px"
                  variant={"outlined"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                  onClick={() => handleDownloadDocument(DocumentTypes.SOCIETARIO)}
                >
                  <DownloadIcon fill={theme.colors.green} />
                  {t("corporate")}
                </Button>
              )}

              {detailedDocuments.filter((item) => item.category === DocumentTypes.DUE).length >
                0 && (
                <Button
                  width="305px"
                  height="45px"
                  variant={"outlined"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                  onClick={() => handleDownloadDocument(DocumentTypes.DUE)}
                >
                  <DownloadIcon fill={theme.colors.green} />
                  Due diligence
                </Button>
              )}
            </Row>
          </Card>
        </Col>
      )}
    </>
  );
};

DocumentationInvestor.propTypes = {
  projectId: PropTypes.string,
  termsApproved: PropTypes.bool,
  setShowModal: PropTypes.func,
};
