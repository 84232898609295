/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import upload from "../../assets/icons/upload-green.svg"
import arrowLeft from "../../assets/icons/arrow-left.svg"
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import useGetPlantsList from "../NewProject/hooks/useGetPlants";
import { Card, WrapperIcon } from "./styles";
import { Progress } from "../../components/Progress";

export const PlantsDocumentsList = () => {
  const { t } = useTranslation("documents");
  const navigate = useNavigate();
  const { search } = useLocation();
  const projectName = new URLSearchParams(search).get("projectName");
  const projectId = new URLSearchParams(search).get("projectId");
  const { data, isSuccess, isLoading } = useGetPlantsList(projectId);
  const [plantsList, setPlantsList] = useState([]);

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      setPlantsList(
        data.data.filter((objeto) => !objeto.hasOwnProperty("removedBy"))
      );
    }
  }, [isSuccess, data]);

  const handleBack = () => {
    navigate(`/projects/documents?projectName=${projectName}&projectId=${projectId}`)
  }

  const handlePlant = (id, name) => {
    navigate(`/projects/documents/plants/${id}?plantName=${name}&projectName=${projectName}&projectId=${projectId}`)
  }

  return (
    <ModuleContainer direction="column" padding="17px 69px 56px 32px">
      <Link icon={<img alt="arrow" src={arrowLeft} />} onClick={handleBack}>
        {t("back")}
      </Link>
      {isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.green} />
        </Div> 
      ) : (
        <>
          <Text 
            size={theme.fonts.size.h5i}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            mt="28px"
          >
            {t("docPlantsTitle")}
          </Text>
          <Div gap="20px" m="40px 0 0 0">
            {plantsList.map((ele, indx) => (
              <Card key={indx} onClick={() => handlePlant(ele._id, ele.name)}>
                <WrapperIcon>
                  <img alt="upload" src={upload} />
                </WrapperIcon>
                <Progress showInfo={true} percent={0} height={"9px"}/>
                <Text
                  size={theme.fonts.size.h6}
                  color={theme.colors.blue}
                  weight={theme.fonts.weight.medium}
                  mt="7px"
                >
                  {ele.name}
                </Text>
              </Card>
            ))}
          </Div>
        </>
      )}
    </ModuleContainer>
  )
}