import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import useGetProjects from "../../hooks/useGetProjects";
import { Div } from "../../../../styles/Common";
import { EmptyProject } from "./emptyProject";
import { ProjectsTabsView } from "./projectsTabsView";
import { theme } from "../../../../styles/theme";
import { Input } from "../../../../components/Input";
import { Button } from "../../../../components/Button";
import search from "../../../../assets/icons/Search.svg";
import filterIcon from "../../../../assets/icons/Filtrar.svg";
import { useTranslation } from "react-i18next";
import { ModalUploadNewProject } from "./modalUploadNewProject";
import { DrawerFilter } from "./DrawerFilter";
import ModalLoadProjects from "./modalLoadProjects";
import { ModalValidationLoadProjects } from "./modalValidationLoadProjects";

export const ProjectsList = () => {
  const { t } = useTranslation(["projectsTabsView", "projects", "common"]);
  const [activeKey, setActiveKey] = useState("1");
  const [projectsData, setProjectsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [showModalLoad, setShowModalLoad] = useState(false);
  const [showModalValidationLoadProjects, setModalValidationLoadProjects] =
    useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [initialHasProjects, setInitialHasProjects] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [totalProjects, setTotalProjects] = useState({
    totalCreated: 0,
    totalDrafted: 0,
    totalIdentified: 0,
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [fetchAction, setFetchAction] = useState(false);
  const [filterValues, setFilterValues] = useState({
    type: {
      solar: false,
      eolico: false,
    },
    instance: {
      sale: false,
      financing: false,
    },
  });
  const [confirmedFilterValues, setConfirmedFilterValues] =
    useState(filterValues);

  const currentPage = 0;
  const [pageSize, setPageSize] = useState(5);
  const [payload, setPayload] = useState({
    limit: pageSize,
    offset: currentPage,
    query: searchTerm,
    projectType: [],
    instance: [],
    selectedTab: "created",
  });

  const getProjects = useGetProjects(payload);
  const { data: dataProjects, isLoading, isSuccess } = getProjects;

  useEffect(() => {
    if (isSuccess) {
      const { totalCreated, totalDrafted, totalIdentified } = dataProjects.data;
      const projects =
        totalCreated !== 0 || totalDrafted !== 0 || totalIdentified !== 0;
      setProjectsData(dataProjects?.data.projects);
      if (!projectsData.length && !initialHasProjects) {
        setInitialHasProjects(projects);
      }
      setTotalProjects({
        totalCreated: totalCreated,
        totalDrafted: totalDrafted,
        totalIdentified: totalIdentified,
      });
      setInitialLoading(false);
      setForceUpdate(!forceUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, dataProjects]);

  useEffect(() => {
    const projectTypes = [];
    if (confirmedFilterValues?.type?.solar) projectTypes.push("solar");
    if (confirmedFilterValues?.type?.eolico) projectTypes.push("eolic");

    const instances = [];
    if (confirmedFilterValues?.instance?.sale) instances.push("sale");
    if (confirmedFilterValues?.instance?.financing) instances.push("financing");

    setPayload({
      ...payload,
      limit: pageSize,
      offset: currentPage,
      query: searchTerm,
      projectType: projectTypes.length > 0 ? projectTypes : [],
      instance: instances.length > 0 ? instances : [],
    });
    setForceUpdate(!forceUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, pageSize, currentPage, confirmedFilterValues, fetchAction]);

  const handleShowModal2 = () => setShowModal2(true);
  const handleCloseModal2 = () => setShowModal2(false);

  const handleShowFilter = () => setShowFilter(true);
  const handleCloseFilter = () => {
    setFilterValues(confirmedFilterValues);
    setShowFilter(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleReFetch = () => {
    setPageSize(5);
    setFetchAction(!fetchAction);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleReFetch();
      setIsSearch(!isSearch);
    }
  };

  const handleChange = (group, id) => (e) => {
    const { checked } = e.target;
    setFilterValues((prevValues) => ({
      ...prevValues,
      [group]: {
        ...prevValues[group],
        [id]: checked,
      },
    }));
  };

  const resetFilterValues = () => {
    const newFilterValues = {
      type: { solar: false, eolico: false },
      instance: { sale: false, financing: false },
    };
    setFilterValues(newFilterValues);
    setConfirmedFilterValues(newFilterValues);
    setShowFilter(false);
    handleReFetch();
  };

  const handleSaveFilters = () => {
    setConfirmedFilterValues(filterValues);
    setShowFilter(false);
    handleReFetch();
  };

  const handleCloseModal = () => {
    setShowModalLoad(false);
  };

  const handleShowModalValidationLoadProjects = () => {
    handleCloseModal();
    setModalValidationLoadProjects(true);
  };

  const handleShowModalUploadProject = () => {
    handleCloseModalValidationLoadProjects();
    setShowModal2(true);
  };

  const handleCloseModalValidationLoadProjects = () =>
    setModalValidationLoadProjects(false);

  return (
    <>
      <Div
        p={"31px 69px 0px 32px"}
        style={{ flexDirection: "column", width: "100%" }}
      >
        <Div style={{ flexDirection: "column", width: "100%" }}>
          <Div
            gap="16px"
            m="0 0 39px 0"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              boxSizing: "border-box",
            }}
          >
            <Input
              sizeLabel={theme.fonts.size.xs}
              suffix={
                <img
                  alt="search"
                  src={search}
                  onClick={handleReFetch}
                  style={{ cursor: "pointer" }}
                />
              }
              width={"323px"}
              placeholder={`${t("common:search")}...`}
              style={{ borderRadius: "23px", marginTop: "-10px" }}
              mt={"-8px"}
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            {activeKey === "3" && totalProjects.totalIdentified === 0 && (
              <Button
                variant={"outlined"}
                width={"273px"}
                onClick={() => setShowModalLoad(true)}
              >
                {t("projects:empty.loadProjectText")}
              </Button>
            )}
            {initialHasProjects > 0 && (
              <>
                <Button width={"153px"} onClick={handleShowModal2}>
                  {t("newProject")}
                </Button>
              </>
            )}
            <Button
              width={"110px"}
              variant={"outlined"}
              icon
              onClick={handleShowFilter}
            >
              {t("filter")}
              <img alt="icon" src={filterIcon} style={{ marginLeft: "7px" }} />
            </Button>
            <DrawerFilter
              openDrawer={showFilter}
              handleClose={handleCloseFilter}
              filterValues={filterValues}
              handleChange={handleChange}
              handleCleanFilters={resetFilterValues}
              handleSaveFilters={handleSaveFilters}
            ></DrawerFilter>
          </Div>
        </Div>

        {initialLoading ? (
          <Div width="100%" height="100%" justify="center" align="center">
            <ReactLoading color={theme.colors.green} />
          </Div>
        ) : !initialHasProjects ? (
          <EmptyProject isCreated={true} height="70vh" />
        ) : (
          <ProjectsTabsView
            projects={projectsData}
            totalProjects={totalProjects}
            payload={payload}
            setPayload={setPayload}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            setProjectsData={setProjectsData}
            setPageSize={setPageSize}
            pageSize={pageSize}
            isLoading={isLoading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleReFetch={handleReFetch}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
          />
        )}
        {showModal2 && (
          <ModalUploadNewProject
            showModal={showModal2}
            handleCloseModal={handleCloseModal2}
          />
        )}
        {showModalLoad && (
          <ModalLoadProjects
            showModal={showModalLoad}
            handleCloseModal={handleCloseModal}
            handleShowModalValidationLoadProjects={
              handleShowModalValidationLoadProjects
            }
          />
        )}
        {showModalValidationLoadProjects && (
          <ModalValidationLoadProjects
            showModal={showModalValidationLoadProjects}
            handleCloseModal={handleCloseModalValidationLoadProjects}
            handleShowModalUploadProject={handleShowModalUploadProject}
          />
        )}
      </Div>
    </>
  );
};
