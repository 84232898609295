import React from "react";
import PropTypes from "prop-types";
import { Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { EuroIcon } from "../../../assets/icons";
import DatePicker from "../../../components/DatePicker";

export const License = ({
  constructionLicenseInfo,
  setConstructionLicenseInfo,
  handleChangeNumber,
  handleDate,
  disabled,
}) => {
  const { t } = useTranslation("infoPlants");

  return (
    <>
      <Row gap="28px" m="33.5px 0 0 0">
        <Input
          id="estimatedICIO"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("estimated")}
          width="100%"
          suffix={<EuroIcon />}
          value={constructionLicenseInfo.estimatedICIO}
          onChange={(e) =>
            handleChangeNumber(
              e,
              constructionLicenseInfo,
              setConstructionLicenseInfo
            )
          }
          disabled={disabled}
        />
        <DatePicker
          label={t("dateEstimated")}
          sizeLabel={theme.fonts.size.default}
          width="100%"
          placeholder={t("format")}
          format={"DD/MM/YYYY"}
          value={constructionLicenseInfo.estimatedLOObtentionDate}
          style={{ width: "100%" }}
          onChange={(event) =>
            handleDate(
              event,
              "estimatedLOObtentionDate",
              constructionLicenseInfo,
              setConstructionLicenseInfo
            )
          }
          disabled={disabled}
        />
        <Input
          id="estimatedLOCost"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("estimatedCostL.O")}
          width="100%"
          suffix={<EuroIcon />}
          value={constructionLicenseInfo.estimatedLOCost}
          onChange={(e) =>
            handleChangeNumber(
              e,
              constructionLicenseInfo,
              setConstructionLicenseInfo
            )
          }
          disabled={disabled}
        />
      </Row>
    </>
  );
};

License.propTypes = {
  constructionLicenseInfo: PropTypes.object,
  setConstructionLicenseInfo: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  handleDate: PropTypes.func,
  disabled: PropTypes.bool,
};
