/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { Div, Text } from "../../../styles/Common";
import { Link } from "../../../components/Link";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Upload } from "../../../components/Upload";
import { Collapse } from "../../../components/Collapse";
import { IconButton } from "../../../components/IconButton";
import uploadIcon from "../../../assets/icons/upload-green.svg";
import useSaveDocuments from "../../Documents/hooks/useSaveDocuments";
import useGetGroupDocuments from "../../DueDocuments/hooks/useGetGroupDocuments";

export const DrawerDocuments = ({ 
  openDrawer, 
  handleClose, 
  projectId,
  societyId 
}) => {
  const { t } = useTranslation("documents");
  const queryClient = useQueryClient();
  const subdomain = ["corp01", "corp02", "corp03", "corp04", "corp05", "corp06"];
  const getDocumentsList = useGetGroupDocuments(projectId, 2, 3, subdomain, societyId);
  const saveDocs = useSaveDocuments();
  const [activeKey, setActiveKey] = useState(["0"]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [docList, setDocList] = useState([
    {
      key: "1",
      code: "corp01",
      documents: [],
    },
    {
      key: "2",
      code: "corp02",
      documents: [],
    },
    {
      key: "3",
      code: "corp03",
      documents: [],
    },
    {
      key: "4",
      code: "corp04",
      documents: [],
    },
    {
      key: "5",
      code: "corp05",
      documents: [],
    },
    {
      key: "6",
      code: "corp06",
      documents: [],
    },
  ]);

  const allRequestsSuccessful = getDocumentsList?.every(
    (ele) => ele.isSuccess && !!ele.data
  );

  useEffect(() => {
    if (allRequestsSuccessful) {
      const savedDocs = getDocumentsList.map((ele) => ele.data.data.data);
      const documentsList = docList.map((item, index) => {
        if (savedDocs[index].length > 0) {
          return {
            ...item,
            documents: savedDocs[index]
          };
        }
        return item;
      });
      
      setDocList(documentsList);
    }
  }, [allRequestsSuccessful]);


  const handleSave = (list, code) => {
    const currentDocuments = list.find((ele) => ele.code === code)?.documents;

    const payload = {
      description: "",
      projectId: projectId,
      uploadtype: 2,
      category: 3,
      subdomain: code,
      societyId: societyId,
      documents: [...currentDocuments]
    }

    saveDocs.reset();
    saveDocs.mutate(
      { ...payload },
      {
        onSuccess: () => {
          toast.success(t("documentsSuccess"));
          queryClient.invalidateQueries(["dueDocumentsList", projectId, 2, 3, subdomain, societyId])
        },
        onError: (err) => {
          toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
            "No ha sido posible guardar los documentos, por favor intenta nuevamente";
        },
      }
    );
    setForceUpdate(!forceUpdate);
  }

  const handleSaveAll = () => {
    docList.forEach((item) => {
      if (item.documents.length > 0) {
        handleSave(docList, item.code);
      }
    });
  };
  
  const allDocsArray = docList.reduce((acc, obj) => {
    acc.push(...obj.documents);
    return acc;
  }, []);

  const maxSize = 500000000;  
  const handleUploadDocument = (info, code) => {
    const { fileList } = info;
    const allDocs = allDocsArray.map(obj => obj.name);
    const filteredDocs = fileList.filter(obj2 => !allDocs.includes(obj2.name));

    setDocList(prevDocList => {
      return prevDocList.map(doc => {
        if (doc.code === code) {
          const existingDocNames = doc.documents.map(existingDoc => existingDoc.name);
          const filesForCode = filteredDocs.filter(file => !existingDocNames.includes(file.name));
  
          filesForCode.forEach(file => {
            if (
              file?.type &&
              file?.size &&
              file.type !== "application/x-msdownload" &&
              file.size < maxSize
            ) {
              const newDocument = {
                name: file.name,
                doc: file,
                size: file.size,
                code: code,
              };
              doc.documents.push(newDocument);
            } else {
              toast.error(t("newProject:invalidDocument"));
            }
          });
        }
        return doc;
      });
    });
    setForceUpdate(!forceUpdate);
  };

  const handleDeleteDocument = (index, code) => {
    const currentDocuments = docList.find((ele) => ele.code === code);
    const allDocs = docList.filter((ele) => ele.code !== code);
    const newDocs = currentDocuments.documents.filter((ele, indx) => indx !== index);

    setDocList([...allDocs, {...currentDocuments, documents: newDocs}]);
  }

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const panel = (documents) => {
    return (
      <Div m="32px 0 0 0">
        <Upload
          docsList={documents.documents}
          handleRemoveDocument={handleDeleteDocument}
          handleUploadDocument={handleUploadDocument}
          documentCode={documents.code}
          direction={"column"}
        />
      </Div>
    );
  };

  const panels = [
    {
      key: "1",
      header: t("corporateDocs.doc1"),
      panel: panel(docList.find((ele) => ele.key === "1")),
    },
    {
      key: "2",
      header: t("corporateDocs.doc2"),
      panel: panel(docList.find((ele) => ele.key === "2")),
    },
    {
      key: "3",
      header: t("corporateDocs.doc3"),
      panel: panel(docList.find((ele) => ele.key === "3")),
    },
    {
      key: "4",
      header: t("corporateDocs.doc4"),
      panel: panel(docList.find((ele) => ele.key === "4")),
    },
    {
      key: "5",
      header: t("corporateDocs.doc5"),
      panel: panel(docList.find((ele) => ele.key === "5")),
    },
    {
      key: "6",
      header: t("corporateDocs.doc6"),
      panel: panel(docList.find((ele) => ele.key === "6")),
    },
  ];

  return (
    <Drawer
      title={t("uploadDocuments")}
      open={openDrawer}
      onClose={handleClose}
      width={"568px"}
    >
      <Div direction="column" height="100%" justify="space-between">
        <Div direction="column">
          <Text
            color={theme.colors.gray300}
            mt="24px"
            mb="28px"
            lineHeight="25px"
          >
            {t("corporateModalMessage")}
          </Text>
          <Div
            direction={"column"}
            width="100%"
            style={{
              maxHeight: "524px",
              height: "524px",
              overflowY: "auto",
            }}
          >
            <Collapse
              panels={panels}
              width={"100%"}
              activeKey={activeKey}
              handlePanelChange={handlePanelChange}
              headerHeight={"116px"}
              fontSize={theme.fonts.size.default}
              fontWeight={theme.fonts.weight.regular}
              fontColor={theme.colors.gray200}
              customIcon={
                <IconButton icon={uploadIcon} width={"37px"} height={"37px"} />
              }
            />
          </Div>
        </Div>
        <Div direction="column" align="center" justify="center" gap="24px">
          <Button
            width={"169px"}
            loading={saveDocs.isLoading}
            onClick={handleSaveAll}
          >
            {t("saveDocs")}
          </Button>
          <Link onClick={handleClose}>{t("cancel")}</Link>
        </Div>
      </Div>
    </Drawer>
  );
};

DrawerDocuments.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  projectId: PropTypes.string,
  societyId: PropTypes.string,
};
