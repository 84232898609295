import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDealIntelByProjectId = (projectId) => {
  const data = useQuery(
    ["getDealIntelByProjectId", projectId],
    () => axiosClient.get(`deal-intel/${projectId}`),
    { enabled: !!projectId }
  );

  return data;
};
export default useGetDealIntelByProjectId;
