import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { Div } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { useTranslation } from "react-i18next";
import { CommonFields } from "./commonFields";
import DatePicker from "../../../../components/DatePicker";

export const SalePreInfoPlants = ({
  plantsInfo,
  setPlantsInfo,
  projectType,
  financingType,
  mwp,
  years,
  anual,
  ha,
  m,
  kv,
  handleChange,
  handleSelect,
  handleDate,
  handleChangeNumber,
  disabled,
  provincesOptions,
  municipalityOptions,
  formError,
}) => {
  const { t } = useTranslation("newPlant");

  return (
    <>
      <Div width="100%" m="0 0 33.5px 0" />
      <Row gutter={[32, 32]}>
        <CommonFields
          financingType={financingType}
          projectType={projectType}
          plantsInfo={plantsInfo}
          setPlantsInfo={setPlantsInfo}
          mwp={mwp}
          years={years}
          anual={anual}
          ha={ha}
          handleChange={handleChange}
          handleSelect={handleSelect}
          handleChangeNumber={handleChangeNumber}
          disabled={disabled}
          provincesOptions={provincesOptions}
          municipalityOptions={municipalityOptions}
          formError={formError}
        />
        <Col md={8}>
          <DatePicker
            label={t("rtbDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaEstimadaRtBPlanta}
            style={{ width: "100%" }}
            onChange={(event) =>
              handleDate(
                event,
                "fechaEstimadaRtBPlanta",
                plantsInfo,
                setPlantsInfo
              )
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <DatePicker
            label={t("codDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaEstimadaCoDPlanta}
            style={{ width: "100%" }}
            onChange={(event) =>
              handleDate(
                event,
                "fechaEstimadaCoDPlanta",
                plantsInfo,
                setPlantsInfo
              )
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"longitud"}
            label={t("lineLongDist")}
            suffix={<img alt="m" src={m} />}
            value={plantsInfo.longitud}
            onChange={(event) =>
              handleChangeNumber(event, plantsInfo, setPlantsInfo)
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"typeLine"}
            label={t("typeOfLine")}
            width={"100%"}
            value={plantsInfo.typeLine}
            onChange={(event) =>
              handleChangeNumber(event, plantsInfo, setPlantsInfo)
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"tensionLine"}
            label={t("lineVoltage")}
            suffix={<img alt="kv" src={kv} />}
            value={plantsInfo.tensionLine}
            onChange={(event) =>
              handleChangeNumber(event, plantsInfo, setPlantsInfo)
            }
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  );
};

SalePreInfoPlants.propTypes = {
  projectType: PropTypes.string,
  plantsInfo: PropTypes.object,
  setPlantsInfo: PropTypes.func,
  financingType: PropTypes.string,
  mwp: PropTypes.string,
  years: PropTypes.string,
  anual: PropTypes.string,
  ha: PropTypes.string,
  m: PropTypes.string,
  kv: PropTypes.string,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  disabled: PropTypes.bool,
  provincesOptions: PropTypes.array,
  municipalityOptions: PropTypes.array,
  formError: PropTypes.object,
};
