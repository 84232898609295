import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetQyA = (projectId) => {
  const data = useQuery(
    ["getQuestionsList", projectId],
    () => axiosClient.get(`project/answer/${projectId}/question`),
    { enabled: !!projectId }
  );

  return data;
};
export default useGetQyA;
