import styled from "styled-components";
import { theme } from "../../styles/theme";

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 216px;
  background: ${theme.colors.blue} 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  margin: 28px 0 32px 0;
  padding: 22px 29px 28px 29px; 
`

export const WrapperDocs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 90%;
  background: ${theme.colors.light} 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  padding: 23px 20px 0px 29px; 
`
export const TransparentScroll = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;
  max-height: 564px;
  height: 564px;
  overflow-x: hidden;

  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* Color del scrollbar */
  
  /* WebKit */
  &::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Color del thumb del scrollbar */
    border-radius: 10px; /* Borde redondeado */
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  padding: 16px 24px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000010;
  border-radius: 14px;
`