import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetProjectActivity = (payload) => {
  const data = useQuery(
    ["investorActivityList", payload],
    () =>
      axiosClient.get(`/investors-activity/getByProject/${payload.projectId}?offset=${payload.offset}&limit=${payload.limit}&query=${payload.query}`),
    { enabled: !!payload.projectId }
  );

  return data;
};
export default useGetProjectActivity;
