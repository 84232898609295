import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Div, Text, Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { Tag } from "../../../components/Tag";
import { CloseIcon } from "../../../assets/icons";
import Checkbox from "../../../components/Checkbox";
import useGetScraperById from "../hooks/useGetScraperById";
import useUpdateScraperAdditionalInfo from "../hooks/useUpdateScraperAdditionalInfo";

export const DrawerAditionalInfo = ({
  openDrawer,
  handleClose,
  selectedScraper,
}) => {
  const { t } = useTranslation("scraper");
  const updateScraper = useUpdateScraperAdditionalInfo();
  const queryClient = useQueryClient();

  const getScraper = useGetScraperById(selectedScraper?.id);
  const { data, isSuccess } = getScraper;
  const [form, setForm] = useState({
    township: "",
    nominalPower: null,
    lineType: "",
    capex: null,
    nodoName: "",
  });
  useEffect(() => {
    if (isSuccess && data?.data?.data) {
      setForm({
        township: data?.data?.data.ubicacion.municipio || "",
        nominalPower: data?.data?.data.potenciaNominal,
        lineType: data?.data?.data.tipoLinea,
        capex: data?.data?.data.capex,
        nodoName: data?.data?.data.nodoRedElectrica,
      });
    }
  }, [data, isSuccess]);

  const [Items, setItems] = useState([]);
  useEffect(() => {
    if (form.township) {
      const townships = form.township
        .split(",")
        .map((item) => ({ township: item.trim() }));
      setItems(townships);
    } else {
      setItems([]);
    }
  }, [form]);

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newForm = { ...form };
    if (id === "nominalPower" || id === "capex") {
      newForm[id] = Number(value);
    } else {
      newForm[id] = value;
    }
    setForm(newForm);
  };
  const handleCheckbox = (name) => {
    const newForm = { ...form };
    if (
      (form.lineType === "SOTERRADA" && name === "AEREA") ||
      (form.lineType === "AEREA" && name === "SOTERRADA")
    ) {
      newForm.lineType = "MIXTA";
    } else {
      newForm.lineType = name;
    }
    setForm(newForm);
  };
  const removeTownship = (index) => {
    const updatedItems = [...Items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);

    const newForm = { ...form };
    newForm.township = updatedItems.map((item) => item.township).join(", ");
    setForm(newForm);
  };

  const handleSave = () => {
    const formData = {
      distance: data?.data?.data.distancia,
      lineType: form.lineType,
      nominalPower: form.nominalPower,
      gridNode: form.nodoName,
      capex: form.capex,
      url: data?.data?.data.url,
      publicationDate: data?.data?.data.fecha_publicacion,
      permission: data?.data?.data.permiso,
      technology: data?.data?.data.tecnologia,
      location: {
        autonomousCommunity: data?.data?.data.ubicacion.comunidadAutonoma,
        province: data?.data?.data.ubicacion.provincia,
        city: form.township,
      },
    };
    updateScraper.reset();
    updateScraper.mutate(
      { scraperId: data?.data?.data._id, formData: formData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getScraper");
          toast.success(t(`success`));
          handleClose();
        },
        onError: (err) => {
          toast.error(err.response.data.result.code.message);
        },
      }
    );
  };

  return (
    <Drawer
      title={selectedScraper?.name}
      open={openDrawer}
      onClose={handleClose}
      width="430px"
      padding="0px 31px 40px 28px"
    >
      <Row>
        <Text
          color={theme.colors.blue}
          mb="24px"
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.medium}
        >
          {t("additionalInfo")}
        </Text>
      </Row>
      <Row m="0 0 14px 0">
        <Input
          label={t("township")}
          id="township"
          width="100%"
          sizeLabel={theme.fonts.size.default}
          arrowColor={theme.colors.gray200}
          value={form.township}
          onChange={handleChange}
        />
      </Row>
      <Row style={{ display: "flex", flexWrap: "wrap" }} m="0 0 32px 0">
        {Items.map((item, index) => (
          <Tag
            closable
            closeIcon={
              <CloseIcon
                width="24px"
                height="24px"
                fill={theme.colors.gray300}
              />
            }
            key={index}
            width="auto"
            height="40px"
            color={theme.colors.gray100}
            p="9px 12px 11px 16px"
            onClose={() => removeTownship(index)}
          >
            <Text color={theme.colors.gray300}>{item.township}</Text>
          </Tag>
        ))}
      </Row>
      <Row m=" 0 0 32px 0">
        <Input
          width="100%"
          id="nominalPower"
          label={t("nominalPower")}
          placeholder={t("townshipInput")}
          type="number"
          sizeLabel={theme.fonts.size.default}
          suffix="MWp"
          value={form.nominalPower}
          onChange={handleChange}
        />
      </Row>
      <Row m=" 0 0 14px 0">
        <Text color={theme.colors.gray200} size={theme.fonts.size.default}>
          {t("lineType")}
        </Text>
      </Row>
      <Row justify="space-between" m=" 0 0 32px 0">
        <Checkbox
          label={t("aerial")}
          checked={form.lineType === "AEREA"}
          onChange={() => handleCheckbox("AEREA")}
        />
        <Checkbox
          label={t("underground")}
          checked={form.lineType === "SOTERRADA"}
          onChange={() => handleCheckbox("SOTERRADA")}
        />
        <Checkbox
          label={t("combined")}
          checked={form.lineType === "MIXTA"}
          onChange={() => handleCheckbox("MIXTA")}
        />
      </Row>
      <Row m=" 0 0 32px 0">
        <Input
          width="100%"
          id="capex"
          label="CAPEX"
          sizeLabel={theme.fonts.size.default}
          value={form.capex}
          onChange={handleChange}
        />
      </Row>
      <Row m=" 0 0 32px 0">
        <Input
          width="100%"
          id="nodoName"
          label={t("node")}
          sizeLabel={theme.fonts.size.default}
          value={form.nodoName}
          onChange={handleChange}
        />
      </Row>
      <Div justify="center" align="end" height="100%" gap="10px">
        <Button
          width="115px"
          variant={"outlined"}
          onClick={handleClose}
        >
          {t("close")}
        </Button>
        <Button
          width="115px"
          onClick={handleSave}
          loading={updateScraper.isLoading}
        >
          {t("save")}
        </Button>
      </Div>
    </Drawer>
  );
};

DrawerAditionalInfo.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedScraper: PropTypes.object,
};
