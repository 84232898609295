import React, { useState, useEffect } from "react";
import { ModuleContainer, Text, Row, Col } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { ArrowLeftIcon } from "../../assets/icons";
import { Card } from "../../components/Card";
import { Select } from "../../components/Select2";
import { Input } from "../../components/Input";
import { Radio } from "../../components/Radio";
import { Button } from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import useEditDealIntel from "./hooks/useEditDealIntel";
import useSaveDealIntel from "./hooks/useSaveDealIntel";
import { validateDealIntelForm } from "../../utilities/validations";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetDealIntelByProjectId from "./hooks/useGetDealIntelByProjectId";
import moment from "moment";
import { useQueryClient } from "react-query";

export const DealIntel = () => {
  const { t } = useTranslation("dealIntel");
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const projectId = new URLSearchParams(search).get("projectId");
  const getDealIntelByProjectId = useGetDealIntelByProjectId(projectId);
  const { data, isSuccess } = getDealIntelByProjectId;

  const editDealIntel = useEditDealIntel();
  const saveDealIntel = useSaveDealIntel();
  const [dealIntelId, setDealIntelId] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [form, setForm] = useState({
    financingTerm: null,
    ncTarget: null,
    priceSensitivity: null,
    targetDate: null,
    targetLeverage: null,
    competitiveProcess: null,
    otherAspects: "",
  });
  useEffect(() => {
    if (isSuccess && data?.data?.data?._id) {
      setForm({
        financingTerm: data?.data?.data?.tenor,
        ncTarget: data?.data?.data?.NCTarget,
        priceSensitivity: data?.data?.data?.priceSensitivity,
        targetDate: moment(data?.data?.data?.offerTargetDate),
        targetLeverage: data?.data?.data?.leverageAmount,
        competitiveProcess: data?.data?.data?.competitionProcess,
        otherAspects: data?.data?.data?.others,
      });
      setDealIntelId(data?.data?.data?._id);
    }
  }, [data?.data?.data, isSuccess]);

  const [errorForm, setErrorForm] = useState({
    financingTerm: { error: false, message: "" },
    ncTarget: { error: false, message: "" },
    priceSensitivity: { error: false, message: "" },
    targetDate: { error: false, message: "" },
    targetLeverage: { error: false, message: "" },
    competitiveProcess: { error: false, message: "" },
    otherAspects: { error: false, message: "" },
  });

  const financingOptions = [
    { value: "Short Term", label: "Bridge" },
    { value: "Medium Term", label: "Medio" },
    { value: "Long Term", label: "Largo Plazo" },
  ];

  const handleSelect = (value, id) => {
    const newErrorForm = { ...errorForm };
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newForm = { ...form };
    newForm[id] = value;
    setForm(newForm);
  };

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = { ...errorForm };
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newForm = { ...form };
    if (id === "otherAspects") {
      newForm[id] = value;
    } else {
      newForm[id] = Number(value);
    }
    setForm(newForm);
  };

  const handleChangeDate = (date) => {
    const newErrorForm = { ...errorForm };
    newErrorForm.targetDate.error = false;
    newErrorForm.targetDate.message = "";
    setErrorForm(newErrorForm);

    const newForm = { ...form };
    newForm.targetDate = date;
    setForm(newForm);
  };
  const handleRadio = (event) => {
    const { value, name } = event.target;

    const newErrorForm = { ...errorForm };
    newErrorForm[name].error = false;
    newErrorForm[name].message = "";
    setErrorForm(newErrorForm);
    const newForm = { ...form };
    newForm[name] = value;
    setForm(newForm);
  };

  const handleSave = () => {
    const formData = {
      projectId: projectId,
      tenor: form.financingTerm,
      NCTarget: form.ncTarget,
      priceSensitivity: form.priceSensitivity,
      offerTargetDate: form.targetDate,
      leverageAmount: form.targetLeverage,
      competitionProcess: form.competitiveProcess,
      others: form.otherAspects,
    };
    const validation = validateDealIntelForm.validate(form, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.map((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message = `${t("field")}`;
      });
      setErrorForm(newErrorForm);
    } else {
      const action = dealIntelId ? editDealIntel : saveDealIntel;
      action.reset();
      action.mutate(formData, {
        onSuccess: () => {
          queryClient.invalidateQueries(["getDealIntelByProjectId"]);
          toast.success(t("savedSuccessfully"));
        },
        onError: (err) => {
          toast.error(`${err.response.data.result.code.message}`);
        },
      });
    }
    setForceUpdate(!forceUpdate);
  };

  return (
    <ModuleContainer direction="column" padding="16px 50px 14px 32px">
      <Row
        align="center"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/projects")}
      >
        <ArrowLeftIcon width="32px" height="32px" />
        <Text color={theme.colors.green} weight={theme.fonts.weight.medium}>
          {t("goBack")}
        </Text>
      </Row>
      <Card
        width="100%"
        height="auto"
        margin="28px 0px 39px 0"
        padding="29.5px 47px 36px 47px"
        shadow="5px 11px 26px #00000022"
      >
        <Row width="100%" align="center" justify="space-between" m="0 0 27px 0">
          <Text
            size={theme.fonts.size.h5}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
          >
            {t("typeFinancing")}
          </Text>
        </Row>
        <Row justify="space-between" gap="37px" m=" 0 0 24px 0">
          <Select
            options={financingOptions}
            value={form.financingTerm}
            width="310px"
            label={t("financingTerm")}
            sizeLabel={theme.fonts.size.default}
            placeholder={t("select")}
            arrowColor={theme.colors.gray200}
            onChange={(value) => handleSelect(value, "financingTerm")}
            error={errorForm.financingTerm.error}
            helper={
              errorForm.financingTerm.error
                ? errorForm.financingTerm.message
                : ""
            }
          />
          <Input
            width="310px"
            label="NC target"
            value={form.ncTarget}
            type="number"
            sizeLabel={theme.fonts.size.default}
            suffix={t("months")}
            onChange={handleChange}
            id="ncTarget"
            error={errorForm.ncTarget.error}
            helper={errorForm.ncTarget.error ? errorForm.ncTarget.message : ""}
          />
          <Col m=" 0 90px 0 0">
            <Text color={theme.colors.gray200} mb="16px">
              {t("priceSensitivity")}
            </Text>
            <Row gap="12px">
              <Radio
                name="priceSensitivity"
                label={t("medium")}
                value={"Medium"}
                checked={form.priceSensitivity === "Medium"}
                onChange={handleRadio}
                error={errorForm.priceSensitivity.error}
                helper={
                  errorForm.priceSensitivity.error
                    ? errorForm.priceSensitivity.message
                    : ""
                }
              />
              <Radio
                name="priceSensitivity"
                label={t("high")}
                value={"High"}
                checked={form.priceSensitivity === "High"}
                onChange={handleRadio}
              />
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" gap="37px" m=" 0 0 24px 0">
          <DatePicker
            width="310px"
            style={{ width: "100%" }}
            label={t("dateOffer")}
            value={form.targetDate}
            sizeLabel={theme.fonts.size.default}
            placeholder="10/10/23"
            onChange={handleChangeDate}
            error={errorForm.targetDate.error}
            helper={
              errorForm.targetDate.error ? errorForm.targetDate.message : ""
            }
          />
          <Input
            width="310px"
            label={t("target")}
            value={form.targetLeverage}
            type="number"
            sizeLabel={theme.fonts.size.default}
            suffix="€/Mw"
            onChange={handleChange}
            id="targetLeverage"
            error={errorForm.targetLeverage.error}
            helper={
              errorForm.targetLeverage.error
                ? errorForm.targetLeverage.message
                : ""
            }
          />
          <Col m=" 0 90px 0 0">
            <Text color={theme.colors.gray200} mb="16px">
              {t("competitiveProcess")}
            </Text>
            <Row gap="12px">
              <Radio
                label={t("yes")}
                name="competitiveProcess"
                onChange={handleRadio}
                value={true}
                checked={form.competitiveProcess === true}
                error={errorForm.competitiveProcess.error}
                helper={
                  errorForm.competitiveProcess.error
                    ? errorForm.competitiveProcess.message
                    : ""
                }
              />
              <Radio
                label="No"
                name="competitiveProcess"
                onChange={handleRadio}
                value={false}
                checked={form.competitiveProcess === false}
              />
            </Row>
          </Col>
        </Row>
        <Row m=" 0 0 40px 0">
          <Input
            width="100%"
            height="138px"
            value={form.otherAspects}
            label={t("other")}
            sizeLabel={theme.fonts.size.default}
            placeholder={t("include")}
            onChange={handleChange}
            id="otherAspects"
            error={errorForm.otherAspects.error}
            helper={
              errorForm.otherAspects.error ? errorForm.otherAspects.message : ""
            }
          />
        </Row>
        <Row>
          <Button
            width="205px"
            onClick={handleSave}
            loading={saveDealIntel.isLoading || editDealIntel.isLoading}
          >
            {t("save")}
          </Button>
        </Row>
      </Card>
      <Row justify="center">
        <Text size={theme.fonts.size.sm} color={theme.colors.gray300}>
          {t("alter")}
        </Text>
      </Row>
    </ModuleContainer>
  );
};
