import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import arrowLeft from "../../assets/icons/arrow-left.svg"
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import Tabs from "../../components/Tabs";
import { TabsContent } from "./components/TabsContent";
import useGetActivity from "./hooks/useGetActivity";
import { ModalReminders } from "../ProjectActivity/components/ModalReminders";
import { ListIcon, Notification } from "../../assets/icons";

export const InvestorActivity = () => {
  const { t } = useTranslation("investorActivity");
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState("1");
  const [payload, setPayload] = useState({
    id: id,
    limit: 10,
    offset: 0,
    query: '',
    projectType: 'financing'
  });
  const { data, isSuccess, isLoading } = useGetActivity(payload); 
  const [projectsInfo, setProjectsInfo] = useState({
    totalProjectInvitations: 0,
    discardedProjects: 0,
    closedOperations: 0
  });
  const [activitiesList, setActivityList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleReminders = () => {
    setShowModal(true);
  };

  const handleProjectActivity = (projectId, projectName) => {
    navigate(`/projects/${projectId}/activity?projectName=${projectName}`)
  }

  useEffect(() => {
    if (isSuccess) {
      const info = data?.data?.data;
      setProjectsInfo({
        totalProjectInvitations: info?.generalInformation.invitations,
        discardedProjects: info?.generalInformation.discardedTimes,
        closedOperations: info?.generalInformation.closedOperations,
      })
      setActivityList(
        info.activities.map((ele, i) => ({
          key: i,
          ...ele,
          ...ele.actions,
          actions: [
            {
              id: ele._id,
              name: ele.name,
              tooltip: t("sendReminder"),
              icon: (
                <Notification
                  width="32px"
                  height="32px"
                  fill={theme.colors.green}
                  onClick={handleReminders}
                />
              ),
            },
            {
              id: ele._id,
              name: ele.name,
              tooltip: t("projectActivity"),
              icon: (
                <ListIcon
                  width="32px"
                  height="32px"
                  fill={theme.colors.green}
                  onClick={() => handleProjectActivity(ele.projectId, ele.projectName)}
                />
              ),
            },
          ],
        }))
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  const handleBack = () => {
    navigate("/users?key=3")
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
    const projectType = key === '1' ? 'financing' : 'sale';
    setPayload({...payload, projectType: projectType});
    setForceUpdate(!forceUpdate);
  };

  const panels = [
    {
      tab: t("debtProject"),
      key: "1",
    },
    {
      tab: t("m&aProject"),
      key: "2",
    },
  ];


  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <ModuleContainer direction="column" padding="11px 69px 0 32px">
      <ModalReminders 
        showModal={showModal}
        handleClose={handleClose}
      />
      <Link icon={<img alt="arrow" src={arrowLeft} />} onClick={handleBack}>
        {t("back")}
      </Link>
      <Card 
        width="100%"
        margin="36px 0px 50px 0"
        padding="31px 38px 34px 32px"
        shadow="0px 7px 26px #0000000B"
        style={{ maxWidth: "100%" }}
      >
        <Div justify="space-between" m="0 0 24px 0">
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("title")}
          </Text>
          <Button width={"211px"}>
            {t("inviteProject")}
          </Button>
        </Div>
        <Tabs
          defaultActiveKey="1"
          onChange={handleTabChange}
          activeKey={activeKey}
          color={theme.colors.gray500}
        >
          {panels.map((panel) => (
            <Tabs.Panel tab={panel.tab} key={panel.key}>
              <TabsContent 
                source={activeKey === "1" ? "debt" : "m&a" }
                projectsInfo={projectsInfo}
                activitiesList={activitiesList}
                payload={payload}
                setPayload={setPayload}
                handleReminders={handleReminders}
                isLoading={isLoading}
              />
            </Tabs.Panel>
          ))}
        </Tabs>
      </Card>
    </ModuleContainer>
  )
};