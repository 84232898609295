import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetProjectRequests = () => {
  const data = useQuery(["getProjectRequests"], () =>
    axiosClient.get(`project-request-upload`)
  );

  return data;
};
export default useGetProjectRequests;
