import { Progress } from "antd";
import styled from "styled-components";
import { theme } from "../../styles/theme";

export const CustomProgress = styled(Progress)`
  && {
    &.ant-progress {
      & .ant-progress-inner {
        background-color: ${theme.colors.gray100};
        border-radius: ${({ radius }) => radius || "6px"} !important;
        height: ${({ height }) => height || "12px"};
      }

      & .ant-progress-bg {
        background-color: ${theme.colors.green};
        border-radius: ${({ radius }) => radius || "6px"} !important;
        height: ${({ height }) => height || "12px"} !important;
      }

      & .ant-progress-text {
        font-family: ${theme.fonts.family};
        font-size: ${theme.fonts.size.sm};
        color: ${theme.colors.green};
      }
    }
  }
`