import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 522px;
  height: auto;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 26px #0000000B;
  border-radius: 15px;
  padding: 17px 0 48px 16px;
`;
