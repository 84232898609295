import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetInvestorAcceptance = (projectId) => {
  const data = useQuery(
    ["getInvestorAcceptance", projectId],
    () => axiosClient.get(`project/${projectId}/investor-terms/acceptance`),
    { enabled: projectId !== null }
  );

  return data;
};
export default useGetInvestorAcceptance;
