import React from "react";
import PropTypes from "prop-types";
import { Row, Div } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { Card } from "../../../components/Card";
import { CopyIcon, DeleteIcon } from "../../../assets/icons";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../components/DatePicker";

export const Milestones = ({ keyMilestones, setKeyMilestones }) => {
  const { t } = useTranslation("teaserDocumentation");
  const handleAdd = () => {
    setKeyMilestones((prevItems) => [
      ...prevItems,
      {
        name: "",
        date: null,
      },
    ]);
  };

  const handleDelete = (index) => {
    setKeyMilestones((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  const handleCopy = (index) => {
    setKeyMilestones((prevItems) => {
      const itemToCopy = { ...prevItems[index] };
      return [...prevItems, itemToCopy];
    });
  };

  const handleChange = (event, index) => {
    const { value } = event.target;
    setKeyMilestones((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].name = value;
      return updatedItems;
    });
  };

  const handleChangeDate = (date, index) => {
    setKeyMilestones((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].date = date;
      return updatedItems;
    });
  };

  return (
    <>
      {keyMilestones.map((milestone, index) => (
        <Card
          key={index}
          width="100%"
          height="124px"
          margin="24px 0px 24px 0px"
          padding="20px 36px 28px 29px"
          shadow="0px 3px 10px #00000010"
        >
          <Row align="center" justify="space-between">
            <Input
              label={t("name")}
              placeholder={t("confirmationInterest")}
              width="418px"
              value={milestone.name}
              onChange={(event) => handleChange(event, index)}
            />
            <DatePicker
              label={t("date")}
              placeholder="DD/MM/YYYY"
              width="418px"
              format={"DD/MM/YYYY"}
              value={milestone.date}
              style={{ width: "100%" }}
              onChange={(date) => handleChangeDate(date, index)}
            />
            <Div
              width="37px"
              height="37px"
              m="32px 0 0 0"
              background={theme.colors.green100}
              radius="19px"
              align="center"
              justify="center"
              onClick={() => keyMilestones.length > 1 && handleDelete(index)}
              style={{ cursor: keyMilestones.length > 1 && "pointer" }}
            >
              <DeleteIcon fill={theme.colors.green} />
            </Div>
            <Div
              width="37px"
              height="37px"
              m="32px 0 0 0"
              background={theme.colors.green100}
              radius="19px"
              align="center"
              justify="center"
              onClick={() => handleCopy(index)}
              style={{ cursor: "pointer" }}
            >
              <CopyIcon />
            </Div>
          </Row>
        </Card>
      ))}

      <Button onClick={handleAdd}>
        {t("addAnother")}{" "}
      </Button>
    </>
  );
};

Milestones.propTypes = {
  keyMilestones: PropTypes.array,
  setKeyMilestones: PropTypes.func,
};
