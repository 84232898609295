import React from "react";
import PropTypes from "prop-types";
import Drawer from "../../../../components/Drawer";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../../styles/Common";
import Checkbox from "../../../../components/Checkbox";
import { theme } from "../../../../styles/theme";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";
import { TransparentScroll } from "../styles";

export const FilterDrawer = ({
  open,
  onClose,
  filtersOptions,
  handleFilterChange,
  handleSaveFilters,
  cleanFilters,
}) => {
  const { t } = useTranslation("projectsTabsView");

  const capacityFilter = [
    { label: "- 10MW", value: "<10" },
    { label: "10MW - 50MW", value: "10-50" },
    { label: "+ 50MW", value: ">50" },
  ];

  const countriesFilter = [
    { label: "spain", value: "España" },
    { label: "uk", value: "Reino Unido" },
    { label: "italy", value: "Italia" },
    { label: "us", value: "Estados Unidos" },
  ];

  const phaseFilter = [
    { label: "preDevelopment", value: "Pre-development phase" },
    { label: "development", value: "Development" },
    { label: "rtbOrRtfImminent", value: "RtB or RtB imminent" },
    { label: "construction", value: "Construction" },
    { label: "operation", value: "Operation" },
  ];

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("filters.filterProjects")}
      width={"332px"}
    >
      <TransparentScroll>
        <Div m="26px 0 14px 0">
          <Text
            size={theme.fonts.size.h5i}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
          >
            {t("filters.type")}
          </Text>
        </Div>
        <Div m="0 0 20px 0">
          <Checkbox.Group style={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              label={t("filters.solar")}
              onChange={handleFilterChange("type", "solar")}
              checked={filtersOptions.type.includes("solar")}
            />
            <Checkbox
              label={t("filters.eolic")}
              onChange={handleFilterChange("type", "eolic")}
              checked={filtersOptions.type.includes("eolic")}
            />
          </Checkbox.Group>
        </Div>
        <hr
          style={{
            width: "100%",
            border: `1px solid ${theme.colors.gray100}`,
            opacity: 1,
            margin: "0 0 14px 0",
          }}
        />
        <Div m="0 0 14px 0">
          <Text
            size={theme.fonts.size.h5i}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
          >
            {t("filters.capacity")}
          </Text>
        </Div>
        <Div m="0 0 14px 0">
          <Checkbox.Group direction="column">
            {capacityFilter.map((item, index) => (
              <Checkbox
                key={index}
                label={item.label}
                onChange={handleFilterChange("capacity", item.value)}
                checked={filtersOptions.capacity.includes(item.value)}
              />
            ))}
          </Checkbox.Group>
        </Div>
        <hr
          style={{
            width: "100%",
            border: `1px solid ${theme.colors.gray100}`,
            opacity: 1,
            margin: "0 0 14px 0",
          }}
        />
        <Div m="0 0 14px 0">
          <Text
            size={theme.fonts.size.h5i}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
          >
            {t("filters.countries")}
          </Text>
        </Div>
        <Div m="0 0 14px 0">
          <Checkbox.Group direction="column">
            {countriesFilter.map((country, index) => (
              <Checkbox
                key={index}
                label={t(`filters.${country.label}`)}
                onChange={handleFilterChange("countries", country.value)}
                checked={filtersOptions.countries.includes(country.value)}
              />
            ))}
          </Checkbox.Group>
        </Div>
        <hr
          style={{
            width: "100%",
            border: `1px solid ${theme.colors.gray100}`,
            opacity: 1,
            margin: "0 0 14px 0",
          }}
        />
        <Div m="0 0 14px 0">
          <Text
            size={theme.fonts.size.h5i}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
          >
            {t("filters.phase")}
          </Text>
        </Div>
        <Div m="0 0 14px 0">
          <Checkbox.Group direction="column">
            {phaseFilter.map((state, index) => (
              <Checkbox
                key={index}
                label={t(`filters.${state.label}`)}
                onChange={handleFilterChange("phase", state.value)}
                checked={filtersOptions.phase.includes(state.value)}
              />
            ))}
          </Checkbox.Group>
        </Div>
      </TransparentScroll>
      <Div
        direction="column"
        align="center"
        justify="center"
        gap="24px"
        p="12px 0"
      >
        <Button width={"169px"} onClick={handleSaveFilters}>
          {t("filters.save")}
        </Button>
        <Link onClick={cleanFilters}>{t("filters.clean")}</Link>
      </Div>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  filtersOptions: PropTypes.object,
  handleFilterChange: PropTypes.func,
  handleSaveFilters: PropTypes.func,
  cleanFilters: PropTypes.func,
};
