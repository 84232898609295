import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetSocieties = (projectId) => {
  const data = useQuery(
    ["getSocietiesList", projectId],
    () => axiosClient.get(`society?projectId=${projectId}`), { enabled: !!projectId });

  return data;
};
export default useGetSocieties;