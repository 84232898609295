import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCreateTeaserSale = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.post("/teaser/sale", formData);
  });
  return mutation;
};
export default useCreateTeaserSale;
