import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetMunicipality = (provinceId) => {
  const data = useQuery(
    ["municipalityList", provinceId],
    () => axiosClient.get(`provinces/${provinceId}`),
    { enabled: provinceId !== null }
  );

  return data;
};
export default useGetMunicipality;
