import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Modal } from "../../components/Modal";
import { LikeIcon } from "../../assets/icons";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link";
import arrowBack from "../../assets/icons/arrow-left.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../Sources/styles";
import Tabs from "../../components/Tabs";
import { Button } from "../../components/Button";
import useGetSource from "./hooks/useGetSource";
import { BasicInformation } from "../Sources/components/basicInfo";
import { JuniorDebt } from "../Sources/components/juniorDebt";
import { SeniorDebt } from "../Sources/components/seniorDebt";
import { Intermediation } from "../Sources/components/intermediation";
import { unformatNumber } from "../../utilities/helpers";
import useGetStrategyByType from "../Sources/hooks/useGetStrategyByType";
import useGetInvestingCompanies from "../Sources/hooks/usegetInvestingCompanies";
import { StringToNumber } from "../../utilities/numberFunctions/stringToNumber";
import useUpdateSource from "./hooks/useUpdateSource";
import toast from "react-hot-toast";

export const UpdateSource = () => {
  const { t, i18n } = useTranslation("strategies");
  const locale = i18n.language;
  const navigate = useNavigate();
  const { id } = useParams();
  const update = useUpdateSource();
  const { data, isSuccess, isLoading } = useGetSource(id);
  const [sourceForm, setSourceForm] = useState({});
  const { data: dataStrategies, isSuccess: isSuccessDataStrategies } =
  useGetStrategyByType(1);
const [strategiesOptions, setStrategiesOptions] = useState([]);
const { data: dataInvestors, isSuccess: isSuccessInvestors } =
  useGetInvestingCompanies();
const [investorsOptions, setInvestorsOptions] = useState([]);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleBack = () => {
    navigate("/strategies?key=2");
  };

  useEffect(() => {
    if (isSuccess) {
      const info = data?.data;
      setSourceForm({
        name: info?.name || "",
        strategyId: info?.strategyId?._id || null,
        investorId: info?.investorId || null,
        minFinancingTicket: info?.minFinancingTicket || "",
        maxFinancingTicket: info?.maxFinancingTicket || null,
        tenor: info?.tenor || "",
        isShort: info?.isShort,
        productionScenario: info?.productionScenario || null,
        PPATenorRequirement: info?.PPATenorRequirement || "",
        priceCurveCondition: info?.priceCurveCondition,
        priceCurveScenario: info?.priceCurveScenario || null,
        PPACoverage: info?.PPACoverage || "",
        DSCRForPPA: info?.DSCRForPPA || "",
        DSCRForMerchant: info?.DSCRForMerchant || "",
        interestCalculationBase: info?.interestCalculationBase || null,
        interest: info?.interest || null,
        interestAccured: info?.interestAccured,
        euriborFloor: info?.euriborFloor,
        maximumLeverage: info?.maximumLeverage || "",
        financingOpeningFee: info?.financingOpeningFee || "",
        financingSpread: info?.financingSpread || "",
        makeWholeComission: info?.makeWholeComission || "",
        nonCallPeriod: info?.nonCallPeriod || null,
        cashSweepActivation: info?.cashSweepActivation || null,
        CSYearsInPeriod: info?.CSYearsInPeriod || "",
        CSRatioPeriod1: info?.CSRatioPeriod1 || "",
        swapHedge: info?.swapHedge || null,
        hedge: info?.hedge || "",
        intermediationOpeningFee: info?.intermediationOpeningFee || "",
        intermediationSpread: info?.intermediationSpread || "",
        debtPayment: info?.debtPayment || null,
        longIntermediationOpeningFee: info?.longIntermediationOpeningFee || null,
        longIntermediationSpread: info?.longIntermediationSpread || null,
        shortTenor: info?.shortTenor || null,
        balloonLegalMaturity: info?.balloonLegalMaturity || null,
        shortFinancingOpeningFee: info?.shortFinancingOpeningFee || null,
        shortInterest: info?.shortInterest || null,
        shortFinancingSpread: info?.shortFinancingSpread || null,
        maxLeverageOverCAPEX: info?.maxLeverageOverCAPEX || "",
        DSCR: info?.DSCR || "",
        juniorOpeningFee: info?.juniorOpeningFee || "",
        id: info?._id || null,
        merchantFinancingTenor: info?.merchantFinancingTenor || null,
        legalTenor: info?.legalTenor || "",
        CSStartingYear: info?.CSStartingYear || "",
        swapRate: info?.swapRate || "",
        swapMaturity: info?.swapMaturity || null,
        swapStartDate: info?.swapStartDate || null,
        juniorSpread: info?.juniorSpread || "",
        isJunior: info?.isJunior,
        euriborFloorSwap: info?.euriborFloorSwap,
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessDataStrategies) {
      setStrategiesOptions(
        dataStrategies?.data?.data.map((ele) => ({
          label: ele.name,
          value: ele._id,
        }))
      );
    }
  }, [isSuccessDataStrategies, dataStrategies]);

  useEffect(() => {
    if (isSuccessInvestors) {
      setInvestorsOptions(
        dataInvestors?.data.map((ele) => ({
          label: ele.name,
          value: ele._id,
        }))
      );
    }
  }, [isSuccessInvestors, dataInvestors]);

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleClose = () => {
    setShowModalSuccess(false);
    switch (activeKey) {
      case "1":
        setActiveKey("2");
        break;
      case "2":
        setActiveKey("3");
        break;
      case "3":
        navigate("/strategies?key=2");
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = value;
    setSourceForm(newSourceForm);
  };

  const handleChangeNumber = (event) => {
    const { id, value } = event.target;
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = unformatNumber(value, locale).replace(/\D/g, "");
    setSourceForm(newSourceForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value, id) => {
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = value;
    setSourceForm(newSourceForm);
  };

  const handleRadio = (event) => {
    const { name, value } = event.target;
    const newSourceForm = { ...sourceForm };

    newSourceForm[name] = value;
    setSourceForm(newSourceForm);
  };

  const panels = [
    {
      key: '1',
      tab: t("basicInfo"),
      children: (
        <BasicInformation
          sourceForm={sourceForm}
          handleChange={handleChange}
          handleChangeNumber={handleChangeNumber}
          handleSelect={handleSelect}
          handleRadio={handleRadio}
          strategiesOptions={strategiesOptions}
          investorsOptions={investorsOptions}
        />
      ),
    },
    {
      key: '2',
      tab: sourceForm.isJunior ? t("juniorDebt") : t("seniorDebt"),
      children:  sourceForm.isJunior ? (
        <JuniorDebt
          sourceForm={sourceForm}
          setSourceForm={setSourceForm}
        />
      ) : (
        <SeniorDebt
          sourceForm={sourceForm}
          handleChange={handleChange}
          handleRadio={handleRadio}
          handleSelect={handleSelect}
          setSourceForm={setSourceForm}
        />
      ),
    },
    {
      key: '3',
      tab: t("intermediation"),
      children: (
        <Intermediation
          sourceForm={sourceForm}
          setSourceForm={setSourceForm}
        />
      ),
    },
  ];

  const objectsInfo = (activeKey) => {
    const basicPayload = {
      name: sourceForm.name,
      strategyId: sourceForm.strategyId,
      investorId: sourceForm.investorId,
      minFinancingTicket: Number(sourceForm.minFinancingTicket),
      maxFinancingTicket: Number(sourceForm.maxFinancingTicket),
      merchantFinancingTenor: sourceForm.merchantFinancingTenor,
      isShort: sourceForm.isShort,
      ...(sourceForm.id && { id: sourceForm.id }),
    };

    const juniorDebtPayload = {
      tenor: StringToNumber(sourceForm.tenor),
      maxLeverageOverCAPEX: StringToNumber(sourceForm.maxLeverageOverCAPEX),
      DSCR: StringToNumber(sourceForm.DSCR),
      juniorOpeningFee: StringToNumber(sourceForm.juniorOpeningFee),
      juniorSpread: StringToNumber(sourceForm.juniorSpread),
    };
    let seniorDebtPayload = {
      productionScenario: sourceForm.productionScenario,
      debtPayment: sourceForm.debtPayment,
      tenor: StringToNumber(sourceForm.tenor),
      legalTenor: StringToNumber(sourceForm.legalTenor),
      PPATenorRequirement: StringToNumber(sourceForm.PPATenorRequirement),
      priceCurveCondition: sourceForm.priceCurveCondition,
      priceCurveScenario: sourceForm.priceCurveScenario,
      PPACoverage: StringToNumber(sourceForm.PPACoverage),
      DSCRForPPA: StringToNumber(sourceForm.DSCRForPPA),
      DSCRForMerchant: StringToNumber(sourceForm.DSCRForMerchant),
      interestCalculationBase: sourceForm.interestCalculationBase,
      interest: sourceForm.interest,
      interestAccured: sourceForm.interestAccured,
      euriborFloor: sourceForm.euriborFloor,
      maximumLeverage: StringToNumber(sourceForm.maximumLeverage),
      financingOpeningFee: StringToNumber(sourceForm.financingOpeningFee),
      financingSpread: StringToNumber(sourceForm.financingSpread),
      makeWholeComission: StringToNumber(sourceForm.makeWholeComission),
      nonCallPeriod: sourceForm.nonCallPeriod,
      cashSweepActivation: sourceForm.cashSweepActivation,
    };

    if (sourceForm.cashSweepActivation) {
      seniorDebtPayload = {
        ...seniorDebtPayload,
        CSYearsInPeriod: StringToNumber(sourceForm.CSYearsInPeriod),
        CSStartingYear: StringToNumber(sourceForm.CSStartingYear),
        CSRatioPeriod1: StringToNumber(sourceForm.CSRatioPeriod1),
      };
    }
    if (sourceForm.swapHedge) {
      seniorDebtPayload = {
        ...seniorDebtPayload,
        euriborFloorSwap: sourceForm.euriborFloorSwap,
        swapRate: StringToNumber(sourceForm.swapRate),
        hedge: StringToNumber(sourceForm.hedge),
        swapStartDate: sourceForm.swapStartDate,
        swapMaturity: sourceForm.swapMaturity,
      };
    }

    switch (activeKey) {
      case "1":
        return basicPayload;
      case "2":
        if (sourceForm.isJunior) {
          return {
            ...basicPayload,
            ...juniorDebtPayload,
          };
        } else {
          return {
            ...basicPayload,
            ...seniorDebtPayload,
          };
        }
      case "3":
        if (sourceForm.isJunior) {
          return {
            ...basicPayload,
            ...juniorDebtPayload,
            intermediationOpeningFee: StringToNumber(sourceForm.intermediationOpeningFee),
            intermediationSpread: StringToNumber(sourceForm.intermediationSpread),
          };
        } else {
          return {
            ...basicPayload,
            ...seniorDebtPayload,
            intermediationOpeningFee: StringToNumber(sourceForm.intermediationOpeningFee),
            intermediationSpread: StringToNumber(sourceForm.intermediationSpread),
          };
        }
      default:
        break;
    }

    return basicPayload;
  };

  const handleUpdate = () => {
    const payload = objectsInfo(activeKey);

    function removeNullAndZero(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([, value]) => value !== null && value !== 0 && value !== ""
        )
      );
    }

    const filteredPayload = removeNullAndZero(payload);

    update.reset();
    update.mutate(filteredPayload, {
      onSuccess: (res) => {
        setSourceForm({ ...sourceForm, id: res?.data.updateFinancingSourceDto.id });
        setShowModalSuccess(true);
      },
      onError: (err) => {
        toast.error(t(`common:${err.response.data.result.code}`));
      },
    });
  };


  return (
    <ModuleContainer direction="column" padding="0px 69px 52px 32px">
      <Modal open={showModalSuccess} onCancel={handleClose}>
        <Modal.Header
          icon={
            <LikeIcon
              stroke={theme.colors.green}
              width={"50px"}
              height={"50px"}
            />
          }
          iconBackground={theme.colors.green100}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 63px 0px 63px"}
          title={t("sourceSuccessUpdateTitle")}
          weightTitle={theme.fonts.weight.medium}
        />
        <Modal.Body
          margin="28px 0 32px 0"
        >
          <Text size={theme.fonts.size.default} color={theme.colors.gray500} style={{ textAlign: "center" }}>
            {t("sourceSuccessUpdateMessage")}
          </Text>
        </Modal.Body>
      </Modal>
      <Div m="16px 0 36px 0">
        <Link icon={<img alt="back" src={arrowBack} />} onClick={handleBack}>
          {t("goBack")}
        </Link>
      </Div>
      <Div>
        {isLoading ? (
          <Div width="100%" height="100%" justify="center" align="center">
            <ReactLoading color={theme.colors.green} />
          </Div>
        ) : (
          <Card>
            <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
              {panels.map((panel) => (
                <Tabs.Panel tab={panel.tab} key={panel.key}>
                  <Div width="100%" direction="column" p="28px 37px 0px 22px">
                    {panel.children}
                  </Div>
                </Tabs.Panel>
              ))}
            </Tabs>
            <Div m="32px 0 0 0" gap="16px">
              <Button
                variant={"outlined"}
                width="177px"
                onClick={handleBack}
              >
                  {t("cancel")}
                </Button>
              <Button width="207px" onClick={handleUpdate}>
                {t("saveInfo")}
              </Button>
            </Div>
          </Card>
        )}
      </Div>
    </ModuleContainer>
  )
};