import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useUpdateScraperAdditionalInfo = () => {
  const mutation = useMutation(({ scraperId, formData }) => {
    return axiosClient.patch(`/scraper/${scraperId}`, formData);
  });
  return mutation;
};
export default useUpdateScraperAdditionalInfo;
