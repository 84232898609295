import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetScraperById = (scraperId) => {
  const data = useQuery(
    ["getScraperById", scraperId],
    () => axiosClient.get(`scraper/${scraperId}`),
    { enabled: scraperId !== null && scraperId !== undefined }
  );

  return data;
};
export default useGetScraperById;
