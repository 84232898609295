import React, { useState } from "react";
import { Div, ModuleContainer } from "../../styles/Common";
import { Link } from "../../components/Link";
import { useTranslation } from "react-i18next";
import arrowBack from "../../assets/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/Tabs";
import { Button } from "../../components/Button";
import { Card } from "./styles";
import { BondProgram } from "./components/bondProgram";
import { SizingParameters } from "./components/sizingParams";
import { Intermediation } from "./components/intermediation";

export const UpdateSourceByProject = () => {
  const { t } = useTranslation("strategies");
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const [sourceForm, setSourceForm ] = useState({
    investorId: null,
    legalTenor: "",
    interest: null,
    makeWholeComission: "",
    debtPayment: null,
    juniorOpeningFee: "",
    juniorSpread: "",
    cashSweepActivation: true,
    priceCurveScenario: null,
    DSCRForMerchant: "",
    maximumLeverage: "",
    totalOpeningFee: 2.5,
    totalSpread: 6,
    intermediationOpeningFee: "",
    intermediationSpread: "",
  });

  const handleBack = () => {
    navigate("/projects");
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleSelect = (value, id) => {
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = value;
    setSourceForm(newSourceForm);
  };

  const panels = [
    {
      key: '1',
      tab: t("bondProgram"),
      children: 
        <BondProgram 
          sourceForm={sourceForm} 
          setSourceForm={setSourceForm} 
          handleSelect={handleSelect}
        />,
    },
    {
      key: '2',
      tab: t("sizingParameters"),
      children: 
        <SizingParameters 
          sourceForm={sourceForm} 
          setSourceForm={setSourceForm} 
          handleSelect={handleSelect}
        />,
    },
    {
      key: '3',
      tab: t("intermediation"),
      children: 
        <Intermediation 
          sourceForm={sourceForm} 
          setSourceForm={setSourceForm} 
        />,
    },
  ];

  return (
    <ModuleContainer direction="column" padding="0px 69px 52px 32px">
      <Div m="16px 0 36px 0">
        <Link icon={<img alt="back" src={arrowBack} />} onClick={handleBack}>
          {t("backAndCancel")}
        </Link>
      </Div>
      <Div direction="column">
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
          {panels.map((panel) => (
            <Tabs.Panel tab={panel.tab} key={panel.key}>
              <Card>
                {panel.children}
              </Card>
            </Tabs.Panel>
          ))}
        </Tabs>
        <Div m="44px 0 0 0">
          <Button width="206px" onClick={() => {}}>
            {t("saveInfo")}
          </Button>
        </Div>
      </Div>
    </ModuleContainer>
  )
};