import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import percentage from "../../../assets/icons/percent.svg";
import { FormatNumber, HandleChangeNumber } from "../../../utilities/numberFunctions/handleChange";

export const SizingParameters = ({ sourceForm, setSourceForm, handleSelect }) => {
  const { t, i18n } = useTranslation("strategies");
  const locale = i18n.language;

  const helperProps = {
    helperColor: theme.colors.gray300
  }

  const priceCurveOptions = [
    { value: "Central curve", label: "Central curve" },
    { value: "Low curve", label: "Low curve" },
    { value: "Avg. central and low", label: "Avg. central and low" },
  ];

  return (
    <>
      <Text 
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        color={theme.colors.blue}
        mb="22px"
      >
        {t("sizingParameters")}
      </Text>
      <Row gutter={[32, 32]} style={{ width: '100%' }}>
        <Col md={8}>
          <Select 
            id="priceCurveScenario"
            label={t("seniorDebtForm.priceCurveScenario")}
            options={priceCurveOptions}
            value={sourceForm.priceCurveScenario}
            onChange={(e) => handleSelect(e, "priceCurveScenario")}
            helper={`${t("sourceValue")}: Central curve`}
            {...helperProps}
            width={"100%"}
          />
        </Col>
        <Col md={8}>
          <Input 
            id="DSCRForMerchant"
            label={t("seniorDebtForm.DSCRMerchant")}
            value={FormatNumber(sourceForm.DSCRForMerchant, locale)}
            onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale)}
            helper={`${t("sourceValue")}: ${1,3}`}
            {...helperProps}
          />
        </Col>
        <Col md={8}>
          <Input 
            id="maximumLeverage"
            label={t("seniorDebtForm.maxLeverageCAPEX")}
            value={FormatNumber(sourceForm.maximumLeverage, locale)}
            onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
            suffix={<img alt="percentage" src={percentage} />}
            helper={`${t("sourceValue")}: ${90}`}
            {...helperProps}
          />
        </Col>
      </Row>
    </>
  )
};

SizingParameters.propTypes = {
  sourceForm: PropTypes.object,
  setSourceForm: PropTypes.func,
  handleSelect: PropTypes.func,
};