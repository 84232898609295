import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDocumentsByProjectId = (projectId) => {
  const data = useQuery(
    ["getDocumentsByProjectId", projectId],
    () => axiosClient.get(`documentation?projectId=${projectId}`),
    { enabled: projectId !== null }
  );

  return data;
};
export default useGetDocumentsByProjectId;
