import React, { useState } from "react";
import { Collapse } from "../../../../components/Collapse";
import { Text, Row, Col } from "../../../../styles/Common";
import DatePicker from "../../../../components/DatePicker";
import { theme } from "../../../../styles/theme";
import { Radio } from "../../../../components/Radio";
import { Button } from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { TransactionSummaryLoan } from "./transactionSummaryLoan";
import { Alter5 } from "./alter5";
import { Annex1 } from "./annex1";
import { Annex2 } from "./annex2";
import { Annex3 } from "./annex3";
//import { Annex4 } from "./annex4";
import { TransactionSummaryEsiBond } from "./transactionSummaryEsiBond";

export const FinancingStrategy = ({
  tabActiveKey,
  strategyData,
  setStrategyData,
  handleDeleteStrategy,
  strategiesTabs,
  p50Condition,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  const [activeKey, setActiveKey] = useState(["0"]);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const handleChangeStrategy = (event, item) => {
    const { value, id } = event.target;
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][item][id] = value;
      return updatedItems;
    });
  };
  const handleChangeNumberStrategy = (event, item, locale, source) => {
    const { value, id } = event.target;
    const formatValue = value.replace(/[^\d.,]/g, "");
    let formattedValue;
    if (locale === "es") {
      formattedValue = formatValue.replace(".", ",");
    } else {
      formattedValue = formatValue.replace(",", ".");
    }
    if (source === "percentage") {
      const numericValue = parseFloat(formattedValue.replace(",", "."));
      if (numericValue > 100) {
        formattedValue = "100";
      }
    }
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][item][id] = formattedValue;
      return updatedItems;
    });
  };

  const handleChangeCurrency = (event, item) => {
    const { id, value } = event.target;

    const newFormInfo = [...strategyData];

    newFormInfo[Number(tabActiveKey) - 1][item][id] = value;
    setStrategyData(newFormInfo);
  };

  const handleSelectStrategy = (value, id, item) => {
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][item][id] = value;

      if (id === "p50" && value) {
        updatedItems[Number(tabActiveKey) - 1][item]["p90"] = null;
      }

      if (id === "p90" && value) {
        updatedItems[Number(tabActiveKey) - 1][item]["p50"] = null;
      }

      return updatedItems;
    });
  };

  const handleRadioStrategy = (event, item, name) => {
    const { value } = event.target;

    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][item][name] = value;
      return updatedItems;
    });
  };
  const handleDateStrategy = (date, id, item) => {
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][item][id] = date;
      return updatedItems;
    });
  };

  const items = [
    {
      key: "1",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            1.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("transactionSummary")}
          </Text>
        </Row>
      ),
      panel:
        strategiesTabs[Number(tabActiveKey - 1)]?.investmentInstrument === 1 ? (
          <TransactionSummaryLoan
            strategyData={strategyData}
            setStrategyData={setStrategyData}
            tabActiveKey={tabActiveKey}
          />
        ) : (
          <TransactionSummaryEsiBond
            strategyData={strategyData}
            setStrategyData={setStrategyData}
            tabActiveKey={tabActiveKey}
          />
        ),
    },
    {
      key: "2",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            2.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("fee")}
          </Text>
        </Row>
      ),
      panel: (
        <Alter5
          strategyData={strategyData}
          tabActiveKey={tabActiveKey}
          handleChangeStrategy={handleChangeStrategy}
          handleChangeNumberStrategy={handleChangeNumberStrategy}
        />
      ),
    },
    {
      key: "3",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("annex1")}
          </Text>
        </Row>
      ),
      panel: (
        <Annex1
          strategyData={strategyData}
          tabActiveKey={tabActiveKey}
          handleChangeStrategy={handleChangeStrategy}
          handleSelectStrategy={handleSelectStrategy}
          handleChangeNumberStrategy={handleChangeNumberStrategy}
          p50Condition={p50Condition}
        />
      ),
    },
    {
      key: "4",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("annex2")}
          </Text>
        </Row>
      ),
      panel: (
        <Annex2
          strategyData={strategyData}
          tabActiveKey={tabActiveKey}
          handleChangeCurrency={handleChangeCurrency}
        />
      ),
    },
    {
      key: "5",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("annex3")}
          </Text>
        </Row>
      ),
      panel: (
        <Annex3
          strategyData={strategyData}
          tabActiveKey={tabActiveKey}
          handleChangeStrategy={handleChangeStrategy}
        />
      ),
    },
    /*  {
      key: "6",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("annex4")}
          </Text>
        </Row>
      ),
      panel: (
        <Annex4
          strategyData={strategyData}
          tabActiveKey={tabActiveKey}
          handleChangeStrategy={handleChangeStrategy}
          handleSelectStrategy={handleSelectStrategy}
        />
      ),
    }, */
  ];

  return (
    <>
      <Collapse
        activeKey={activeKey}
        handlePanelChange={handlePanelChange}
        panels={items}
        group={true}
        marginBottom={"0px"}
        headerHeight="50px"
      />
      <Col m=" 0 0 24px 0">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h6}
          color={theme.colors.blue}
          mt="31.5px"
          mb="23.5px"
        >
          {t("additionalDocumentation")}
        </Text>
        <DatePicker
          label={t("dateClosing")}
          width="310px"
          placeholder="10/10/23"
          colorPlaceholder={theme.colors.blue}
          style={{ width: "100%" }}
          value={
            strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
              ?.expectedClosingDate
              ? strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.expectedClosingDate
              : null
          }
          onChange={(value) =>
            handleDateStrategy(
              value,
              "expectedClosingDate",
              "additionalDocumentation"
            )
          }
        />
      </Col>
      <Row
        justify="space-between"
        gap="32px 80px"
        m=" 0 0 47px 0"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
      >
        {/* Client Requirement
         <Col>
          <Text color={theme.colors.gray200} align="left" mb="10px">
            {t("includeProposed")}
          </Text>
          <Row gap="48px">
            <Radio
              label={t("yes")}
              value={true}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.includeProposedCapitalStructureInTeaser
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "includeProposedCapitalStructureInTeaser"
                )
              }
            />
            <Radio
              label="No"
              value={false}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.includeProposedCapitalStructureInTeaser === false
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "includeProposedCapitalStructureInTeaser"
                )
              }
            ></Radio>
          </Row>
        </Col> */}
        <Col>
          <Text color={theme.colors.gray200} align="left" mb="10px">
            {t("hideTeaser")}
          </Text>
          <Row gap="48px">
            <Radio
              label={t("yes")}
              value={true}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideTeaserToSponsor
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideTeaserToSponsor"
                )
              }
            ></Radio>
            <Radio
              label="No"
              value={false}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideTeaserToSponsor === false
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideTeaserToSponsor"
                )
              }
            ></Radio>
          </Row>
        </Col>

        <Col>
          <Text color={theme.colors.gray200} align="left" mb="10px">
            {t("hideAnnex1")}
          </Text>
          <Row gap="48px">
            <Radio
              label={t("yes")}
              value={true}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideAnnex1
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideAnnex1"
                )
              }
            ></Radio>
            <Radio
              label="No"
              value={false}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideAnnex1 === false
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideAnnex1"
                )
              }
            ></Radio>
          </Row>
        </Col>

        <Col>
          <Text color={theme.colors.gray200} align="left" mb="10px">
            {t("hideAnnex")}
          </Text>
          <Row gap="48px">
            <Radio
              label={t("yes")}
              value={true}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideAnnex2
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideAnnex2"
                )
              }
            ></Radio>
            <Radio
              label="No"
              value={false}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideAnnex2 === false
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideAnnex2"
                )
              }
            ></Radio>
          </Row>
        </Col>

        <Col>
          <Text color={theme.colors.gray200} align="left" mb="10px">
            {t("hideAnnex3")}
          </Text>
          <Row gap="48px">
            <Radio
              label={t("yes")}
              value={true}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideAnnex3
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideAnnex3"
                )
              }
            ></Radio>
            <Radio
              label="No"
              value={false}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideAnnex3 === false
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideAnnex3"
                )
              }
            ></Radio>
          </Row>
        </Col>

        <Col>
          <Text color={theme.colors.gray200} align="left" mb="10px">
            {t("hideAnnex4")}
          </Text>
          <Row gap="48px">
            <Radio
              label={t("yes")}
              value={true}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideAnnex4
              }
              onChange={(event) =>
                handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideAnnex4"
                )
              }
            ></Radio>
            <Radio
              label="No"
              value={false}
              checked={
                strategyData[Number(tabActiveKey) - 1]?.additionalDocumentation
                  ?.hideAnnex4 === false
              }
              onChange={(event) =>
                !handleRadioStrategy(
                  event,
                  "additionalDocumentation",
                  "hideAnnex4"
                )
              }
            ></Radio>
          </Row>
        </Col>
      </Row>

      <Row m="0 0 54.5px 0" gap="16px">
        <Button
          width="206px"
          height="45px"
          variant={"outlined"}
          onClick={handleDeleteStrategy}
        >
          {t("deleteStrategy")}
        </Button>
      </Row>
    </>
  );
};

FinancingStrategy.propTypes = {
  tabActiveKey: PropTypes.string,
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  handleDeleteStrategy: PropTypes.func,
  strategiesTabs: PropTypes.number,
  p50Condition: PropTypes.string,
};
