import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetPlantInfo = (plantId) => {
  const data = useQuery(
    ["plantInfo", plantId],
    () => axiosClient.get(`plant/${plantId}`), { enabled: !!plantId });

  return data;
};
export default useGetPlantInfo;