import React from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Col, Text } from "../../../styles/Common";
import { Modal } from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { DeleteIcon } from "../../../assets/icons";
import { Button } from "../../../components/Button";
import useDeleteScraper from "../hooks/useDeleteScraper";

const ModalDelete = ({
  showModal,
  handleCloseModal,
  page,
  selectedScraper,
}) => {
  const { t } = useTranslation(page);

  const deleteScraper = useDeleteScraper();
  const queryClient = useQueryClient();

  const handleDelete = () => {
    deleteScraper.reset();
    deleteScraper.mutate(selectedScraper?.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["getScraper"]);
        toast.success(t("deleteSuccess"));
        handleCloseModal();
      },
      onError: (err) => {
        toast.error(
          t(
            err.response.data.result.code.message ||
              err.response.data.result.code
          )
        );
      },
    });
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        icon={
          <DeleteIcon fill={theme.colors.red} width={"50px"} height={"50px"} />
        }
        iconBackground={theme.colors.white}
        iconBorderColor={theme.colors.red}
        iconWidth={"90px"}
        iconHeight={"90px"}
        align="-webkit-center"
        padding={"22px 20px 0px 20px"}
        title={t("deleteTitle")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="12px 0 24px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Text
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
            mb="24px"
          >
            {t("successDescription")}
          </Text>
          <Button
            width="150px"
            onClick={handleDelete}
            loading={deleteScraper.isLoading}
          >
            {t("deleteButton")}
          </Button>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalDelete.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  page: PropTypes.string,
  selectedScraper: PropTypes.object,
};

export default ModalDelete;
