import React from "react";
import PropTypes from "prop-types";
import { Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { EuroIcon } from "../../../assets/icons";

export const Taxes = ({
  taxesInfo,
  setTaxesInfo,
  handleChangeNumber,
  disabled,
}) => {
  const { t } = useTranslation("infoPlants");

  return (
    <>
      <Row gap="28px" m="33.5px 0 0 0">
        <Input
          id="estimatedUrbanDevelopmentTax"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("estimatedUrban")}
          width="100%"
          suffix={<EuroIcon />}
          value={taxesInfo.estimatedUrbanDevelopmentTax}
          onChange={(e) => handleChangeNumber(e, taxesInfo, setTaxesInfo)}
          disabled={disabled}
        />
        <Input
          id="estimatedIBICE"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("estimatedIbice")}
          width="100%"
          suffix={<EuroIcon />}
          value={taxesInfo.estimatedIBICE}
          onChange={(e) => handleChangeNumber(e, taxesInfo, setTaxesInfo)}
          disabled={disabled}
        />
        <Input
          id="estimatedIAE"
          type="number"
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("estimatedIae")}
          width="100%"
          suffix={<EuroIcon />}
          value={taxesInfo.estimatedIAE}
          onChange={(e) => handleChangeNumber(e, taxesInfo, setTaxesInfo)}
          disabled={disabled}
        />
      </Row>
    </>
  );
};

Taxes.propTypes = {
  taxesInfo: PropTypes.object,
  setTaxesInfo: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  disabled: PropTypes.bool,
};
