import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Select } from "../../../components/Select";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { Radio } from "../../../components/Radio2";
import yearsIcon from "../../../assets/icons/years.svg";
import percentIcon from "../../../assets/icons/percent.svg";
import emwhIcon from "../../../assets/icons/emwh.svg";
import { formatNumber } from "../../../utilities/helpers";
import {
  FormatNumber,
  HandleChangeNumber,
} from "../../../utilities/numberFunctions/handleChange";

export const EnergySalesScheme = ({
  energySalesSchemeInfo,
  setEnergySalesSchemeInfo,
  handleSelect,
  handleRadio,
  handleChangeFormat,
  handleChangePercent,
  errorForm,
  setErrorForm,
  resetErrors,
}) => {
  const { t, i18n } = useTranslation("newProject");
  const locale = i18n.language;

  const inputProps = {
    sizeLabel: theme.fonts.size.default,
    weightLabel: theme.fonts.weight.regular,
    width: "100%",
  };

  const optionsEnergySalesScheme = [
    { label: t("energySalesScheme.options.select"), value: 0, disabled: true },
    {
      label: t("energySalesScheme.options.powerSalesAgreement"),
      value: 1,
      disabled: false,
    },
    {
      label: t("energySalesScheme.options.saleToMarket"),
      value: 2,
      disabled: false,
    },
    { label: t("energySalesScheme.options.hybrid"), value: 3, disabled: false },
    {
      label: t("energySalesScheme.options.auction"),
      value: 4,
      disabled: false,
    },
  ];

  return (
    <>
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h6}
        color={theme.colors.blue}
        mb="32px"
      >
        {t("energySalesScheme.title")}
      </Text>

      <Select
        sizeLabel={theme.fonts.size.default}
        weightLabel={theme.fonts.weight.regular}
        borderColor={theme.colors.gray100}
        color={theme.colors.gray300}
        label={t("energySalesScheme.label")}
        options={optionsEnergySalesScheme}
        onChange={(value, item) =>
          handleSelect(
            value,
            item,
            "optionsEnergySalesScheme",
            energySalesSchemeInfo,
            setEnergySalesSchemeInfo
          )
        }
        height="30px"
        radius="8px"
        style={{ width: "50%" }}
        value={energySalesSchemeInfo.optionsEnergySalesScheme}
        error={errorForm.energySalesSchemeInfo.optionsEnergySalesScheme}
      />
      {energySalesSchemeInfo.optionsEnergySalesScheme !== 2 &&
        energySalesSchemeInfo.optionsEnergySalesScheme !== null && (
          <Row gutter={[32, 32]} style={{ marginTop: "32px" }}>
            <Col md={8}>
              <Input
                {...inputProps}
                id="agreementPPAElectricitySoldPercentage"
                label={t("energySalesScheme.percentagePPA")}
                value={
                  energySalesSchemeInfo.agreementPPAElectricitySoldPercentage
                }
                onChange={(e) =>
                  handleChangePercent(
                    e,
                    energySalesSchemeInfo,
                    setEnergySalesSchemeInfo
                  )
                }
                suffix={<img alt="percent" src={percentIcon} />}
                disabled={energySalesSchemeInfo.optionsEnergySalesScheme === 1}
                error={
                  errorForm.energySalesSchemeInfo
                    .agreementPPAElectricitySoldPercentage
                }
              />
            </Col>
            <Col md={8}>
              <Input
                {...inputProps}
                id="agreementMarketElectricitySoldPercentage"
                label={t("energySalesScheme.percentageElect")}
                value={
                  energySalesSchemeInfo.agreementMarketElectricitySoldPercentage
                }
                onChange={(e) =>
                  handleChangePercent(
                    e,
                    energySalesSchemeInfo,
                    setEnergySalesSchemeInfo
                  )
                }
                suffix={<img alt="percent" src={percentIcon} />}
                disabled={energySalesSchemeInfo.optionsEnergySalesScheme === 1}
                error={
                  errorForm.energySalesSchemeInfo
                    .agreementMarketElectricitySoldPercentage
                }
              />
            </Col>
            <Col md={8} style={{ marginTop: "16px" }}>
              <Input
                {...inputProps}
                id="agreementPPAPrice"
                label={t("energySalesScheme.price")}
                value={
                  formatNumber(
                    energySalesSchemeInfo.agreementPPAPrice,
                    locale
                  ) === "0"
                    ? "0"
                    : formatNumber(
                        energySalesSchemeInfo.agreementPPAPrice,
                        locale
                      )
                }
                onChange={(e) =>
                  handleChangeFormat(
                    e,
                    energySalesSchemeInfo,
                    setEnergySalesSchemeInfo
                  )
                }
                suffix={<img alt="e/MWh" src={emwhIcon} />}
                error={errorForm.energySalesSchemeInfo.agreementPPAPrice}
              />
            </Col>
            <Col md={8}>
              <Input
                {...inputProps}
                id="agreementPPATerm"
                label={t("energySalesScheme.term")}
                value={FormatNumber(
                  energySalesSchemeInfo.agreementPPATerm,
                  locale
                )}
                onChange={(e) => {
                  const newErrorForm = { ...errorForm };
                  resetErrors(newErrorForm);
                  setErrorForm(newErrorForm);

                  HandleChangeNumber(
                    e,
                    energySalesSchemeInfo,
                    setEnergySalesSchemeInfo,
                    locale
                  );
                }}
                suffix={<img alt="years" src={yearsIcon} />}
                error={errorForm.energySalesSchemeInfo.agreementPPATerm}
              />
            </Col>
            <Col md={8}>
              <Div direction="column" align="start" gap="5px">
                <Text
                  size={theme.fonts.size.sm}
                  color={
                    errorForm.energySalesSchemeInfo
                      .agreementPPASaleVolumeCommitment
                      ? theme.colors.red
                      : theme.colors.gray200
                  }
                  align="left"
                >
                  {t("energySalesScheme.salesVolume")}
                </Text>

                <Radio
                  label={t("energySalesScheme.yes")}
                  value={true}
                  name={"agreementPPASaleVolumeCommitment"}
                  checked={
                    energySalesSchemeInfo.agreementPPASaleVolumeCommitment
                  }
                  onChange={(e) =>
                    handleRadio(
                      e,
                      energySalesSchemeInfo,
                      setEnergySalesSchemeInfo
                    )
                  }
                />
                <Radio
                  label={t("energySalesScheme.no")}
                  value={false}
                  name={"agreementPPASaleVolumeCommitment"}
                  checked={
                    !energySalesSchemeInfo.agreementPPASaleVolumeCommitment
                  }
                  onChange={(e) =>
                    handleRadio(
                      e,
                      energySalesSchemeInfo,
                      setEnergySalesSchemeInfo
                    )
                  }
                />
              </Div>
            </Col>

            {energySalesSchemeInfo.agreementPPASaleVolumeCommitment && (
              <Col md={8}>
                <Input
                  {...inputProps}
                  id="agreementPPASaleVolumeAmount"
                  label={t("energySalesScheme.salesVolumeAmount")}
                  value={FormatNumber(
                    energySalesSchemeInfo.agreementPPASaleVolumeAmount,
                    locale
                  )}
                  onChange={(e) => {
                    const newErrorForm = { ...errorForm };
                    resetErrors(newErrorForm);
                    setErrorForm(newErrorForm);
                    HandleChangeNumber(
                      e,
                      energySalesSchemeInfo,
                      setEnergySalesSchemeInfo,
                      locale
                    );
                  }}
                  error={
                    errorForm.energySalesSchemeInfo.agreementPPASaleVolumeAmount
                  }
                />
              </Col>
            )}
          </Row>
        )}
    </>
  );
};

EnergySalesScheme.propTypes = {
  energySalesSchemeInfo: PropTypes.object,
  setEnergySalesSchemeInfo: PropTypes.func,
  handleSelect: PropTypes.func,
  handleRadio: PropTypes.func,
  handleChangeFormat: PropTypes.func,
  handleChangePercent: PropTypes.func,
  errorForm: PropTypes.object,
  setErrorForm: PropTypes.func,
  resetErrors: PropTypes.func,
};
