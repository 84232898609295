import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Card= styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 252px;
  width: 252px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000010;
  border-radius: 14px;
  opacity: 1;
  padding: 18px 11px 13px 9px;
  cursor: pointer;
`
export const WrapperIcon= styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background: ${theme.colors.light} 0% 0% no-repeat padding-box;
  border: 1px solid ${theme.colors.green};
  opacity: 1;
  margin-bottom: 10px;
`
