import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useSaveDocuments = () => {
  const mutation = useMutation((payload) => {
    const formData = new FormData();

    formData.append("projectId", payload.projectId);
    formData.append("description", payload.description);
    formData.append("uploadtype", payload.uploadtype);
    formData.append("category", payload.category);
    formData.append("plantId", payload.plantId);
    formData.append("subdomain", payload.subdomain);
    formData.append("name", payload?.name);

    payload?.documents?.forEach((doc) => {
      if (doc.doc) {
        const originFile = doc.doc;
        formData.append("files", originFile.originFileObj);
      }
    });

    return axiosClient.post("/documentation/uploadFile", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  });

  return mutation;
};

export default useSaveDocuments;
