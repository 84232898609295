import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../../styles/theme";
import { Div, Text } from "../../../../styles/Common";
import { Col, Row } from "antd";
import { Select } from "../../../../components/Select2";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../../components/Radio2/Group";
import { Radio } from "../../../../components/Radio2";
import {
  AACOptions,
  AAPOptions,
  ConnectionPermissionOptions,
  DIAOptions,
  DUPOptions,
} from "../../../../utilities/plantsOptions";
import DatePicker from "../../../../components/DatePicker";

export const SalePrePermissions = ({
  permissions,
  setPermissions,
  handleSelect,
  handleDate,
  handleRadio,
  disabled,
}) => {
  const { t } = useTranslation("newPlant");

  return (
    <>
      <Div width="100%" m="0 0 33.5px 0" />
      <Row gutter={[32, 32]}>
        <Col md={8}>
          <Select
            label={t("connectionPermission")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={ConnectionPermissionOptions}
            value={permissions.interconexion}
            onChange={(event) =>
              handleSelect(event, "interconexion", permissions, setPermissions)
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("dia")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={DIAOptions}
            value={permissions.DIA}
            onChange={(event) =>
              handleSelect(event, "DIA", permissions, setPermissions)
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <DatePicker
            label={t("diaDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={permissions.DIADate}
            onChange={(event) =>
              handleDate(event, "DIADate", permissions, setPermissions)
            }
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("aap")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={AAPOptions}
            value={permissions.AAP}
            onChange={(event) =>
              handleSelect(event, "AAP", permissions, setPermissions)
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <DatePicker
            label={t("aapDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={permissions.AAPDate}
            onChange={(event) =>
              handleDate(event, "AAPDate", permissions, setPermissions)
            }
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("aac")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={AACOptions}
            value={permissions.AAC}
            onChange={(event) =>
              handleSelect(event, "AAC", permissions, setPermissions)
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <DatePicker
            label={t("aacDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={permissions.AACDate}
            onChange={(event) =>
              handleDate(event, "AACDate", permissions, setPermissions)
            }
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("dup")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={DUPOptions}
            value={permissions.DUP}
            onChange={(event) =>
              handleSelect(event, "DUP", permissions, setPermissions)
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <DatePicker
            label={t("dupDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={permissions.DUPDate}
            onChange={(event) =>
              handleDate(event, "DUPDate", permissions, setPermissions)
            }
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </Col>
        <Col md={8}>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mt="2px"
            mb="8px"
            align="left"
          >
            {t("admispro")}
          </Text>
          <RadioGroup
            name={"admisionOSCA"}
            gap={"48px"}
            value={permissions.admisionOSCA}
            onChange={(event) =>
              handleRadio(event, permissions, setPermissions)
            }
            disabled={disabled}
          >
            <Radio label={t("yes")} value={true} />
            <Radio label={t("no")} value={false} />
          </RadioGroup>
        </Col>
        <Col md={8}>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mt="2px"
            mb="8px"
            align="left"
          >
            {t("equipmentMed")}
          </Text>
          <RadioGroup
            name={"equiposMedicion"}
            gap={"48px"}
            value={permissions.equiposMedicion}
            onChange={(event) =>
              handleRadio(event, permissions, setPermissions)
            }
            disabled={disabled}
          >
            <Radio label={t("yes")} value={true} />
            <Radio label={t("no")} value={false} />
          </RadioGroup>
        </Col>
        <Col md={8}>
          <DatePicker
            label={t("taxDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={permissions.fechaIVA}
            style={{ width: "100%" }}
            onChange={(event) =>
              handleDate(event, "fechaIVA", permissions, setPermissions)
            }
            disabled={disabled}
          />
        </Col>
        <Col md={8}></Col>
      </Row>
      <Div m="32px 0 0 0" align="center" gap="20px">
        <Text
          size={theme.fonts.size.sm}
          color={theme.colors.gray200}
          align="left"
        >
          {t("buildingLicense")}:
        </Text>
        <RadioGroup
          name={"workLicenses"}
          gap={"32px"}
          value={permissions.workLicenses}
          onChange={(event) => handleRadio(event, permissions, setPermissions)}
          disabled={disabled}
        >
          <Radio label={t("obtained")} value={1} />
          <Radio label={t("inProcess")} value={2} />
          <Radio label={t("notRequested")} value={3} />
        </RadioGroup>
      </Div>
    </>
  );
};

SalePrePermissions.propTypes = {
  permissions: PropTypes.object,
  setPermissions: PropTypes.func,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleRadio: PropTypes.func,
  disabled: PropTypes.bool,
};
