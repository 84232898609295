/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { Div, Text } from "../../../styles/Common";
import { CardLink } from "../styles";
import deleteIcon from "../../../assets/icons/delete-green.svg";
import { IconButton } from "../../../components/IconButton";
import { Input } from "../../../components/Input";
import { Link } from "../../../components/Link";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import useGetDocuments from "../hooks/useGetDocuments";

export const DrawerLinks = ({
  openDrawer,
  handleClose,
  linksInfo,
  link,
  handleDelete,
  handleAddLink,
  handleChange,
  handleSave,
  projectId,
  setLinksInfo,
  categoryDoc,
  uploadType,
}) => {
  const { t } = useTranslation("documents");
  const { data, isSuccess } = useGetDocuments(projectId, uploadType, categoryDoc);

  useEffect(() => {
    if (isSuccess && data?.data.data.length > 0) {
      setLinksInfo(data?.data.data.map((ele) => ({
        description: ele.description,
        url: ele.url
      })))
    }
  }, [isSuccess, data])

  return (
    <Drawer
      title={t("externLinkTitle")}
      open={openDrawer}
      onClose={handleClose}
    >
      <Div direction="column" height="100%" justify="space-between">
        <Div direction="column">
          <Text
            color={theme.colors.gray500}
            mt="16px"
            mb="24px"
            lineHeight="24px"
          >
            {t("externLinkMessage")}
          </Text>
          <Div
            direction="column"
            style={{
              maxHeight: "424px",
              height: "424px",
              overflowY: "auto",
            }}
          >
            <Div direction="column" gap="25px" align="center">
              {linksInfo.map((ele, indx) => (
                <CardLink key={indx}>
                  <Div align="center" justify="space-between">
                    <Text>{`${t("link")} ${indx + 1}`}</Text>
                    {indx !== 0 && (
                      <IconButton
                        icon={deleteIcon}
                        width="37px"
                        height="37px"
                        onClick={() => handleDelete(indx)}
                      />
                    )}
                  </Div>
                  <Div
                    m={indx !== 0 ? "3px 0 0 0" : "12px 0 0 0"}
                    direction="column"
                    gap="16px"
                  >
                    <Input
                      label={t("urlLink")}
                      weightLabel={theme.fonts.weight.regular}
                      sizeLabel={theme.fonts.size.default}
                      id={"url"}
                      value={ele.url}
                      onChange={(event) => handleChange(event, indx)}
                    />
                    <Input
                      label={t("description")}
                      weightLabel={theme.fonts.weight.regular}
                      sizeLabel={theme.fonts.size.default}
                      id={"description"}
                      value={ele.description}
                      onChange={(event) => handleChange(event, indx)}
                    />
                  </Div>
                </CardLink>
              ))}
            </Div>
            <Link
              icon={
                <img alt="link" src={link} width={"24px"} height={"24px"} />
              }
              onClick={handleAddLink}
              style={{ marginTop: "25px", marginBottom: "25px" }}
            >
              {t("addLink")}
            </Link>
          </Div>
        </Div>
        <Div direction="column" align="center" justify="center" gap="24px">
          <Button
            width={"169px"}
            onClick={handleSave}
          >
            {t("saveLinks")}
          </Button>
          <Link onClick={handleClose}>{t("cancel")}</Link>
        </Div>
      </Div>
    </Drawer>
  );
};

DrawerLinks.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  linksInfo: PropTypes.array,
  link: PropTypes.string,
  handleDelete: PropTypes.func,
  handleAddLink: PropTypes.func,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
  projectId: PropTypes.string,
  setLinksInfo: PropTypes.func,
  categoryDoc: PropTypes.number,
  uploadType: PropTypes.number,
};