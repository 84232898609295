import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import { WrapperIcon } from "../styles";
import deleteIcon from "../../../assets/icons/Delete-error.svg"
import { Text } from "../../../styles/Common";
import { useTranslation } from "react-i18next";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";

export const ModalDelete = ({ 
  open, 
  handleClose, 
  handleDelete, 
  societyName,
  societyId,
  isLoading, 
}) => {
  const { t } = useTranslation("documents");

  return (
    <Modal open={open} onCancel={handleClose} width={"478px"}>
      <Modal.Body padding={"0 16px"} margin={"32px 0 46px 0"} style={{ textAlign: "-webkit-center" }}>
        <WrapperIcon>
          <img alt="delete" src={deleteIcon} />
        </WrapperIcon>
        <Text
          size={theme.fonts.size.h4}
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
          mb="18px"
        >
          {t("deleteTitle")}
        </Text>
        <Text color={theme.colors.gray500}>
          {t("deleteMessage1")}{" "}
          <span style={{ color: theme.colors.green }}>{societyName}.</span>
          {" "}{t("deleteMessage2")}
        </Text>
      </Modal.Body>
      <Modal.Footer gap="24px" align="center" justify="center">
        <Button
          variant={"outlined"}
          onClick={handleClose}
        >
          {t("cancel")}
        </Button>
        <Button 
          onClick={() => handleDelete(societyId)} 
          loading={isLoading}
        >
          {t("deleteSociety")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

ModalDelete.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  societyName: PropTypes.string,
  societyId: PropTypes.string,
  isLoading: PropTypes.bool, 
};