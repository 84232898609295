/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import { Link } from "../../components/Link";
import { CardHeader, WrapperDocs } from "./styles";
import { theme } from "../../styles/theme";
import { Row, Col } from "antd";
import { Input } from "../../components/Input";
import { Collapse } from "../../components/Collapse";
import { getCountsByCategory } from "../../utilities/plantDocuments";
import { ArrowRight } from "../../assets/icons";
import { Upload } from "../../components/Upload";
import Drawer from "../../components/Drawer";
import { ExtraButtons } from "./components/ExtraButtons";
import { Button } from "../../components/Button";
import { DrawerContent } from "./components/DrawerContent";
import useGetDocuments from "../Documents/hooks/useGetDocuments";
import useSaveQuestions from "./hooks/useSaveQuestions";
import useGetQuestions from "./hooks/useGetQuestions";
import moment from "moment";
import useSavePlantsDocuments from "./hooks/useSavePlantsDocs";

export const PlantDocuments = () => {
  const { t } = useTranslation("documents");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();
  const saveDocs = useSavePlantsDocuments();
  const projectName = new URLSearchParams(search).get("projectName");
  const projectId = new URLSearchParams(search).get("projectId");
  const [questionType, setQuestionType] = useState("qGeneral");
  const saveQuestions = useSaveQuestions();
  const { data: dataQuest, isSuccess: isSuccessQuest } = useGetQuestions(
    questionType,
    id
  );
  const [documentSelected, setDocumentSelected] = useState(null);
  const [documentNameSelected, setDocumentNameSelected] = useState("");
  const [activeKey, setActiveKey] = useState(["0"]);
  const [docList, setDocList] = useState([
    {
      label: "interconnection.doc1",
      code: "inter01",
      documents: [],
    },
    {
      label: "interconnection.doc2",
      code: "inter02",
      documents: [],
    },
    {
      label: "interconnection.doc3",
      code: "inter03",
      documents: [],
    },
    {
      label: "interconnection.doc4",
      code: "inter04",
      documents: [],
    },
    {
      label: "interconnection.doc5",
      code: "inter05",
      documents: [],
    },
    {
      label: "interconnection.doc6",
      code: "inter06",
      documents: [],
    },
    {
      label: "interconnection.doc7",
      code: "inter07",
      documents: [],
    },
    {
      label: "interconnection.doc8",
      code: "inter08",
      documents: [],
    },
    {
      label: "interconnection.doc9",
      code: "inter09",
      documents: [],
    },
    {
      label: "interconnection.doc10",
      code: "inter10",
      documents: [],
    },
    {
      label: "interconnection.doc11",
      code: "inter11",
      documents: [],
    },
    {
      label: "interconnection.doc12",
      code: "inter12",
      documents: [],
    },
    {
      label: "plantPermissions.doc1",
      code: "perm01",
      documents: [],
    },
    {
      label: "plantPermissions.doc2",
      code: "perm02",
      documents: [],
    },
    {
      label: "plantPermissions.doc3",
      code: "perm03",
      documents: [],
    },
    {
      label: "plantPermissions.doc4",
      code: "perm04",
      documents: [],
    },
    {
      label: "plantPermissions.doc5",
      code: "perm05",
      documents: [],
    },
    {
      label: "plantPermissions.doc6",
      code: "perm06",
      documents: [],
    },
    {
      label: "plantPermissions.doc7",
      code: "perm07",
      documents: [],
    },
    {
      label: "plantPermissions.doc8",
      code: "perm08",
      documents: [],
    },
    {
      label: "plantPermissions.doc9",
      code: "perm09",
      documents: [],
    },
    {
      label: "technicalDocs.doc1",
      code: "tech01",
      documents: [],
    },
    {
      label: "technicalDocs.doc2",
      code: "tech02",
      documents: [],
    },
    {
      label: "technicalDocs.doc3",
      code: "tech03",
      documents: [],
    },
    {
      label: "technicalDocs.doc4",
      code: "tech04",
      documents: [],
    },
    {
      label: "technicalDocs.doc5",
      code: "tech05",
      documents: [],
    },
    {
      label: "technicalDocs.doc6",
      code: "tech06",
      documents: [],
    },
    {
      label: "technicalDocs.doc7",
      code: "tech07",
      documents: [],
    },
    {
      label: "environmental.doc1",
      code: "env01",
      documents: [],
    },
    {
      label: "environmental.doc2",
      code: "env02",
      documents: [],
    },
    {
      label: "environmental.doc3",
      code: "env03",
      documents: [],
    },
    {
      label: "environmental.doc4",
      code: "env04",
      documents: [],
    },
    {
      label: "environmental.doc5",
      code: "env05",
      documents: [],
    },
    {
      label: "lands.doc1",
      code: "land01",
      documents: [],
    },
    {
      label: "lands.doc2",
      code: "land02",
      documents: [],
    },
    {
      label: "lands.doc3",
      code: "land03",
      documents: [],
    },
    {
      label: "lands.doc4",
      code: "land04",
      documents: [],
    },
    {
      label: "lands.doc5",
      code: "land05",
      documents: [],
    },
    {
      label: "production.doc1",
      code: "prod01",
      documents: [],
    },
    {
      label: "taxes.doc1",
      code: "tax01",
      documents: [],
    },
    {
      label: "taxes.doc2",
      code: "tax02",
      documents: [],
    },
    {
      label: "suppliers.doc1",
      code: "supp01",
      documents: [],
    },
    {
      label: "suppliers.doc2",
      code: "supp02",
      documents: [],
    },
    {
      label: "suppliers.doc3",
      code: "supp03",
      documents: [],
    },
    {
      label: "suppliers.doc4",
      code: "supp04",
      documents: [],
    },
    {
      label: "suppliers.doc5",
      code: "supp05",
      documents: [],
    },
    {
      label: "insuranceAndGuarantees.doc1",
      code: "insu01",
      documents: [],
    },
    {
      label: "insuranceAndGuarantees.doc2",
      code: "insu02",
      documents: [],
    },
    {
      label: "insuranceAndGuarantees.doc3",
      code: "insu03",
      documents: [],
    },
  ]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const { data, isSuccess, isLoading } = useGetDocuments(projectId, 2, 2, id);
  const [general, setGeneral] = useState({
    name: "",
    ubicacionPlanta: "",
    questionType: "qGeneral",
  });

  const initialInterConnection = {
    nivelTension: null,
    nombrePuntoConexion: "",
    puntoConexionConfirmado: null,
    IVAREE: null,
    fechaIVA: "",
    infraestructurasElectricasEnPropiedadDespuesDeRTB: null,
    infraestrcturasMismoExpediente: null,
    tramitacionNoDependePromotor: null,
    conflictoTramitacionCNMC: null,
    terminoMunicipal: "",
    questionType: "qInterconnection",
  };
  const [interconnection, setInterconnection] = useState(
    initialInterConnection
  );

  const initialProjectInfo = {
    cuantiaAval: null,
    costeAvales: null,
    costoEstimadoEPC: null,
    costoTotalInfraestructurasElectricas: null,
    costesEstimadosOM: null,
    questionType: "qProjectInfo",
  };
  const [projectInfo, setProjectInfo] = useState(initialProjectInfo);

  const initialPlantPermisssions = {
    licenciasCaducadas: null,
    permisosOrganismosAfectados: "",
    permisosOrganismosAfectadosOtros: "",
    questionType: "qPlantPermissions",
  };
  const [plantPermissions, setPlantPermissions] = useState(
    initialPlantPermisssions
  );

  const initialEnviromental = {
    conflictoAmbiental: null,
    questionType: "qAmbiental",
  };
  const [environmental, setEnvironmental] = useState(initialEnviromental);

  const initialLand = {
    activosInmobiliarios: [
      {
        nombre: "",
        datos: "",
      },
    ],
    relacionDeBienes: [],
    cargosYGravamenes: null,
    questionType: "qLand",
  };
  const [land, setLand] = useState(initialLand);

  const initialTaxes = {
    impuestoConstruccion: null,
    otrosImpuestos: null,
    bonificacionImpuestos: null,
    questionType: "qTaxes",
  };
  const [taxes, setTaxes] = useState(initialTaxes);

  const initialProviders = {
    proveedorPaneles: "",
    proveedorInversores: "",
    proveedorSeguidores: "",
    EPCista: "",
    otrosProveedores: "",
    existeContratoPrestacionServicios: null,
    operadorOM: "",
    questionType: "qProviders",
  };
  const [providers, setProviders] = useState(initialProviders);

  const initialInsurance = {
    brokerSeguros: "",
    questionType: "qInsurance",
  };
  const [insurance, setInsurance] = useState(initialInsurance);

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (isSuccess) {
      data?.data.data.forEach((newDoc) => {
        const matchingDoc = docList.find(
          (doc) => doc.code === newDoc.subdomain
        );
        if (matchingDoc) {
          matchingDoc.documents.push({
            name: newDoc.name,
            size: newDoc.size,
            url: newDoc.url,
            _id: newDoc._id,
          });
        }
      });

      const newDocList = docList.map((doc) => {
        const uniqueDocuments = doc.documents.filter(
          (document, index, self) =>
            index === self.findIndex((d) => d._id === document._id)
        );
        return { ...doc, documents: uniqueDocuments };
      });

      setDocList(newDocList);
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccess, data]);

  const currentQuestions = (type) => {
    switch (type) {
      case "qGeneral":
        return setGeneral;
      case "qInterconnection":
        return setInterconnection;
      case "qProjectInfo":
        return setProjectInfo;
      case "qPlantPermissions":
        return setPlantPermissions;
      case "qEnvironmental":
        return setEnvironmental;
      case "qLand":
        return setLand;
      case "qTaxes":
        return setTaxes;
      case "qProviders":
        return setProviders;
      case "qInsurance":
        return setInsurance;
      default:
        return setGeneral;
    }
  };

  useEffect(() => {
    if (isSuccessQuest && dataQuest?.data.result.data.length > 0) {
      const quest = dataQuest?.data.result.data;
      const set = currentQuestions(questionType);

      if (quest[quest.length - 1].questionType === "qInterconnection") {
        setInterconnection({
          ...quest[quest.length - 1],
          fechaIVA: quest[quest.length - 1].fechaIVA
            ? moment(quest[quest.length - 1])
            : null,
        });
      } else if (quest[quest.length - 1].questionType === "qLand") {
        setLand({
          ...quest[quest.length - 1],
          activosInmobiliarios:
            quest[quest.length - 1].activosInmobiliarios.length > 0
              ? quest[quest.length - 1].activosInmobiliarios
              : [
                  {
                    nombre: "",
                    datos: "",
                  },
                ],
        });
      } else {
        set(quest[quest.length - 1]);
      }
    }
  }, [isSuccessQuest, dataQuest]);

  const handlePanelChange = (key) => {
    setActiveKey(key);
    setDocumentSelected(null);
    setDocumentNameSelected("");
  };

  const handleBack = () => {
    navigate(
      `/projects/documents/plants?projectName=${projectName}&projectId=${projectId}`
    );
  };

  const handleSelectDoc = (code, name) => {
    setDocumentSelected(code);
    setDocumentNameSelected(name);
  };

  const handleOpenDrawer = (id, questType) => {
    setOpenDrawer(true);
    setCurrentId(id);
    setQuestionType(questType);
  };

  const handleClose = () => {
    setInterconnection(initialInterConnection);
    setProjectInfo(initialProjectInfo);
    setPlantPermissions(initialPlantPermisssions);
    setEnvironmental(initialEnviromental);
    setLand(initialLand);
    setTaxes(initialTaxes);
    setProviders(initialProviders);
    setInsurance(initialInsurance);
    setOpenDrawer(false);
    setCurrentId("");
    setQuestionType("qGeneral");
  };

  const handleSave = (list, code) => {
    const currentDocuments = list.find((ele) => ele.code === code)?.documents;

    const payload = {
      description: "",
      projectId: projectId,
      uploadtype: 2,
      category: 2,
      plantId: id,
      subdomain: code,
      documents: [...currentDocuments],
    };

    saveDocs.reset();
    saveDocs.mutate(
      { ...payload },
      {
        onSuccess: () => {
          toast.success(t("documentsSuccess"));
        },
        onError: (err) => {
          toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
            "No ha sido posible guardar los documentos, por favor intenta nuevamente";
        },
      }
    );
    setForceUpdate(!forceUpdate);
  };

  const maxSize = 500000000;
  const [processedFiles, setProcessedFiles] = useState(new Set());
  const [loadedDocuments, setLoadedDocuments] = useState([]);
  const [errorFiles, setErrorFiles] = useState(new Set());

  const handleUploadDocument = useCallback(
    (file, documentCode) => {
      if (processedFiles.has(file.name) || errorFiles.has(file.name)) return;

      const reader = new FileReader();
      reader.onload = () => {
        const newDoc = {
          name: file.name,
          size: file.size,
          doc: file,
          code: documentCode,
        };

        if (
          file?.type &&
          file?.size &&
          file.type === "application/pdf" &&
          file.size < maxSize
        ) {
          setDocList((prevDocList) =>
            prevDocList.map((doc) =>
              doc.code === documentCode
                ? { ...doc, documents: [...doc.documents, newDoc] }
                : doc
            )
          );

          setProcessedFiles((prev) => new Set(prev).add(file.name));
          setLoadedDocuments((prev) => [...prev, newDoc]);

          const updatedDocs = docList.map((doc) =>
            doc.code === documentCode
              ? { ...doc, documents: [...doc.documents, newDoc] }
              : doc
          );
          handleSave(updatedDocs, documentCode);
        } else {
          if (!errorFiles.has(file.name)) {
            setErrorFiles((prev) => new Set(prev).add(file.name));
            toast.error(t("newProject:invalidDocument"));
          }
        }
      };
      reader.readAsDataURL(file);
    },
    [processedFiles, errorFiles, loadedDocuments, docList, handleSave, maxSize]
  );

  const handleChangeDoc = (info, code) => {
    const { file } = info;
    if (file.status === "done" || file.status === "uploading") {
      if (file.originFileObj) {
        handleUploadDocument(file.originFileObj, code);
      }
    }
  };

  const handleDeleteDocument = (index, code) => {
    const currentDocuments = docList.find((ele) => ele.code === code);
    const allDocs = docList.filter((ele) => ele.code !== code);
    const newDocs = currentDocuments.documents.filter(
      (ele, indx) => indx !== index
    );

    setDocList([...allDocs, { ...currentDocuments, documents: newDocs }]);
    handleSave([...allDocs, { ...currentDocuments, documents: newDocs }], code);
  };

  const panel = (documents) => {
    return (
      <Div m="32.5px 0 0 0">
        <Div width="50%">
          <WrapperDocs>
            {documents.map((ele, indx) => (
              <Div
                key={ele.code}
                minheight="67px"
                justify="space-between"
                style={{
                  borderBottom:
                    indx !== documents.length - 1 &&
                    `1px solid ${theme.colors.gray100}`,
                  cursor: "pointer",
                }}
                onClick={() => handleSelectDoc(ele.code, ele.label)}
              >
                <Text
                  color={
                    ele.code === documentSelected
                      ? theme.colors.blue
                      : theme.colors.gray300
                  }
                  weight={
                    ele.code === documentSelected
                      ? theme.fonts.weight.medium
                      : theme.fonts.weight.regular
                  }
                  style={{ width: "357px" }}
                >
                  {t(ele.label)}
                </Text>
                <ArrowRight
                  fill={
                    ele.code === documentSelected
                      ? theme.colors.blue
                      : theme.colors.gray300
                  }
                />
              </Div>
            ))}
          </WrapperDocs>
        </Div>
        <Div width="50%" justify="center">
          <Div direction="column" width="426px">
            {documentSelected && (
              <>
                <Text
                  color={theme.colors.blue}
                  weight={theme.fonts.weight.medium}
                  mb="32px"
                >
                  {t(documentNameSelected)}
                </Text>
                <Upload
                  docsList={
                    documents.find((doc) => doc.code === documentSelected)
                      ?.documents
                  }
                  handleRemoveDocument={handleDeleteDocument}
                  handleUploadDocument={handleChangeDoc}
                  documentCode={
                    documents.find((doc) => doc.code === documentSelected)?.code
                  }
                  direction={"column"}
                />
              </>
            )}
          </Div>
        </Div>
      </Div>
    );
  };

  const panels = [
    {
      key: "1",
      header: t("interconnection.interconnection"),
      panel: panel(docList.filter((ele) => ele.code.includes("inter"))),
      extra: (
        <ExtraButtons
          id={"int"}
          uploadDocuments={getCountsByCategory(docList)["inter"] || 0}
          totalDocuments={12}
          totalQuestions={10}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={"qInterconnection"}
        />
      ),
    },
    {
      key: "2",
      header: t("financialInfo.financialInfo"),
      panel: null,
      extra: (
        <ExtraButtons
          id={"finan"}
          uploadDocuments={0}
          totalDocuments={0}
          totalQuestions={5}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={"qProjectInfo"}
        />
      ),
    },
    {
      key: "3",
      header: t("plantPermissions.plantPermissions"),
      panel: panel(docList.filter((ele) => ele.code.includes("perm"))),
      extra: (
        <ExtraButtons
          id={"perm"}
          uploadDocuments={getCountsByCategory(docList)["perm"] || 0}
          totalDocuments={9}
          totalQuestions={2}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={"qPlantPermissions"}
        />
      ),
    },
    {
      key: "4",
      header: t("technicalDocs.technicalDocs"),
      panel: panel(docList.filter((ele) => ele.code.includes("tech"))),
      extra: (
        <ExtraButtons
          id={"tech"}
          uploadDocuments={getCountsByCategory(docList)["tech"] || 0}
          totalDocuments={7}
          totalQuestions={0}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={""}
        />
      ),
    },
    {
      key: "5",
      header: t("environmental.environmental"),
      panel: panel(docList.filter((ele) => ele.code.includes("env"))),
      extra: (
        <ExtraButtons
          id={"env"}
          uploadDocuments={getCountsByCategory(docList)["env"] || 0}
          totalDocuments={5}
          totalQuestions={1}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={"qEnvironmental"}
        />
      ),
    },
    {
      key: "6",
      header: t("lands.lands"),
      panel: panel(docList.filter((ele) => ele.code.includes("land"))),
      extra: (
        <ExtraButtons
          id={"land"}
          uploadDocuments={getCountsByCategory(docList)["land"] || 0}
          totalDocuments={5}
          totalQuestions={3}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={"qLand"}
        />
      ),
    },
    {
      key: "7",
      header: t("production.production"),
      panel: panel(docList.filter((ele) => ele.code.includes("prod"))),
      extra: (
        <ExtraButtons
          id={"prod"}
          uploadDocuments={getCountsByCategory(docList)["prod"] || 0}
          totalDocuments={1}
          totalQuestions={0}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={""}
        />
      ),
    },
    {
      key: "8",
      header: t("taxes.taxes"),
      panel: panel(docList.filter((ele) => ele.code.includes("tax"))),
      extra: (
        <ExtraButtons
          id={"tax"}
          uploadDocuments={getCountsByCategory(docList)["tax"] || 0}
          totalDocuments={2}
          totalQuestions={3}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={"qTaxes"}
        />
      ),
    },
    {
      key: "9",
      header: t("suppliers.suppliers"),
      panel: panel(docList.filter((ele) => ele.code.includes("supp"))),
      extra: (
        <ExtraButtons
          id={"supp"}
          uploadDocuments={getCountsByCategory(docList)["supp"] || 0}
          totalDocuments={5}
          totalQuestions={7}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={"qProviders"}
        />
      ),
    },
    {
      key: "10",
      header: t("insuranceAndGuarantees.insuranceAndGuarantees"),
      panel: panel(docList.filter((ele) => ele.code.includes("insu"))),
      extra: (
        <ExtraButtons
          id={"insu"}
          uploadDocuments={getCountsByCategory(docList)["insu"] || 0}
          totalDocuments={3}
          totalQuestions={1}
          answeredQuestions={0}
          totalIncidents={0}
          handleOpenDrawer={handleOpenDrawer}
          questionType={"qInsurance"}
        />
      ),
    },
  ];

  const questionsToSend = (currId) => {
    switch (currId) {
      case "int":
        return interconnection;
      case "finan":
        return Object.entries(projectInfo).reduce((acc, [key, value]) => {
          acc[key] = isNaN(value) ? value : parseInt(value, 10);
          return acc;
        }, {});
      case "perm":
        return plantPermissions;
      case "env":
        return environmental;
      case "land":
        return {
          ...land,
          activosInmobiliarios: land.activosInmobiliarios.some((obj) =>
            Object.values(obj).some((val) => val.trim() !== "")
          )
            ? land.activosInmobiliarios
            : [],
        };
      case "tax":
        return taxes;
      case "supp":
        return providers;
      case "insu":
        return insurance;
      case "gener":
        return general;
      default:
        return {};
    }
  };

  const handleSaveQuest = (currId) => {
    const questions = questionsToSend(currId);
    const filteredQuestions = { ...questions };
    delete filteredQuestions.createdAt;
    delete filteredQuestions._id;
    delete filteredQuestions.__v;

    const payload = {
      plantId: id,
      ...filteredQuestions,
    };

    saveQuestions.reset();
    saveQuestions.mutate(
      { ...payload },
      {
        onSuccess: () => {
          toast.success(t("documentsSuccess"));
          setOpenDrawer(false);
          setCurrentId("");
          setQuestionType("qGeneral");
          queryClient.invalidateQueries(["getQuestionsList", questionType]);
        },
        onError: (err) => {
          toast.error(
            t(`${err?.response?.data?.result?.code?.message}`) ||
              "No ha sido posible guardar las preguntas, por favor intenta nuevamente"
          );
        },
      }
    );
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event, questions, setQuestions) => {
    const { id, value } = event.target;
    const newQuestions = { ...questions };

    newQuestions[id] = value;
    setQuestions(newQuestions);
  };

  const handleChangeNumber = (event, questions, setQuestions) => {
    const { id, value } = event.target;
    const newQuestions = { ...questions };
    newQuestions[id] = value.replace(/[^0-9.]/g, "");
    setQuestions(newQuestions);
  };

  const handleRadio = (event, questions, setQuestions) => {
    const { name, value } = event.target;
    const newQuestions = { ...questions };

    newQuestions[name] = value;
    setQuestions(newQuestions);
  };

  const handleSelect = (value, id, questions, setQuestions) => {
    const newQuestions = { ...questions };

    newQuestions[id] = value;
    setQuestions(newQuestions);
  };

  const saveTimerRef = useRef(null);

  const handleChangeGeneral = (event) => {
    const { id, value } = event.target;
    const newQuestions = { ...general };

    newQuestions[id] = value;
    setGeneral(newQuestions);

    clearTimeout(saveTimerRef.current);

    saveTimerRef.current = setTimeout(() => {
      handleSaveQuest("gener");
    }, 2000);
  };

  const handleChangeLand = (event, index, type) => {
    const { id, value } = event.target;

    setLand((prevState) => ({
      ...prevState,
      [type]: prevState[type].map((item, i) =>
        i === index ? { ...item, [id]: value } : item
      ),
    }));
  };

  const handleAddAsset = () => {
    const landInfo = { ...land };
    setLand({
      ...landInfo,
      activosInmobiliarios: [
        ...landInfo.activosInmobiliarios,
        {
          nombre: "",
          datos: "",
        },
      ],
    });
  };

  const handleAddProperty = () => {
    const landInfo = { ...land };
    setLand({
      ...landInfo,
      relacionDeBienes: [
        ...landInfo.relacionDeBienes,
        {
          nombre: "",
          identificacion: "",
        },
      ],
    });
  };

  const handleDelete = (array, index, id) => {
    const updatedLandInfo = array.filter((ele, indx) => indx !== index);

    setLand({ ...land, [id]: updatedLandInfo });
  };

  if (isLoading) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    );
  }

  return (
    <ModuleContainer direction="column" padding="17px 69px 56px 32px">
      <Drawer
        open={openDrawer}
        title={t("questions")}
        onClose={handleClose}
        width={"425px"}
        padding={"5px 37px 24px 29px"}
      >
        <Div direction="column" height="100%" justify="space-between">
          <DrawerContent
            id={currentId}
            interconnection={interconnection}
            setInterconnection={setInterconnection}
            projectInfo={projectInfo}
            setProjectInfo={setProjectInfo}
            plantPermissions={plantPermissions}
            setPlantPermissions={setPlantPermissions}
            environmental={environmental}
            setEnvironmental={setEnvironmental}
            land={land}
            setLand={setLand}
            taxes={taxes}
            setTaxes={setTaxes}
            providers={providers}
            setProviders={setProviders}
            insurance={insurance}
            setInsurance={setInsurance}
            handleChange={handleChange}
            handleChangeNumber={handleChangeNumber}
            handleRadio={handleRadio}
            handleSelect={handleSelect}
            handleChangeLand={handleChangeLand}
            handleAddAsset={handleAddAsset}
            handleAddProperty={handleAddProperty}
            handleDelete={handleDelete}
          />
          <Div direction="column" align="center" justify="center" gap="24px">
            <Button width={"169px"} onClick={() => handleSaveQuest(currentId)}>
              {t("saveAnswers")}
            </Button>
            <Link onClick={handleClose}>{t("cancel")}</Link>
          </Div>
        </Div>
      </Drawer>
      <Link icon={<img alt="arrow" src={arrowLeft} />} onClick={handleBack}>
        {t("back")}
      </Link>
      <CardHeader>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.white}
          weight={theme.fonts.weight.medium}
          mb="16px"
        >
          {t("important")}
        </Text>
        <Text
          size={theme.fonts.size.sm}
          color={theme.colors.white}
          lineHeight={"21px"}
        >
          {t("note1")}
          <br />
          {t("note2")}
          <br />
          {t("note3")}
          <br />
          {t("note4")}
        </Text>
      </CardHeader>
      <Text
        size={theme.fonts.size.h5i}
        color={theme.colors.blue}
        weight={theme.fonts.weight.medium}
        mb="24px"
      >
        {t("generalData")}
      </Text>
      <Row gutter={[32, 0]}>
        <Col md={10}>
          <Input
            id="name"
            label={t("plantName")}
            sizeLabel={theme.fonts.size.default}
            background={"transparent"}
            value={general.name}
            onChange={handleChangeGeneral}
          />
        </Col>
        <Col md={14}>
          <Input
            id="ubicacionPlanta"
            label={t("plantLocations")}
            sizeLabel={theme.fonts.size.default}
            background={"transparent"}
            helper={t("plantsLocationMessage")}
            helperColor={theme.colors.gray300}
            value={general.ubicacionPlanta}
            onChange={handleChangeGeneral}
          />
        </Col>
      </Row>
      <Div m="48px 0 0 0">
        <Collapse
          panels={panels}
          width={"100%"}
          background={theme.colors.light}
          activeKey={activeKey}
          handlePanelChange={handlePanelChange}
          headerHeight={"75px"}
        />
      </Div>
    </ModuleContainer>
  );
};
