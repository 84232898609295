import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetInvestingCompanies = (payload) => {
  const { projectId, limit, offset, name } = payload;

  const data = useQuery(["investingCompaniesList", payload], 
    () => axiosClient.get(`company/get-investing-companies/${projectId}?limit=${limit}&offset=${offset}&name=${name}`)
  );

  return data;
};

export default useGetInvestingCompanies;
