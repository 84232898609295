import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetStrategyByType = (strategyType) => {
  const data = useQuery(
    ["getStrategies", strategyType],
    () => axiosClient.get(`strategy/by-type/${strategyType}`),
    { enabled: strategyType !== null && strategyType !== undefined }
  );

  return data;
};
export default useGetStrategyByType;
