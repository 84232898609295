import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { Div, Row } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select2";
import {
  debtProfileOptions,
  interestOptions,
} from "../../../../utilities/generalOptions";
import { FormatNumber } from "../../../../utilities/numberFunctions/handleChange";
import { formatCurrency } from "../../../../utilities/numberFunctions/handleChangeCurrency";

export const TransactionSummaryLoan = ({
  strategyData,
  setStrategyData,
  tabActiveKey,
}) => {
  const { t, i18n } = useTranslation("teaserDocumentation");
  const locale = i18n.language;

  const handleChangeStrategy = (event) => {
    const { value, id } = event.target;
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1].transactionSummary.loan[id] =
        value;
      return updatedItems;
    });
  };

  const handleChangeCurrency = (event) => {
    const { id, value } = event.target;

    const newFormInfo = [...strategyData];

    newFormInfo[Number(tabActiveKey) - 1].transactionSummary.loan[id] = value;
    setStrategyData(newFormInfo);
  };

  const handleSelectStrategy = (value, id) => {
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1].transactionSummary.loan[id] =
        value;
      return updatedItems;
    });
  };

  const handleChangeNumberStrategy = (event, locale, source) => {
    const { value, id } = event.target;
    const newForm = [...strategyData];

    const formatValue = value.replace(/[^\d.,]/g, "");

    let formattedValue;
    if (locale === "es") {
      formattedValue = formatValue.replace(".", ",");
    } else {
      formattedValue = formatValue.replace(",", ".");
    }

    if (source === "percentage") {
      const numericValue = parseFloat(formattedValue.replace(",", "."));
      if (numericValue > 100) {
        formattedValue = "100";
      }
    }

    newForm[Number(tabActiveKey) - 1].transactionSummary.loan[id] =
      formattedValue;

    setStrategyData(newForm);
  };

  return (
    <>
      <Row gap="23px" m="33.5px 0 24px 0">
        <Input
          label={t("targetAmount")}
          id="targetAmount"
          width="100%"
          suffix="€"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .targetAmount,
            locale
          )}
          onChange={handleChangeCurrency}
        />
        <Input
          label="Tenor"
          id="tenor"
          type="number"
          min={0}
          width="100%"
          suffix="Años"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan.tenor
          }
          onChange={handleChangeStrategy}
        />
        <Select
          width="100%"
          label={t("margin")}
          placeholder="Selecciona"
          sizeLabel={theme.fonts.size.default}
          options={interestOptions}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .margin
          }
          onChange={(value) => handleSelectStrategy(value, "margin")}
        />
      </Row>
      <Row gap="23px" m="0 0 24px 0">
        <Input
          label={t("marginVariable")}
          id="marginVariable"
          width="100%"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={FormatNumber(
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .marginVariable,
            locale
          )}
          onChange={(e) => handleChangeNumberStrategy(e, locale, "percentage")}
        />
        <Input
          label={t("advanceFee")}
          id="upfrontFee"
          width="100%"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={FormatNumber(
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .upfrontFee,
            locale
          )}
          onChange={(e) => handleChangeNumberStrategy(e, locale, "percentage")}
        />
        <Input
          label={t("availabilityFee")}
          id="availabilityFee"
          width="100%"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={FormatNumber(
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .availabilityFee,
            locale
          )}
          onChange={(e) => handleChangeNumberStrategy(e, locale, "percentage")}
        />
      </Row>
      <Row gap="23px">
        <Input
          label="Non-Call"
          id="nonCall"
          type="number"
          min={0}
          width="100%"
          suffix="Años"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .nonCall
          }
          onChange={handleChangeStrategy}
        />
        <Select
          width="100%"
          label={t("debtProfile")}
          placeholder="Selecciona"
          sizeLabel={theme.fonts.size.default}
          options={debtProfileOptions}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .debtProfile
          }
          onChange={(value) => handleSelectStrategy(value, "debtProfile")}
        />
        <Div width="100%" />
      </Row>
    </>
  );
};

TransactionSummaryLoan.propTypes = {
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
