import React from 'react';
import { Modal } from '../../../../components/Modal';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../styles/theme';
import PropTypes from 'prop-types';
import { Div, Text } from '../../../../styles/Common';
import { ObjectiveCard, TypeCard, WrapperIcon } from '../styles';
import { FinancingIcon, SaleIcon2 } from '../../../../assets/icons';
import { Button } from '../../../../components/Button';
import { Link } from '../../../../components/Link';

export const ModalDuplicate = ({
  open,
  onClose,
  handleDuplicate,
  duplicateInfo,
  setDuplicateInfo,
  isLoading
}) => {
  const { t } = useTranslation("projectTableManager");

  const objective = [
    { 
      label: t("modalDuplicate.financing"), 
      value: "financing",
      icon: <FinancingIcon fill={theme.colors.green} />
    },
    { 
      label: t("modalDuplicate.sale"), 
      value: "sale",
      icon: <SaleIcon2 /> 
    },
  ];

  const financingType = [
    { label: t("modalDuplicate.constructionFinancing"), value: "construction" },
    { label: t("modalDuplicate.operationalRefinancing"), value: "financed" },
  ];

  const saleType = [
    { label: t("modalDuplicate.preRtBCOD"), value: "RtBCOD" },
    { label: t("modalDuplicate.InOperation"), value: "operation" },
  ];

  const returnArray = () => {
    if (duplicateInfo.objective === 'financing') {
      return financingType;
    }
    
    if (duplicateInfo.objective === 'sale') {
      return saleType;
    }

    return [];
  };

  const handleSelect = (key, value) => {
    const info = {...duplicateInfo};
    info[key] = value;

    if (key === 'objective') {
      info.type = ""
    }

    setDuplicateInfo(info);
  };

  return (
    <Modal open={open} onCancel={onClose}>
      <Modal.Header
        title={t("modalDuplicate.title")}
        weightTitle={theme.fonts.weight.medium}
        sizeTitle={theme.fonts.size.h4}
        align={"center"}
      />
      <Modal.Body align={"center"} padding="36px 37px">
        <Text 
          color={theme.colors.gray500} 
          align='center'
          mb='36px'
        >
          {t("modalDuplicate.subtitle")}
        </Text>
        <Div justify="space-between">
          {objective.map((ele, indx) => (
            <ObjectiveCard 
              key={indx} 
              onClick={() => handleSelect('objective', ele.value)}
              style={{ background: duplicateInfo.objective === ele.value ? theme.colors.green100 : theme.colors.white }}
            >
              <WrapperIcon>
                {ele.icon}
              </WrapperIcon>
              <Text color={theme.colors.gray300}>{ele.label}</Text>
            </ObjectiveCard>
          ))}
        </Div>
        {duplicateInfo.objective !== "" && (
          <>
            <Text
              color={theme.colors.blue}
              size={theme.fonts.size.h5i}
              weight={theme.fonts.weight.medium}
              mt='39px'
              mb='24px'
            >
              {t("modalDuplicate.status")}
            </Text>
            <Div justify="space-between">
              {returnArray().map((ele, indx) => (
                <TypeCard
                  key={indx} 
                  onClick={() => handleSelect('type', ele.value)}
                  style={{ background: duplicateInfo.type === ele.value ? theme.colors.green100 : theme.colors.white }}
                >
                  <Text color={theme.colors.green} style={{ textAlign: 'center' }}>{ele.label}</Text>
                </TypeCard>
              ))}
            </Div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer 
        direction='column' 
        justify='center' 
        align='center' 
        gap='24px'
        m="36px 0 0 0"
      >
        <Button 
          width={'183px'} 
          onClick={handleDuplicate}
          disabled={!duplicateInfo.objective || !duplicateInfo.type}
          loading={isLoading}
        >
          {t('modalDuplicate.title')}
        </Button>
        <Link onClick={onClose}>
          {t('modalDuplicate.cancel')}
        </Link>
      </Modal.Footer>
    </Modal>
  )
};

ModalDuplicate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleDuplicate: PropTypes.func,
  duplicateInfo: PropTypes.object,
  setDuplicateInfo: PropTypes.func,
  isLoading: PropTypes.bool,
};