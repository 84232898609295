import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { Row, Text } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { formatCurrency } from "../../../../utilities/numberFunctions/handleChangeCurrency";

export const Annex2 = ({
  strategyData,
  tabActiveKey,
  handleChangeCurrency,
}) => {
  const { t, i18n } = useTranslation("teaserDocumentation");
  const locale = i18n.language;
  return (
    <>
      <Row m="24.5px 0 18px 0">
        <Text weight={theme.fonts.weight.medium} color={theme.colors.blue}>
          {t("totalUses")}
        </Text>
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        <Input
          label="EPC"
          id="epc"
          width="100%"
          suffix="€"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.epc, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
        <Input
          label={t("otherCAPEX")}
          id="otherCapex"
          width="100%"
          suffix="€"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.otherCapex, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
        <Input
          label={t("preOperationalCosts")}
          id="preOperationalCosts"
          suffix="€"
          width="100%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.preOperationalCosts, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        <Input
          label={t("upfrontFee")}
          id="upfrontFee"
          suffix="€"
          width="100%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.upfrontFee, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
        <Input
          label={t("advisors")}
          id="advisors"
          suffix="€"
          width="100%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.advisors, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
        <Input
          label={t("agencyFee")}
          id="agencyFee"
          suffix="€"
          width="100%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.agencyFee, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        <Input
          label={"Swaption fee"}
          id="swaptionFee"
          suffix="€"
          width="31.5%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.swaptionFee, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
        <Input
          label={t("financialExpenses")}
          id="financialExpenses"
          suffix="€"
          width="31.5%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.financialExpenses, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
        <Input
          label={t("dsraEndowment")}
          id="dsraEndowment"
          suffix="€"
          width="31.5%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.dsraEndowment, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
      </Row>
      <Row>
        <Text
          weight={theme.fonts.weight.medium}
          color={theme.colors.blue}
          mb="18px"
        >
          {t("totalSources")}
        </Text>
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        <Input
          label={t("equity")}
          id="equity"
          suffix="€"
          width="31.5%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.equity, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
        <Input
          label={t("seniorDebt")}
          id="seniorDebt"
          suffix="€"
          width="31.5%"
          sizeLabel={theme.fonts.size.default}
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].anexoTwo.seniorDebt, locale)}
          onChange={(event) => handleChangeCurrency(event, "anexoTwo")}
        />
      </Row>
    </>
  );
};

Annex2.propTypes = {
  strategyData: PropTypes.array,
  tabActiveKey: PropTypes.string,
  handleChangeCurrency: PropTypes.func,
};
