import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { theme } from "../../styles/theme";

import { CustomProgress } from "./styles";

export const Progress = ({ percent, showInfo, radius, height }) => {
  const screenWidth = window.innerWidth;
  const widthBar = screenWidth - 450 - 69;
  const tooltipPosition =
    percent === 0
      ? (percent * widthBar) / 100 + 450
      : (percent * widthBar) / 100 + 420;

  return showInfo ? (
    <CustomProgress
      percent={percent}
      showInfo={showInfo}
      radius={radius}
      height={height}
    />
  ) : (
    <div style={{ position: "relative", width: "100%" }}>
      <Tooltip
        open
        title={`${percent} %`}
        color={theme.colors.white}
        overlayStyle={{ left: tooltipPosition }}
        overlayInnerStyle={{
          color: theme.colors.blue,
          fontFamily: theme.fonts.family,
          fontSize: "14px",
          borderTop: `1px solid ${theme.colors.gray100}`,
          borderRight: `1px solid ${theme.colors.gray100}`,
          borderLeft: `1px solid ${theme.colors.gray100}`,
          width: "61px",
          height: "37px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomProgress percent={percent} showInfo={showInfo} />
      </Tooltip>
    </div>
  );
};

Progress.propTypes = {
  percent: PropTypes.number,
  showInfo: PropTypes.bool,
  radius: PropTypes.string,
  height: PropTypes.string,
};