export const HandleChangeNumber = (e, form, setForm, locale, source) => {
  const { value, id } = e.target;
  const newForm = { ...form };

  const formatValue = value.replace(/[^\d.,]/g, "");

  let formattedValue;
  if (locale === "es") {
    formattedValue = formatValue.replace(/\./g, "");
    formattedValue = formattedValue.replace(",", ".");
  } else {
    formattedValue = formatValue.replace(",", ".");
  }

  if (source === "percentage") {
    const numericValue = parseFloat(formattedValue.replace(",", "."));
    if (numericValue > 100) {
      formattedValue = "100";
    }
  }

  if (source === "promoter") {
    newForm.company[id] = formattedValue;
  } else {
    newForm[id] = formattedValue;
  }

  setForm(newForm);
};

export const FormatNumber = (value, locale) => {
  if (!value) {
    return null;
  }
  const strValue = String(value);
  if (locale === "es") {
    return strValue.replace(".", ",");
  } else {
    return strValue;
  }
};
