import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCopyPlant = () => {
  const mutation = useMutation((plantId) => {
    return axiosClient.put(`/plant/${plantId}/copy`);
  });
  return mutation;
};
export default useCopyPlant;
