import React from "react";
import PropTypes from "prop-types";
import Drawer from "../../../../components/Drawer";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../../styles/Common";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../styles/theme";
import { Link } from "../../../../components/Link";
import { Card } from "./styles";
import { IconButton } from "../../../../components/IconButton";
import deleteIcon from "../../../../assets/icons/delete-green.svg";
import chatIcon from "../../../../assets/icons/Chat.svg";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select2";

export const DrawerQuestions = ({ 
  openDrawer,
  handleClose,
  questionsInfo,
  handleAddQuestion,
  handleDelete,
  handleSelect,
  handleChange,
  handleSave,
  isLoading,
  topicOptions
 }) => {
  const { t } = useTranslation(["investorProjectDetail"]);

  const isButtonDisabled = questionsInfo.length === 1 && questionsInfo[0].topic === '' && questionsInfo[0].answer === '';

  const inputProps = {
    weightLabel: theme.fonts.weight.regular,
    sizeLabel: theme.fonts.size.default
  }

  return (
    <Drawer
      title={t("drawerQuestions.title")}
      open={openDrawer}
      onClose={handleClose}
      width={"405px"}
    >
      <Div 
        direction="column"
        m="16px 0 0 0" 
        justify="space-between" 
        height="100%" 
      >
        <Div direction="column">
          <Text color={theme.colors.gray500} lineHeight="24px">
            {t("drawerQuestions.subtitle")}
          </Text>
          <Div
            direction="column"
            m="25px 0 0 0"
            style={{
              maxHeight: "424px",
              height: "424px",
              overflowY: "auto",
            }}
          >
            <Div direction="column" gap="25px" align="center" p="5px 0 0 0">
              {questionsInfo.map((ele, indx) => (
                <Card key={indx}>
                  <Div align="center" justify="space-between">
                    <Text>{`${t("drawerQuestions.question")} ${indx + 1}`}</Text>
                    {indx !== 0 && (
                      <IconButton
                        icon={deleteIcon}
                        width="37px"
                        height="37px"
                        onClick={() => handleDelete(indx)}
                      />
                    )}
                  </Div>
                  <Div
                    m={indx !== 0 ? "3px 0 0 0" : "12px 0 0 0"}
                    direction="column"
                    gap="16px"
                  >
                    <Select
                      {...inputProps}
                      id={"topic"}
                      label={t("drawerQuestions.selectTopic")}
                      options={topicOptions}
                      width={"100%"}
                      value={ele.topic}
                      onChange={(event) => handleSelect(event, "topic", indx)}
                    />
                    <Input
                      {...inputProps}
                      id={"answer"}
                      label={t("drawerQuestions.questions")}
                      value={ele.answer}
                      onChange={(event) => handleChange(event, indx)}
                    />
                  </Div>
                </Card>
              ))}
            </Div>
            <Link
              icon={
                <img alt="chat" src={chatIcon} width={"24px"} height={"24px"} />
              }
              onClick={handleAddQuestion}
              style={{ marginTop: "25px", marginBottom: "25px" }}
            >
              {t("drawerQuestions.addQuestion")}
            </Link>
          </Div>
        </Div>
        <Div direction="column" align="center" justify="center" gap="24px">
          <Button
            width={"169px"}
            loading={isLoading}
            onClick={handleSave}
            disabled={isButtonDisabled}
          >
            {t("drawerQuestions.sendQuestions")}
          </Button>
          <Link onClick={handleClose}>{t("drawerQuestions.cancel")}</Link>
        </Div>
      </Div>
    </Drawer>
  )
};

DrawerQuestions.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  questionsInfo: PropTypes.array,
  handleAddQuestion: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSelect: PropTypes.func,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
  isLoading: PropTypes.bool,
  topicOptions: PropTypes.array,
};