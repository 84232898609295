import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import {
  FormatNumber,
} from "../../../../utilities/numberFunctions/handleChange";
import { Radio } from "../../../../components/Radio";
import { formatCurrency } from "../../../../utilities/numberFunctions/handleChangeCurrency";

export const Platform = ({
  strategyData,
  setStrategyData,
  handleChangeNumber,
  handleChange,
  tabActiveKey,
  handleChangeCurrency,
}) => {
  const { t, i18n } = useTranslation("teaserDocumentation");
  const locale = i18n.language;

  const handleRadio = (event) => {
    const { value, name } = event.target;
    const newForm = [...strategyData];

    if (value === "value") {
      newForm[Number(tabActiveKey) - 1].feePercentage1 = null;
      newForm[Number(tabActiveKey) - 1].feePercentage2 = null;
    } else if (value === "percentage") {
      newForm[Number(tabActiveKey) - 1].feeValue = null;
    }
    newForm[Number(tabActiveKey) - 1][name] = value;

    setStrategyData(newForm);
  };
  return (
    <>
      <Row p="0 100px 0 0 " m=" 33.5px 0 24px 0" gap="24px">
        <Col>
          <Radio
            label={t("comisionMW")}
            name="feeType"
            value={"value"}
            checked={strategyData[Number(tabActiveKey) - 1].feeType === "value"}
            colorLabel={theme.colors.gray200}
            onChange={handleRadio}
          />
          <Input
            id="feeValue"
            width="310px"
            suffix="€/Mwp"
            helperColor={theme.colors.gray200}
            helper={t("feeNotIncluded")}
            value={formatCurrency(strategyData[Number(tabActiveKey) - 1].feeValue, locale)}
            onChange={handleChangeCurrency}
            disabled={
              strategyData[Number(tabActiveKey) - 1].feeType === "percentage"
            }
          />
        </Col>
        <Col>
          <Radio
            label={t("comision%")}
            name="feeType"
            value={"percentage"}
            checked={
              strategyData[Number(tabActiveKey) - 1].feeType === "percentage"
            }
            colorLabel={theme.colors.gray200}
            onChange={handleRadio}
          />
          <Row gap="10px">
            <Input
              id="feePercentage1"
              width="149px"
              suffix="%"
              value={FormatNumber(
                strategyData[Number(tabActiveKey) - 1].feePercentage1,
                locale
              )}
              onChange={(event) =>
                handleChangeNumber(event, locale, "percentage")
              }
              disabled={
                strategyData[Number(tabActiveKey) - 1].feeType === "value"
              }
            />
            <Input
              id="feePercentage2"
              width="149px"
              suffix="€"
              value={formatCurrency(strategyData[Number(tabActiveKey) - 1].feePercentage2, locale)}
              onChange={handleChangeCurrency}
              disabled={
                strategyData[Number(tabActiveKey) - 1].feeType === "value"
              }
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Input
          label={t("feeConsiderations")}
          id="feeConsiderations"
          width="100%"
          height="138px"
          value={strategyData[Number(tabActiveKey) - 1].feeConsiderations}
          onChange={(event) =>
            handleChange(event, strategyData, setStrategyData)
          }
        />
      </Row>
    </>
  );
};

Platform.propTypes = {
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  tabActiveKey: PropTypes.string,
  handleChangeCurrency: PropTypes.func,
};
