import React from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { Modal } from "../../../components/Modal";
import { useTranslation } from "react-i18next";
import { Col, Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { emailLength } from "../../../utilities/helpers";
import { useState } from "react";
import useSendInvitation from "../hooks/useSendInvitation";
import { Link } from "../../../components/Link";

export const InviteCollaboratorModal = ({ showModal, handleCloseModal, handleShowModalSendInvitation, projectId }) => {
    const { t } = useTranslation("documents");
    const [collaboratorForm, setCollaboratorForm] = useState({
        name: "",
        email: "",
        position: "",
    });
    const [errorForm, setErrorForm] = useState({
        name: { error: false, message: "" },
        email: { error: false, message: "" },
        position: { error: false, message: "" }
    });
    
    const sendInvitation = useSendInvitation();

    const handleChange = (event) => {
        const { value, id } = event.target;
        setErrorForm(prevState => ({
            ...prevState,
            [id]: { error: false, message: "" }
        }));
        setCollaboratorForm(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleSendCollaboration = () => {
        let hasError = false;
        const newErrorForm = { ...errorForm };
        Object.keys(collaboratorForm).forEach(key => {
            if (!collaboratorForm[key]) {
                newErrorForm[key] = { error: true, message: t("common:requiredField") };
                hasError = true;
            }
        });

        if (!isValidEmail(collaboratorForm.email)) {
            newErrorForm.email = { error: true, message: t("common:invalidEmail") };
            hasError = true;
        }

        setErrorForm(newErrorForm);
        if (!hasError) {
            handleShowModalSendInvitation();
        }
    };

    const handleSave = () => {
        toast.remove();
        const formData = {
            project_id: projectId,
            name: collaboratorForm.name,
            email: collaboratorForm.email,
            role: collaboratorForm.position,
            isActive: true,
            createdBy: "admin"
        }
        sendInvitation.reset();
        sendInvitation.mutate(formData, {
            onSuccess: (res) => {
                if (res) {
                    handleSendCollaboration();
                } else {
                    toast.error('No se ha podido enviar')
                }
            }
        });
    }

    return (
        <>
            <Modal open={showModal} onCancel={handleCloseModal}>
                <Modal.Header
                    iconBackground={theme.colors.green100}
                    iconWidth={"50px"}
                    padding={"20px 0px 0px 41px"}
                    title={t("modalCollaborate.title")}
                    weightTitle={theme.fonts.weight.medium}
                    sizeTitle={theme.fonts.size.h5i}
                />
                <Modal.Body
                    margin="0px 0 32px 0"
                    align={"center"}
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <Col width="100%" height="100%">
                        <Row width="100%" m="30px 0 24px 0">
                            <Input
                                label={t("modalCollaborate.name")}
                                id="name"
                                onChange={handleChange}
                                error={errorForm.name.error}
                                helper={errorForm.name.message}
                                width="100%"
                            />
                        </Row>
                        <Row width="100%" m="0px 0 24px 0">
                            <Input
                                label={t("common:corporateEmail")}
                                id="email"
                                maxLength={emailLength}
                                onChange={handleChange}
                                error={errorForm.email.error}
                                helper={errorForm.email.message}
                                width="100%"
                            />
                        </Row>
                        <Row width="100%" m="0px 0 24px 0">
                            <Input
                                label={t("modalCollaborate.position")}
                                id="position"
                                onChange={handleChange}
                                error={errorForm.position.error}
                                helper={errorForm.position.message}
                                width="100%"
                            />
                        </Row>
                        <Col width="100%" justify="center" align="center" style={{ gap: "24px" }}>
                            <Button width={"167px"} onClick={handleSave}>
                                {t("modalCollaborate.sendInvitation")}
                            </Button>
                            <Link onClick={handleCloseModal}>
                                {t("modalCollaborate.cancel")}
                            </Link>
                        </Col>
                    </Col>
                </Modal.Body>
            </Modal>

        </>
    );
};

InviteCollaboratorModal.propTypes = {
    showModal: PropTypes.bool, 
    handleCloseModal: PropTypes.func, 
    handleShowModalSendInvitation: PropTypes.func, 
    projectId: PropTypes.string, 
  };