import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";
import { getUserInfo } from "../../../utilities/helpers";
import useGetIpAddress from "../../../core/hooks/useGetIp";

const useSetActivityLog = () => {
  const { companyId, id } = getUserInfo();
  const userAgent = navigator.userAgent;
  const { data } = useGetIpAddress();
  const ipAddress = data?.data.ip;

  const mutation = useMutation((formData) => {
    const payload = {
      createdBy: id,
      action: formData.action,
      investorId: companyId,
      ip: ipAddress,
      navigator: userAgent,
      projectId: formData.projectId,
    }

    return axiosClient.post("/investors-activity", payload);
  });
  return mutation;
};
export default useSetActivityLog;