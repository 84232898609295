import { useQuery } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useGetStrategyRelated = (companyId) => {
  const data = useQuery(
    ["getStrategyRelated", companyId],
    () => axiosClient.get(`strategy/related-strategies/${companyId}`),
    { enabled: !!companyId }
  );

  return data;
};
export default useGetStrategyRelated;
