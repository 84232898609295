import React from "react";
import { useNavigate } from "react-router-dom";
import { Div, Text, Row } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import Drawer from "../../../../components/Drawer";
import { Card } from "../../../../components/Card";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ChatCheckIcon, DocumentGrayIcon } from "../../../../assets/icons";

export const DrawerActivityProject = ({
  openDrawer,
  handleClose,
  selectedProject,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("projects");

  return (
    <Drawer
      title={selectedProject?.name}
      open={openDrawer}
      onClose={handleClose}
      width="332px"
      padding="10px 36px"
    >
      <Text
        color={theme.colors.green}
        weight={theme.fonts.weight.medium}
        mb="24px"
        mt="8px"
      >
        {t("drawerActivityProject.activity")}
      </Text>
      <Card
        width="271px"
        height="129px"
        padding="20px 12px 14px 17px"
        margin="0 0 24px 0"
        shadow={"0px 3px 10px #00000010"}
      >
        <Row justify="left" align="center" m="0 0 9px 0" gap="8px">
          <Div
            justify="center"
            align="center"
            width="30px"
            height="30px"
            radius="50px"
            background={theme.colors.green100}
          >
            <ChatCheckIcon />
          </Div>
          <Text color={theme.colors.blue} weight={theme.fonts.weight.medium}>
            {t("drawerActivityProject.questionsAnswer")}
          </Text>
        </Row>
        <Row m="0 0 13px 0">
          <Text size={theme.fonts.size.sm} color={theme.colors.gray300}>
            {`${selectedProject?.totalQuestions} ${t("drawerActivityProject.numberQuestions")}`}
          </Text>
        </Row>
        <Row align="center" gap="12px">
          <Button
            width="180px"
            height="30px"
            onClick={() =>
              navigate(
                `manager-questions?projectName=${selectedProject?.name}&projectId=${selectedProject?._id}`
              )
            }
          >
            {t("drawerActivityProject.seeQuestions")}
          </Button>
        </Row>
      </Card>

      <Card
        width="271px"
        height="158px"
        padding="20px 12px 14px 17px"
        margin="0 0 24px 0"
        shadow={"0px 3px 10px #00000010"}
      >
        <Row justify="left" align="center" m="0 0 9px 0" gap="8px">
          <Div
            justify="center"
            align="center"
            width="30px"
            height="30px"
            radius="50px"
            background={theme.colors.green100}
          >
            <DocumentGrayIcon width={"24px"} height={"24px"} />
          </Div>
          <Text color={theme.colors.blue} weight={theme.fonts.weight.medium}>
            {t("drawerActivityProject.ActivityProject")}
          </Text>
        </Row>
        <Row m="0 0 13px 0">
          <Text size={theme.fonts.size.sm} color={theme.colors.gray300}>
            {t("drawerActivityProject.ActivityInvestors")}
          </Text>
        </Row>
        <Row align="center">
          <Button
            width="129px"
            height="30px"
            onClick={() =>
              navigate(
                `${selectedProject._id}/activity?projectName=${selectedProject.name}&projectId=${selectedProject?._id}`
              )
            }
          >
            {t("drawerActivityProject.seeActivity")}
          </Button>
        </Row>
      </Card>

      <Div
        direction="column"
        align="center"
        justify="center"
        gap="24px"
        style={{ marginTop: "auto" }}
      >
        <Link onClick={handleClose}> {t("drawerDocumentation.close")}</Link>
      </Div>
    </Drawer>
  );
};

DrawerActivityProject.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedProject: PropTypes.object,
};
