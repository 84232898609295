import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { Radio } from "../../../components/Radio";
import { useTranslation } from "react-i18next";

export const AditionalInfo = ({
  additionalInfo,
  setAdditionalInfo,
  handleChange,
  handleRadio,
  disabled,
}) => {
  const { t } = useTranslation(["infoPlants"]);

  return (
    <>
      <Row gap="42.5px" m="33.5px 0 0  0">
        <Col width="30%">
          <Input
            id="technology"
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("technology")}
            width="100%"
            helper={t("fixedStructure")}
            helperSize={theme.fonts.size.xs}
            helperColor={theme.colors.gray300}
            value={additionalInfo.technology}
            onChange={(e) => handleChange(e, additionalInfo, setAdditionalInfo)}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray200}
            align="left"
            mb="10px"
          >
            {t("spv")}
          </Text>
          <Radio.Group
            name="thirdPartySpv"
            gap="48px"
            value={additionalInfo.thirdPartySpv}
            onChange={(event) =>
              handleRadio(event, additionalInfo, setAdditionalInfo)
            }
            disabled={disabled}
          >
            <Radio label={t("yes")} value={true}></Radio>
            <Radio label={t("no")} value={false}></Radio>
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
};

AditionalInfo.propTypes = {
  additionalInfo: PropTypes.object,
  setAdditionalInfo: PropTypes.func,
  handleChange: PropTypes.func,
  handleRadio: PropTypes.func,
  disabled: PropTypes.bool,
};
