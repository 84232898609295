import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDuplicateProject = () => {
  const mutation = useMutation((payload) => {
    return axiosClient.post(`/project/${payload.projectId}/duplicate`, { projectType: payload.projectType });
  });
  return mutation;
};
export default useDuplicateProject;
