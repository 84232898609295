import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeleteScraper = () => {
  const mutation = useMutation((scraperId) => {
    return axiosClient.delete(`/scraper/${scraperId}`);
  });
  return mutation;
};
export default useDeleteScraper;
