import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import { Text } from "../../../styles/Common";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../components/Radio2/Group";
import { Radio } from "../../../components/Radio2";
import DatePicker from "../../../components/DatePicker";
import { ContractStatusOptions } from "../../../utilities/plantsOptions";
import { FormatNumber, HandleChangeNumber } from "../../../utilities/numberFunctions/handleChange";

export const FinancingRefinancing = ({
  plantsInfo,
  handleSelect,
  handleDate,
  handleRadio,
  setPlantsInfo,
}) => {
  const { t, i18n } = useTranslation("newPlant");
  const locale = i18n.language;

  return (
    <>
      <Row gutter={[32, 32]}>
        <Col md={8} style={{ marginTop: "16px" }}>
          <DatePicker
            label={t("startUpDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaPuestaEnMarcha}
            style={{ width: "100%" }}
            onChange={(event) => handleDate(event, "fechaPuestaEnMarcha")}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("contractStatus")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={ContractStatusOptions}
            value={plantsInfo.estadoContratoTecnicoCTA}
            onChange={(event) =>
              handleSelect(event, "estadoContratoTecnicoCTA")
            }
          />
        </Col>
        <Col md={8} style={{ marginTop: "16px" }}>
          <Input
            id={"codigoIT"}
            label={t("itCode")}
            value={FormatNumber(plantsInfo.codigoIT, locale)}
            onChange={(e) => HandleChangeNumber(e, plantsInfo, setPlantsInfo, locale)}
          />
        </Col>
        <Col md={8} style={{ marginTop: "16px" }}>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mt="2px"
            mb="18px"
            align="left"
          >
            {t("startupCertificate")}
          </Text>
          <RadioGroup
            name={"actaPuestaEnMarcha"}
            gap={"48px"}
            value={plantsInfo.actaPuestaEnMarcha}
            onChange={handleRadio}
          >
            <Radio label={t("yes")} value={true} />
            <Radio label={t("no")} value={false} />
          </RadioGroup>
        </Col>
      </Row>
    </>
  );
};

FinancingRefinancing.propTypes = {
  plantsInfo: PropTypes.object,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleRadio: PropTypes.func,
  setPlantsInfo: PropTypes.func,
};
