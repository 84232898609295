import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetSociety = (id) => {
  const enabled = id !== null;
  const data = useQuery(
    ["getSocietyInfo", id],
    () => axiosClient.get(`society/${id}`), { enabled: enabled});

  return data;
};
export default useGetSociety;