import React, { useState } from "react";
import PropTypes from "prop-types";
import { Div, Text } from "../../../../styles/Common";
import { CardInvestorProj, DashedLine, Divider, WrapperImage } from "../styles";
import { theme } from "../../../../styles/theme";
import { CloseIcon, SunIcon, WindyIcon } from "../../../../assets/icons";
import { Link } from "../../../../components/Link";
import { Button } from "../../../../components/Button";
import { dateFormat, formatNumber, formatToMillions, ReturnYearLabel, unformatNumber } from "../../../../utilities/helpers";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import ModalDiscardProject from "./ModalDiscardProject";

export const FinancingProjects = ({
  projectsData,
  returnImg,
  handleDetail,
}) => {
  const { t, i18n } = useTranslation("projectsTabsView");
  const locale = i18n.language;
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const valueTypes = ["", "-", "#######", null, undefined, "NaN"];

  const formatValue = (value) => {
    if (valueTypes.includes(value)) {
      return "-";
    } else {
      return formatNumber(unformatNumber(value), locale);
    }
  };

  return (
    <Div direction="column" gap="24px">
      <ModalDiscardProject
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedProject={selectedProject}
        instanceType={"financing"}
        selectedProjectId={selectedProjectId}
      />
      {projectsData.map((ele, indx) => (
        <CardInvestorProj key={indx}>
          <WrapperImage backgImg={returnImg(ele?.projectType.type.name)}>
            <Text
              color={theme.colors.white}
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.medium}
            >
              {ele?.teaser?.name}
            </Text>
            <Div align="center" m="3px 0 0 0">
              <Text size={theme.fonts.size.sm} color={theme.colors.yellow200}>
                {ele?.financingSourceName}
              </Text>
            </Div>
            <DashedLine />
            <Div justify="space-between">
              <Div direction="column">
                <Text
                  color={theme.colors.yellow200}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("investmentAmount")}
                </Text>
                <Text
                  color={theme.colors.yellow200}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.semibold}
                >
                  { ele?.teaser?.teaserInvestment ? `€ ${formatToMillions(unformatNumber(ele?.teaser?.teaserInvestment), locale)}` : "€ -"}
                </Text>
              </Div>
              <Div direction="column">
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("size")}
                </Text>
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.semibold}
                >
                  {`${formatValue(ele?.teaser?.teaserProjectSize)} MWp`}
                </Text>
              </Div>
            </Div>
          </WrapperImage>
          <Div direction="column" p="20px 25.5px 22px 0" width="100%">
            <Row>
              <Col md={8}>
                <Div direction="column" align="flex-start">
                  <Text
                    color={theme.colors.blue}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.semibold}
                  >
                    {t("type")}:
                  </Text>
                  <Div>
                    {ele?.projectType.type.name === "solar" ? (
                      <SunIcon
                        fill={theme.colors.gray200}
                        width={24}
                        height={24}
                      />
                    ) : (
                      <WindyIcon
                        fill={theme.colors.gray200}
                        width={24}
                        height={24}
                      />
                    )}
                    <Text color={theme.colors.gray200}>
                      {t(`${ele?.projectType.type.name}`)}
                    </Text>
                  </Div>
                </Div>
              </Col>
              <Col md={8}>
                <Div direction="column" align="flex-start">
                  <Text
                    color={theme.colors.blue}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.semibold}
                  >
                    {t("targetClosing")}:
                  </Text>
                  <Text
                    color={theme.colors.gray200}
                    size={theme.fonts.size.default}
                  >
                    {ele?.expectedClosingDate
                      ? dateFormat(ele?.expectedClosingDate)
                      : "-"}
                  </Text>
                </Div>
              </Col>
              <Col md={8} style={{ textAlign: "-webkit-right" }}>
                <Link
                  onClick={() => {
                    setSelectedProject(ele?.teaser?.name);
                    setSelectedProjectId(ele?._id);
                    setShowModal(true);
                  }}
                >
                  {t("discardProject")}
                  <CloseIcon fill={theme.colors.green} />
                </Link>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col md={8}>
                <Div direction="column" align="flex-start">
                  <Text
                    color={theme.colors.blue}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.semibold}
                  >
                    {t("rtb")}:
                  </Text>
                  <Text
                    color={theme.colors.gray200}
                    size={theme.fonts.size.default}
                  >
                    {ele?.rtbProject ? dateFormat(ele?.rtbProject) : "-"}
                  </Text>
                </Div>
              </Col>
              <Col md={8}>
                <Div direction="column" align="flex-start">
                  <Text
                    color={theme.colors.blue}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.semibold}
                  >
                    {t("targetTenor")}:
                  </Text>
                  <Text
                    color={theme.colors.gray200}
                    size={theme.fonts.size.default}
                  >
                    {ele?.teaser?.teaserTargetTenor ? 
                      `${formatValue(ele?.teaser?.teaserTargetTenor)} ${ReturnYearLabel(ele?.teaser?.teaserTargetTenor, t("year"), t("years"))}` 
                      : "-" 
                    }
                  </Text>
                </Div>
              </Col>
              <Col md={8} style={{ textAlign: "end" }}>
                <Button
                  onClick={() => handleDetail(ele?._id)}
                >
                  Acceso Inversión
                </Button>
              </Col>
            </Row>
          </Div>
        </CardInvestorProj>
      ))}
    </Div>
  );
};

FinancingProjects.propTypes = {
  projectsData: PropTypes.array,
  returnImg: PropTypes.func,
  handleDetail: PropTypes.func,
};
