import React, {useState, useEffect} from "react";
import toast from "react-hot-toast";
import { Text, Row, Div,Col } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {UploadIcon, EyeIcon, DeleteIcon} from "../../../assets/icons";
import { Upload } from "antd";
import useSaveDocuments from "../../Documents/hooks/useSaveDocuments";
import { Link } from "../../../components/Link";
import useDeleteDocument from "../../Documents/hooks/useDeleteDocument";
import useGetDocuments from "../../Documents/hooks/useGetDocuments";
import { useQueryClient } from "react-query";


export const DrawerIndicativeOffer = ({
  openDrawer,
  handleClose,
  projectId,
}) => {

  const { t } = useTranslation("investorProjectDetail");

  const [document, setDocument] = useState(null);
  const saveDocs = useSaveDocuments();
  const deleteDocs = useDeleteDocument();
  const queryClient = useQueryClient();


  const { data: dataDocument, isSuccess: isSuccessDocument } = useGetDocuments(projectId, 2, 7);
  useEffect(() => {
  if (isSuccessDocument && dataDocument?.data?.data.length > 0 ) {
    setDocument(dataDocument?.data?.data[0])
  }
}, [dataDocument, isSuccessDocument]);

  
  const maxSize = 500000000;
  const handleUpload = (info) => {
      const { file } = info;
      if (file.type !== "application/x-msdownload" && file.size < maxSize) {
       setDocument(file);
       } else {
        toast.dismiss()
        toast.error(t("drawerIndicativeOffer.notPermitted"));
      }
  }

  const handleUploadDocument = () => {
    if (saveDocs.isLoading) {
      return;
    }
   
    const payload = {
      description: "indivative offer",
      name: document.name,
      size: document.size,
      type: document.type,
      projectId: projectId,
      uploadtype: 2,
      category: 7,
      subdomain: "",
      documents: [{ doc: document }],
    };
     saveDocs.reset();
     saveDocs.mutate(
      { ...payload },
      {
        onSuccess: () => {
          toast.remove();
          toast.success(t("drawerIndicativeOffer.documentsSuccess"));
          handleClose();
        },
        onError: (err) => {
          toast.remove();
          toast.error(
            err?.response?.data?.result?.code?.message.code
          );
        },
      }
    );    
  };

  const handleDeleteDocument = (documentId) => {
    if (documentId) {
      deleteDocs.reset();
      deleteDocs.mutate(documentId, {
        onSuccess: () => {
          setDocument(null);
          queryClient.invalidateQueries(["getDocumentsByProjectId"]);
          toast.success(t("drawerIndicativeOffer.deleted"));
        },
        onError: (err) => {
          toast.error(
            err?.response?.data?.result?.code?.message.map(() =>
              (t("drawerIndicativeOffer.error"))
            )
          );
        },
      });
    } else {
      setDocument(null);
      toast.success(t("drawerIndicativeOffer.deleted"));
    }
  };

  return (
    <Drawer
      open={openDrawer}
      onClose={handleClose}
      width="405px"
      padding="10px 36px"
    >
      <Text 
      color={theme.colors.blue} 
      size={theme.fonts.size.h5}
      weight={theme.fonts.weight.medium}
      mb="24px" 
      >
        {t("drawerIndicativeOffer.offer")}
      </Text>
      <Row m="0 0 46px 0">
            <Text
            color={theme.colors.gray500} 
            ><span  style={{fontWeight:theme.fonts.weight.medium}}>{t("drawerIndicativeOffer.important")} </span> 
            {t("drawerIndicativeOffer.note")}
            </Text>
      </Row>
        {document === null ? 
        
            <Div
              style={{ cursor: "pointer" }}
              direction="column"
              background={theme.colors.green100}
              width="100%"
              radius="7px"
              justify="center"
              align="center"
              p="22px 36px 21px 36px"
              border="1px dashed"
              borderColor={theme.colors.gray200}
              height="171px"
            >
              <Upload
                multiple={false}
                showUploadList={false}
                onChange={handleUpload}
              >
                <Div
                  background={theme.colors.gray100}
                  radius="50%"
                  width="48px"
                  height="48px"
                  justify="center"
                  align="center"
                  m="0 0 8px 0"
                >
                  <UploadIcon fill={theme.colors.gray200} />
                </Div>
                <Row gap="2px" justify="center">
                  <Text color={theme.colors.green}>{t("drawerIndicativeOffer.clic")}</Text>
                  <Text color={theme.colors.gray500}>{t("drawerIndicativeOffer.upload")}</Text>
                </Row>
                <Row justify="center">
                  <Text 
                  mb="8px" 
                  color={theme.colors.gray300}
                  size={theme.fonts.size.sm}
                  >
                    {t("drawerIndicativeOffer.files")}
                  </Text>
                </Row>
              </Upload>
            </Div>
            : 
            <Div
              background={theme.colors.green100}
              radius="7px"
              align="center"
              p="10px 18px 10px 12px"
              border="1px solid"
              borderColor={theme.colors.gray100}
              justify="space-between"
            >
              <Col align="left" width="100px">
                <Text mb="7px" color={theme.colors.gray300}>
                {document?.name}
                </Text>
                <Text size={theme.fonts.size.sm} color={theme.colors.green}>
                {document?.size ? `${(document.size/(1024 * 1024)).toFixed(2)}Mb`: "--"} 
                </Text>
              </Col>
              <Row gap="13px">
                <Div
                  radius="50%"
                  width="38px"
                  height="38px"
                  justify="center"
                  align="center"
                  m="0 0 8px 0"
                >
                  <Link
                  onClick={()=>setDocument(null)}
                  >
                    <DeleteIcon fill={theme.colors.gray200} onClick={()=> handleDeleteDocument (document?._id) } />
                  </Link>
                </Div>
                <Div
                  radius="50%"
                  width="38px"
                  height="38px"
                  justify="center"
                  align="center"
                  m="0 0 8px 0"
                >
                  <Link>
                    <EyeIcon fill={theme.colors.gray200} />
                  </Link>
                </Div>
              </Row>
            </Div>
            }
          <Div align="end" justify="center" height="100%">
            <Div direction="column" align="center" gap="24px">
              <Button 
              width="169px" 
              onClick={handleUploadDocument}
              disabled={document===null}
              loading = {saveDocs.isLoading}
              >{t("drawerIndicativeOffer.sendOffer")}</Button>
              <Link onClick={handleClose}>
                {t("cancel")}
              </Link>
            </Div>
          </Div>
    </Drawer>
  );
};

DrawerIndicativeOffer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};
