import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetSource = (id) => {
  const data = useQuery(["sourceInfo", id], 
    () => axiosClient.get(`financingSource/${id}`), { enabled: !!id }
  );

  return data;
};

export default useGetSource;