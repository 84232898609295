import decode from "jwt-decode";
import { axiosClient } from "../core/services/axiosInstance";
import moment from "moment-timezone";

export const setLocalValue = (name, value) => {
  localStorage.setItem(name, value);
};

export const getToken = () => {
  const user = localStorage.getItem("user");

  return user;
};
export const getUserInfo = () => {
  const user = localStorage.getItem("user")
    ? decode(localStorage.getItem("user"))
    : {
        role: [],
        id: null,
        name: "",
        companyId: null,
      };
  return user;
};

export const uploadFiles = async (file, url) => {
  const formData = new FormData();
  formData.append("File", file);
  return axiosClient.post(url, formData);
};

export const download = async (url, filename, instance, token) =>
  instance
    .post(
      url,
      {},
      {
        responseType: "blob",
        headers: { Authorization: token },
      }
    )
    .then(
      (response) => {
        const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.setAttribute("download", `${filename}`);
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      (e) => {
        return Promise.reject(e);
      }
    );

export const downloadFile = async (url, filename, instance, token) =>
  instance
    .get(url, {
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const contentType = response.headers["content-type"];
      const pdfUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.setAttribute("download", `${filename}`);
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((error) => {
      return Promise.reject(error);
    });

export const downloadAndOpenFile = async (url, filename, instance, token) =>
  instance
    .get(url, {
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const contentType = response.headers["content-type"];
      const pdfUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      window.open(pdfUrl, "_blank");
    })
    .catch((error) => {
      return Promise.reject(error);
    });

export const fetchImageUrl = async (url, token) => {
  try {
    const response = await axiosClient.get(url, {
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    });

    const contentType = response.headers["content-type"];
    const imageUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType })
    );

    return imageUrl;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const dateFormat = (value) => {
  // Crear el momento en la zona horaria de Irlanda
  const date = moment.tz(value, "Europe/Dublin");
  // Convertir a UTC
  const utcDate = date.utc();

  // Obtener el año, mes y día en UTC
  const year = utcDate.year();
  let month = utcDate.month() + 1; // Los meses son de 0 a 11
  let day = utcDate.date();

  // Formatear para asegurarse de que siempre sean dos dígitos
  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;

  return `${day}/${month}/${year}`;
};

export const commify = (n) => {
  var parts = n.toString().split(",");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "")
  );
};

export const nameLength = 100;
export const emailLength = 100;
export const phoneLength = 15;
export const passwordLength = 25;

export const getModule = {
  "/projects": "projects",
  "/my-account": "myAccount",
  "/my-account/change-password": "changePassword",
  "/projects/new-project": "newProject",
  "/projects/new-plant": "newPlant",
  "/projects/project-detail": "projectDetail",
  "/projects/edit-project": "editProject",
  "/projects/info-plants": "infoPlants",
  "/projects/investor-project-detail": "projects",
  "/strategies": "strategies",
  "/strategies/source": "newSource",
  "/users": "users",
  "/users/invite-investor": "inviteInvestor",
  "/scraper": "scraper",
  "/projects/activity-project": "activityProject",
};

export const getInitials = (fullname) => {
  if (fullname === undefined || "") {
    return "";
  } else {
    const names = fullname.split(" ");
    const nameInitial = names[0].charAt(0).toUpperCase();
    let lastnameInitial = "";
    if (names.length > 1) {
      lastnameInitial = names[names.length - 1].charAt(0).toUpperCase();
    }
    return `${nameInitial}${lastnameInitial}`;
  }
};

export const getLabelFromValue = (value, options) => {
  const option = options.find((option) => option.value === value);
  return option ? option.label : null;
};
export const socialStatusOptions = [
  { value: 1, label: "Todos los proyectos pertenecen a una única sociedad" },
  {
    value: 2,
    label:
      "Los proyectos pertenecen a distintas sociedades que a su vez cuelgan de una única sociedad holding",
  },
  { value: 3, label: "En definición" },
];

export const optionsEnergySalesScheme = [
  {
    label: "100% Power Purchase Agreement (PPA)",
    value: 1,
  },
  {
    label: "100% Merchant Sale",
    value: 2,
  },
  { label: "Hybrid", value: 3 },
  {
    label: "Auction",
    value: 4,
  },
];

export const landsOptions = [
  {
    value: 1,
    label: "Propiedad",
  },
  {
    value: 2,
    label: "Arrendamiento",
  },
  {
    value: 3,
    label: "Pendiente",
  },
];

export const formatThousandsValue = (value) => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  } else {
    return value?.toString() || "0";
  }
};

export const formatFileSize = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2) + " Mb";
};

export const InitInvestorFilters = {
  "filters.type": [
    { label: "filters.solar", value: "solar", checked: false },
    { label: "filters.eolic", value: "eolic", checked: false },
  ],
  "filters.capacity": [
    { label: "- 10MW", value: "-10", checked: false },
    { label: "10MW - 50MW", value: "10-50", checked: false },
    { label: "+ 50MW", value: "+50", checked: false },
  ],
  "filters.countries": [
    { label: "España", value: "es", checked: false },
    { label: "Reino Unido", value: "uk", checked: false },
    { label: "Italia", value: "it", checked: false },
    { label: "USA", value: "us", checked: false },
  ],
  "filters.phase": [
    { label: "Pre-development", value: 1, checked: false },
    { label: "Development", value: 2, checked: false },
    { label: "RtB or Rtf Imminent", value: 3, checked: false },
    { label: "Construction", value: 4, checked: false },
    { label: "Operation", value: 5, checked: false },
  ],
  "filters.strategy": [
    { label: "Minority Equity Shareholding", value: 1, checked: false },
    { label: "Co-develpment", value: 2, checked: false },
    { label: "Assest Acquisition", value: 3, checked: false },
    { label: "Construction Bridge Debt - DI", value: 4, checked: false },
    { label: "Development Bridge Debt - DI", value: 5, checked: false },
  ],
};

export const formatNumberOpex = (value, language) => {
  if (value === undefined || value === 'NaN' || value === "") {
    return;
  }

  value = String(value);
  let integerPart, decimalPart;
  [integerPart, decimalPart] = value.split(".");
  
  integerPart = integerPart.slice(0, 5);
  if (language === "es") {
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (decimalPart) {
    decimalPart = decimalPart.slice(0, 2);
    if (language === "es") {
      return `${integerPart},${decimalPart}`;
    } else {
      return `${integerPart}.${decimalPart}`;
    }
  } else {
    return integerPart;
  }
};

export const unformatNumberOpex = (value, language) => {
  if (language === "es") {
    return value.replace(".", "");
  } else {
    return value.replace(",", "");
  }
};

export const formatNumber = (value, language) => {
  const formatter = new Intl.NumberFormat(language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
  });
  return formatter.format(value);
};

export const unformatNumber = (value, language) => {
const newValue = value.toString();

  if (language === "es") {
    return newValue.replace(/\./g, "").replace(/,/g, ".");
  } else {
    return newValue.replace(/,/g, "");
  }
};

export const projectStatusPromoter = {
  1: "phase_I",
  2: "phase_II",
};

export const projectStatusManager = {
  0: "underStudy",
  1: "structuring",
  2: "readyToDistribute",
  3: "termsheetSent",
  4: "termsheetRejected",
  5: "termsheetSigned",
  6: "projectClosed",
  8: "phaseTwo",
};

export const getLabelAssetType = (value) => {
  switch (value) {
    case 1:
      return "Solar PV Plants";
    case 2:
      return "Onshore Wind Farms";
    case 3:
      return "Rooftop Solar Installations";
    case 4:
      return "Offshore Wind Farms";
    case 5:
      return "BESS";
    default:
      return null;
  }
};

export const getLabelAssetVertical = (value) => {
  switch (value) {
    case "Solar PV Plants":
      return "Solar PV Plants";
    case 'Onshore Wind Farms"':
      return "Onshore Wind Farms";
    default:
      return null;
  }
};

export const formDataAndProjectFormConvertion = {
  teaserName: "basicInfo.teaserName",
  name: "basicInfo.projectName",
  acceptBuyOffers: "basicInfo.purchase",
  dueDiligence: "basicInfo.dueDiligences",
  socialStatus: "basicInfo.societyStatus",
  projectTotalMW: "basicInfo.numberMG",
  projectCostsOption: "capexInfo.totalProjectCost",
  capex: "capexInfo.totalProjectCost",
  substationCosts: "capexInfo.substationReinforcementCost",
  solarPanelsCosts: "capexInfo.solarPanelsCosts",
  transmissionLineCosts: "capexInfo.transmissionLineCost",
  turbinesCosts: "capexInfo.totalTurbinesCost",
  transformersCosts: "capexInfo.totalTransformersCost",
  invertersCosts: "capexInfo.invertersCosts",
  otherCosts: "capexInfo.additionalInvestmentCosts",
  omPerMW: "opexInfo.costeRefuerzos",
  assetManagmentPerMW: "opexInfo.costeLinea",
  insurancesPerMW: "opexInfo.costeTotal",
  networkAccessCostsPerMW: "opexInfo.otrosCostesNetwork",
  localTaxesPerMW: "opexInfo.otrosCostesTaxes",
  otherCostsPerMW: "opexInfo.costeTotaltubinas",
  marketAgencyCostsPerMW: "opexInfo.otrosCostesMarket",
  agreementType: "energySalesSchemeInfo.optionsEnergySalesScheme",
  agreementPPAElectricitySoldPercentage:
    "energySalesSchemeInfo.agreementPPAElectricitySoldPercentage",
  agreementMarketElectricitySoldPercentage:
    "energySalesSchemeInfo.agreementMarketElectricitySoldPercentage",
  agreementPPAPrice: "energySalesSchemeInfo.agreementPPAPrice",
  agreementPPATerm: "energySalesSchemeInfo.agreementPPATerm",
  agreementPPASaleVolumeCommitment:
    "energySalesSchemeInfo.agreementPPASaleVolumeCommitment",
  agreementPPASaleVolumeAmount:
    "energySalesSchemeInfo.agreementPPASaleVolumeAmount",
  consideracionesSobrePrecio: "priceInfo.consideracionesSobrePrecio",
  precioOrientativoMW: "priceInfo.precioOrientativoMW",
  sponsorName: "promoterInfo.companyName",
  experiencesYears: "promoterInfo.experiencesYears",
  completedProjects: "promoterInfo.completedProjects",
  totalMW: "promoterInfo.totalMW",
  plants: "plantsList.plantsList",
};

export const formatToMillions = (number, language) => {
  if (number === null || number === "") {
    return '-';
  }

  const forcedNumber = typeof number !== 'number' ? unformatNumber(number) : number;

  const millions = forcedNumber / 1000000;
  
  const decimalSeparator = language === 'es' ? ',' : '.';
  const formattedNumber = millions.toFixed(2).replace('.', decimalSeparator);
  
  return `${formattedNumber} M`;
};
export const FinancialModelFields = {
  projectTotalMW: "",
  location: "",
  productionp50: "",
  productionp90: "",
  opexTotal: "",
  capexTotal: "",
  rtbDate: "",
  codDate: "",
  total_uses: "",
  EPC: "",
  other_capex: "",
  pre_operational_costs: "",
  upfront_fee: "",
  agency_fee: "",
  swaption_fee: "",
  financial_expenses: "",
  DSRA_endowment: "",
  total_sources: "",
  equity: "",
  senior_Debt: "",
  junior_Debt: "",
  miniperm: "",
  commitment_fee2: "",
  commitment_fee: "",
  interest: "",
  curve: "",
  percentagePPA: "",
  agreementPPAPrice: "",
  horasEquivalentes: "",
  DSCRForMerchant: "",
  DSCRForPPA: "",
  leverage: "",
  year: "",
  proyect_IRR: "",
  equity_IRR: "",
  debt_IRR: "",
  cancelationCosto: "",
  ratio: "",
  total_investment_Eur_MW: "",
  tenor_from_COD: "",
  financialModelFile: "",
  financialModelImg1: "",
  financialModelImg2: "",
  financialModelImg3: "",
  financialModelImg4: "",
  debt_installed_capacity: ""
};

export const ReturnYearLabel = (value, year, years) => {
  if (value === 1) {
    return year;
  } else {
    return years;
  }
}