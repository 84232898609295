import React from "react";
import PropTypes from "prop-types";
import { Card, WrapperTag } from "./styles";
import { useTranslation } from "react-i18next";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { Col, Row } from "antd";
import { theme } from "../../../styles/theme";
import { Div, Text } from "../../../styles/Common";
import close from "../../../assets/icons/close-tag.svg";
import mw from "../../../assets/icons/mw.svg";
import { Select } from "../../../components/Select2";

export const CompanyData = ({
  countriesOptions,
  userData,
  handleSelect,
  country,
  handleChangeCompany,
  handleDelete,
  handleUpdate,
  loading,
}) => {
  const { t } = useTranslation("myAccount");

  return (
    <Card
      width="75%"
      height="max-content"
      p="27px 44px 42px 41px"
      style={{ minHeight: "551px" }}
    >
      <Text
        color={theme.colors.blue}
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h5}
        mb="36px"
      >
        {t("companyData")}
      </Text>
      <Input
        label={t("companyName")}
        sizeLabel={theme.fonts.size.default}
        value={userData.company.name}
        width={"100%"}
        disabled
        style={{ marginBottom: "32px" }}
      />

      {userData.role[0] !== "Manager" && (
        <>
          <Select
            options={countriesOptions}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            showSearch
            onChange={handleSelect}
            value={country}
            label={t("companyCountries")}
            sizeLabel={theme.fonts.size.default}
            width={"100%"}
          />

          <Div
            m="12px 0 0 0"
            style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}
          >
            {userData?.company?.countries?.map((ele, indx) => (
              <WrapperTag key={indx}>
                <Text
                  color={theme.colors.gray300}
                  size={theme.fonts.size.sm}
                  style={{ wordBreak: "break-word" }}
                >
                  {ele}
                </Text>
                <img
                  alt="close"
                  src={close}
                  style={{ cursor: "pointer", flexShrink: 0 }}
                  onClick={() => handleDelete(indx)}
                />
              </WrapperTag>
            ))}
          </Div>
          <Row
            style={{ justifyContent: "space-between", margin: "30px 0 36px 0" }}
          >
            <Col style={{ marginTop: "20px" }}>
              <Input
                label={t("years")}
                sizeLabel={theme.fonts.size.default}
                id={"experiencesYears"}
                value={userData.company.experiencesYears}
                onChange={handleChangeCompany}
                width={"174px"}
              />
            </Col>
            <Col style={{ marginTop: "20px" }}>
              <Input
                label={t("projects")}
                sizeLabel={theme.fonts.size.default}
                id={"completedProjects"}
                value={userData.company.completedProjects}
                onChange={handleChangeCompany}
                width={"208px"}
              />
            </Col>
            <Col>
              <Input
                suffix={<img alt="mw" src={mw} />}
                label={t("totalMW")}
                sizeLabel={theme.fonts.size.default}
                id={"totalMW"}
                value={userData.company.totalMW}
                onChange={handleChangeCompany}
                width={"213px"}
              />
            </Col>
          </Row>
        </>
      )}
      <Button
        onClick={handleUpdate}
        loading={loading}
        style={{ marginTop: "auto" }}
      >
        {t("saveChanges")}
      </Button>
    </Card>
  );
};

CompanyData.propTypes = {
  countriesOptions: PropTypes.array,
  userData: PropTypes.object,
  handleSelect: PropTypes.func,
  country: PropTypes.string,
  handleChangeCountry: PropTypes.func,
  handleChangeCompany: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  loading: PropTypes.bool,
};
