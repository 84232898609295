import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useUpdateTeaser = () => {
  const mutation = useMutation(({ teaserId, formData }) => {
    return axiosClient.put(`/teaser/${teaserId}`, formData);
  });
  return mutation;
};
export default useUpdateTeaser;
