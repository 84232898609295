import React from "react";
import PropTypes from "prop-types";
import { Table } from "../../../components/Table";
import { useTranslation } from "react-i18next";
import { Div } from "../../../styles/Common";
import { Tooltip } from "antd";
import { theme } from "../../../styles/theme";
import { ExternalLinkIcon } from "../../../assets/icons";
import { Skeleton } from "../../../components/Skeleton";
import { dateFormat } from "../../../utilities/helpers";

export const TableContent = ({ 
  source, 
  pages,
  totalItems,
  handleChangeTable,
  data,
  isLoading
}) => {
  const { t } = useTranslation("investorActivity");

  const returnValue = (value) => {
    if (value === 0 || value === null || !value) {
      return 'No';
    } else {
      return t("yes");
    }
  }

  const debtColumns = [
    {
      title: t("projectName"),
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: t("teaserAccessed"),
      dataIndex: "teaserAccessed",
      key: "teaserAccessed",
      render: (value) => returnValue(value),
    },
    {
      title: t("tearmsheetAccessed"),
      dataIndex: "termsheetAccessed",
      key: "termsheetAccessed",
      render: (value) => returnValue(value),
    },
    {
      title: t("modelDownloaded"),
      dataIndex: "downloadedModel",
      key: "downloadedModel",
      render: (value) => returnValue(value),
    },
    {
      title: t("comments"),
      dataIndex: "comments",
      key: "comments",
      render: (value) => (
        <Div>
          {value !== null ? 
            <Div gap="35px">
              {t('yes')}
              <ExternalLinkIcon fill={theme.colors.green} style={{ cursor: "pointer" }} />
            </Div>
           : (
            'No'
          )}
        </Div>
      )
    },
    {
      title: t("signNDA"),
      dataIndex: "dnaSign",
      key: "dnaSign",
      render: (value) => returnValue(value),
    },
    {
      title: t("dataRoomAccess"),
      dataIndex: "dataRoomAccessed",
      key: "dataRoomAccessed",
      render: (value) => returnValue(value),
    },
    {
      title: t("dueDiligenciesAccess"),
      dataIndex: "DiligenciesAccessed",
      key: "DiligenciesAccessed",
      render: (value) => returnValue(value),
    },
    {
      title: t("lastReminderDate"),
      dataIndex: "lastReminderDate",
      key: "lastReminderDate",
      render: (text) => text ? dateFormat(text) : '-',
    },
    {
      title: t("lastActionDate"),
      dataIndex: "lastActionDate",
      key: "lastActionDate",
      render: (text) => text ? dateFormat(text) : '-',
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      render: (actions) => (
        <Div justify="space-between" align="center" style={{ cursor: "pointer" }}>
          {actions.map((action, i) => 
            <Tooltip 
              key={i} 
              title={action.tooltip} 
              color={theme.colors.white}
              placement="topRight"
              overlayInnerStyle={{
                color: theme.colors.gray300,
                fontFamily: theme.fonts.family,
                fontSize: theme.fonts.size.sm,
              }}
            >
              {action.icon}
            </Tooltip>
          )}
        </Div>
      ),
    },
  ];

  const maColumns = [
    {
      title: t("projectName"),
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: t("teaserAccessed"),
      dataIndex: "teaserAccessed",
      key: "teaserAccessed",
      render: (value) => returnValue(value),
    },
    {
      title: t("teaserDownloaded"),
      dataIndex: "teaserAccessed",
      key: "teaserAccessed",
      render: (value) => returnValue(value),
    },
    {
      title: t("t&cRequest"),
      dataIndex: "termsAndConditionsRequested",
      key: "termsAndConditionsRequested",
      render: (value) => returnValue(value),
    },
    {
      title: t("signT&C"),
      dataIndex: "signedTermsAndConditions",
      key: "signedTermsAndConditions",
      render: (value) => returnValue(value),
    },
    {
      title: t("dataRoomAccess"),
      dataIndex: "dataRoomAccessed",
      key: "dataRoomAccessed",
      render: (value) => returnValue(value),
    },
    {
      title: t("nboSended"),
      dataIndex: "nboSended",
      key: "nboSended",
      render: (value) => returnValue(value),
    },
    {
      title: t("questions"),
      dataIndex: "questions",
      key: "questions",
      render: (value) => returnValue(value),
    },
    {
      title: t("lastReminderDate"),
      dataIndex: "lastReminderDate",
      key: "lastReminderDate",
      render: (text) => text ? dateFormat(text) : '-',
    },
    {
      title: t("lastActionDate"),
      dataIndex: "lastActionDate",
      key: "lastActionDate",
      render: (text) => text ? dateFormat(text) : '-',
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      render: (actions) => (
        <Div justify="space-between" align="center" style={{ cursor: "pointer" }}>
          {actions.map((action, i) => 
            <Tooltip 
              key={i} 
              title={action.tooltip} 
              color={theme.colors.white}
              placement="topRight"
              overlayInnerStyle={{
                color: theme.colors.gray300,
                fontFamily: theme.fonts.family,
                fontSize: theme.fonts.size.sm,
              }}
            >
              {action.icon}
            </Tooltip>
          )}
        </Div>
      ),
    },
  ];

  return (
    <Table 
      scroll={{ x: 500 }}
      columns={source === "debt" ? debtColumns : maColumns}
      data={data}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      pagination={{
        defaultCurrent: 1,
        ...pages,
        total: totalItems,
        position: ["bottomRight"],
      }}
      onChange={handleChangeTable}
      rowSelection={{
        type: 'checkbox',
      }}
      shadow={"none"}
      padding={"0px"}
      style={{ width: "75vw" }}
      locale={{
        emptyText: 
        isLoading ? (
          <Div justify="center" gap="16px">
            {[...Array(9)].map((item, idx) => (
              <Skeleton
                key={idx}
                title={false}
                paragraph={{
                  rows: 10,
                  width: "100%",
                }}
                loading
              />
            ))}
          </Div>
        ) : (
          t('noData')
        ),
      }}
    />
  )
};

TableContent.propTypes = {
  source: PropTypes.string,
  pages: PropTypes.object,
  totalItems: PropTypes.number,
  handleChangeTable: PropTypes.func,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};