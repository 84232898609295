import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../../styles/theme";
import { Div, Row, Text } from "../../../../styles/Common";
import { DownloadIcon } from "../../../../assets/icons";
import { Button } from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import useSetActivityLog from "../../hooks/useSetActivityLog";

export const ExecutionDocs = ({ projectId }) => {
    const { t } = useTranslation("executionDocs");
    const setLog = useSetActivityLog();

    const handleDownload = () => {
        const payload = {
            action: "download-financial-agreement",
            projectId: projectId,
        }
        setLog.reset();
        setLog.mutate(payload);
    };

    return (
        <>
            <Div direction="column"
                style={{
                    paddingBottom: "6.25rem"
                }}>
                <Row justify="space-between" align="center" m=" 0 0 23px 0">
                    <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.h5i}
                        color={theme.colors.blue}
                        style={{ margin: "0", padding: "10px 0" }}
                    >   
                       {t("title")}
                    </Text>
                </Row>
                <Button
                    width="305px"
                    height="45px"
                    variant={"outlined"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                    }}
                    onClick={handleDownload}
                >
                    <DownloadIcon fill={theme.colors.green} />
                    {t("downloadFinancingContract")}
                </Button>
            </Div>
        </>
    )
};


ExecutionDocs.propTypes = {
    projectId: PropTypes.string,
};