/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { Div, Text } from "../../../styles/Common";
import { Link } from "../../../components/Link";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Upload } from "../../../components/Upload";
import useGetDocuments from "../hooks/useGetDocuments";

export const DrawerDocuments = ({
  openDrawer,
  handleClose,
  handleUploadDocument,
  docList,
  handleSaveDocs,
  saveDocs,
  handleRemoveDocument,
  projectId,
  setDocList,
}) => {
  const { t } = useTranslation("documents");
  const { data, isSuccess } = useGetDocuments(projectId, 2, 5);
  
  useEffect(() => {
    if (isSuccess && data?.data.data.length > 0) {
      setDocList(data?.data.data.map((ele) => ({
        name: ele.name,
        size: ele.size,
        doc: ele.url
      })))
    }
  }, [isSuccess, data])

  return (
    <Drawer
      title={t("documentsTitle")}
      open={openDrawer}
      onClose={handleClose}
      width={"499px"}
    >
      <Div direction="column" height="100%" justify="space-between">
        <Div direction="column">
          <Text
            color={theme.colors.gray500}
            mt="16px"
            mb="24px"
            lineHeight="24px"
          >
            {t("documentsMessage")}
          </Text>
          <Div direction={"column"} width="100%" justify="center">
            <Upload 
              docsList={docList} 
              handleUploadDocument={handleUploadDocument} 
              handleRemoveDocument={handleRemoveDocument} 
              direction={'column'}
            />
          </Div>
        </Div>
        <Div direction="column" align="center" justify="center" gap="24px">
          <Button
            width={"169px"}
            isLoading={saveDocs.isLoading}
            disabled={docList.length === 0}
            onClick={handleSaveDocs}
          >
            {t("saveLinks")}
          </Button>
          <Link onClick={handleClose}>{t("cancel")}</Link>
        </Div>
      </Div>
    </Drawer>
  );
};

DrawerDocuments.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  handleUploadDocument: PropTypes.func,
  docList: PropTypes.array,
  handleSaveDocs: PropTypes.func,
  saveDocs: PropTypes.object,
  handleRemoveDocument: PropTypes.func,
  projectId: PropTypes.string,
  setDocList: PropTypes.func,
};