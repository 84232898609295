import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDelegateQuestion = () => {
  const mutation = useMutation(({ projectId, questionId }) => {
    return axiosClient.patch(
      `project/${projectId}/questions/${questionId}/delegate`
    );
  });
  return mutation;
};
export default useDelegateQuestion;
