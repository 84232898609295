/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Row, Text, Div, ModuleContainer } from "../../styles/Common";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "../../components/Card";
import { theme } from "../../styles/theme";
import { Collapse } from "../../components/Collapse";
import { AditionalInfo } from "./components/aditionalInfo";
import { Interconnection } from "./components/interconnection";
import { Terrain } from "./components/terrain";
import { License } from "./components/license";
import { Others } from "./components/others";
import { ProjectEngineering } from "./components/projectEngineering";
import { Taxes } from "./components/taxes";
import { Button } from "../../components/Button";
import mwp from "../../assets/icons/mwp.svg";
import years from "../../assets/icons/years.svg";
import anual from "../../assets/icons/anual.svg";
import ha from "../../assets/icons/ha.svg";
import m from "../../assets/icons/m.svg";
import kv from "../../assets/icons/kv.svg";
import e from "../../assets/icons/euro.svg";
import { FinancingConstructionInfoPlants } from "./components/PlantsInfoComponents/financingConstructionInfoPlants";
import { FinancingRefinancingInfoPlants } from "./components/PlantsInfoComponents/financingRefinancingInfoPlants";
import { FinancingConstructionPermissions } from "./components/PermissionsComponents/financingConstructionPermissions";
import { SalePreInfoPlants } from "./components/PlantsInfoComponents/salePreInfoPlants";
import { SalePrePermissions } from "./components/PermissionsComponents/salePrePermissions";
import { SaleOperationInfoPlants } from "./components/PlantsInfoComponents/saleOperationInfoPlants";
import { useTranslation } from "react-i18next";
import useGetPlantInfo from "../NewProject/hooks/useGetPlantInfo";
import useUpdateAdditionalInfoPlants from "./hooks/useUpdateAdditionalInfoPlants";
import ModalSuccess from "../NewProject/components/ModalSuccess";
import useGetProvinces from "../NewPlants/hooks/useGetProvinces";
import useGetMunicipality from "../NewPlants/hooks/useGetMunicipality";
import { validatePlantForm } from "../../utilities/validations";

export const InfoPlants = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const saveInfo = useUpdateAdditionalInfoPlants();
  const projectType = new URLSearchParams(search).get("projectType");
  const financingType = new URLSearchParams(search).get("financingType");
  const projectId = new URLSearchParams(search).get("projectId");
  const plantId = new URLSearchParams(search).get("plantId");
  const projectName = new URLSearchParams(search).get("projectName");
  const { t } = useTranslation(["infoPlants"]);

  const [showModal, setShowModal] = useState(false);
  const [activeKey, setActiveKey] = useState(["0"]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [formError, setFormError] = useState({
    name: {
      error: false,
      message: "",
    },
    potenciaPico: {
      error: false,
      message: "",
    },
    horasEquivalentes: {
      error: false,
      message: "",
    },
    horasEquivalentes90: {
      error: false,
      message: "",
    },
  });

  const { data, isSuccess } = useGetPlantInfo(plantId);
  const [plantsInfo, setPlantsInfo] = useState({
    projectId: projectId,
    potenciaPico: "",
    plazoContratoArrendamiento: "",
    costeArrendamiento: "",
    potenciaNominal: "",
    estadoTerrenoPlanta: null,
    superficie: "",
    name: "",
    location: "",
    horasEquivalentes90: "",
    horasEquivalentes: "",
    actaPuestaEnMarcha: null,
    codigoIT: "",
    costesEstimadosOM: "",
    costesImpuestos: "",
    costesSeguros: "",
    estadoContratoTecnicoCTA: null,
    fechaPuestaEnMarcha: "",
    municipio: "",
    otrosGastos: "",
    longitud: "",
    typeLine: null,
    tensionLine: "",
    fechaEstimadaCoDPlanta: "",
    fechaEstimadaRtBPlanta: null,
  });
  const [permissions, setPermissions] = useState({
    AAC: null,
    AACDate: null,
    interconexion: null,
    workLicenses: null,
    AAP: null,
    AAPDate: null,
    admisionOSCA: null,
    DIA: null,
    DIADate: null,
    DUP: null,
    DUPDate: null,
    equiposMedicion: null,
    fechaIVA: null,
  });

  const [additionalInfo, setAdditionalInfo] = useState({
    technology: "",
    thirdPartySpv: null,
  });

  const [interconnectionInfo, setInterconnectionInfo] = useState({
    connectionOrTransportationSet: "",
    sharedLine: "",
    requiresIntermediateSubstation: null,
    substationType: "",
    substationCost: null,
    estimatedInterconnectionCost: null,
    connectionSet: "",
    existingOrPlannedSet: "",
  });

  const [landsInfo, setLandsInfo] = useState({
    percentageOfOwnedLand: null,
    landValue: null,
    leasedHectares: null,
    pricePerHectare: null,
    expansionPossibility: "",
    percentageOfLandIncluded: null,
    contractDuration: null,
    estimatedAnnualProduction: null,
  });

  const [engineeringInfo, setEngineeringInfo] = useState({
    updatedGenerationPlantProject: null,
    includesEnergyStorageSystem: null,
  });

  const [constructionLicenseInfo, setConstructionLicenseInfo] = useState({
    estimatedICIO: null,
    estimatedLOObtentionDate: null,
    estimatedLOCost: null,
  });

  const [taxesInfo, setTaxesInfo] = useState({
    estimatedUrbanDevelopmentTax: null,
    estimatedIBICE: null,
    estimatedIAE: null,
  });

  const [otherInfo, setOtherInfo] = useState({
    CODDeadline: "",
    otherDetails: "",
  });
  useEffect(() => {
    if (isSuccess) {
      setPlantsInfo({
        projectId: data?.data?.projectId || projectId,
        potenciaPico: data?.data?.potenciaPico || "",
        plazoContratoArrendamiento:
          data?.data?.plazoContratoArrendamiento || "",
        costeArrendamiento: data?.data?.costeArrendamiento || "",
        potenciaNominal: data?.data?.potenciaNominal || "",
        estadoTerrenoPlanta: data?.data?.estadoTerrenoPlanta || null,
        superficie: data?.data?.superficie || "",
        name: data?.data?.name || "",
        location: data?.data?.location || "",
        horasEquivalentes90: data?.data?.horasEquivalentes90 || "",
        horasEquivalentes: data?.data?.horasEquivalentes || "",
        actaPuestaEnMarcha: data?.data?.actaPuestaEnMarcha || null,
        codigoIT: data?.data?.codigoIT || "",
        costesEstimadosOM: data?.data?.costesEstimadosOM || "",
        costesImpuestos: data?.data?.costesImpuestos || "",
        costesSeguros: data?.data?.costesSeguros || "",
        estadoContratoTecnicoCTA: data?.data?.estadoContratoTecnicoCTA || null,
        fechaPuestaEnMarcha: data.data.fechaPuestaEnMarcha
          ? dayjs(data.data.fechaPuestaEnMarcha)
          : null,
        municipio: data?.data?.municipio || "",
        otrosGastos: data?.data?.otrosGastos || "",
        longitud: data?.data?.longitud || "",
        typeLine: data?.data?.typeLine || null,
        tensionLine: data?.data?.tensionLine || "",
        fechaEstimadaCoDPlanta: data?.data?.fechaEstimadaCoDPlanta
          ? dayjs(data?.data?.fechaEstimadaCoDPlanta)
          : null,
        fechaEstimadaRtBPlanta: data?.data?.fechaEstimadaRtBPlanta
          ? dayjs(data?.data?.fechaEstimadaRtBPlanta)
          : null,
      });
    }
    setPermissions({
      AAC: data?.data?.AAC || null,
      AACDate: data?.data?.AACDate ? dayjs(data?.data?.AACDate) : null,
      interconexion: data?.data?.interconexion || null,
      workLicenses: data?.data?.workLicenses || null,
      AAP: data?.data?.AAP || null,
      AAPDate: data?.data?.AAPDate ? dayjs(data?.data?.AAPDate) : null,
      admisionOSCA: data?.data?.admisionOSCA || null,
      DIA: data?.data?.DIA || null,
      DIADate: data?.data?.DIADate ? dayjs(data?.data?.DIADate) : null,
      DUP: data?.data?.DUP || null,
      DUPDate: data?.data?.DUPDate ? dayjs(data?.data?.DUPDate) : null,
      equiposMedicion: data?.data?.equiposMedicion || null,
      fechaIVA: data?.data?.fechaIVA ? dayjs(data?.data?.fechaIVA) : null,
    });
    setAdditionalInfo({
      technology: data?.data?.technology || "",
      thirdPartySpv: data?.data?.thirdPartySpv || null,
    });
    setInterconnectionInfo({
      connectionOrTransportationSet:
        data?.data?.connectionOrTransportationSet || "",
      sharedLine: data?.data?.sharedLine || "",
      requiresIntermediateSubstation:
        data?.data?.requiresIntermediateSubstation || null,
      substationType: data?.data?.substationType || "",
      substationCost: data?.data?.substationCost || null,
      estimatedInterconnectionCost:
        data?.data?.estimatedInterconnectionCost || null,
      connectionSet: data?.data?.connectionSet || "",
      existingOrPlannedSet: data?.data?.existingOrPlannedSet || "",
    });
    setLandsInfo({
      percentageOfOwnedLand: data?.data?.percentageOfOwnedLand || null,
      landValue: data?.data?.landValue || null,
      leasedHectares: data?.data?.leasedHectares || null,
      pricePerHectare: data?.data?.pricePerHectare || null,
      expansionPossibility: data?.data?.expansionPossibility || "",
      percentageOfLandIncluded: data?.data?.percentageOfLandIncluded || null,
      contractDuration: data?.data?.contractDuration || null,
      estimatedAnnualProduction: data?.data?.estimatedAnnualProduction || null,
    });
    setEngineeringInfo({
      updatedGenerationPlantProject:
        data?.data?.updatedGenerationPlantProject || null,
      includesEnergyStorageSystem:
        data?.data?.includesEnergyStorageSystem || null,
    });
    setConstructionLicenseInfo({
      estimatedICIO: data?.data?.estimatedICIO || null,
      estimatedLOObtentionDate: data?.data?.estimatedLOObtentionDate
        ? dayjs(data?.data?.estimatedLOObtentionDate)
        : null,
      estimatedLOCost: data?.data?.estimatedLOCost || null,
    });
    setTaxesInfo({
      estimatedUrbanDevelopmentTax:
        data?.data?.estimatedUrbanDevelopmentTax || null,
      estimatedIBICE: data?.data?.estimatedIBICE || null,
      estimatedIAE: data?.data?.estimatedIAE || null,
    });
    setOtherInfo({
      CODDeadline: data?.data?.CODDeadline
        ? dayjs(data?.data?.CODDeadline)
        : null,
      otherDetails: data?.data?.otherDetails || "",
    });
  }, [isSuccess, data]);

  const { data: provincesData, isSuccess: isSuccessProvinces } =
    useGetProvinces();
  const [provincesOptions, setProvincesOptions] = useState([]);
  useEffect(() => {
    if (isSuccessProvinces) {
      setProvincesOptions(
        provincesData?.data?.data.map((ele, indx) => ({
          key: indx,
          label: ele.label,
          value: ele.code,
        }))
      );
    }
  }, [isSuccessProvinces, provincesData]);

  const { data: municipalityData, isSuccess: isSuccessMunicipality } =
    useGetMunicipality(plantsInfo.location);
  const [municipalityOptions, setMunicipalityOptions] = useState([]);
  useEffect(() => {
    if (isSuccessMunicipality) {
      setMunicipalityOptions(
        municipalityData?.data?.data.map((ele, indx) => ({
          key: indx,
          label: ele.label,
          value: ele.code,
        }))
      );
    }
  }, [isSuccessMunicipality, municipalityData]);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const handleChange = (event, form, setForm) => {
    const { id, value } = event.target;

    if (id === "name") {
      const newErrorForm = formError;
      newErrorForm[id].error = false;
      newErrorForm[id].message = "";
      setFormError(newErrorForm);
    }

    const newFormInfo = { ...form };
    newFormInfo[id] = value;
    setForm(newFormInfo);
  };

  const handleChangeNumber = (event, form, setForm) => {
    const { id, value } = event.target;
    if (
      id === "potenciaPico" ||
      id === "horasEquivalentes" ||
      id === "horasEquivalentes90"
    ) {
      const newErrorForm = formError;
      newErrorForm[id].error = false;
      newErrorForm[id].message = "";
      setFormError(newErrorForm);
    }

    const newFormInfo = { ...form };
    newFormInfo[id] = value.replace(/[^0-9.]/g, "");
    setForm(newFormInfo);
  };

  const handleSelect = (value, id, form, setForm) => {
    const newFormInfo = { ...form };

    if (id === "location") {
      newFormInfo["municipio"] = null;
    }
    newFormInfo[id] = value;
    setForm(newFormInfo);
  };

  const handleDate = (value, id, form, setForm) => {
    const newFormInfo = { ...form };

    newFormInfo[id] = value;
    setForm(newFormInfo);
  };

  const handleRadio = (event, form, setForm) => {
    const { value, name } = event.target;
    const newFormInfo = { ...form };

    newFormInfo[name] = value;
    setForm(newFormInfo);
  };

  const handleCancel = () => {
    navigate(
      `/projects/edit-project?projectId=${projectId}&selected=plants&projectName=${projectName}`
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/projects");
  };

  const handleSave = () => {
    const formValidation = {
      name: plantsInfo.name,
      potenciaPico: plantsInfo.potenciaPico,
      horasEquivalentes90: plantsInfo.horasEquivalentes90,
      horasEquivalentes: plantsInfo.horasEquivalentes,
    };

    if (!(projectType === "solar" && financingType === "construction")) {
      delete formValidation.horasEquivalentes;
    }

    const validation = validatePlantForm.validate(formValidation, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = formError;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        switch (ele.context.label) {
          case "horasEquivalentes90":
          case "horasEquivalentes":
            newErrorForm[ele.context.label].message = t("errorHours");
            break;
          default:
            newErrorForm[ele.context.label].message = t("required");
            break;
        }
      });
      setFormError(newErrorForm);
      setActiveKey("1");
      setForceUpdate(!forceUpdate);
    } else {
      saveInfo.reset();
      saveInfo.mutate(
        {
          plantId: plantId,
          formData: {
            plantDto: {
              ...plantsInfo,
              ...permissions,
            },
            plantInformationDto: {
              ...additionalInfo,
              ...interconnectionInfo,
              ...landsInfo,
              ...engineeringInfo,
              ...constructionLicenseInfo,
              ...taxesInfo,
              ...otherInfo,
            },
          },
        },
        {
          onSuccess: () => {
            setShowModal(true);
          },
          onError: (err) => {
            toast.error(
              t(
                `common:${
                  err.response.data.result.code.message ||
                  err.response.data.result.code
                }`
              )
            );
          },
        }
      );
    }
  };

  const handleComponentPlantInfo = () => {
    switch (financingType) {
      case "construction":
        return (
          <FinancingConstructionInfoPlants
            plantsInfo={plantsInfo}
            setPlantsInfo={setPlantsInfo}
            projectType={projectType}
            financingType={financingType}
            mwp={mwp}
            years={years}
            anual={anual}
            ha={ha}
            m={m}
            kv={kv}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleChangeNumber={handleChangeNumber}
            provincesOptions={provincesOptions}
            municipalityOptions={municipalityOptions}
            formError={formError}
          />
        );
      case "financed":
        return (
          <FinancingRefinancingInfoPlants
            plantsInfo={plantsInfo}
            setPlantsInfo={setPlantsInfo}
            projectType={projectType}
            financingType={financingType}
            mwp={mwp}
            years={years}
            anual={anual}
            ha={ha}
            m={m}
            kv={kv}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleRadio={handleRadio}
            handleChangeNumber={handleChangeNumber}
            provincesOptions={provincesOptions}
            municipalityOptions={municipalityOptions}
            formError={formError}
          />
        );
      case "RtBCOD":
        return (
          <SalePreInfoPlants
            plantsInfo={plantsInfo}
            setPlantsInfo={setPlantsInfo}
            projectType={projectType}
            financingType={financingType}
            mwp={mwp}
            years={years}
            anual={anual}
            ha={ha}
            m={m}
            kv={kv}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleChangeNumber={handleChangeNumber}
            provincesOptions={provincesOptions}
            municipalityOptions={municipalityOptions}
            formError={formError}
          />
        );
      case "operation":
        return (
          <SaleOperationInfoPlants
            plantsInfo={plantsInfo}
            setPlantsInfo={setPlantsInfo}
            projectType={projectType}
            financingType={financingType}
            mwp={mwp}
            years={years}
            anual={anual}
            ha={ha}
            e={e}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleChangeNumber={handleChangeNumber}
            provincesOptions={provincesOptions}
            municipalityOptions={municipalityOptions}
            formError={formError}
          />
        );
    }
  };

  const handleComponentPermissions = () => {
    switch (financingType) {
      case "construction":
        return (
          <FinancingConstructionPermissions
            permissions={permissions}
            setPermissions={setPermissions}
            handleSelect={handleSelect}
            handleRadio={handleRadio}
            handleDate={handleDate}
          />
        );
      case "financed":
        return (
          <>
            <Div
              background={theme.colors.gray100}
              height="1px"
              width="100%"
              m="0 0 33.5px 0"
            />
            <Text
              size={theme.fonts.size.sm}
              color={theme.colors.gray200}
              mt="2px"
              mb="8px"
              align="left"
            >
              N/A
            </Text>
          </>
        );
      case "RtBCOD":
        return (
          <SalePrePermissions
            permissions={permissions}
            setPermissions={setPermissions}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleRadio={handleRadio}
          />
        );
      case "operation":
        return (
          <>
            <Div
              background={theme.colors.gray100}
              height="1px"
              width="100%"
              m="0 0 33.5px 0"
            />
            <Text
              size={theme.fonts.size.sm}
              color={theme.colors.gray200}
              mt="2px"
              mb="8px"
              align="left"
            >
              N/A
            </Text>
          </>
        );
    }
  };

  const items = [
    {
      key: "1",
      header: t("information"),
      panel: handleComponentPlantInfo(),
    },
    {
      key: "2",
      header: t("permissions"),
      panel: handleComponentPermissions(),
    },
    {
      key: "3",
      header: t("additional"),
      panel: (
        <AditionalInfo
          additionalInfo={additionalInfo}
          setAdditionalInfo={setAdditionalInfo}
          handleChange={handleChange}
          handleRadio={handleRadio}
        />
      ),
    },
    {
      key: "4",
      header: t("interconnection"),
      panel: (
        <Interconnection
          interconnectionInfo={interconnectionInfo}
          setInterconnectionInfo={setInterconnectionInfo}
          handleChange={handleChange}
          handleChangeNumber={handleChangeNumber}
          handleRadio={handleRadio}
        />
      ),
    },
    {
      key: "5",
      header: t("lands"),
      panel: (
        <Terrain
          landsInfo={landsInfo}
          setLandsInfo={setLandsInfo}
          handleChangeNumber={handleChangeNumber}
          handleRadio={handleRadio}
        />
      ),
    },
    {
      key: "6",
      header: t("project"),
      panel: (
        <ProjectEngineering
          engineeringInfo={engineeringInfo}
          setEngineeringInfo={setEngineeringInfo}
          handleRadio={handleRadio}
        />
      ),
    },
    {
      key: "7",
      header: t("license"),
      panel: (
        <License
          constructionLicenseInfo={constructionLicenseInfo}
          setConstructionLicenseInfo={setConstructionLicenseInfo}
          handleChangeNumber={handleChangeNumber}
          handleDate={handleDate}
        />
      ),
    },
    {
      key: "8",
      header: t("Taxes"),
      panel: (
        <Taxes
          taxesInfo={taxesInfo}
          setTaxesInfo={setTaxesInfo}
          handleChangeNumber={handleChangeNumber}
        />
      ),
    },
    {
      key: "9",
      header: t("Others"),
      panel: (
        <Others
          otherInfo={otherInfo}
          setOtherInfo={setOtherInfo}
          handleChange={handleChange}
          handleDate={handleDate}
        />
      ),
    },
  ];

  return (
    <ModuleContainer direction="column" padding="33px 69px 0px 32px">
      <ModalSuccess
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        page="infoPlants"
      />
      <Card
        width="100%"
        background={theme.colors.white}
        height="auto"
        margin="0 0 48px 0"
        padding="15px 36px 29px 38px"
      >
        <Collapse
          activeKey={activeKey}
          handlePanelChange={handlePanelChange}
          panels={items}
          group={true}
          marginBottom={"0px"}
        />
      </Card>
      <Row gap="10px" m="0 0 34px 0">
        <Button
          width="141px"
          height="45px"
          variant={"outlined"}
          onClick={handleCancel}
        >
          {t("cancel")}
        </Button>
        <Button
          width="192px"
          height="45px"
          onClick={handleSave}
          loading={saveInfo.isLoading}
        >
          {t("save")}
        </Button>
      </Row>
    </ModuleContainer>
  );
};
