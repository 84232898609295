import React from "react";
import { Div, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import Drawer from "../../../../components/Drawer";
import Checkbox from "../../../../components/Checkbox";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const DrawerFilterManager = ({
  openDrawer,
  handleClose,
  filterValues,
  handleChange,
  handleCleanFilters,
  handleSaveFilters,
}) => {
  const { t } = useTranslation("projects");

  const statusFilter = [
    { label: "structuration", value: 1 },
    { label: "distribute", value: 2 },
    { label: "sent", value: 3 },
    { label: "termsheetRejected", value: 4 },
    { label: "termsheetSigned", value: 5 },
    { label: "projectCosed", value: 6 },
  ];

  const otherFilters = [
    { label: "thermsheet", value: "thermsheet" },
    { label: "offer", value: "offer" },
    { label: "published", value: "published" },
    { label: "questions", value: "questions" },
  ];

  return (
    <Drawer
      title={t("filter.title")}
      open={openDrawer}
      onClose={handleClose}
      width={"332px"}
      height="768px"
    >
      <Div m="26px 0 14px 0">
        <Text
          size={theme.fonts.size.h5i}
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
        >
          {t("filter.type")}
        </Text>
      </Div>
      <Div m="0 0 20px 0">
        <Checkbox.Group style={{ display: "flex", flexDirection: "row" }}>
          <Checkbox
            label={t("filter.solar")}
            onChange={handleChange("type", "solar")}
            checked={filterValues.type.includes("solar")}
          />
          <Checkbox
            label={t("filter.eolic")}
            onChange={handleChange("type", "eolic")}
            checked={filterValues.type.includes("eolic")}
          />
        </Checkbox.Group>
      </Div>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          opacity: 1,
          margin: "0 0 14px 0",
        }}
      />
      <Div m="0 0 14px 0">
        <Text
          size={theme.fonts.size.h5i}
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
        >
          {t("filter.instance")}
        </Text>
      </Div>
      <Div m="0 0 20px 0">
        <Checkbox.Group style={{ display: "flex", flexDirection: "row" }}>
          <Checkbox
            label={t("filter.sale")}
            onChange={handleChange("instance", "sale")}
            checked={filterValues.instance.includes("sale")}
          />
          <Checkbox
            label={t("filter.financing")}
            onChange={handleChange("instance", "financing")}
            checked={filterValues.instance.includes("financing")}
          />
        </Checkbox.Group>
      </Div>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          opacity: 1,
          margin: "0 0 14px 0",
        }}
      />
      <Div m="0 0 14px 0">
        <Text
          size={theme.fonts.size.h5i}
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
        >
          {t("filter.status")}
        </Text>
      </Div>
      <Div m="0 0 14px 0">
        <Checkbox.Group direction="column">
          {statusFilter.map((state, index) => (
            <Checkbox
              key={index}
              label={t(`filter.${state.label}`)}
              onChange={handleChange("status", state.value)}
              checked={filterValues.status.includes(state.value)}
            />
          ))}
        </Checkbox.Group>
      </Div>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          opacity: 1,
          margin: "0 0 14px 0",
        }}
      />
      <Div m="0 0 14px 0">
        <Text
          size={theme.fonts.size.h5i}
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
        >
          {t("filter.others")}
        </Text>
      </Div>
      <Div m="0 0 100px 0">
        <Checkbox.Group direction="column">
          {otherFilters.map((state, index) => (
            <Checkbox
              key={index}
              label={t(`filter.${state.label}`)}
              onChange={handleChange("others", state.value)}
              checked={filterValues.others.includes(state.value)}
            />
          ))}
        </Checkbox.Group>
      </Div>
      <hr />
      <Div
        direction="column"
        width="327px"
        align="center"
        justify="center"
        gap="16px"
        background={theme.colors.white}
        p="10px 0"
        style={{ position: "fixed", bottom: "0px", right: "5px" }}
      >
        <Button width={"169px"} onClick={handleSaveFilters}>
          {t("filter.save")}
        </Button>
        <Link onClick={handleCleanFilters}>{t("filter.clean")}</Link>
      </Div>
    </Drawer>
  );
};

DrawerFilterManager.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  filterValues: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleCleanFilters: PropTypes.func.isRequired,
  handleSaveFilters: PropTypes.func.isRequired,
};
