import React from "react";
import PropTypes from "prop-types";
import { Row } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { useTranslation } from "react-i18next";

export const Other = ({
  strategyData,
  setStrategyData,
  handleChange,
  tabActiveKey,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  return (
    <>
      <Row m=" 33.5px 0 0 0">
        <Input
          label={t("other")}
          id="other"
          width="100%"
          height="138px"
          value={strategyData[Number(tabActiveKey) - 1].other}
          onChange={(event) =>
            handleChange(event, strategyData, setStrategyData)
          }
        />
      </Row>
    </>
  );
};

Other.propTypes = {
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  handleChange: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
