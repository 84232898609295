import styled from "styled-components";
import { theme } from "../../styles/theme";

export const WrapperSubtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 25px 29px 24px 29px;
  background-color: ${theme.colors.gray100};
  min-height: 97px;
  width: 100%;
`

export const WrapperAdd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 252px;
  height: 136px;
  border: 1px dashed ${theme.colors.green};
  border-radius: 14px;
  cursor: pointer;
  gap: 11px;
`

export const WrapperAddIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  border: 1px solid ${theme.colors.green};
  opacity: 1;
`
export const WrapperSociety = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 6px 6px 17px;
  width: 252px;
  height: 136px;
  border-radius: 14px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000010;
`

export const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  background: ${theme.colors.error100} 0% 0% no-repeat padding-box;
  border: 1px solid ${theme.colors.error};
  border-radius: 100%;
  margin-bottom: 16px;
`