import styled from "styled-components";
import { theme } from "../../../../styles/theme";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  height: fit-content;
  padding: 20px 24px 18px 24px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000010;
  border-radius: 14px;
  opacity: 1;
`

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 522px;
  padding: 9px 15px 36px 16px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 5px 11px 26px #00000022;
  border-radius: 20px;
  opacity: 1;
  margin-bottom: 43px;
`

export const SubCardList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 38px 13px 14px;
  background: ${({ background }) => background} 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
`

export const WrapperDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 23px;
  background: ${theme.colors.light} 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
`