import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../components/Modal";
import { Div, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../components/Select2";

export const ModalOwner = ({
  showModal,
  handleCloseModal,
  companiesOptions,
  usersOptions,
  companyId,
  ownerId,
  handleSelectCompany,
  handleSelectOwner,
}) => {
  const { t } = useTranslation("projectsTabsView");

  return (
    <Modal open={showModal} onCancel={handleCloseModal} radius={"20px"}>
      <Modal.Body
        margin="28px 0 0px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Div direction="column" width="100%">
          <Div width="100%" m="0 0 15px 0">
            <Text
              size={theme.fonts.size.h5i}
              color={theme.colors.blue}
              weight={theme.fonts.weight.medium}
            >
              {t("selectPromoter")}
            </Text>
          </Div>
          <Div direction="column" gap="16px">
            <Select 
              label={t("company")}
              placeholder={t("select")}
              options={companiesOptions}
              value={companyId}
              onChange={handleSelectCompany}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              width={"100%"} 
            />
            <Select 
              label={t("owner")}
              placeholder={t("select")}
              options={usersOptions}
              value={ownerId}
              onChange={handleSelectOwner}
              disabled={companyId === null}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              width={"100%"} 
            />
          </Div>
        </Div>
      </Modal.Body>
    </Modal>
  )
};

ModalOwner.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  companyId: PropTypes.string,
  ownerId: PropTypes.string,
  handleSelectCompany: PropTypes.func,
  handleSelectOwner: PropTypes.func,
  companiesOptions: PropTypes.array,
  usersOptions: PropTypes.array,
};