import React, { useState, useEffect } from "react";
import { Div, Text, Row, ModuleContainer } from "../../styles/Common";
import { useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { Table } from "../../components/Table";
import { Skeleton } from "../../components/Skeleton";
import { Card } from "../../components/Card";
import Checkbox from "../../components/Checkbox";
import { CloseIcon, SendIcon } from "../../assets/icons";
import { ModalPromoterQuestions } from "./components/ModalPromoterQuestions";
import useGetQuestionsByProject from "./hooks/useGetQuestionsByProject";
import { ModalAnswerQuestion } from "./components/ModalAnswerQuestion";
import useDelegateQuestion from "./hooks/useDelegateQuestion";
import toast from "react-hot-toast";
import { getLabelFromValue } from "../../utilities/helpers";
import { ProjectQuestionStatus } from "../../utilities/generalOptions";
//import { useNavigate } from "react-router-dom";
export const ManagerQuestions = () => {
  const { t } = useTranslation("managerQuestions");
  //const navigate = useNavigate();

  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("projectId");

  const delegateQuestion = useDelegateQuestion();

  const [selectedQuestion, setSelectedQuestion] = useState({});

  const getQuestionsByProject = useGetQuestionsByProject(projectId);
  const { data, isSuccess, isLoading } = getQuestionsByProject;
  const [questionsList, setQuestionsList] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setQuestionsList(
        data?.data?.data.map((item) => ({
          ...item,
          status: item?.status
            ? getLabelFromValue(item.status, ProjectQuestionStatus)
            : "pending",
        }))
      );
    }
  }, [data, isSuccess]);
  const [showModalSuccessDerived, setShowModalSuccessDerived] = useState(false);
  const [showModalAnswer, setShowModalAnswer] = useState(false);

  const handleDerive = (row) => {
    delegateQuestion.reset();
    delegateQuestion.mutate(
      {
        projectId: projectId,
        questionId: row?.questionId,
      },
      {
        onSuccess: () => {
          handleCloseModal();
          setSelectedQuestion(row);
          setShowModalSuccessDerived(true);
        },
        onError: (err) => {
          toast.error(
            err.response?.data?.result?.errorMessage?.message ||
              err.response?.data?.result?.code
          );
        },
      }
    );
  };

  const tableColumns = [
    {
      title: t("nameCompany"),
      dataIndex: "companyName",
      key: "companyName",
      render: (value) => (
        <Row>
          <Checkbox
            label={value}
            colorLabel={theme.colors.gray500}
            onClick={(e) => e.stopPropagation()}
          />
        </Row>
      ),
    },
    {
      title: t("nameInvestor"),
      dataIndex: "investorName",
      key: "investorName",
    },
    {
      title: t("theme"),
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: t("question"),
      dataIndex: "question",
      key: "question",
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <Div
          width="auto"
          height="36px"
          radius="20px"
          p="10px"
          background={stateColors(value).backgroundColor}
          style={{ color: stateColors(value).textColor }}
          align="center"
          justify="center"
        >
          {t(value)}
        </Div>
      ),
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      render: (_, row) => (
        <Div justify="space-between" style={{ cursor: "pointer" }}>
          <Tooltip
            title={t("derive")}
            color={theme.colors.white}
            placement="topRight"
            overlayInnerStyle={{
              color: theme.colors.gray300,
              fontFamily: theme.fonts.family,
              fontSize: theme.fonts.size.sm,
            }}
          >
            <SendIcon
              fill={theme.colors.green}
              onClick={(e) => {
                e.stopPropagation();
                handleDerive(row);
              }}
            />
          </Tooltip>
          <CloseIcon fill={theme.colors.green} />
        </Div>
      ),
    },
  ];

  const handleCloseModal = () => {
    setSelectedQuestion({});
    setShowModalSuccessDerived(false);
    setShowModalAnswer(false);
  };

  const stateColors = (state) => {
    switch (state) {
      case "pending":
        return {
          textColor: theme.colors.yellow700,
          backgroundColor: theme.colors.yellow100,
        };
      case "derived":
        return {
          textColor: theme.colors.blue400,
          backgroundColor: theme.colors.blue200,
        };
      case "answered":
        return {
          textColor: theme.colors.green500,
          backgroundColor: theme.colors.greenLight,
        };

      default:
        return {
          textColor: theme.colors.black,
          backgroundColor: theme.colors.gray100,
        };
    }
  };

  return (
    <ModuleContainer padding="35px 50px 40px 50px">
      <ModalPromoterQuestions
        showModal={showModalSuccessDerived}
        handleCloseModal={handleCloseModal}
      />
      <ModalAnswerQuestion
        showModal={showModalAnswer}
        handleCloseModal={handleCloseModal}
        selectedQuestion={selectedQuestion}
        handleDerive={handleDerive}
        isLoading={delegateQuestion.isLoading}
        projectId={projectId}
      />
      <Card
        padding={"22px 29.5px 30px 29px"}
        height="max-content"
        width={"100%"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Div width="100%" align="center" justify="space-between" m="0 0 51px 0">
          <Text
            color={theme.colors.blue}
            size={theme.fonts.size.h5}
            weight={theme.fonts.weight.medium}
          >
            {t("manageQuestions")}
          </Text>
          <Div align="center" gap="16px">
            <Checkbox
              label={t("frequentlyAskedQuestion")}
              colorLabel={theme.colors.green}
            />
          </Div>
        </Div>
        <Div>
          <Table
            data={questionsList}
            shadow={"0px"}
            padding={"0px"}
            columns={tableColumns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setSelectedQuestion(record);
                setShowModalAnswer(true);
              },
              style: { cursor: "pointer" },
            })}
            locale={{
              emptyText: isLoading ? (
                <Div justify="center" gap="16px">
                  {[...Array(9)].map((item, idx) => (
                    <Skeleton
                      key={idx}
                      title={false}
                      paragraph={{
                        rows: 10,
                        width: "100%",
                      }}
                    />
                  ))}
                </Div>
              ) : (
                "No Data"
              ),
            }}
          />
        </Div>
      </Card>
    </ModuleContainer>
  );
};

ManagerQuestions.propTypes = {};
