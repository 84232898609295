import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Card, WrapperIcon, WrapperTitle } from "./styles";
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import userAdd from "../../assets/icons/User-add.svg"
import document from "../../assets/icons/Document.svg"
import link from "../../assets/icons/Link.svg"
import upload from "../../assets/icons/upload-green.svg"
import { DrawerLinks } from "./components/DrawerLinks";
import useSaveLinks from "./hooks/useSaveLinks";
import { DrawerDocuments } from "./components/DrawerDocuments";
import useSaveDocuments from "./hooks/useSaveDocuments";
import { InviteCollaboratorModal } from "./components/inviteCollaboratorModal";
import { SendInvitationModal } from "./components/sendInvitationModal";
import { Tooltip } from 'antd';

export const Documents = () => {
  const { t } = useTranslation(["documents", "common", "newProject"]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const projectName = new URLSearchParams(search).get("projectName");
  const projectId = new URLSearchParams(search).get("projectId");
  const saveLinks = useSaveLinks();
  const saveDocs = useSaveDocuments();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalSendInvitation, setShowModalSendInvitation] = useState(false);
  const [openDrawerMassive, setOpenDrawerMassive] = useState(false);
  const [categoryDoc, setCategoryDoc] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [linksInfo, setLinksInfo] = useState([
    { url: "", description: "" }
  ]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowModalSendInvitation = () => {
    handleCloseModal();
    setShowModalSendInvitation(true);
  };

  const handleCloseModalSendInvitation = () => setShowModalSendInvitation(false);

  const [docList, setDocList] = useState([]);

  const maxSize = 500000000;
  const handleUploadDocument = (info) => {
    const { fileList } = info;
    const newDocList = [...docList];

    fileList.forEach((file) => {
      if (file?.type && file?.size) {
        if (file.type !== "application/x-msdownload" && file.size < maxSize) {
          const exists = newDocList.some(
            (doc) => doc.name === file.name && doc.size === file.size
          );
          if (!exists) {
            const newDocument = {
              name: file.name,
              doc: file,
              size: file.size,
            };
            newDocList.push(newDocument);
          }
        } else {
          toast.error(t("newProject:invalidDocument"));
        }
      }
    });

    setDocList(newDocList);
  };


  const handleLink = (category, upType) => {
    setOpenDrawer(true)
    setCategoryDoc(category)
    setUploadType(upType)
  };

  const handleDocuments = (id) => {
    if (id === 'plants') {
      navigate(`/projects/documents/plants?projectName=${projectName}&projectId=${projectId}`)
    }
    if (id === 'corporation') {
      navigate(`/projects/documents/corporate-documents?projectName=${projectName}&projectId=${projectId}`)
    }
    if (id === 'due') {
      navigate(`/projects/documents/due-diligences?projectName=${projectName}&projectId=${projectId}`)
    }
  }

  const cardInfo = [
    { title: t("plantsDocumentation"), message: t("plantsMessage"), onClickLink: () => handleLink(2, 1), onClickDoc: () => handleDocuments('plants') },
    { title: t("corporateDocuments"), message: t("corporateMessage"), onClickLink: () => handleLink(3, 1), onClickDoc: () => handleDocuments('corporation') },
    { title: t("dueDiligences"), message: t("dueMessage"), onClickLink: () => handleLink(4, 1), onClickDoc: () => handleDocuments('due') },
  ];

  const handleAddLink = () => {
    setLinksInfo([
      ...linksInfo,
      { url: "", description: "" }
    ])
  };

  const handleSaveDocs = () => {
    const payload = {
      description: "",
      projectId: projectId,
      uploadtype: 2,
      category: 5,
      documents: [...docList]
    }

    if (docList.length > 0) {
      saveDocs.reset();
      saveDocs.mutate(
        { ...payload },
        {
          onSuccess: () => {
            toast.success(t("documentsSuccess"))
            setOpenDrawerMassive(false)
            setDocList([])
          },
          onError: (err) => {
            toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
              "No ha sido posible guardar los documentos, por favor intenta nuevamente";
          },
        }
      );
    }
  };

  const handleDelete = (index) => {
    const updatedLinks = linksInfo.filter((ele, indx) => indx !== index)

    setLinksInfo(updatedLinks)
  };

  const handleChange = (event, index) => {
    const { id, value } = event.target;
    const newLinks = [...linksInfo];

    newLinks[index][id] = value;
    setLinksInfo(newLinks)
  };

  const handleClose = () => {
    setOpenDrawer(false)
    setOpenDrawerMassive(false)
    setCategoryDoc(null)
    setUploadType(null)
    setLinksInfo([{ url: "", description: "" }])
    queryClient.invalidateQueries(["documentsList", projectId, uploadType || 2, categoryDoc || 5])
  };

  const handleRemoveDocument = (index) => {
    const newDocs = docList.filter((ele, indx) => index !== indx)
    setDocList(newDocs)
  }

  const handleSave = () => {
    const filteredLinks = linksInfo.filter(objeto =>
      Object.values(objeto).some(valor => valor.trim() !== '')
    );

    const isValid = filteredLinks.every(obj =>
      Object.values(obj).every(valor => valor)
    );

    const payload = linksInfo.map((linkInfo) => ({
      ...linkInfo,
      projectId: projectId,
      uploadtype: uploadType,
      category: categoryDoc
    }));

    if (!isValid) {
      toast.error(t("errorFields"))
    } else {
      saveLinks.reset();
      saveLinks.mutate(
        [...payload],
        {
          onSuccess: () => {
            toast.success(t("documentsSuccess"))
            setCategoryDoc(null)
            setUploadType(null)
            setOpenDrawer(false)
            setLinksInfo([{ url: "", description: "" }])
          },
          onError: (err) => {
            toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
              "No ha sido posible guardar los documentos, por favor intenta nuevamente";
          },
        }
      );
    }
  }

  return (
    <ModuleContainer direction="column" padding="31px 69px 56px 32px">
      <DrawerLinks
        openDrawer={openDrawer}
        handleClose={handleClose}
        linksInfo={linksInfo}
        link={link}
        handleDelete={handleDelete}
        handleAddLink={handleAddLink}
        handleChange={handleChange}
        handleSave={handleSave}
        projectId={projectId}
        setLinksInfo={setLinksInfo}
        categoryDoc={categoryDoc}
        uploadType={uploadType}
      />
      <DrawerDocuments
        openDrawer={openDrawerMassive}
        handleClose={handleClose}
        handleUploadDocument={handleUploadDocument}
        docList={docList}
        handleSaveDocs={handleSaveDocs}
        saveDocs={saveDocs}
        handleRemoveDocument={handleRemoveDocument}
        projectId={projectId}
        setDocList={setDocList}
      />
      <WrapperTitle>
        <Div width="66%" height="100%" direction="column" justify="space-between">
          <Text
            color={theme.colors.white}
            size={theme.fonts.size.h6}
            weight={theme.fonts.weight.medium}
          >
            {t("title")}
          </Text>
          <Text color={theme.colors.white}>{t("message")}</Text>
        </Div>
        <Div width="34%" justify="flex-end">
          <Button
            variant={"outlined"}
            icon={<img alt="add" src={userAdd} />}
            width={"212px"}
            onClick={handleShowModal}
          >
            {t("inviteCollaborator")}
          </Button>
        </Div>
      </WrapperTitle>
      <Div m="40px 0 43px 0" width="100%" justify="space-between">
        {cardInfo.map((ele, indx) => (
          <Card key={indx}>
            <Div m="0 0 20px 0">
              <WrapperIcon>
                <img alt="document" src={document} />
              </WrapperIcon>
              <Text
                color={theme.colors.blue}
                size={theme.fonts.size.h6}
                weight={theme.fonts.weight.medium}
                style={{ alignContent: "center" }}
              >
                {t(`${ele.title}`)}
              </Text>
            </Div>
            <Text color={theme.colors.gray500}>
              {t(`${ele.message}`)}
            </Text>
            <Div
              direction="column"
              gap="11.5px"
              m="31px 0 0 0"
              align="center"
            >
              <Button
                width="233px"
                onClick={ele.onClickDoc}
              >
                {t("uploadDocuments")}
              </Button>
              <Div align="center" gap="6px">
                <hr style={{ border: `1px solid ${theme.colors.gray100}`, width: "104px" }} />
                <Text
                  weight={theme.fonts.weight.medium}
                  color={theme.colors.blue}
                >
                  o
                </Text>
                <hr style={{ border: `1px solid ${theme.colors.gray100}`, width: "104px" }} />
              </Div>
              <Button
                width="233px"
                variant={"outlined"}
                icon={<img alt="link" src={link} />}
                onClick={ele.onClickLink}
              >
                {t("uploadLink")}
              </Button>
            </Div>
          </Card>
        ))}
      </Div>
      <Div width="100%" justify="center" m="0 0 29px 0">
        <Tooltip 
          title={t("massiveUploadMessage")} 
          color={theme.colors.white} 
          overlayInnerStyle={{
            color: theme.colors.gray200,
            fontSize: theme.fonts.size.sm,
            fontFamily: theme.fonts.family,
            textAlign: 'center'
          }}
        >
          <Button
            width="334px"
            variant={"outlined"}
            icon={<img alt="upload" src={upload} />}
            onClick={() => setOpenDrawerMassive(true)}
          >
            {t("uploadMassive")}
          </Button>
        </Tooltip>
      </Div>
      <Text
        color={theme.colors.gray300}
        size={theme.fonts.size.sm}
        style={{ textAlign: "center" }}
      >
        {t("common:poweredBy")}
      </Text>
      {showModal && (
        <InviteCollaboratorModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleShowModalSendInvitation={handleShowModalSendInvitation}
          projectId={projectId}
        />
      )}
      {showModalSendInvitation && (
        <SendInvitationModal
          showModal={showModalSendInvitation}
          handleCloseModalSendInvitation={handleCloseModalSendInvitation}
        />
      )}
    </ModuleContainer>
  )
}

