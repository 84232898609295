import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useSignTermsAndConditions = () => {
  const mutation = useMutation((id) => {
    return axiosClient.post(`/investors-notification/sign-terms-and-conditions`, 
      { investorInvitationId: id }
    );
  });
  return mutation;
};
export default useSignTermsAndConditions;