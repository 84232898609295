import styled from "styled-components";
import backImage from "../../assets/images/backgroundLoginLink.png";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: url(${backImage}) 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: cover;
  justify-content: center;
`;
