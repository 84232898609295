export const formatCurrency = (value, locale) => {
  if (!value) return value;

  const strValue = String(value);

  let thousandsSeparator, decimalSeparator;
  if (locale === "es") {
    thousandsSeparator = ".";
    decimalSeparator = ",";
  } else {
    thousandsSeparator = ",";
    decimalSeparator = ".";
  }

  const cleanedValue = strValue.replace(new RegExp(`[^0-9${decimalSeparator}]`, "g"), "");

  const [integerPart, decimalPart] = cleanedValue.split(decimalSeparator);

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

  return decimalPart !== undefined
    ? `${formattedIntegerPart}${decimalSeparator}${decimalPart.slice(0, 2)}`
    : formattedIntegerPart;
};

export const handleChangeFormatCurrency = (event, form, setForm) => {
  const { id, value } = event.target;
  const newFormInfo = { ...form };

  newFormInfo[id] = value;
  setForm(newFormInfo);
};

export const parseCurrency = (value, locale) => {
  if (value === "" || value === null || value === "-") {
    return "";
  }

  const strValue = String(value);

  const cleanedValue = locale === "es" 
    ? strValue.replace(/\./g, "").replace(",", ".")   
    : strValue.replace(/,/g, "");                  

  return parseFloat(cleanedValue);
};