import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../components/Modal";
import { Div, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../components/Select2";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";

export const ModalChangeOwner = ({
  showModal,
  handleCloseModal,
  companiesOptions,
  usersOptions,
  companyId,
  ownerId,
  handleSelectCompany,
  handleSelectOwner,
  projectName,
  handleConfirmChangeOwner,
  loading,
}) => {
  const { t } = useTranslation("projectTableManager");

  const isValid = companyId !== null && ownerId !== null;

  return (
    <Modal open={showModal} onCancel={handleCloseModal} radius={"20px"} width={"478px"}>
      <Modal.Header 
        title={t("changeOwner")} 
        weightTitle={theme.fonts.weight.medium}
        sizeTitle={theme.fonts.size.h4}
        align={"center"}
      />
      <Modal.Body
        margin="28px 0 0px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Div direction="column" width="100%">
          <Div width="100%" m="0 0 15px 0">
            <Text color={theme.colors.gray500} align="center">
              {t("message")}{" "}
              <span style={{ color: theme.colors.green }}>
                {projectName}
              </span>
              {`. ${t("message2")}`}
            </Text>
          </Div>
          <Div direction="column" gap="16px">
            <Select 
              label={t("selectCompany")}
              placeholder={t("select")}
              options={companiesOptions}
              value={companyId}
              onChange={handleSelectCompany}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              width={"100%"} 
            />
            <Select 
              label={t("selectOwner")}
              placeholder={t("select")}
              options={usersOptions}
              value={ownerId}
              onChange={handleSelectOwner}
              disabled={companyId === null}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              width={"100%"} 
            />
          </Div>
        </Div>
      </Modal.Body>
      <Div m="32px 0 0 0" gap="24px" direction="column" justify="center" align="center">
        <Button 
          onClick={handleConfirmChangeOwner} 
          disabled={!isValid}
          loading={loading}
        >
            {t("changeOwner")}
        </Button>
        <Link onClick={handleCloseModal}>{t("cancel")}</Link>
      </Div>
    </Modal>
  )
};

ModalChangeOwner.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  companiesOptions: PropTypes.array,
  usersOptions: PropTypes.array,
  companyId: PropTypes.string,
  ownerId: PropTypes.string,
  handleSelectCompany: PropTypes.func,
  handleSelectOwner: PropTypes.func,
  projectName: PropTypes.string,
  handleConfirmChangeOwner: PropTypes.func,
  loading: PropTypes.bool,
};