import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetContacts = (companyId) => {
  const data = useQuery(
    ["getMotherCompanies", companyId],
    () =>
      axiosClient.get(`scraper/information/companies/${companyId}/contacts`),
    { enabled: companyId !== null && companyId !== undefined }
  );

  return data;
};
export default useGetContacts;
