import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { Row, Text } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";

export const Annex3 = ({
  strategyData,
  handleChangeStrategy,
  tabActiveKey,
}) => {
  const { t } = useTranslation("teaserDocumentation");
  return (
    <>
      <Row m="24.5px 0 18px 0">
        <Text weight={theme.fonts.weight.medium} color={theme.colors.blue}>
          {t("debtRatios")}
        </Text>
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        <Input
          label={t("debtToCapitalRatio")}
          id="debtToCapitalRatio"
          type="number"
          width="31.5%"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].anexoThree.debtToCapitalRatio
          }
          onChange={(event) => handleChangeStrategy(event, "anexoThree")}
        />
        <Input
          label={t("debtToMwRatio")}
          id="debtToMwRatio"
          type="number"
          width="31.5%"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].anexoThree.debtToMwRatio
          }
          onChange={(event) => handleChangeStrategy(event, "anexoThree")}
        />
      </Row>
      <Row m=" 0 0 25px 0">
        <Input
          label={t("underlyingLoanSensitivities")}
          id="underlyingLoanSensitivities"
          width="100%"
          height="138px"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].anexoThree
              .underlyingLoanSensitivities
          }
          onChange={(event) => handleChangeStrategy(event, "anexoThree")}
        />
      </Row>
    </>
  );
};

Annex3.propTypes = {
  strategyData: PropTypes.array,
  handleChangeStrategy: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
