import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeleteDocument = () => {
  const mutation = useMutation((documentId) => {
    return axiosClient.delete(`/documentation/${documentId}`);
  });
  return mutation;
};
export default useDeleteDocument;
