import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import search from "../../../assets/icons/Search.svg";
import { Table } from "../../../components/Table";
import { Skeleton } from "../../../components/Skeleton";
import { dateFormat } from "../../../utilities/helpers";
//import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import useGetAllUsers from "../hooks/useGetAllUsers";
import useDebounce from "../../../core/hooks/useDebounce";
import { DeleteIcon } from "../../../assets/icons";
import ModalDelete from "./ModalDelete";
import { ModalInvitePromoter } from "./ModalInvitePromoter";
export const Promoter = () => {
  const { t } = useTranslation("users");
  // const navigate = useNavigate();
  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useDebounce(searchTerm, 600);
  const [promoterList, setPromoterList] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalInvite, setShowModalInvite] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const [payloadOptions, setPayloadOptions] = useState({
    limit: pages.pageSize,
    offset: pages.pageNumber,
    name: searchValue,
    userRole: "Developer",
  });

  useEffect(() => {
    setPayloadOptions({
      limit: pages.pageSize,
      offset: pages.pageNumber,
      name: searchValue,
      userRole: "Developer",
    });
  }, [pages, searchValue]);

  const getAllUsers = useGetAllUsers(payloadOptions);
  const { data, isLoading } = getAllUsers;

  useEffect(() => {
    setTotalItems(data?.data?.total);
    setPromoterList(
      data?.data?.data.map((ele, indx) => ({
        key: indx,
        createdAt: ele?.createdAt,
        name: ele?.name,
        email: ele?.email,
        state: ele?.isActive,
        actions: [
          {
            id: ele.id,
            name: ele.name,
            tooltip: t("promoterComponent.deletePromoter"),
            icon: (
              <DeleteIcon
                width="32px"
                height="32px"
                fill={theme.colors.green}
                onClick={() => {
                  setSelectedUser({
                    name: ele.name,
                    id: ele.id,
                    isActive: ele?.isActive,
                  });
                  setShowModalDelete(true);
                }}
              />
            ),
          },
        ],
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setPages({
      ...pages,
      pageNumber: 0,
    });
    setSearchTerm(value);
  };

  const handleChangeTable = (pagination) => {
    const newPayload = { ...payloadOptions };
    newPayload.offset = pagination.current - 1;
    setPayloadOptions(newPayload);
    setPages({
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
  };

  const handleCloseModal = () => {
    setSelectedUser({});
    setShowModalDelete(false);
    setShowModalInvite(false);
  };

  const tableColumns = [
    {
      title: t("date"),
      width: "14.1%",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => dateFormat(text),
    },
    {
      title: t("name"),
      width: "30.5%",
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("email"),
      width: "29%",
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("promoterComponent.state"),
      width: "15.7%",
      dataIndex: "state",
      key: "state",
      render: (state) =>
        state
          ? t("promoterComponent.active")
          : t("promoterComponent.whitoutRegister"),
    },
    {
      title: t("actions"),
      width: "10.7%",
      dataIndex: "actions",
      key: "actions",
      render: (actions) => (
        <Div justify="center" style={{ cursor: "pointer" }}>
          {actions.map((action, i) => (
            <Tooltip
              key={i}
              title={action.tooltip}
              color={theme.colors.white}
              placement="topRight"
              overlayInnerStyle={{
                color: theme.colors.gray300,
                fontFamily: theme.fonts.family,
                fontSize: theme.fonts.size.sm,
              }}
            >
              {action.icon}
            </Tooltip>
          ))}
        </Div>
      ),
    },
  ];

  return (
    <Div direction="column">
      <ModalDelete
        showModal={showModalDelete}
        handleCloseModal={handleCloseModal}
        selectedUser={selectedUser}
      />
      <ModalInvitePromoter
        showModal={showModalInvite}
        handleClose={handleCloseModal}
      />
      <Div
        width="100%"
        align="center"
        justify="space-between"
        m="34px 0 32px 0"
      >
        <Text
          color={theme.colors.gray500}
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.semibold}
        >
          {t("promoter")}
        </Text>
        <Div align="center" gap="16px">
          <Input
            sizeLabel={theme.fonts.size.xs}
            suffix={
              <img alt="search" src={search} style={{ cursor: "pointer" }} />
            }
            width={"332px"}
            placeholder={`${t("search")}...`}
            style={{ borderRadius: "23px", marginTop: "-10px" }}
            mt={"-8px"}
            onChange={handleChangeSearch}
            value={searchTerm}
          />
          <Button width={"169px"} onClick={() => setShowModalInvite(true)}>
            {t("promoterComponent.invitePromoter")}
          </Button>
        </Div>
      </Div>
      <Table
        columns={tableColumns}
        data={promoterList}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={{
          defaultCurrent: 0,
          ...pages,
          total: totalItems,
          position: ["bottomRight"],
          showSizeChanger: false,
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Div justify="center" gap="16px">
              {[...Array(9)].map((item, idx) => (
                <Skeleton
                  key={idx}
                  title={false}
                  paragraph={{
                    rows: 10,
                    width: "100%",
                  }}
                  loading
                />
              ))}
            </Div>
          ) : (
            "No Data"
          ),
        }}
      />
    </Div>
  );
};

Promoter.propTypes = {};
